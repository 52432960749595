<template>
  <div>
    <Head :title="`${form.admin.name}`" />
    <div class="flex justify-start max-w-3xl mb-8">
      <h1 class="text-3xl font-bold">
        <Link
          class="text-indigo-500 hover:text-indigo-600"
          :href="$routes.admin_admins()"
        >
          Admins
        </Link>
        <span class="font-medium text-indigo-400">/</span>
        {{ form.admin.name }}
      </h1>
    </div>
    <div class="max-w-3xl overflow-hidden bg-white rounded shadow">
      <admin-form v-model="form" @submit="submit">
        <div class="flex items-center px-8 py-4 bg-gray-100 border-t border-gray-200">
          <loading-button
            :loading="form.processing"
            class="ml-auto btn-indigo"
            type="submit"
          >
            Update Admin
          </loading-button>
        </div>
      </admin-form>
    </div>
  </div>
</template>

<script>
import { Head, Link } from '@inertiajs/vue3';
import Layout from '@/Layouts/Admin.vue';
import LoadingButton from '@/Shared/LoadingButton.vue';
import AdminForm from './Form.vue';

export default {
  components: {
    Head,
    Link,
    LoadingButton,
    AdminForm,
  },
  layout: Layout,
  props: {
    admin: {
      type: Object,
      required: true,
    }
  },
  remember: 'form',
  data() {
    return {
      form: this.$inertia.form({
        admin: this.admin,
        authenticity_token: document.querySelector('meta[name="csrf-token"]').content
      }),
    };
  },
  methods: {
    submit() {
      this.form.put(this.$routes.admin_admin(this.admin.id), {
        onSuccess: () => this.form.reset('password', 'password_confirmation'),
      });
    },
    destroy() {
      if (confirm('Are you sure you want to delete this admin?')) {
        this.$inertia.delete(this.$routes.admin_admin(this.admin.id));
      }
    },
  },
};
</script>
