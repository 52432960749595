<template>
  <div class="how-to-apply-section" :class="{'py-10': !isContentOnly}">
    <div class="container-user-sm">
      <div class="how-to-apply-info">
        <div v-if="!isContentOnly" class="mb-8 text-center title">
          <h3 class="text-primary-600  r:text-[18px] font-black leading-[1.53] tracking-[0.25px]" v-html="viewsJa.home.blocks.how_to_apply.title.other"></h3>
          <h2 class="r:text-[24px] font-black leading-[1.53] tracking-[0.25px]" v-html="viewsJa.home.blocks.how_to_apply.title.main_1"></h2>
        </div>
        <div class="how-to-apply-steps">
          <div class="how-to-apply-steps-info">
            <template v-for="(step, index) in viewsJa.home.blocks.how_to_apply.steps" :key="index">
              <div class="flex justify-between px-6 py-4 mb-1 step-info bg-neutral-gray-50 rounded-2xl">
                <div class="r:text-[16px] font-black leading-[1.53] tracking-[0.25px] step-title">
                  <div class="flex items-center">
                    <span class="inline-flex items-center justify-center w-8 h-8 mr-2 text-center text-white align-middle rounded-full bg-primary-500">{{ index }}</span>
                    <span class="block" v-html="step.title"></span>
                  </div>
                  <a id="open-quotation-modal-how-to-apply" data-testid="open-quotation-modal-how-to-apply" href="javascript:;" class="inline r:text-[14px] text-tw-blue-500 hover:opacity-70 transition r:tracking-[0.25px] leading-[1.57] underline font-normal" @click.prevent="openModal()">{{ step.open_modal }} </a>
                  <span class="block md:inline mt-2 r:text-[14px] font-normal leading-[1.53] tracking-[0.25px]" v-html="step.desc"></span>
                </div>
                <images :name=step.img class="r:w-[110px] ml-3.5"/>
              </div>
            </template>
          </div>
          <div class="pt-6 pb-8 text-center rounded-2xl time-apply bg-primary-50">
            <h2 class="r:text-[24px] font-black leading-[1.53] tracking-[0.25px] mb-2" v-html="viewsJa.home.blocks.how_to_apply.title.main_2"></h2>
            <images name="applyTime" class="mx-auto" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Layout from '@/Layouts/User.vue';
import Images from '@/Shared/Images.vue';

export default {
  components: {
    Images,
  },
  layout: Layout,
  props: {
    setQuotationOpen: {
      type: Function,
    },
    isContentOnly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      viewsJa: this.jaConfig.viewsJa,
      isQuotationOpen: false,
    }
  },
  methods: {
    openModal(localCareType) {
      this.$emit('open-modal', localCareType);
    }
  }
}
</script>
