<template>
  <div class="tab-content" :id="`tab-content-${id}`" v-show="isActive"><slot></slot></div>
</template>

<script>
import { ref, inject, watch, onBeforeMount } from 'vue';

export default {
  setup() {
    const index = ref(0);
    const isActive = ref(false);

    const tabs = inject("tabsprovider");

    watch(
      () => tabs.selectedIndex,
      () => {
        isActive.value = index.value === tabs.selectedIndex;
      }
    );

    onBeforeMount(() => {
      index.value = tabs.count;
      tabs.count++;
      isActive.value = index.value === tabs.selectedIndex;
    });
    return {index, isActive};
  },
  props: {
    id: { required: true },
    route: { type: String, default: null },
  },
}
</script>
