<template>
  <div class="pt-8 pb-10 bg-primary-500 insurance-simulator-section">
    <div class="mx-auto container-user-sm">
      <div class="insurance-simulator-info">
        <div class="flex items-center justify-center mb-6 text-center title">
          <h2 class="text-left r:text-[18px] font-bold leading-[1.53] tracking-[0.25px] text-white mr-3" v-html="viewsJa.home.blocks.insurance_simulator.title.main"></h2>
          <images name="humanFinger"/>
        </div>
        <div class="flex items-stretch justify-between -mx-1">
          <template v-for="(button, idx) in viewsJa.home.blocks.insurance_simulator.btn_simulator" :key="idx">
            <div class="w-1/2 mb-8 bg-white rounded-2xl mx-1 flex flex-col">
              <a href="javascript:;" :id="`open-quotation-modal-simulator-${idx}`" :data-testid="`open-quotation-modal-simulator-${idx}`" class="overflow-hidden rounded-2xl shadow-[0_2px_7px_0_rgba(0,0,0,0.20)] flex-1 flex flex-col hover:opacity-70 transition" @click.prevent="openModal(idx)">
                <div :id="`open-quotation-modal-simulator-${idx}-div-1`" class="flex flex-col items-center justify-between flex-1">
                  <div :id="`open-quotation-modal-simulator-${idx}-div-1-div`" class="flex flex-col items-center p-4">
                    <span :id="`open-quotation-modal-simulator-${idx}-div-1-span-1`" class="r:text-[16px] leading-[1.33] tracking-[0.25px] font-bold xs:flex flex-col items-center md:inline-block" v-html="button.title"></span>
                    <span :id="`open-quotation-modal-simulator-${idx}-div-1-span-2`" class="r:text-[16px] leading-[1.33] tracking-[0.25px]" v-html="button.desc"></span>
                  </div>
                  <images :id="`open-quotation-modal-simulator-${idx}-div-1-img`" :name="button.img" class="mx-auto mb-2" />
                </div>
                <div :id="`open-quotation-modal-simulator-${idx}-div-2`" class="py-4 btn-blue-gradient-horizontal r:min-h-[50px]">
                  <svg :id="`open-quotation-modal-simulator-${idx}-div-2-svg`" class="mx-auto" xmlns="http://www.w3.org/2000/svg" width="19" height="18" viewBox="0 0 19 18" fill="none">
                    <path :id="`open-quotation-modal-simulator-${idx}-div-2-path`" fill-rule="evenodd" clip-rule="evenodd" d="M6.57036 3.26646C6.97549 2.8911 7.60819 2.91524 7.98355 3.32036L13.2458 9.00001L7.98355 14.6796C7.60819 15.0848 6.97549 15.1089 6.57036 14.7335C6.16524 14.3582 6.1411 13.7255 6.51646 13.3204L10.5193 9.00001L6.51646 4.67965C6.1411 4.27452 6.16524 3.64182 6.57036 3.26646Z" fill="white"/>
                  </svg>
                </div>
              </a>
            </div>
          </template>
        </div>
        <!-- benefits -->
        <div class="flex items-center justify-center explanation-title mb-4">
          <svg class="mr-2" xmlns="http://www.w3.org/2000/svg" width="13" height="38" viewBox="0 0 13 38" fill="none">
            <path d="M1.5 0.5L11.5 37" stroke="#F1BA87" stroke-width="2"/>
          </svg>
          <h4 v-html="viewsJa.home.blocks.insurance_simulator.title.explanation" class="text-white text-center r:text-[14px] font-black leading-[1.53] tracking-[0.25px]"></h4>
          <svg class="ml-2" xmlns="http://www.w3.org/2000/svg" width="13" height="38" viewBox="0 0 13 38" fill="none">
            <path d="M11.5 1L1.5 37" stroke="#F1BA87" stroke-width="2"/>
          </svg>
        </div>
        <div class="benefits">
          <div class="benefit-block">
            <div class="flex flex-wrap items-stretch overflow-hidden benefit-infos rounded-2xl">
              <template v-for="(benefit, idx) in viewsJa.home.blocks.insurance_simulator.benefits" :key="idx">
                <div :class="[
                  'p-4 bg-white benefit w-1/3 border-primary-600',
                  (idx % 3 !== 0 ? 'border-r' : ''),
                  (idx > 0 && idx < 4 ? 'border-b' : '')
                ]">
                  <div class="text-center">
                    <p class="r:min-h-[48px] r:text-[12px] leading-[1.33] tracking-[0.25px] mb-1 font-black" v-html="benefit.title"></p>
                    <images :name="benefit.img" class="mx-auto r:h-[50px]" />
                  </div>
                </div>
              </template>
            </div>
          </div>
          <div class="text-center">
            <button id="open-quotation-modal-benefits" data-testid="open-quotation-modal-benefits" type="button"  class="cursor-pointer inline-flex items-center justify-center rounded-full btn-gradient btn-blue-gradient-horizontal r:text-[14px] py-2 px-2 xs:r:px-[18px] xs:py-2.5 font-medium text-white shadow-gray-1 w-full leading-[1.33] r:min-h-[65px] mt-6" @click.prevent="openModal()">
              <span id="open-quotation-modal-benefits-span" class="ml-2" v-html="viewsJa.quotations.modal.btn_open"/>
              <svg id="open-quotation-modal-benefits-svg" xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19" fill="none" class="ml-2">
                <path id="open-quotation-modal-benefits-path" fill-rule="evenodd" clip-rule="evenodd" d="M6.82036 3.76646C7.22549 3.3911 7.85819 3.41524 8.23355 3.82036L13.4958 9.50001L8.23355 15.1796C7.85819 15.5848 7.22549 15.6089 6.82036 15.2335C6.41524 14.8582 6.3911 14.2255 6.76646 13.8204L10.7693 9.50001L6.76646 5.17965C6.3911 4.77452 6.41524 4.14182 6.82036 3.76646Z" fill="white"/>
              </svg>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
:deep(.benefit-block) {
  position: relative;
  border-radius: 16px;
  ::before {
    content: '';
    position: absolute;
    bottom: -16px;
    left: 50%;
    transform: translateX(-50%);
    width: 0;
    height: 0;
    border-left: 22px solid transparent;
    border-right: 22px solid transparent;
    border-top: 16px solid white;
  }
}
</style>


<script>
import Layout from '@/Layouts/User.vue';
import SimulatorBox from '@/Pages/Home/Blocks/SimulatorBox.vue';
import Images from '@/Shared/Images.vue';
import Modal from '@/Shared/Modal.vue';

export default {
  components: {
    Images,
    Modal,
    SimulatorBox,
  },
  layout: Layout,
  props: {
    setQuotationOpen: {
      type: Function,
    }
  },
  data() {
    return {
      viewsJa: this.jaConfig.viewsJa,
      isQuotationOpen: false,
    }
  },
  methods: {
    openModal(localCareType) {
      this.$emit('open-modal', localCareType);
    }
  }
}
</script>
