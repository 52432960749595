<template>
  <div class="sns-wrap">
    <a :id="`social-${name}`" :data-testid="`social-${name}`" class="transition hover:opacity-70" href="javascript:;" @click.prevent="shareSNS(name)">
      <images :name="name" :hasSrcSet="true" :id="`social-${name}-img`" />
    </a>
  </div>
</template>

<script>
import Images from '@/Shared/Images.vue';
export default {
  components: {
    Images
  },
  props: {
    name  : {
      type: String,
      required: true
    },
    shareUrl: {
      type: String,
      required: true
    },
    twitterTitle: {
      type: String,
      required: false
    },
    twitterHastag: {
      type: String,
      required: false
    },
  },
  data () {
    return {
      shareInfo: {
        facebook: {
          url: `https://www.facebook.com/sharer/sharer.php?u=${this.shareUrl}`,
          name: 'facebook-share-dialog'
        },
        twitter: {
          url: `https://twitter.com/share?text=${this.twitterTitle}&hashtags=${this.twitterHastag}&url=${this.shareUrl}`,
          name: 'twitter-share-dialog'
        },
        line: {
          url: `https://social-plugins.line.me/lineit/share?url=${this.shareUrl}`,
          name: 'line-share-dialog'
        }
      }
    }
  },
  methods: {
    shareSNS (name) {
      const popupWidth = 670,
            popupHeight = 745;
      let topPos = (window.innerHeight - popupHeight) / 2,
          leftPos = (window.innerWidth - popupWidth) / 2,
          fixedResolution = 'width=' + popupWidth + ',height=' + popupHeight + ',top=' + topPos + ',left=' + leftPos;

      window.open(this.shareInfo[name].url, this.shareInfo[name].name, fixedResolution);
    },
  }
}
</script>
