<template>
  <div class="not-have-application bg-neutral-gray-50">
    <div class="r:max-w-[672px] mx-auto pt-8 pb-6">
      <div class="px-6 mb-6 title-section">
        <span class="text-primary-600 r:text-[18px] leading-[1.53] r:tracking-[0.25px] mb-1">{{ viewsJa.credit_cards.not_have_application.sub_title }}</span>
        <h1 class="r:text-[34px] font-black leading-[1.53] r:tracking-[0.25px]" v-html="viewsJa.credit_cards.not_have_application.title"></h1>
      </div>
      <div class="description-section">
        <p class="r:text-[16px] leading-[1.53] r:tracking-[0.25px] px-6 mb-4">{{ viewsJa.credit_cards.not_have_application.description }}</p>
      </div>
      <div class="pt-16 mt-16">
        <div class="flex-col items-center form-group text-centerflex">
          <a :href="$routes.root()" class="w-56 flex items-center justify-center rounded-full btn-gradient btn-primary-gradient-horizontal r:text-[14px] xs:r:text-[16px] py-2 px-2 r:xs:px-[18px] xs:pt-4 xs:pb-3.5 mx-auto font-medium text-white shadow-gray-1 leading-[1.4] r:min-h-[56px]" id="back-top-page" data-testid="back-top-page">
            <span id="back-top-page-span" class="mr-2">{{ viewsJa.credit_cards.not_have_application.btn.back_to_top }}</span>
            <svg id="back-top-page-svg" xmlns="http://www.w3.org/2000/svg" width="18" height="19" viewBox="0 0 18 19" fill="none">
              <path id="back-top-page-path" fill-rule="evenodd" clip-rule="evenodd" d="M6.32036 3.76646C6.72549 3.3911 7.35819 3.41524 7.73355 3.82036L12.9958 9.50001L7.73355 15.1796C7.35819 15.5848 6.72549 15.6089 6.32036 15.2335C5.91524 14.8582 5.8911 14.2255 6.26646 13.8204L10.2693 9.50001L6.26646 5.17965C5.8911 4.77452 5.91524 4.14182 6.32036 3.76646Z" :fill="'#D3DDE8'"/>
            </svg>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Layout from '@/Layouts/User.vue';

export default {
  components: {
  },
  layout: (h, page) => h(Layout, { footerClass: 'bg-neutral-gray-50 pt-14' }, () => page),
  props: {
  },
  setup () {
  },
  data () {
    return {
      viewsJa: this.jaConfig.viewsJa,
    }
  },
}
</script>
