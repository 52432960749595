<template>
  <Loading :loading="isLoading"/>
  <div class="payment-information bg-neutral-gray-50">
    <div class="r:max-w-[672px] mx-auto pt-8 pb-6">
      <contract-progress :currentStep="5" class="px-6 mb-6" />
      <div class="px-6 mb-24 title-section">
        <span class="text-primary-600 r:text-[18px] leading-[1.53] r:tracking-[0.25px] mb-1">{{ viewsJa.contracts.payment_information.sub_title }}</span>
        <h1 class="r:text-[34px] font-black leading-[1.53] r:tracking-[0.25px]" v-html="viewsJa.contracts.payment_information.title"></h1>
      </div>
      <div class="card-info-section">
        <div class="px-6 pb-4 card-title">
          <h2 class="r:text-[24px] leading-[1.53] r:tracking-[0.25px]">{{ viewsJa.contracts.payment_information.card_info.title }}</h2>
          <h3 v-if="cardType == 'me'" class="r:text-[16px] leading-[1.53] r:tracking-[0.25px]">{{ viewsJa.contracts.payment_information.card_info.sub_title }}</h3>
        </div>
        <form class="form-contract-payment" id="form-contract-payment" method="post">
          <input type="hidden" name="authenticity_token" :value="authenToken" autocomplete="off">
          <div class="px-6 py-8 bg-white border-b border-tw-black-200">
            <div class="form-control-group" v-if="cardList.length">
              <h3 class="r:text-[18px] leading-[1.53] r:tracking-[0.25px] text-primary-600 mb-4">{{ viewsJa.contracts.payment_information.card_info.register_with_card }}</h3>
              <div class="form-group">
                <template v-for="(card, idx) in cardList" :key="card.cardno">
                  <radio-input
                    v-model="form.cardRegistered"
                    :id="`user_card_registered_${idx}`"
                    name="user[card_registered]"
                    :class="`card-selection radio-primary ${v$.form.cardRegistered.$error ? 'form-invalid' : ''} ${v$.form.cardRegistered.$dirty ? 'form-dirty' : ''}`"
                    :radioValue="card.type"
                    :hasCustomLabel="true"
                    @change="handleSetTouch('cardRegistered'); handleActiveCard(card)"
                  >
                    <label :for="`user_card_registered_${idx}`" class="mb-4">
                      <strong class="block mb-1 r:text-[16px] font-black leading-[1.53] r:tracking-[0.25px]">{{ card.masked_number }}</strong>
                      <span class="flex items-center mb-1">
                        <span class="r:text-[16px] leading-[1.53] r:tracking-[0.25px]">{{ viewsJa.contracts.payment_information.card_info.effect_date }}</span>
                        <span class="r:text-[16px] leading-[1.53] r:tracking-[0.25px] ml-1">{{ `${card.expiration.month}/${card.expiration.year}` }}</span>
                      </span>
                      <span class="block mb-2 r:text-[16px] leading-[1.53] r:tracking-[0.25px] ml-1 uppercase">{{ card.holder_name }}</span>
                      <span class="flex justify-end">
                        <images :name="`${card.brand.toLowerCase()}Card`" class="r:w-[86px] r:h-[66px]" :hasSrcSet="true" />
                      </span>
                    </label>
                  </radio-input>
                </template>
                <radio-input
                  v-model="form.cardRegistered"
                  id="user_card_registered_other"
                  name="user[card_registered]"
                  :class="`card-selection radio-primary ${v$.form.cardRegistered.$error ? 'form-invalid' : ''} ${v$.form.cardRegistered.$dirty ? 'form-dirty' : ''}`"
                  radioValue="new"
                  :hasCustomLabel="true"
                  @change="handleSetTouch('cardRegistered'); handleActiveCard()"
                >
                  <label for="user_card_registered_other" class="">
                    <strong class="block r:text-[16px] font-black leading-[1.53] r:tracking-[0.25px]">{{ viewsJa.contracts.payment_information.card_info.card_other }}</strong>
                  </label>
                </radio-input>
              </div>
            </div>
            <div
              v-if="!cardList.length || form.cardRegistered == 'new'"
              class="form-control-group"
              :class="cardList.length ? 'mt-4 pl-5 border-l-4 border-neutral-gray-200' : ''"
            >
              <!-- card number -->
              <div class="mb-4 form-group">
                <label class="flex items-center mb-2">
                  <span class="r:text-[16px] leading-[1.53] r:tracking-[0.25px]">{{ modelsJa.activerecord.attributes.contracts.card_number }}</span>
                  <span class="r:text-[10px] r:tracking-[0.25px] leading-[1.53] text-tw-red-500 bg-tw-red-50 rounded px-1 mt-1 ml-2">{{ viewsJa.contracts.required }}</span>
                </label>
                <div class="flex mb-2 -mx-2">
                  <div class="w-full px-2">
                    <text-input
                      v-model="form.cardNumber"
                      class="w-full"
                      id="user_card_number"
                      name="user[card_number]"
                      :placeholder="viewsJa.contracts.payment_information.card_info.placeholders.card_number"
                      :customClass="`form-element px-3 py-2.5 r:min-h-[52px] ${v$.form.cardNumber.$error ? 'form-invalid' : ''} ${v$.form.cardNumber.$dirty ? 'form-dirty' : ''}`"
                      @change="handleSetTouch('cardNumber')"
                    />
                  </div>
                </div>
              </div>
              <!-- expired date -->
              <div class="mb-4 form-group">
                <label class="flex items-center mb-2">
                  <span class="r:text-[16px] leading-[1.53] r:tracking-[0.25px]">{{ modelsJa.activerecord.attributes.contracts.expired_date }}</span>
                  <span class="r:text-[10px] r:tracking-[0.25px] leading-[1.53] text-tw-red-500 bg-tw-red-50 rounded px-1 mt-1 ml-2">{{ viewsJa.contracts.required }}</span>
                </label>
                <div class="flex mb-2 -mx-0.5">
                  <div class="px-0.5">
                    <select-input
                      v-model="form.expiredMonth"
                      id="user_expired_month"
                      name="user[expired_month]"
                      class="w-full"
                      :customClass="`form-element r:min-w-[90px] px-3 py-2.5 r:min-h-[52px] ${v$.form.expiredMonth.$error || v$.form.expiredDate.$error ? 'form-invalid' : ''} ${v$.form.expiredMonth.$dirty ? 'form-dirty' : ''} ${!form.expiredMonth ? 'text-neutral-gray-300' : ''}`"
                      @change="handleSetTouch('expiredMonth'); handleUpdateExpiredDate('expiredMonth', 'expiredYear')"
                    >
                      <option value="">{{ viewsJa.contracts.payment_information.card_info.placeholders.expired_date.month }}</option>
                      <option :value="monthItem" v-for="monthItem of months" :key="monthItem">{{ monthItem }}</option>
                    </select-input>
                  </div>
                  <div class="px-0.5">
                    <select-input
                      v-model="form.expiredYear"
                      id="user_expired_year"
                      name="user[expired_year]"
                      class="w-full"
                      :modelValue="form.expiredYear"
                      :customClass="`form-element r:min-w-[90px] px-3 py-2.5 r:min-h-[52px] ${v$.form.expiredYear.$error || v$.form.expiredDate.$error ? 'form-invalid' : ''} ${v$.form.expiredYear.$dirty ? 'form-dirty' : ''} ${!form.expiredYear ? 'text-neutral-gray-300' : ''}`"
                      @change="handleSetTouch('expiredYear'); handleUpdateExpiredDate('expiredMonth', 'expiredYear')"
                    >
                      <option value="">{{ viewsJa.contracts.payment_information.card_info.placeholders.expired_date.year }}</option>
                      <option :value="yearItem" v-for="yearItem of years" :key="yearItem">{{ yearItem }}</option>
                    </select-input>
                  </div>
                  <text-input
                    v-model="form.expiredDate"
                    id="user_expired_date"
                    name="user[expired_date]"
                    type="hidden"
                    :customClass="`form-element ${v$.form.expiredDate.$error ? 'form-invalid' : ''} ${v$.form.expiredDate.$dirty ? 'form-dirty' : ''}`"
                  />
                </div>
              </div>
              <!-- security code -->
              <div class="mb-2 form-group">
                <label class="flex items-center mb-2">
                  <span class="r:text-[16px] leading-[1.53] r:tracking-[0.25px]">{{ modelsJa.activerecord.attributes.contracts.security_code }}</span>
                  <span class="r:text-[10px] r:tracking-[0.25px] leading-[1.53] text-tw-red-500 bg-tw-red-50 rounded px-1 mt-1 ml-2">{{ viewsJa.contracts.required }}</span>
                </label>
                <div class="flex items-center mb-2 -mx-0.5">
                  <div class="px-0.5">
                    <text-input
                      v-model="form.securityCode"
                      class="r:w-[60px]"
                      id="user_security_code"
                      name="user[security_code]"
                      :placeholder="viewsJa.contracts.payment_information.card_info.placeholders.security_code"
                      :customClass="`form-element px-3 py-2.5 r:min-h-[52px] ${v$.form.securityCode.$error ? 'form-invalid' : ''} ${v$.form.securityCode.$dirty ? 'form-dirty' : ''}`"
                      @change="handleSetTouch('securityCode')"
                    />
                  </div>
                  <div class="ml-1.5 px-0.5">
                    <images name="contractsCodeSample" />
                  </div>
                </div>
                <span class="block r:text-[14px] leading-[1.53] r:tracking-[0.25px] text-neutral-gray-400" v-html="viewsJa.contracts.payment_information.card_info.note.security_code"></span>
              </div>
            </div>
          </div>
          <div class="px-6 py-8 bg-white border-b border-neutral-gray-100">
            <span class="text-primary-600 r:text-[18px] leading-[1.53] r:tracking-[0.25px] mb-6 block">{{ viewsJa.contracts.payment_information.card_info.support_company_label }}</span>
            <div class="flex items-center justify-center flex-wrap xs:flex-nowrap r:mx-[-5px]">
              <div class="r:px-[5px] mt-2">
                <images name="jcbCard" class="w-full r:max-w-[62px]" :hasSrcSet="true"/>
              </div>
              <div class="r:px-[5px] mt-2">
                <images name="visaCard" class="w-full r:max-w-[62px]" :hasSrcSet="true"/>
              </div>
              <div class="r:px-[5px] mt-2">
                <images name="dinersCard" class="w-full r:max-w-[62px]" :hasSrcSet="true"/>
              </div>
              <div class="r:px-[5px] mt-2">
                <images name="masterCard" class="w-full r:max-w-[62px]" :hasSrcSet="true"/>
              </div>
              <div class="r:px-[5px] mt-2">
                <images name="amexCard" class="w-full r:max-w-[62px]" :hasSrcSet="true"/>
              </div>
            </div>
          </div>
          <div class="pt-16 payment-action">
            <label class="mb-2 flex justify-center text-tw-red-600 r:text-[14px] leading-[1]" for="last_name" v-if="v$.form.cardNumber.$error || v$.form.expiredYear.$error || v$.form.expiredMonth.$error || v$.form.securityCode.$error || gmoError.state || v$.form.expiredDate.$error">
              <span class="leading-[1.53] r:tracking-[0.25px]">{{ !gmoError.message ? viewsJa.contracts.payment_information.card_info.errors.field_required : gmoError.message }}</span>
            </label>
            <div class="flex flex-col items-center text-center form-group">
              <span v-if="isDisabledSubmitButton" class="text-tw-red-500 r:text-[14px] leading-[1.53] r:tracking-[0.25px] mb-2">{{ viewsJa.contracts.blocks.customer_information.not_complete_form }}</span>
              <button type="button" id="submit-payment" data-testid="submit-payment" :disabled="isDisabledSubmitButton" class="mb-6 inline-flex items-center justify-center rounded-full btn-gradient btn-primary-gradient-horizontal r:text-[14px] xs:r:text-[16px] py-2 px-2 xs:r:px-[18px] xs:py-4 font-medium text-white shadow-gray-1 leading-[1.33] r:min-h-[53px]" @click.prevent="submitGMO">
                <span id="submit-payment-span" class="mr-2">{{ viewsJa.contracts.payment_information.btn.submit }}</span>
                <svg id="submit-payment-svg" xmlns="http://www.w3.org/2000/svg" width="18" height="19" viewBox="0 0 18 19" fill="none">
                  <path id="submit-payment-path" fill-rule="evenodd" clip-rule="evenodd" d="M6.32036 3.76646C6.72549 3.3911 7.35819 3.41524 7.73355 3.82036L12.9958 9.50001L7.73355 15.1796C7.35819 15.5848 6.72549 15.6089 6.32036 15.2335C5.91524 14.8582 5.8911 14.2255 6.26646 13.8204L10.2693 9.50001L6.26646 5.17965C5.8911 4.77452 5.91524 4.14182 6.32036 3.76646Z" :fill="isDisabledSubmitButton ? '#D3DDE8' : '#FFFFFF'"/>
                </svg>
              </button>
              <button id="back-to-confirm" data-testid="back-to-confirm" type="button" class="btn-solid flex items-center justify-center rounded-full r:text-[14px] xs:r:text-[16px] py-2 px-2 r:xs:px-[18px] xs:py-4 text-white bg-neutral-gray-400 leading-[1.33] r:tracking-[0.25px] r:min-h-[53px] shadow-gray-1" @click.prevent="setBackToConfirm">
                <svg id="back-to-confirm-svg" xmlns="http://www.w3.org/2000/svg" width="18" height="19" viewBox="0 0 18 19" fill="none">
                  <path id="back-to-confirm-path" fill-rule="evenodd" clip-rule="evenodd" d="M11.6796 15.2335C11.2745 15.6089 10.6418 15.5848 10.2665 15.1796L5.00417 9.49999L10.2665 3.82035C10.6418 3.41523 11.2745 3.3911 11.6796 3.76645C12.0848 4.14181 12.1089 4.77451 11.7335 5.17964L7.73066 9.49999L11.7335 13.8204C12.1089 14.2255 12.0848 14.8582 11.6796 15.2335Z" fill="white"/>
                </svg>
                <span id="back-to-confirm-span" class="ml-2">{{ viewsJa.contracts.payment_information.btn.back_to_form }}</span>
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.card-selection {
  &.form-invalid {
    label {
      @apply border-tw-red-600;
    }
  }
  label {
    @apply relative block cursor-pointer border border-neutral-gray-100 rounded-2xl py-4 r:pl-[72px] pr-6 bg-neutral-gray-50;
    &:before,
    &:after {
      @apply absolute rounded-full transition-all;
      content: '';
    }
    &:before {
      @apply left-6 r:top-[18px] r:w-[20px] r:h-[20px] border border-tw-black-400 bg-white;
    }
    &:after {
      @apply r:left-[29px] r:top-[23px] w-2.5 h-2.5 opacity-0 bg-white;
    }
  }
  input {
    @apply w-1 h-1 opacity-0 absolute;
    &:checked {
      + label {
        &:before {
          @apply border-primary-600 bg-primary-600;
        }
        &:after {
          @apply opacity-100;
        }
      }
    }
  }
}
</style>

<script>
import { router } from '@inertiajs/vue3';
import { useVuelidate } from '@vuelidate/core';
import { useHead } from '@unhead/vue'
import { required, minLength, maxLength, numeric } from '@vuelidate/validators';
import { futureDate } from '@/utils/custom-validators';
import { convertJaParams, convertDateString } from '@/utils/utils';
import Layout from '@/Layouts/User.vue';
import TextInput from '@/Shared/TextInput.vue';
import SelectInput from '@/Shared/SelectInput.vue';
import RadioInput from '@/Shared/RadioInput.vue';
import IconRawSvg from '@/Shared/IconRawSvg.vue';
import Images from '@/Shared/Images.vue';
import Loading from '@/Shared/Loading.vue';
import ContractProgress from '@/Pages/Contracts/Components/ContractProgress.vue';
import { setMask } from '@/utils/utils';
import axios from 'axios';
import { CARD_API_ERROR_MESSAGES } from '@/utils/constants';

export default {
  components: {
    TextInput,
    SelectInput,
    RadioInput,
    IconRawSvg,
    Images,
    ContractProgress,
    Loading,
  },
  layout: (h, page) => h(Layout, { footerType: 'simple', footerClass: 'bg-neutral-gray-50' }, () => page),
  props: {
    shopId: {
      type: String,
      default: '',
    },
    mulPayURL: {
      type: String,
      default: '',
    },
    cardList: {
      type: Array,
      default: [],
    },
    cardType: {
      type: String,
      default: 'new',
    },
  },
  setup (props) {
    useHead({
      script: [{
        src: props.mulPayURL,
        defer: true,
      }],
    })
    return {
      v$: useVuelidate(),
    }
  },
  data () {
    return {
      setMask: setMask,
      convertJaParams: convertJaParams,
      modelsJa: this.jaConfig.modelsJa,
      viewsJa: this.jaConfig.viewsJa,
      isValid: true,
      form: {
        cardRegistered: this.cardList.length === 0 ? "new" : this.cardType,
        cardNumber: '',
        expiredYear: '',
        expiredMonth: '',
        expiredDate: '',
        securityCode: '',
      },
      fieldServerErr: {
        card_number: false,
        expired_year: false,
        expired_month: false,
        security_code: false,
      },
      gmoError: {
        state: false,
        message: '',
      },
      authenToken: document.querySelector('meta[name="csrf-token"]').content,
      months: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
      years: [],
      apiGmoErrorMessages: {},
      isLoading: false,
    }
  },
  mounted() {
    // pre-check login state to clear session storage in form page
    if (this.isLogged) {
      localStorage.setItem('trackLoggedIn', true);
    }
    localStorage.removeItem('userFormStep');
    localStorage.removeItem('notFirstLoadForm');
    localStorage.removeItem('pdfjs.history');
    // render year
    const currentYear = new Date().getFullYear();
    const gap = 10;
    for (let i = currentYear; i < (currentYear + gap); i++) {
      this.years.push(i);
    }
  },
  methods: {
    submitGMO () {
      if(this.cardList.length === 0 || this.form.cardRegistered === 'new') {
        const cardObj = {
          cardno: this.form.cardNumber.replaceAll('_', ''),
          expire: `${this.form.expiredYear}${convertDateString(this.form.expiredMonth)}`,
          securitycode: this.form.securityCode,
          tokennumber: '2'
        }

        const self = this;
        self.isLoading = true;

        Multipayment.init(this.shopId);
        Multipayment.getToken(cardObj, (response) => {
          self.isLoading = false;
          this.execPurchase(response)
        });
      } else {
        this.submitForm(null)
      }
    },
    execPurchase(response) {
      if(response.resultCode != '000') {
        this.gmoError.state = true;
        if(CARD_API_ERROR_MESSAGES[response.resultCode]) {
          this.gmoError.message = `${this.viewsJa.contracts.api_error_messages.connection_text}${CARD_API_ERROR_MESSAGES[response.resultCode]}`;
        } else {
          this.gmoError.message = `${response.resultCode}${this.viewsJa.contracts.api_error_messages.connection_text}`;
        }
      } else {
        this.submitForm(response)
      }
    },
    handleSetTouch (field) {
      this.v$.form[field].$touch();
    },
    setBackToConfirm () {
      localStorage.setItem('userFormStep', 4);
      localStorage.setItem('notFirstLoadForm', true);
      localStorage.setItem('isFromPayment', true);
      router.visit(this.$routes.new_contract());
    },
    submitForm (response) {
      const self = this;
      const cardData = {
        user: {
          token: response ? response.tokenObject.token[0] : "",
          token_for_bin_verification: response ? response.tokenObject.token[1] : "",
          card_type: self.cardList.length === 0 ? "new" : self.form.cardRegistered
        },
        authenticity_token: self.authenToken
      }

      self.isLoading = true;

      axios({
        method: 'post',
        url: self.$routes.regist_card_contracts(),
        data: cardData,
      }).then(function (response) {
        window.location.href = response.data.redirect_url;
      }).catch(function (_error) {
        self.gmoError.message = self.viewsJa.credit_cards.edit.regist_card_error_message;
        self.gmoError.state = true;

        if(_error.response && _error.response.status === 400 && _error.response.data.messages) {
          localStorage.setItem('invalidCard', _error.response.data.messages);
          localStorage.setItem('userFormStep', 4);
          localStorage.setItem('notFirstLoadForm', true);
          localStorage.setItem('isFromPayment', true);
          window.location.href = _error.response.data.redirect_url || self.$routes.root();
          // window.location.reload();
        }
      }).finally(function () {
        self.isLoading = false;
      });
    }
  },
  computed: {
    setTouchParallel () {
      return (targetField) => {
        this.v$.form[targetField].$touch();
      }
    },
    handleActiveCard () {
      return (card) => {
        if (card) {
          this.v$.form.cardNumber.$reset();
          this.v$.form.expiredYear.$reset();
          this.v$.form.expiredMonth.$reset();
          this.v$.form.securityCode.$reset();
          this.form.cardNumber = '';
          this.form.expiredYear = '';
          this.form.expiredMonth = '';
        }
      }
    },
    handleUpdateExpiredDate () {
      return (monthField, yearField) => {
        const today = new Date();
        if (this.v$.form[monthField].$dirty && this.v$.form[yearField].$dirty) {
          if (!!this.form[monthField] && !!this.form[yearField]) {
            this.form.expiredDate = `${this.form[yearField]}-${convertDateString(this.form[monthField])}-${convertDateString(today.getDate())}`;
          } else {
            this.form.expiredDate = '';
          }
          this.v$.form.expiredDate.$touch();
        }
      }
    },
    isFormHasError () {
      return this.v$.form.cardNumber.$error ||
        this.v$.form.expiredYear.$error ||
        this.v$.form.expiredMonth.$error ||
        this.v$.form.securityCode.$error ||
        this.v$.form.expiredDate.$error
    },
    isDisabledSubmitButton () {
      return (!this.form.cardNumber || !this.form.expiredYear ||
        !this.form.expiredMonth || !this.form.securityCode ||
        this.isFormHasError || !this.form.expiredDate) && this.form.cardRegistered === 'new';
    },
  },
  validations () {
    const form = {
      cardRegistered: {},
      cardNumber: {},
      expiredYear: {},
      expiredMonth: {},
      expiredDate: {},
      securityCode: {},
    };
    if (this.cardList && this.cardList.length) {
      form.cardRegistered = { required };
    }
    if (this.cardList.length === 0 || this.form.cardRegistered === 'new') {
      form.cardNumber = { required, minLength: minLength(14), maxLength: maxLength(16), numeric };
      form.expiredYear = { required };
      form.expiredMonth = { required };
      form.expiredDate = { futureDate };
      form.securityCode = { required, minLength: minLength(3), maxLength: maxLength(4), numeric };
    }
    return {
      form: {...form}
    };
  },
  validationConfig: {
    $lazy: true,
  },
}
</script>
