<template>
  <text-input
    v-model="postalCode"
    id="user_postal_code"
    name="user[postal_code]"
    :customClass="`form-element px-2 r:py-[15px] xs:px-[15px] r:min-h-[56px] ${v$.postalCode.$error || serverError ? 'form-invalid' : ''} ${v$.postalCode.$dirty ? 'form-dirty' : ''} ${!isValidPostalCode ? '' : 'valid-postal-code'}`"
    :placeholder="viewsJa.contracts.blocks.customer_information.placeholders.postal_code"
    @blur="checkOnBlur"
    @input="handleInputPostalCode($event)"
  />
</template>

<style lang="scss">
.pac-container {
  width: auto !important;
  &:after {
    content: none !important;
  }
  .pac-item {
    @apply px-3 py-3;
    span {
      @apply r:text-[14px];
    }
  }
}
</style>

<script>
import TextInput from '@/Shared/TextInput.vue';
import { Loader } from "@googlemaps/js-api-loader";
import { useVuelidate } from '@vuelidate/core';
import { required, numeric } from '@vuelidate/validators';
import { VALIDATION_RULES } from '@/utils/constants';
import { postalCode } from '@/utils/custom-validators';

export default {
  components: {
    TextInput
  },
  props: {
    apiKey: {
      type: String,
      required: true
    },
    postalCodeVal: {
      type: String,
      default: ''
    },
    serverError: {
      type: Boolean,
      default: false
    },
    isTriggerAddress: {
      type: Boolean,
      default: false
    }
  },
  emits: ['handle-update-location', 'handle-postal-code-error'],
  data() {
    return {
      viewsJa: this.jaConfig.viewsJa,
      postalCodeEle: null,
      postalCode: !!this.postalCodeVal ? this.postalCodeVal : '',
      isValidPostalCode: null,
      service: null,
      autocomplete: null,
      flagPostalCodeOff: false,
      regionOptions: {
        types: ['(regions)'],
        componentRestrictions: {
          country: ['jp']
        }
      },
      formats: ['XXX-XXXX', 'X-XXXXXX', 'XX-XXXXX', 'XXXX-XXX', 'XXXXX-XX', 'XXXXXX-X'],
    }
  },
  setup (props) {
    const loader = new Loader({
      apiKey: props.apiKey,
      libraries: ['places'],
      language: 'ja'
    });
    loader.load();
    return {
      v$: useVuelidate()
    }
  },
  mounted() {
    const delayInitMap = 1000;
    this.postalCodeEle = document.getElementById('user_postal_code');

    setTimeout(() => {
      this.service = new google.maps.places.AutocompleteService();
      this.autocomplete = new google.maps.places.Autocomplete(this.postalCodeEle, this.regionOptions);
      this.searchPostalCode();
      if (this.isTriggerAddress) {
        const self = this;
        const request = {
          input: this.postalCode,
          ...this.regionOptions
        };
        this.service.getPlacePredictions(request, function (predictions) {
          const termsPredict = predictions[0].terms;
          self.updateLocation(termsPredict[2].value, termsPredict[3].value, termsPredict[4].value);
        });
      }
    }, delayInitMap);
  },
  methods: {
    searchPostalCode () {
      const self = this;

      this.selectFirstOnEnter(this.postalCodeEle);
      google.maps.event.addListener(self.autocomplete, 'place_changed', function () {
        const place = self.autocomplete.getPlace();
        self.setPlaceChanged(place);
      });
    },
    setPlaceChanged (place) {
      if (!!place && typeof place.types !== 'undefined' && place.types.indexOf('postal_code') > -1) {
        let addressComponents = place.address_components,
            postalCodePattern = /[^\w]/gi,
            prefectureName = '',
            municipalityName = '',
            townName = '',
            townArray = [];

        for (let adr of addressComponents) {
          if (adr.types.indexOf('postal_code') > -1) {
            this.postalCode = adr.long_name.replace(postalCodePattern, '');
            this.postalCodeEle.value = this.postalCode;
            this.isValidPostalCode = true;
          } else if (adr.types.indexOf('administrative_area_level_1') > -1) {
            prefectureName = adr.long_name;
          } else if (adr.types.indexOf('locality') > -1) {
            municipalityName = adr.long_name;
          } else if (adr.types.indexOf('sublocality_level_1') > -1) {
            townArray.unshift(adr.long_name);
          } else if (adr.types.indexOf('sublocality_level_2') > -1) {
            townArray.push(adr.long_name);
          }
        }
        this.v$.postalCode.$touch();
        townName = townArray.join('');
        this.updateLocation(prefectureName, municipalityName, townName);
      }
    },
    getPlaceByPostalCode (postalCode, newPostalCode, numTry) {;
      const self = this;
      const request = {
        input: newPostalCode,
        ...this.regionOptions
      };
      // Check postal code with new format (include '-') if result is empty
      this.service.getPlacePredictions(request, function (predictions, status) {
        if ((status == 'ZERO_RESULTS' || typeof predictions[0].types === 'undefined')  && numTry < self.formats.length) {
          newPostalCode = self.formatPostalCode(self.formats[numTry], postalCode);
          self.getPlaceByPostalCode(postalCode, newPostalCode, numTry + 1);
        } else if (status == 'OK' && postalCode != newPostalCode) {
          self.postalCodeEle.value = newPostalCode;
          google.maps.event.trigger(self.postalCodeEle, 'focus', {});
          self.postalCodeEle.dispatchEvent(
            new KeyboardEvent('keydown', {
              view: window,
              bubbles: true,
            }),
          )
        }
      });
    },
    selectFirstOnEnter (input) {
      const self = this;
      // store the original event binding function
      let _addEventListener = (input.addEventListener) ? input.addEventListener : input.attachEvent;
      const addEventListenerWrapper = function (type, listener) {
        // Simulate a 'down arrow' keypress on hitting 'return' when no pac suggestion is selected, and then trigger the original listener.
        if (type == 'keydown') {
          let origListener = listener;
          listener = function (event) {
            const suggestionSelected = document.querySelectorAll('.pac-item-selected').length > 0;

            if ((self.postalCodeEle.classList.contains('valid-postal-code'))
                && (event.which == 13 || event.which == 40 || event.which == 38)) {
              event.preventDefault();
            } else {
              if (event.which == 13 && !suggestionSelected) {
                const simulatedDownArrow = new KeyboardEvent('keydown', {
                  keyCode: 40,
                  which: 40
                });
                origListener.apply(input, [simulatedDownArrow]);
              }
            }
            origListener.apply(input, [event]);
          };
        }
        _addEventListener.apply(input, [type, listener]); // add the modified listener
      }
      if (input.addEventListener) {
        input.addEventListener = addEventListenerWrapper;
      } else if (input.attachEvent) {
        input.attachEvent = addEventListenerWrapper;
      }
    },
    updateLocation (prefecture, municipality, town) {
      this.$emit('handle-update-location', prefecture, municipality, town, this.postalCode);
    },
    formatPostalCode (mask, number) {
      var s = '' + number, r = '';
      for (var im = 0, is = 0; im < mask.length && is < s.length; im++) {
        r += mask.charAt(im)=='X' ? s.charAt(is++) : mask.charAt(im);
      }
      return r;
    },
    checkOnBlur () {
      const delayCheckBlur = 200;
      setTimeout(() => {
        if (!VALIDATION_RULES.POSTAL_CODE.test(this.postalCode)) {
          this.v$.postalCode.$touch();
        }
      }, delayCheckBlur);
    }
  },
  computed: {
    handleInputPostalCode () {
      return (e) => {
        const currentValue = e.target.value;
        const isValid = (/^[0-9０-９]*$/g).test(currentValue) || (currentValue).match(/^\d*$/) !== null;

        if (isValid && (/^[0-9０-９]{7}$/i).test(currentValue)) {
          // In case that user enter enough characters (7)
          // we need to check the search results and will try search place with the new format of postal code (include '-') if the result's empty
          this.getPlaceByPostalCode(currentValue, currentValue, 0);
        }
      }
    }
  },
  watch: {
    'v$.postalCode.$error': function (errState) {
      this.$emit('handle-postal-code-error', errState);
      if (this.postalCode && errState) {
        this.$emit('handle-update-location', '', '', '', this.postalCode);
        this.isValidPostalCode = false;
      }
    }
  },
  validations () {
    return {
      postalCode: { required, postalCode, numeric }
    }
  },
  validationConfig: {
    $lazy: true,
  },
}
</script>
