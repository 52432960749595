<template>
  <div class="contract-thankyou bg-neutral-gray-50">
    <div class="r:max-w-[672px] mx-auto pt-8 pb-6">
      <contract-progress :currentStep="6" class="px-6 mb-6" />
      <div class="px-6 mb-24 title-section">
        <span class="text-primary-600 r:text-[18px] leading-[1.53] r:tracking-[0.25px] mb-1">{{ viewsJa.contracts.thankyou.sub_title }}</span>
        <h1 class="r:text-[34px] font-black leading-[1.53] r:tracking-[0.25px] mb-6" v-html="viewsJa.contracts.thankyou.title"></h1>
        <span class="r:text-[16px] leading-[1.53] r:tracking-[0.25px]">{{ viewsJa.contracts.thankyou.sub_content }}</span>
      </div>
      <template v-for="(val, key) in viewsJa.contracts.thankyou.blocks" :key="key">
        <div :class="key == 2 ? '' : 'mb-16'">
          <h2 class="r:text-[24px] leading-[1.53] r:tracking-[0.25px] px-6 mb-4">{{ val.title }}</h2>
          <div class="px-6 py-8 bg-white border-b border-tw-black-200">
            <span class="r:text-[16px] leading-[1.53] r:tracking-[0.25px] inline-block mb-8" v-html="val.content" v-if="val.content"></span>
            <div class="explanation-img" v-if="val.img">
              <images :name="val.img" class="max-w-full m-auto"/>
            </div>
            <span class="text-neutral-gray-900 r:text-[12px] leading-[1.53] r:tracking-[0.25px] inline-block mt-4" v-html="val.bottom_content" v-if="val.bottom_content" />
            <a v-if="val.btn" :id="`go-to-${val.id}`" :data-testid="`go-to-${val.id}`" class="w-fit flex items-center justify-center rounded-full btn-gradient btn-primary-gradient-horizontal btn-arrow r:text-[14px] xs:r:text-[16px] py-2 px-2 xs:px-4 xs:pt-4 xs:pb-3.5 font-bold mx-auto text-tw-black-600 shadow-gray-1 leading-[1.4] r:min-h-[56px]" :href="val.url" :target="val.target">
              <span :id="`go-to-${val.id}-span`" class="text-white r:text-[16px] leading-[1.33] r:tracking-[0.25px]">{{ val.btn }}</span>
              <svg :id="`go-to-${val.id}-svg`" class="ml-3" width="9" height="14" viewBox="0 0 9 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path :id="`go-to-${val.id}-path`" d="M1 0.998535L4 4.00027L7 7.002L4 9.99978L1 13.0015" stroke="#FFFFFF" stroke-width="2"/>
              </svg>
            </a>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import Layout from '@/Layouts/User.vue';
import Images from '@/Shared/Images.vue';
import ContractProgress from '@/Pages/Contracts/Components/ContractProgress.vue';

export default {
  components: {
    Images,
    ContractProgress
  },
  layout: Layout,
  data() {
    return {
      viewsJa: this.jaConfig.viewsJa
    }
  }
}
</script>
