<template>
  <div class="r:max-w-[672px] mx-auto explanation-information">
    <div class="explain-matter-section r:max-w-[672px] mx-auto mb-16">
      <h2 class="r:text-[24px] leading-[1.53] r:tracking-[0.25px] px-6 mb-4">{{ viewsJa.contracts.blocks.explanation_information.groups.explain_matter_information.group_title }}</h2>
      <div class="px-4 py-6 bg-white border-b explain-matter-info xs:px-6 xs:py-8 border-neutral-gray-100">
        <div class="mb-6 xs:mb-8">
          <p
            v-for="(explain, idx) in viewsJa.contracts.blocks.explanation_information.explain_matter.explains" :key="idx"
            class="flex pl-6 relative r:text-[16px] leading-[1.53] r:tracking-[0.25px]"
          >
            <span class="absolute left-2.5 top-3 w-1 h-1 rounded-full bg-neutral-gray-900"></span>
            <span class="flex-1">{{ explain }}</span>
          </p>
        </div>
        <div class="px-4 py-3 border rounded-lg border-tw-red-200 bg-tw-red-50 xs:px-6 xs:py-4">
          <svg class="mb-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M10.8474 3.31557L1.5381 19.3293C1.02136 20.2181 1.66264 21.3327 2.6908 21.3327H21.3094C22.3376 21.3327 22.9788 20.2181 22.4621 19.3293L13.1528 3.31557C12.6387 2.43127 11.3615 2.43127 10.8474 3.31557ZM13.3337 12.666C13.3337 13.4024 12.7368 13.9994 12.0004 13.9994C11.264 13.9994 10.6671 13.4024 10.6671 12.666V8.66604C10.6671 7.92966 11.264 7.3327 12.0004 7.3327C12.7368 7.3327 13.3337 7.92966 13.3337 8.66604V12.666ZM10.3337 16.9994C10.3337 16.0789 11.0799 15.3327 12.0004 15.3327C12.9209 15.3327 13.6671 16.0789 13.6671 16.9994C13.6671 17.9198 12.9209 18.666 12.0004 18.666C11.0799 18.666 10.3337 17.9198 10.3337 16.9994Z" fill="#E33B24"/>
          </svg>
          <h4 class="r:text-[14px] font-bold leading-[1.53] r:tracking-[0.25px] text-tw-red-600 mb-1" v-html="viewsJa.contracts.blocks.explanation_information.explain_matter.pdf_info.title"></h4>
          <p class="text-tw-red-600 r:text-[14px] leading-[1.53] r:tracking-[0.25px] mb-4">{{ viewsJa.contracts.blocks.explanation_information.explain_matter.pdf_info.desc }}</p>
          <div class="flex flex-col items-center text-center">
            <button data-testid="view-pdf-step-2" id="view-pdf-step-2" type="button" class="mb-6 inline-flex items-center justify-center rounded-full btn-gradient btn-primary-gradient-horizontal r:text-[14px] xs:r:text-[16px] py-2 px-2 xs:r:px-[18px] xs:py-4 font-medium text-white shadow-gray-1 leading-[1.33] r:min-h-[53px]" @click.prevent="handleModalState('pdf_info')">
              <span id="view-pdf-step-2-span" class="mr-2">{{ viewsJa.contracts.blocks.explanation_information.explain_matter.pdf_info.btn_view_pdf }}</span>
              <svg id="view-pdf-step-2-svg" xmlns="http://www.w3.org/2000/svg" width="18" height="19" viewBox="0 0 18 19" fill="none">
                <path id="view-pdf-step-2-path" fill-rule="evenodd" clip-rule="evenodd" d="M6.32036 3.76646C6.72549 3.3911 7.35819 3.41524 7.73355 3.82036L12.9958 9.50001L7.73355 15.1796C7.35819 15.5848 6.72549 15.6089 6.32036 15.2335C5.91524 14.8582 5.8911 14.2255 6.26646 13.8204L10.2693 9.50001L6.26646 5.17965C5.8911 4.77452 5.91524 4.14182 6.32036 3.76646Z" fill="#FFFFFF"/>
              </svg>
            </button>
            <span class="flex items-center justify-center">
              <span class="flex items-center justify-center w-5 h-5 border rounded-sm" :class="[isAcceptRule ? 'border-primary-600 bg-primary-600' : 'border-neutral-gray-100 bg-white']">
                <svg :class="[isAcceptRule ? 'opacity-100' : 'opacity-0']" xmlns="http://www.w3.org/2000/svg" width="11" height="9" viewBox="0 0 11 9" fill="none">
                  <path d="M10.001 1L6.99924 4L3.99751 7L0.999729 4" stroke="#FFFFFF" stroke-width="2"/>
                </svg>
              </span>
              <span class="r:text-[16px] leading-[1.53] r:tracking-[0.25px] ml-2.5" :class="{'text-neutral-gray-300': !isAcceptRule}">{{ viewsJa.contracts.blocks.explanation_information.explain_matter.pdf_info.pdf_tick }}</span>
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="particularly-matter-section r:max-w-[672px] mx-auto mb-16">
      <h2 class="r:text-[24px] leading-[1.53] r:tracking-[0.25px] px-6 mb-4">{{ viewsJa.contracts.blocks.explanation_information.groups.particularly_matter_information.group_title }}</h2>
      <div class="px-4 py-6 bg-white border-b matter-info xs:px-6 xs:py-8 border-neutral-gray-100">
        <div class="mb-6 xs:mb-8">
          <p
            v-for="(matter, idx) in viewsJa.contracts.blocks.explanation_information.particularly_matter.matters" :key="idx"
            class="flex pl-6 relative r:text-[16px] leading-[1.53] r:tracking-[0.25px]"
          >
            <span class="absolute left-2.5 top-3 w-1 h-1 rounded-full bg-neutral-gray-900"></span>
            <span class="flex-1">{{ matter }}</span>
          </p>
        </div>
        <div class="text-right">
          <a id="open-modal-insurance-premium" data-testid="open-modal-insurance-premium" href="javascript:;" @click.prevent="handleModalState('insurance_premium')" class="r:text-[16px] underline leading-[2] text-accent-500 font-notosans transition hover:opacity-70" target="_blank" rel="noopener">{{ viewsJa.contracts.blocks.explanation_information.particularly_matter.view_premium_chart }}</a>
        </div>
      </div>
    </div>
    <div class="start-of-coverage-section r:max-w-[672px] mx-auto mb-16">
      <h2 class="r:text-[24px] leading-[1.53] r:tracking-[0.25px] px-6 mb-4">{{ viewsJa.contracts.blocks.explanation_information.groups.start_of_coverage.group_title }}</h2>
      <div class="px-4 py-6 bg-white border-b explain-matter-info xs:px-6 xs:py-8 border-neutral-gray-100">
        <div class="mb-6 xs:mb-8">
          <p class="r:text-[16px] leading-[1.53] r:tracking-[0.25px] mb-6 xs:mb-8" v-html="viewsJa.contracts.blocks.explanation_information.start_of_coverage.desc"></p>
          <images name="thankyouExplanation" class="mx-auto mb-6" />
          <span class="r:text-[12px] leading-[1.53] r:tracking-[0.25px] block">{{ viewsJa.contracts.blocks.explanation_information.start_of_coverage.note_1 }}</span>
          <span class="r:text-[12px] leading-[1.53] r:tracking-[0.25px] block">{{ viewsJa.contracts.blocks.explanation_information.start_of_coverage.note_2 }}</span>
          <span
            v-for="(coverage, idx) in viewsJa.contracts.blocks.explanation_information.start_of_coverage.note_3" :key="idx"
            class="flex r:pl-[18px] relative r:text-[12px] leading-[1.53] r:tracking-[0.25px]"
          >
            <span class="absolute left-2 top-2 r:w-[3px] r:h-[3px] rounded-full bg-neutral-gray-900"></span>
            <span class="flex-1">{{ coverage }}</span>
          </span>
        </div>
      </div>
    </div>
    <div class="group-button r:max-w-[672px] mx-auto">
      <div class="flex flex-col items-center px-4 xs:px-6">
        <span v-if="!isAcceptRule" class="text-tw-red-500 r:text-[14px] leading-[1.53] r:tracking-[0.25px] mb-2">{{ viewsJa.contracts.blocks.explanation_information.not_complete_remind }}</span>
        <button type="button" id="submit-application-form-step-2" :disabled="!isAcceptRule" class="mb-6 inline-flex items-center justify-center rounded-full btn-gradient btn-primary-gradient-horizontal r:text-[14px] xs:r:text-[16px] py-2 px-2 xs:r:px-[18px] xs:py-4 font-medium text-white shadow-gray-1 leading-[1.33] r:min-h-[53px]" @click.prevent="setStep(pageStep + 1)">
          <span id="submit-application-form-step-2-span" class="mr-2">{{ viewsJa.contracts.blocks.explanation_information.btn.next }}</span>
          <svg id="submit-application-form-step-2-svg" xmlns="http://www.w3.org/2000/svg" width="18" height="19" viewBox="0 0 18 19" fill="none">
            <path id="submit-application-form-step-2-path" fill-rule="evenodd" clip-rule="evenodd" d="M6.32036 3.76646C6.72549 3.3911 7.35819 3.41524 7.73355 3.82036L12.9958 9.50001L7.73355 15.1796C7.35819 15.5848 6.72549 15.6089 6.32036 15.2335C5.91524 14.8582 5.8911 14.2255 6.26646 13.8204L10.2693 9.50001L6.26646 5.17965C5.8911 4.77452 5.91524 4.14182 6.32036 3.76646Z" :fill="!isAcceptRule ? '#D3DDE8' : '#FFFFFF'"/>
          </svg>
        </button>
        <button type="button" data-testid="back-form-step-2" id="back-form-step-2" class="btn-solid inline-flex items-center justify-center rounded-full btn-gradient r:text-[14px] xs:r:text-[16px] py-2 px-2 xs:px-4 xs:py-4 text-white bg-neutral-gray-400 leading-[1.33] r:min-h-[53px] r:tracking-[0.25px] shadow-gray-1" @click.prevent="setStep(pageStep - 1)">
          <svg id="back-form-step-2-svg" xmlns="http://www.w3.org/2000/svg" width="18" height="19" viewBox="0 0 18 19" fill="none">
            <path id="back-form-step-2-path" fill-rule="evenodd" clip-rule="evenodd" d="M11.6796 15.2335C11.2745 15.6089 10.6418 15.5848 10.2665 15.1796L5.00417 9.49999L10.2665 3.82035C10.6418 3.41523 11.2745 3.3911 11.6796 3.76645C12.0848 4.14181 12.1089 4.77451 11.7335 5.17964L7.73066 9.49999L11.7335 13.8204C12.1089 14.2255 12.0848 14.8582 11.6796 15.2335Z" fill="white"/>
          </svg>
          <span id="back-form-step-2-span" class="ml-2">{{ viewsJa.contracts.blocks.explanation_information.btn.prev }}</span>
        </button>
      </div>
    </div>
    <modal
      :open="modalState['pdf_info']"
      @close="modalState['pdf_info'] = false"
      :modalContentCls="`!bg-white h-full ${reachMaxSizePdf ? 'r:max-h-[640px]' : '' }`"
      modalDialogCls="sm:r:max-w-[720px]"
    >
      <template #modalHeader>
        <div class="px-4 py-2 border-b border-neutral-gray-200 modal-header">
          <button type="button" class="flex w-12 h-12 p-3 ml-auto group hover:opacity-70" title="Close" style="margin-left: auto;" @click="modalState['pdf_info'] = false">
            <svg class="group-hover:opacity-70" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path d="M6.27614 4.39052C5.75544 3.86983 4.91122 3.86983 4.39052 4.39052C3.86983 4.91122 3.86983 5.75544 4.39052 6.27614L10.1144 12L4.3906 17.7239C3.8699 18.2446 3.8699 19.0888 4.3906 19.6095C4.9113 20.1302 5.75552 20.1302 6.27622 19.6095L12 13.8856L17.7239 19.6095C18.2446 20.1302 19.0888 20.1302 19.6095 19.6095C20.1302 19.0888 20.1302 18.2446 19.6095 17.7239L13.8856 12L19.6095 6.27614C20.1302 5.75544 20.1302 4.91122 19.6095 4.39052C19.0888 3.86982 18.2446 3.86983 17.7239 4.39053L12 10.1144L6.27614 4.39052Z" fill="#93A3B5"/>
            </svg>
          </button>
          <h2 class="r:text-[16px] leading-[1.53] r:tracking-[0.25px]">{{ viewsJa.contracts.blocks.explanation_information.modal.pdf_info.title }}</h2>
        </div>
      </template>
      <template #modalBody>
        <div class="flex-1 px-4 pt-4 xs:pt-6 modal-body">
          <div class="relative h-full border border-neutral-gray-100">
            <div class="toolbar absolute top-2 left-5 right-5 z-[2] flex justify-between">
              <span class="font-notosans font-bold rounded-md bg-neutral-gray-50 text-neutral-gray-700 p-2 r:min-w-[64px] text-center r:text-[12px]">{{ curPagePdf }} / {{ totalPagePdf }}</span>
              <div class="flex items-center">
                <button class="bg-neutral-gray-50 r:w-[34px] r:h-[34px] rounded-full  text-neutral-700 font-montserrat flex items-center justify-center hover:opacity-70 transition" :id="idConfig.zoomOut" type="button">
                  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M3.4209 5.9873H10.2633V7.6978H3.4209V5.9873Z" fill="#4E5966"/>
                    <path d="M6.84236 13.684C8.36049 13.6837 9.83487 13.1755 11.0307 12.2403L14.7906 16L16 14.7907L12.2401 11.031C13.1758 9.83508 13.6844 8.36043 13.6847 6.84199C13.6847 3.06949 10.6151 0 6.84236 0C3.06965 0 0 3.06949 0 6.84199C0 10.6145 3.06965 13.684 6.84236 13.684ZM6.84236 1.7105C9.67253 1.7105 11.9741 4.01197 11.9741 6.84199C11.9741 9.67201 9.67253 11.9735 6.84236 11.9735C4.01219 11.9735 1.71059 9.67201 1.71059 6.84199C1.71059 4.01197 4.01219 1.7105 6.84236 1.7105Z" fill="#4E5966"/>
                  </svg>
                </button>
                <button class="ml-1.5 bg-neutral-gray-50 r:w-[34px] r:h-[34px] rounded-full  text-neutral-700 font-montserrat flex items-center justify-center hover:opacity-70 transition" :id="idConfig.zoomIn" type="button">
                  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7.76573 3.4209H6.05514V5.98665H3.48926V7.69714H6.05514V10.2629H7.76573V7.69714H10.3316V5.98665H7.76573V3.4209Z" fill="#4E5966"/>
                    <path d="M6.84236 0C3.06965 0 0 3.06949 0 6.84199C0 10.6145 3.06965 13.684 6.84236 13.684C8.36049 13.6837 9.83487 13.1755 11.0307 12.2403L14.7906 16L16 14.7907L12.2401 11.031C13.1758 9.83508 13.6844 8.36043 13.6847 6.84199C13.6847 3.06949 10.6151 0 6.84236 0ZM6.84236 11.9735C4.01219 11.9735 1.71059 9.67201 1.71059 6.84199C1.71059 4.01197 4.01219 1.7105 6.84236 1.7105C9.67253 1.7105 11.9741 4.01197 11.9741 6.84199C11.9741 9.67201 9.67253 11.9735 6.84236 11.9735Z" fill="#4E5966"/>
                  </svg>
                </button>
              </div>
            </div>
            <pdf-app class="h-full" style="height: 100%" :pdf="pdfSrc" page-scale="page-width" @pages-rendered="pagesRendered" :config="configPdf" :id-config="idConfig"></pdf-app>
          </div>
        </div>
      </template>
      <template #modalFooter>
        <div class="p-4 xs:py-6 modal-footer">
          <button type="button" data-testid="read-rule" id="read-rule" :disabled="!isReadRule" class="r:max-w-[311px] mx-auto flex items-center justify-center rounded-full btn-gradient btn-primary-gradient-horizontal r:text-[12px] py-2 px-3 font-bol text-white shadow-gray-1 leading-[1.33] r:min-h-[53px]" @click.prevent="handleAcceptRule">
            <svg id="read-rule-svg" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
              <path id="read-rule-path" fill-rule="evenodd" clip-rule="evenodd" d="M12.4447 5.33351C12.6901 5.57897 12.6901 5.97694 12.4447 6.2224L7.11133 11.5557L3.55461 7.99901C3.30915 7.75355 3.31031 7.35675 3.55577 7.11129C3.80123 6.86583 4.1992 6.86583 4.44466 7.11129L7.11133 9.77795L11.5558 5.33351C11.8012 5.08805 12.1992 5.08805 12.4447 5.33351Z" :fill="!isReadRule ? '#B3C0CE' : '#FFFFFF'"/>
            </svg>
            <span id="read-rule-span" class="flex-1 ml-2 text-center">{{ viewsJa.contracts.blocks.explanation_information.modal.pdf_info.btn_accept }}</span>
          </button>
        </div>
      </template>
    </modal>
    <modal
      :open="modalState['insurance_premium']"
      @close="modalState['insurance_premium'] = false"
      modalContentCls="!bg-white"
      modalDialogCls="sm:r:max-w-[720px]"
    >
      <template #modalHeader>
        <div class="px-4 py-2 border-b border-neutral-gray-100 modal-header">
          <button id="close-insurance-premium" data-testid="close-insurance-premium" type="button" class="flex w-12 h-12 p-3 ml-auto group hover:opacity-70" title="Close" style="margin-left: auto;" @click="modalState['insurance_premium'] = false">
            <svg id="close-insurance-premium-svg" class="group-hover:opacity-70" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path id="close-insurance-premium-path" d="M6.27614 4.39052C5.75544 3.86983 4.91122 3.86983 4.39052 4.39052C3.86983 4.91122 3.86983 5.75544 4.39052 6.27614L10.1144 12L4.3906 17.7239C3.8699 18.2446 3.8699 19.0888 4.3906 19.6095C4.9113 20.1302 5.75552 20.1302 6.27622 19.6095L12 13.8856L17.7239 19.6095C18.2446 20.1302 19.0888 20.1302 19.6095 19.6095C20.1302 19.0888 20.1302 18.2446 19.6095 17.7239L13.8856 12L19.6095 6.27614C20.1302 5.75544 20.1302 4.91122 19.6095 4.39052C19.0888 3.86982 18.2446 3.86983 17.7239 4.39053L12 10.1144L6.27614 4.39052Z" fill="#93A3B5"/>
            </svg>
          </button>
          <h2 class="r:text-[16px] leading-[1.53] r:tracking-[0.25px]">{{ viewsJa.contracts.blocks.explanation_information.modal.insurance_premium.title }}</h2>
        </div>
      </template>
      <template #modalBody>
        <div class="flex-1 overflow-y-auto modal-body">
          <div class="px-4 pt-4 overflow-x-auto xs:pt-6" @scroll="handleHideScrollGuide">
            <div class="relative table-responsive r:min-w-[540px]">
              <images id="scroll-guide" name="scrollGuide" class="absolute -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2" />
              <age-price-table></age-price-table>
            </div>
          </div>
          <div class="px-4 pt-4 xs:pt-6">
            <p
              v-for="(desc, idx) in viewsJa.contracts.blocks.explanation_information.modal.insurance_premium.desc" :key="idx"
              class="flex pl-5 relative r:text-[14px] leading-[1.53] r:tracking-[0.25px]"
            >
              <span class="absolute left-2 top-2.5 w-1 h-1 rounded-full bg-neutral-gray-900"></span>
              <span class="flex-1">{{ desc }}</span>
            </p>
          </div>
        </div>
      </template>
      <template #modalFooter>
        <div class="p-4 text-center xs:py-6 modal-footer">
          <button type="button" class="btn-solid inline-flex min-w-[109px] items-center justify-center rounded-full btn-gradient r:text-[14px] py-2 px-2 xs:px-4 xs:py-4 text-white bg-neutral-gray-400 leading-[1.33] r:min-h-[53px] r:tracking-[0.25px] shadow-gray-1" @click.prevent="modalState['insurance_premium'] = false">
            <span>{{ viewsJa.contracts.blocks.explanation_information.modal.insurance_premium.btn_close }}</span>
          </button>
        </div>
      </template>
    </modal>
  </div>
</template>

<style lang="scss" scoped>
.table-responsive {
  @apply r:min-w-[540px];
}
.table-custom {
  @apply border-spacing-0 border-collapse w-full;
  th,
  td {
    @apply border border-neutral-gray-200 p-2;
  }
}
:deep(.pdf-app) {
  .pdfViewer {
    .page {
      @apply border-0 bg-none flex justify-center r:min-h-[330px] xs:min-h-0;
      width: 100% !important;
      &:not(:last-child) {
        @apply mb-2;
      }
    }
  }
}
</style>

<script>
import Images from '@/Shared/Images.vue';
import Modal from '@/Shared/Modal.vue';
import PdfApp from 'vue3-pdf-app';
import 'vue3-pdf-app/dist/icons/main.css';
import AgePriceTable from '@/Pages/Home/Blocks/AgePriceTable.vue';
import pdfFile from '@public/overlaying-for-subscriber-lines-20240417.pdf';

export default {
  components: {
    Images,
    Modal,
    PdfApp,
    AgePriceTable
  },
  props: {
    pageStep: {
      type: Number,
      required: true
    },
  },
  emits: ['set-step'],
  data() {
    return {
      viewsJa: this.jaConfig.viewsJa,
      isReadRule: false,
      isAcceptRule: JSON.parse(localStorage.getItem('isAcceptRule')) || false,
      totalPagePdf: 1,
      curPagePdf: 1,
      pdfSrc: pdfFile,
      idConfig: { zoomIn: "zoomInId", zoomOut: "zoomOutId" },
      configPdf: {
        toolbar: false
      },
      modalState: {
        pdf_info: false,
        insurance_premium: false
      },
      firstShowGuide: true,
      reachMaxSizePdf: false,
      modalPdfSize: 640,
    }
  },
  mounted() {
    const self = this;
    const tablePlanSize = 540;
    const gap = 64;
    const scrollGuide = document.getElementById('scroll-guide');
    window.addEventListener('resize', function () {
      if (self.firstShowGuide) {
        if (window.innerWidth < tablePlanSize + gap) {
          scrollGuide.classList.remove('hidden');
        } else {
          scrollGuide.classList.add('hidden');
        }
      }
      self.reachMaxSizePdf = window.innerHeight > self.modalPdfSize ? true : false;
    });
    window.dispatchEvent(new Event('resize'));
  },
  methods: {
    setStep (step) {
      this.$emit('set-step', step);
      localStorage.setItem('userFormStep', step);
    },
    handleAcceptRule () {
      this.modalState['pdf_info'] = false;
      this.isAcceptRule = true;
      localStorage.setItem('isAcceptRule', this.isAcceptRule)
    },
    pagesRendered (pdfApp) {
      const pdfViewContainer = pdfApp.pdfViewer.container;
      const delayGetPage = 200;
      this.totalPagePdf = pdfApp.pdfViewer.pagesCount;
      let firstTimeRun = true;

      // // check condition enable read rule when scroll pdf
      pdfViewContainer.addEventListener('scroll', () => {
        if (firstTimeRun) {
          setTimeout(() => {
            this.curPagePdf = pdfApp.pdfViewer.currentPageNumber;
            if (this.curPagePdf == this.totalPagePdf && !this.isReadRule && !this.isAcceptRule) {
              this.isReadRule = true;
            } else {
              this.isReadRule = false;
            }
          }, delayGetPage);
          firstTimeRun = false;
        } else {
          this.curPagePdf = pdfApp.pdfViewer.currentPageNumber;
          if (this.isReadRule && this.isAcceptRule) return;
          if (this.curPagePdf == this.totalPagePdf) {
            this.isReadRule = true;
          } else {
            this.isReadRule = false;
          }
        }
      });
      // clear warning pdf plugin
      console.clear();
    },
    handleHideScrollGuide () {
      const scrollGuide = document.getElementById('scroll-guide');
      if (scrollGuide) {
        scrollGuide.classList.add('hidden');
        this.firstShowGuide = false;
      }
    }
  },
  computed: {
    handleModalState () {
      return (modalName) => {
        this.modalState[modalName] = !this.modalState[modalName];
      }
    }
  },
}
</script>
