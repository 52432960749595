<template>
  <div>
    <Head title="Admins" />
    <h1 class="mb-5 text-[1.25rem]">
      Admins Template
    </h1>
    <tab-menu :tabs="tabs"></tab-menu>
    <p>{{ convertJaParams(viewsJa.common.test_vue_ja, {testabc: 200, testdef: "Hello FCx Team"}) }}</p>
    <div class="mb-6">
      <h2 class="r:text-[18px] mb-4">Customer</h2>
      <div class="filter-panel bg-white shadow-[0_0_2rem_0_rgba(136,152,170,0.15)]">
        <div class="p-5 border-b border-tw-gray-250">
          <h3 class="font-bold r:text-[14px] leading-[1.2]">Filter</h3>
        </div>
        <form @submit.prevent="$emit('submit')" class="p-5 mb-6">
          <div class="flex items-center mb-4">
            <label class="r:text-[14px] r:min-w-[160px] r:lg:min-w-[200px]">ユーザーステータス</label>
            <div class="w-full lg:grid lg:grid-flow-row lg:grid-cols-3 lg:gap-4">
              <div class="flex items-center mb-4 lg:mb-0">
                <select-input
                  v-model="form.select_1"
                  class="mr-4 w-full r:max-w-[180px] lg:max-w-[60%]"
                  id="select_1"
                >
                </select-input>
                <label class="r:text-[14px] " for="select_1">課金ステータス</label>
              </div>
              <div class="flex items-center mb-4 lg:mb-0">
                <select-input
                  v-model="form.select_2"
                  class="mr-4 w-full r:max-w-[180px] lg:max-w-[60%]"
                  id="select_2"
                >
                </select-input>
                <label class="r:text-[14px] " for="select_2">契約ステータス</label>
              </div>
              <div class="flex items-center">
                <select-input
                  v-model="form.select_3"
                  class="mr-4 w-full r:max-w-[180px] lg:max-w-[60%]"
                  id="select_3"
                >
                </select-input>
              </div>
            </div>
          </div>
          <div class="flex items-center mb-4">
            <label class="r:text-[14px] r:min-w-[160px] r:lg:min-w-[200px]">お客様情報</label>
            <div class="w-full md:grid md:grid-flow-row md:grid-cols-2 lg:grid-cols-4 xl:grid-cols-6 md:gap-4">
              <div class="mb-4 lg:mb-0 xl:col-span-2">
                <label class="r:text-[14px] " for="input_1">お客様名</label>
                <text-input
                  v-model="form.input_1"
                  class="w-full"
                  id="input_1"
                >
                </text-input>
              </div>
              <div class="mb-4 lg:mb-0">
                <label class="r:text-[14px] " for="input_2">ユーザーID</label>
                <text-input
                  v-model="form.input_2"
                  class="w-full"
                  id="input_2"
                >
                </text-input>
              </div>
              <div class="mb-4 lg:mb-0">
                <label class="r:text-[14px] " for="input_3">UID</label>
                <text-input
                  v-model="form.input_3"
                  class="w-full"
                  id="input_3"
                >
                </text-input>
              </div>
              <div class="mb-4 lg:mb-0 xl:col-span-2">
                <label class="r:text-[14px] " for="input_4">メールアドレス</label>
                <text-input
                  v-model="form.input_4"
                  class="w-full"
                  id="input_4"
                >
                </text-input>
              </div>
            </div>
          </div>
          <div class="flex items-center justify-center">
            <button class="rounded-full bg-accent-500 hover:bg-accent-600 transition text-white font-bold leading-[1.5] py-3.5 px-8 r:min-w-[250px]">検索</button>
          </div>
        </form>
      </div>
      <div class="result-panel bg-white shadow-[0_0_2rem_0_rgba(136,152,170,0.15)]">
        <div class="p-5">
          <div class="flex flex-col justify-between sm:flex-row sm:items-center">
            <h3 class="font-bold r:text-[14px] leading-[1.2] mb-3 sm:mb-0">件数: 2件</h3>
            <div class="flex items-center">
              <strong class="r:text-[14px] leading-[1.2] mr-3">CSVダウンロード</strong>
              <a href="#" class="rounded-full bg-accent-500 hover:bg-accent-600 transition text-white font-bold leading-[1.5] py-3.5 px-8">ユーザーデータ</a>
            </div>
          </div>
        </div>
        <table-custom
          :tableHeader="customers_header"
          :tableBody="customers_content"
          :hasSlotEdit="true"
          :hasSort="true"
        >
          <template #edit>
            <div class="flex flex-col">
              <a href="#" class="text-accent-500 hover:text-accent-600 transition leading-[1.5] r:text-[14px]">編集</a>
              <a href="#" class="text-accent-500 hover:text-accent-600 transition leading-[1.5] r:text-[14px]">削除</a>
            </div>
          </template>
        </table-custom>
        <pagination :meta="meta"></pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { Head } from '@inertiajs/vue3';
import Layout from '@/Layouts/Admin.vue';
import SelectInput from '@/Shared/SelectInput.vue';
import TextInput from '@/Shared/TextInput.vue';
import TableCustom from '@/Shared/Admin/TableCustom.vue';
import TabMenu from '@/Shared/Admin/TabMenu.vue';
import Pagination from '@/Shared/Admin/Pagination.vue';
import { convertJaParams } from '@/utils/utils';

export default {
  components: {
    Head,
    SelectInput,
    TextInput,
    TableCustom,
    TabMenu,
    Pagination
  },
  layout: Layout,
  props: {
    meta: {
      type: Object,
      required: true,
    }
  },
  data() {
    return {
      convertJaParams: convertJaParams,
      viewsJa: this.jaConfig.viewsJa,
      tabs: [
        {
          label: 'Guide',
          url: this.$routes.guide_admin_admins(),
          isActive: true
        },
        {
          label: 'Guide_2',
          url: this.$routes.guide_2_admin_admins(),
          isActive: false
        },
      ],
      customers_header: [
        {
          label: 'ID',
          url: 'https://google.com'
        },
        {
          label: 'Name',
          url: 'https://google.com'
        },
        {
          label: 'User_ID',
          url: 'https://google.com'
        },
        {
          label: 'UID',
          url: 'https://google.com'
        },
        {
          label: 'Email',
          url: 'https://google.com'
        },
      ],
      customers_content: [
        {
          id: 1,
          name: '山田太郎',
          user_id: 'yamada',
          uid: '11111',
          email: 'yamada.test.1@service.com'
        },
        {
          id: 2,
          name: '山田太郎太郎',
          user_id: 'test_yamada',
          uid: '22222',
          email: 'yamada.test.2@service.com'
        }
      ],
      form: {
        select_1: 0,
        select_2: 0,
        select_3: 0,
        input_1: '',
        input_2: '',
        input_3: '',
        input_4: '',
      }
    }
  },
}
</script>
