<template>
  <div class="notification r:max-w-[672px] mx-auto">
    <!-- common-infos -->
    <div class="r:max-w-[672px] mx-auto mb-16 common-section">
      <h2 class="mb-4 px-6 r:text-[24px] leading-[1.53]"> {{ viewsJa.contracts.blocks.notification.groups.common_info.group_title }}</h2>
      <!-- common-info -->
      <div class="px-4 py-6 bg-white border-b common-info xs:px-6 xs:py-8 border-neutral-gray-100">
        <span v-for="(info, index) in viewsJa.contracts.blocks.notification.infos"
          :key="index" class="flex r:text-[16px] leading-[1.53]">
          <span class="mr-2">•</span>
          <span>{{ info }}</span>
        </span>
        <div class="px-5 py-4 mt-8 border rounded-lg border-tw-red-200 bg-tw-red-50 modal-area">
          <svg class="mb-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M10.8474 3.31557L1.5381 19.3293C1.02136 20.2181 1.66264 21.3327 2.6908 21.3327H21.3094C22.3376 21.3327 22.9788 20.2181 22.4621 19.3293L13.1528 3.31557C12.6387 2.43127 11.3615 2.43127 10.8474 3.31557ZM13.3337 12.666C13.3337 13.4024 12.7368 13.9994 12.0004 13.9994C11.264 13.9994 10.6671 13.4024 10.6671 12.666V8.66604C10.6671 7.92966 11.264 7.3327 12.0004 7.3327C12.7368 7.3327 13.3337 7.92966 13.3337 8.66604V12.666ZM10.3337 16.9994C10.3337 16.0789 11.0799 15.3327 12.0004 15.3327C12.9209 15.3327 13.6671 16.0789 13.6671 16.9994C13.6671 17.9198 12.9209 18.666 12.0004 18.666C11.0799 18.666 10.3337 17.9198 10.3337 16.9994Z" fill="#E33B24"/>
          </svg>
          <strong class="block r:text-[14px] text-tw-red-600 leading-[1.53] r:tracking-[0.25px]">{{ viewsJa.contracts.blocks.notification.modal.on_behalf.highlight_note }}</strong>
          <span class="block mb-4 r:text-[14px] text-tw-red-600 leading-[1.53] r:tracking-[0.25px] ">{{ viewsJa.contracts.blocks.notification.modal.on_behalf.desc }}</span>
          <div class="text-center">
            <button type="button" data-testid="btn-on-behalf-info" id="btn-on-behalf-info" class="mb-6 inline-flex items-center justify-center rounded-full btn-gradient btn-primary-gradient-horizontal r:text-[14px] xs:r:text-[16px] py-2 px-2 xs:r:px-[18px] xs:py-4 font-medium text-white shadow-gray-1 leading-[1.33] r:min-h-[53px]" @click.prevent="handleModalState('on_behalf_info')">
              <span id="btn-on-behalf-info-span" class="mr-2 r:min-w-[122px]">{{ viewsJa.contracts.blocks.notification.modal.on_behalf.btn }}</span>
              <svg id="vbtn-on-behalf-info-svg" xmlns="http://www.w3.org/2000/svg" width="18" height="19" viewBox="0 0 18 19" fill="none">
                <path id="vbtn-on-behalf-info-path" fill-rule="evenodd" clip-rule="evenodd" d="M6.32036 3.76646C6.72549 3.3911 7.35819 3.41524 7.73355 3.82036L12.9958 9.50001L7.73355 15.1796C7.35819 15.5848 6.72549 15.6089 6.32036 15.2335C5.91524 14.8582 5.8911 14.2255 6.26646 13.8204L10.2693 9.50001L6.26646 5.17965C5.8911 4.77452 5.91524 4.14182 6.32036 3.76646Z" fill="#FFFFFF"/>
              </svg>
            </button>
            <div class="flex items-center justify-center mb-4 is-read ">
              <span class="flex items-center justify-center w-5 h-5 mr-2 rounded" :class="[isReadModal ? 'bg-primary-600' : 'bg-white']">
                <svg :class="[isReadModal ? 'opacity-100' : 'opacity-0']" xmlns="http://www.w3.org/2000/svg" width="11" height="9" viewBox="0 0 11 9" fill="none">
                  <path d="M10.001 1L6.99924 4L3.99751 7L0.999729 4" stroke="#FFFFFF" stroke-width="2"/>
                </svg>
              </span>
              <span :class="isReadModal ? '' : 'text-neutral-gray-300'">{{ viewsJa.contracts.blocks.notification.modal.on_behalf.agree_check }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- show-plan -->
    <div class="r:max-w-[672px] mx-auto show-plan-section px-4 py-6 xs:px-6 xs:py-4">
      <h2 class="block mb-1 r:text-[16px] leading-[1.53] r:tracking-[0.25px] text-primary-500"> {{ viewsJa.contracts.blocks.notification.groups.show_plan_info.group_title }}</h2>
      <div class="show-plan-infos">
        <span class="r:text-[24px] leading-[1.53] r:tracking-[0.25px] block" v-for="({gender}, _key) in userData.relatives_attributes" :key="_key">
          <span v-if="!ignoreParent[gender]">{{ viewsJa.contracts.blocks.notification.plan_name[quotationData[gender].plan] }}</span>
        </span>
        <span> {{ viewsJa.contracts.blocks.notification.plan_note }}</span>
      </div>
    </div>
    <!-- plan-details -->
    <div class="r:max-w-[672px] mx-auto plan-details-section">
      <div class="px-4 py-6 bg-white border-b plan-details-infos xs:px-6 xs:py-8 border-neutral-gray-100">
        <h2 class="block mb-8 r:text-[18px] leading-[1.53] r:tracking-[0.25px] text-primary-500">{{ viewsJa.contracts.blocks.notification.groups.plan_details_info.group_title }}</h2>
        <span class="r:text-[16px] leading-[1.53] r:tracking-[0.25px] block"  v-for="(parent, index) in userData.relatives_attributes" :key="index">
          <span class="r:min-w-[130px] inline-block mb-2 mr-6 " v-if="parent.gender=='male' && !ignoreParent[parent.gender]"> {{ viewsJa.contracts.blocks.confirmation.father }}</span>
          <span class="r:min-w-[130px] inline-block mb-2 mr-6" v-if="parent.gender=='female' && !ignoreParent[parent.gender]"> {{ viewsJa.contracts.blocks.confirmation.mother }}</span>
          <span class="flex-1 ml-6" v-if="!ignoreParent[parent.gender]">{{ `${parent.last_name}  ${parent.first_name}` }}</span>
        </span>
      </div>
    </div>
    <!-- question -->
    <div class="r:max-w-[672px] mx-auto questions-section">
      <div class="px-4 py-6 bg-white border-b xs:px-6 xs:py-8 questions-infos border-neutral-gray-100">
        <h2 class="block mb-8 r:text-[18px] leading-[1.53] r:tracking-[0.25px] text-primary-500">{{ viewsJa.contracts.blocks.notification.groups.question_info.group_title }}</h2>
        <div
          class="mb-8 question-details last:mb-0"
          v-for="(question, idx) in viewsJa.contracts.blocks.notification.questions"
          :key="idx"
        >
          <h5 class="block text-center font-bold mb-2 r:text-[16px] leading-[1.53] text-neutral-gray-600 py-4 px-8 rounded bg-neutral-100">{{ question.label }}</h5>
          <span class="block r:text-[16px] leading-[1.5]" v-html="question.question_details"></span>
          <!-- open-modal definition_info -->
          <div class="text-right" v-if="question.btn_text_1">
            <button type="button" data-testid="btn-definition-info" id="btn-definition-info" class="underline text-accent-500 hover:text-accent-600 transition leading-[2] r:text-[16px]" @click.prevent="handleModalState('definition_info')">
              <span id="btn-definition-info-span"> {{ question.btn_text_1 }}</span>
            </button>
          </div>
          <!-- open-modal list_and_notices_info -->
          <div class="text-right" v-if="question.btn_text_1">
            <button type="button" data-testid="btn-list-notices-info" id="btn-list-notices-info" class="underline text-accent-500 hover:text-accent-600 transition leading-[2] r:text-[16px]" @click.prevent="handleModalState('list_and_notices_info')">
              <span id="btn-list-notices-info-span"> {{ question.btn_text_2 }}</span>
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- question-checking -->
    <div class=" mt-0.5 question-checking-section r:max-w-[672px] mx-auto mb-16">
      <div class="px-4 py-6 border-b bg-primary-50 question-che xs:px-6 xs:py-8 border-neutral-gray-100">
        <h2 class="block mb-8 r:text-[18px] leading-[1.53] r:tracking-[0.25px] text-primary-500">{{ viewsJa.contracts.blocks.notification.groups.question_checking_info.group_title }}</h2>
        <span class="block mb-4 r:text-[16px] leading-[1.53] r:tracking-[0.25px]"> {{ viewsJa.contracts.blocks.notification.question_checking.desc }}</span>
        <div class="px-4 py-6 bg-white rounded-2xl checking-group">
          <template v-for="(parent, index) in userData.relatives_attributes" :key="index">
            <template v-if="parent.gender === 'male' && !ignoreFromSession.male">
              <div class="group-father">
                <span class="r:text-[14px] flex items-center mb-4">
                  <span>{{ `${parent.last_name}  ${parent.first_name}` }}さま</span>
                  <span class="r:text-[10px] text-tw-red-500 leading-[1] bg-tw-red-50 rounded-sm ml-1 px-1 pt-[calc(5/16)rem] pb-[calc(3/16)rem]"> {{ viewsJa.common.require_field }}</span>
                </span>
                <div class="w-full mb-4">
                  <radio-input
                    v-model="gender_male"
                    id="user_noti_gender_male_0"
                    name="user_noti[gender_male]"
                    :label="viewsJa.contracts.blocks.notification.question_checking.all_no"
                    class="mb-2 radio-bar-primary"
                    radioValue="0"
                    radioClass="r:text-[16px] leading-[1.7] r:tracking-[0.64px]"
                    @change="handleUpdateCondition(); generateIgnoreValue('male')"
                  ></radio-input>
                  <radio-input
                    v-model="gender_male"
                    id="user_noti_gender_male_1"
                    name="user_noti[gender_male]"
                    :label="viewsJa.contracts.blocks.notification.question_checking.even_one"
                    class="radio-bar-primary"
                    radioValue="1"
                    radioClass="r:text-[16px] leading-[1.7] r:tracking-[0.64px]"
                    @change="handleUpdateCondition(); generateIgnoreValue('male')"
                  ></radio-input>
                  <!-- show warning -->
                  <div class="px-5 py-4 mt-1 border rounded-lg warning-note border-tw-red-200 bg-tw-red-50 modal-area" v-if="gender_male === '1'">
                    <svg class="mb-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M10.8474 3.31557L1.5381 19.3293C1.02136 20.2181 1.66264 21.3327 2.6908 21.3327H21.3094C22.3376 21.3327 22.9788 20.2181 22.4621 19.3293L13.1528 3.31557C12.6387 2.43127 11.3615 2.43127 10.8474 3.31557ZM13.3337 12.666C13.3337 13.4024 12.7368 13.9994 12.0004 13.9994C11.264 13.9994 10.6671 13.4024 10.6671 12.666V8.66604C10.6671 7.92966 11.264 7.3327 12.0004 7.3327C12.7368 7.3327 13.3337 7.92966 13.3337 8.66604V12.666ZM10.3337 16.9994C10.3337 16.0789 11.0799 15.3327 12.0004 15.3327C12.9209 15.3327 13.6671 16.0789 13.6671 16.9994C13.6671 17.9198 12.9209 18.666 12.0004 18.666C11.0799 18.666 10.3337 17.9198 10.3337 16.9994Z" fill="#E33B24"/>
                    </svg>
                    <strong class="block r:text-[14px] text-tw-red-600 leading-[1.53] r:tracking-[0.25px]">{{ viewsJa.contracts.blocks.notification.warning_note.highlight_note }}</strong>
                    <span class="block mb-4 r:text-[14px] text-tw-red-600 leading-[1.53] r:tracking-[0.25px] ">{{ convertJaParams(viewsJa.contracts.blocks.notification.warning_note.desc, {first_name: parent.first_name, last_name: parent.last_name}) }}</span>
                    <checkbox-input
                      v-model="ignoreParent.male"
                      id="ignore_male"
                      name="ignore[male]"
                      :label="convertJaParams(viewsJa.contracts.blocks.notification.warning_note.checkbox_note, {first_name: parent.first_name, last_name: parent.last_name})"
                      class="!border-none check-bar-primary"
                      checkboxValue=""
                      checkboxClass="r:text-[16px] leading-[1.53] r:tracking-[0.64px]"
                      @change="checkboxUpdated"
                    >
                    </checkbox-input>
                  </div>
                </div>
              </div>
            </template>
            <template v-if="parent.gender === 'female' && !ignoreFromSession.female">
              <div class="group-mother">
                <span class="r:text-[14px] flex items-center mb-4">
                  <span>{{ `${parent.last_name}  ${parent.first_name}` }}さま</span>
                  <span class="r:text-[10px] text-tw-red-500 leading-[1] bg-tw-red-50 rounded-sm ml-1 px-1 pt-[calc(5/16)rem] pb-[calc(3/16)rem]"> {{ viewsJa.common.require_field }}</span>
                </span>
                <div class="w-full">
                  <radio-input
                    v-model="gender_female"
                    id="user_noti_gender_female_0"
                    name="user_noti[gender_female]"
                    :label="viewsJa.contracts.blocks.notification.question_checking.all_no"
                    class="mb-2 radio-bar-primary"
                    radioValue="0"
                    radioClass="r:text-[16px] leading-[1.7] r:tracking-[0.64px]"
                    @change="handleUpdateCondition(); generateIgnoreValue('female')"
                  ></radio-input>
                  <radio-input
                    v-model="gender_female"
                    id="user_noti_gender_female_1"
                    name="user_noti[gender_female]"
                    :label="viewsJa.contracts.blocks.notification.question_checking.even_one"
                    class="radio-bar-primary"
                    radioValue="1"
                    radioClass="r:text-[16px] leading-[1.7] r:tracking-[0.64px]"
                    @change="handleUpdateCondition(); generateIgnoreValue('female')"
                  ></radio-input>
                  <div class="px-5 py-4 mt-1 border rounded-lg warning-note border-tw-red-200 bg-tw-red-50 modal-area" v-if="gender_female === '1'">
                    <svg class="mb-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M10.8474 3.31557L1.5381 19.3293C1.02136 20.2181 1.66264 21.3327 2.6908 21.3327H21.3094C22.3376 21.3327 22.9788 20.2181 22.4621 19.3293L13.1528 3.31557C12.6387 2.43127 11.3615 2.43127 10.8474 3.31557ZM13.3337 12.666C13.3337 13.4024 12.7368 13.9994 12.0004 13.9994C11.264 13.9994 10.6671 13.4024 10.6671 12.666V8.66604C10.6671 7.92966 11.264 7.3327 12.0004 7.3327C12.7368 7.3327 13.3337 7.92966 13.3337 8.66604V12.666ZM10.3337 16.9994C10.3337 16.0789 11.0799 15.3327 12.0004 15.3327C12.9209 15.3327 13.6671 16.0789 13.6671 16.9994C13.6671 17.9198 12.9209 18.666 12.0004 18.666C11.0799 18.666 10.3337 17.9198 10.3337 16.9994Z" fill="#E33B24"/>
                    </svg>
                    <strong class="block r:text-[14px] text-tw-red-600 leading-[1.53] r:tracking-[0.25px]">{{ viewsJa.contracts.blocks.notification.warning_note.highlight_note }}</strong>
                    <span class="block mb-4 r:text-[14px] text-tw-red-600 leading-[1.53] r:tracking-[0.25px] ">{{ convertJaParams(viewsJa.contracts.blocks.notification.warning_note.desc, {first_name: parent.first_name, last_name: parent.last_name}) }}</span>
                    <checkbox-input
                      v-model="ignoreParent.female"
                      id="ignore_female"
                      name="ignore[female]"
                      :label="convertJaParams(viewsJa.contracts.blocks.notification.warning_note.checkbox_note, {first_name: parent.first_name, last_name: parent.last_name})"
                      class="!border-none check-bar-primary"
                      checkboxValue=""
                      checkboxClass="r:text-[16px] leading-[1.53] r:tracking-[0.64px]"
                      @change="checkboxUpdated"
                    >
                    </checkbox-input>
                  </div>
                </div>
              </div>
            </template>
          </template>
        </div>
        <span class="flex r:text-[16px] leading-[1.5] mt-4" v-for="(note_text, index) in viewsJa.contracts.blocks.notification.note" :key="index">
          <span class="pr-2">•</span>
          <span class=""> {{ note_text }}</span>
        </span>
      </div>
    </div>
    <!-- group buttons -->
    <div class="group-button r:max-w-[672px] mx-auto">
      <div class="flex flex-col items-center px-4 xs:px-6">
        <span :class="isReadModal ? 'hidden' : 'block mb-2 text-tw-red-500'">{{ viewsJa.contracts.blocks.notification.validate_pdf  }}</span>
        <span :class="!validateIgnore ? 'hidden' : 'block mb-2 text-tw-red-500'">{{ viewsJa.contracts.blocks.notification.validate_note  }}</span>

        <button type="button" data-testid="submit-application-form-step-3" id="submit-application-form-step-3" :disabled="activeStep ? false : true" class="mb-6 inline-flex items-center justify-center rounded-full btn-gradient btn-primary-gradient-horizontal r:text-[14px] xs:r:text-[16px] py-2 px-2 xs:r:px-[18px] xs:py-4 font-medium text-white shadow-gray-1 leading-[1.33] r:min-h-[53px]" @click.prevent="setStep(pageStep + 1); handleUpdateUser()">
          <span id="submit-application-form-step-3-span" class="mr-2">{{ viewsJa.contracts.blocks.notification.btn.next }}</span>
          <svg id="submit-application-form-step-3-svg" xmlns="http://www.w3.org/2000/svg" width="18" height="19" viewBox="0 0 18 19" fill="none">
            <path id="submit-application-form-step-3-path" fill-rule="evenodd" clip-rule="evenodd" d="M6.32036 3.76646C6.72549 3.3911 7.35819 3.41524 7.73355 3.82036L12.9958 9.50001L7.73355 15.1796C7.35819 15.5848 6.72549 15.6089 6.32036 15.2335C5.91524 14.8582 5.8911 14.2255 6.26646 13.8204L10.2693 9.50001L6.26646 5.17965C5.8911 4.77452 5.91524 4.14182 6.32036 3.76646Z" :fill="activeStep ? '#FFFFFF' : '#D3DDE8'"/>
          </svg>
        </button>
        <button type="button" data-testid="back-form-step-3" id="back-form-step-3" class="btn-solid inline-flex items-center justify-center rounded-full btn-gradient r:text-[14px] xs:r:text-[16px] py-2 px-2 xs:px-4 xs:py-4 text-white bg-neutral-gray-400 leading-[1.33] r:min-h-[53px] r:tracking-[0.25px] shadow-gray-1" @click.prevent="setStep(pageStep - 1)">
          <svg id="back-form-step-3-svg" xmlns="http://www.w3.org/2000/svg" width="18" height="19" viewBox="0 0 18 19" fill="none">
            <path id="back-form-step-3-path" fill-rule="evenodd" clip-rule="evenodd" d="M11.6796 15.2335C11.2745 15.6089 10.6418 15.5848 10.2665 15.1796L5.00417 9.49999L10.2665 3.82035C10.6418 3.41523 11.2745 3.3911 11.6796 3.76645C12.0848 4.14181 12.1089 4.77451 11.7335 5.17964L7.73066 9.49999L11.7335 13.8204C12.1089 14.2255 12.0848 14.8582 11.6796 15.2335Z" fill="white"/>
          </svg>
          <span id="back-form-step-3-svg-span" class="ml-2">{{ viewsJa.contracts.blocks.notification.btn.prev }}</span>
        </button>
      </div>
    </div>

    <!-- on_behalf_info modal -->
    <modal
      :open="modalState['on_behalf_info']"
      @close="modalState['on_behalf_info'] = false"
      modalContentCls="!bg-white"
      modalDialogCls="sm:r:max-w-[720px]"
    >
      <template #modalHeader>
        <div class="px-4 py-2 border-b border-neutral-gray-100 modal-header">
          <button type="button" class="flex w-12 h-12 p-3 ml-auto group hover:opacity-70" title="Close" style="margin-left: auto;" @click="modalState['on_behalf_info'] = false">
            <svg class="group-hover:opacity-70" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path d="M6.27614 4.39052C5.75544 3.86983 4.91122 3.86983 4.39052 4.39052C3.86983 4.91122 3.86983 5.75544 4.39052 6.27614L10.1144 12L4.3906 17.7239C3.8699 18.2446 3.8699 19.0888 4.3906 19.6095C4.9113 20.1302 5.75552 20.1302 6.27622 19.6095L12 13.8856L17.7239 19.6095C18.2446 20.1302 19.0888 20.1302 19.6095 19.6095C20.1302 19.0888 20.1302 18.2446 19.6095 17.7239L13.8856 12L19.6095 6.27614C20.1302 5.75544 20.1302 4.91122 19.6095 4.39052C19.0888 3.86982 18.2446 3.86983 17.7239 4.39053L12 10.1144L6.27614 4.39052Z" fill="#93A3B5"/>
            </svg>
          </button>
          <h2 class="r:text-[16px] leading-[1.53] r:tracking-[0.25px]">{{ viewsJa.contracts.blocks.notification.modal.on_behalf.desc_title_1 }}</h2>
        </div>
      </template>
      <template #modalBody>
        <div class="flex-1 overflow-y-auto modal-body" ref="modalContent">
          <div class="px-4 pt-4 xs:pt-6">
            <span class="block mb-4 r:text-[14px] leading-[1.53] r:tracking-[0.25px]">{{ viewsJa.contracts.blocks.notification.modal.on_behalf.other_title_1 }}</span>
            <span v-for="(info, index) in viewsJa.contracts.blocks.notification.modal.on_behalf.details_1"
            :key="index" class="flex r:text-[14px] leading-[1.53] mb-4">
              <span class="mr-2">{{ index + 1}}.</span>
              <span v-html="info"></span>
            </span>
            <p class="flex r:text-[12px] leading-[1.53] mb-4" v-html="viewsJa.contracts.blocks.notification.modal.on_behalf.small_note"></p>
            <span class="block px-4 py-2 small-title r:text-[14px] leading-[1.53] r:tracking-[0.25px]">{{ viewsJa.contracts.blocks.notification.modal.on_behalf.desc_title_2 }}</span>
            <span v-for="(info, index) in viewsJa.contracts.blocks.notification.modal.on_behalf.other_desc"
            :key="index" class="flex r:text-[14px] leading-[1.53]">
              <span class="mr-2">•</span>
              <span>{{ info }}</span>
            </span>
          </div>
        </div>
      </template>
      <template #modalFooter>
        <div class="p-4 text-center modal-footer xs:py-6">
          <button type="button" data-testid="btn-read-modal" id="btn-read-modal" :disabled="!checkReadModal" class="shadow-gray-1 inline-flex items-center justify-center rounded-full btn-gradient btn-primary-gradient-horizontal text-white r:text-[10px] xs:r:text-[14px] py-3.5 px-4 xs:px-4 xs:pt-4 xs:pb-3.5 font-bold leading-[1.53] r:min-h-[47px]" @click.prevent="handleModalClose">{{ viewsJa.contracts.blocks.notification.modal.close_modal_behalf }}</button>
        </div>
      </template>
    </modal>

    <!-- definition modal -->
    <modal
      :open="modalState['definition_info']"
      @close="modalState['definition_info'] = false"
      modalContentCls="!bg-white"
      modalDialogCls="sm:r:max-w-[720px]"
    >
      <template #modalHeader>
        <div class="px-4 py-2 border-b border-neutral-gray-100 modal-header">
          <button type="button" class="flex w-12 h-12 p-3 ml-auto group hover:opacity-70" title="Close" style="margin-left: auto;" @click="modalState['definition_info'] = false">
            <svg class="group-hover:opacity-70" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path d="M6.27614 4.39052C5.75544 3.86983 4.91122 3.86983 4.39052 4.39052C3.86983 4.91122 3.86983 5.75544 4.39052 6.27614L10.1144 12L4.3906 17.7239C3.8699 18.2446 3.8699 19.0888 4.3906 19.6095C4.9113 20.1302 5.75552 20.1302 6.27622 19.6095L12 13.8856L17.7239 19.6095C18.2446 20.1302 19.0888 20.1302 19.6095 19.6095C20.1302 19.0888 20.1302 18.2446 19.6095 17.7239L13.8856 12L19.6095 6.27614C20.1302 5.75544 20.1302 4.91122 19.6095 4.39052C19.0888 3.86982 18.2446 3.86983 17.7239 4.39053L12 10.1144L6.27614 4.39052Z" fill="#93A3B5"/>
            </svg>
          </button>
          <h2 class="r:text-[16px] leading-[1.53] r:tracking-[0.25px]">{{ viewsJa.contracts.blocks.notification.modal.definition.desc_title }}</h2>
        </div>
      </template>
      <template #modalBody>
        <div class="flex-1 overflow-y-auto modal-body">
          <div class="px-4 pt-4 xs:pt-6">
            <span class="block mb-6 last:mb-0" v-for="(item, index) in viewsJa.contracts.blocks.notification.modal.definition.details" :key="index">
              <span class="block r:text-[14px] leading-[1.53] mb-6 text-primary-600">{{ item.small_title }}</span>
              <span class="r:text-[14px] leading-[1.53]">{{ item.desc }}</span>
            </span>
          </div>
        </div>
      </template>
      <template #modalFooter>
        <div class="p-4 text-center modal-footer xs:py-6">
          <button type="button" data-testid="btn-close-modal-definition" class="shadow-gray-1 btn-solid max-w-[109px] inline-flex items-center justify-center rounded-full r:text-[12px] xs:r:text-[14px] py-1.5 px-3 xs:px-3.5 xs:py-4 mx-auto w-full text-white bg-neutral-gray-400 leading-[1.53] r:min-h-[47px]" @click.prevent="modalState['definition_info'] = false">{{ viewsJa.contracts.blocks.notification.modal.close_modal_btn }}</button>
        </div>
      </template>
    </modal>

    <!-- list_and_notices -->
    <modal
      :open="modalState['list_and_notices_info']"
      @close="modalState['list_and_notices_info'] = false"
      modalContentCls="!bg-white"
      modalDialogCls="sm:r:max-w-[720px]"
    >
      <template #modalHeader>
        <div class="px-4 py-2 border-b border-neutral-gray-100 modal-header">
          <button type="button" class="flex w-12 h-12 p-3 ml-auto group hover:opacity-70" title="Close" style="margin-left: auto;" @click="modalState['list_and_notices_info'] = false">
            <svg class="group-hover:opacity-70" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path d="M6.27614 4.39052C5.75544 3.86983 4.91122 3.86983 4.39052 4.39052C3.86983 4.91122 3.86983 5.75544 4.39052 6.27614L10.1144 12L4.3906 17.7239C3.8699 18.2446 3.8699 19.0888 4.3906 19.6095C4.9113 20.1302 5.75552 20.1302 6.27622 19.6095L12 13.8856L17.7239 19.6095C18.2446 20.1302 19.0888 20.1302 19.6095 19.6095C20.1302 19.0888 20.1302 18.2446 19.6095 17.7239L13.8856 12L19.6095 6.27614C20.1302 5.75544 20.1302 4.91122 19.6095 4.39052C19.0888 3.86982 18.2446 3.86983 17.7239 4.39053L12 10.1144L6.27614 4.39052Z" fill="#93A3B5"/>
            </svg>
          </button>
            <h2 class="r:text-[16px] leading-[1.53] r:tracking-[0.25px]">{{ viewsJa.contracts.blocks.notification.modal.list_and_notices.desc_title }}</h2>
        </div>
      </template>
      <template #modalBody>
        <div class="flex-1 overflow-y-auto modal-body">
          <div class="px-4">
            <div class="mt-6 wrap-content" v-for="(item, index) in viewsJa.contracts.blocks.notification.modal.list_and_notices.details" :key="index">
              <span class="block r:text-[14px] leading-[1.53] mb-6 text-primary-600">{{ item.small_title }}</span>
              <div class="border table-container border-neutral-gray-200">
                <div class="content">
                  <div class="border-b row border-neutral-gray-200 last:border-none" v-for="(cate, idx) in item.list" :key="idx">
                    <span class="r:text-[14px] leading-[1.53] p-2 border-r bg-neutral-gray-50 title border-neutral-gray-200 ">{{ cate.title }}</span>
                    <span class="r:text-[14px] leading-[1.53] p-2 description" v-html="cate.small_desc"></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
      <template #modalFooter>
        <div class="p-4 text-center modal-footer xs:py-6">
          <button type="button" data-testid="btn-close-modal-list-notices-info" class="shadow-gray-1 btn-solid max-w-[109px] inline-flex items-center justify-center rounded-full r:text-[12px] xs:r:text-[14px] py-1.5 px-3 xs:px-3.5 xs:py-4 mx-auto w-full text-white bg-neutral-gray-400 leading-[1.53] r:min-h-[47px]" @click.prevent="modalState['list_and_notices_info'] = false">{{ viewsJa.contracts.blocks.notification.modal.close_modal_btn }}</button>
        </div>
      </template>
    </modal>
  </div>
</template>

<style  lang="scss" scoped>
  :deep(.tippy-content) {
    padding: 0;
  }
  .table-container {
    .content {
      display: flex;
      flex-direction: column;
    }
    .row {
      display: flex;
    }
    .description {
      flex: 1;
    }
    .title {
      width: 113px;
      font-weight: bold;
    }
  }
</style>

<script>
import { convertJaParams } from '@/utils/utils';
import RadioInput from '@/Shared/RadioInput.vue';
import CheckboxInput from '@/Shared/CheckboxInput.vue';
import Modal from '@/Shared/Modal.vue';

export default {
  components: {
    RadioInput,
    CheckboxInput,
    Modal,
  },
  props: {
    pageStep: {
      type: Number,
      required: true
    },
    quotationData: {
      type: Object,
      required: false,
    },
    relatives: {
      type: Array,
      required: false,
    },
    userInfo: {
      type: Object,
      required: false,
    }
  },
  emits: ['set-step'],
  data() {
    return {
      viewsJa: this.jaConfig.viewsJa,
      convertJaParams: convertJaParams,
      gender_male: '0',
      gender_female: '0',
      activeStep: false,
      checkReadModal: false,
      validateIgnore: false,
      isReadModal: JSON.parse(localStorage.getItem('isReadModal')) || false,
      userData: {},
      modalState: {
        on_behalf_info: false,
      },
      ignoreParent: {
        male: null,
        female: null,
      },
      ignoreFromSession: {},
    }
  },
  created() {
    if (localStorage.getItem('ignoreParent')) {
      this.ignoreFromSession = JSON.parse(localStorage.getItem('ignoreParent'));
      this.ignoreParent = {...this.ignoreFromSession};
    }
  },
  mounted() {
    this.handleUpdateCondition();
    if (localStorage.getItem('userForm')) {
      this.userData = JSON.parse(localStorage.getItem('userForm')).user;
    }
    const modalContent = this.$refs.modalContent;
    modalContent.addEventListener('scroll', this.handleScrollModal);

    // re-assign value of gender when back from step 4
    if(this.ignoreParent.male) {
      this.gender_male = 1;
    }
    if(this.ignoreParent.female) {
      this.gender_female = 1;
    }

  },
  methods: {
    setStep (step) {
      this.$emit('set-step', step);
      localStorage.setItem('userFormStep', step);
    },
    handleUpdateUser() {
      // add value to userForm data
      for (let r in this.userData.relatives_attributes) {
        if (this.userData.relatives_attributes[r].gender == 'male') {
          this.userData.relatives_attributes[r].ignore_value = this.ignoreParent.male;
        } else {
          this.userData.relatives_attributes[r].ignore_value = this.ignoreParent.female;
        }
      }
      // Update the localStorage
      localStorage.setItem('ignoreParent', JSON.stringify(this.ignoreParent));
      localStorage.setItem('userForm', JSON.stringify({user: this.userData}));
    },
    handleUpdateCondition() {
      this.validateIgnore = this.gender_male == '1' || this.gender_female == '1';
      this.activeStep = (this.gender_male == '0' || this.gender_female == '0') && this.isReadModal;
    },

    // close modal and check for checkbox
    handleModalClose() {
      // close modal
      this.modalState['on_behalf_info'] = false;
      // set value to session
      this.isReadModal = true;
      localStorage.setItem('isReadModal', true);
      if(this.isReadModal && this.gender_male == '0' || this.gender_female == '0') {
        this.activeStep = true
      }
    },

    handleScrollModal() {
      const modalContent = this.$refs.modalContent;
      const threshold = 25;
      if (modalContent) {
        const buttonBottom = modalContent.scrollHeight - modalContent.clientHeight - threshold;
        if (modalContent.scrollTop >= buttonBottom) {
          this.checkReadModal = true;
        } else {
          this.checkReadModal= false;
        }
      }
    },

    generateIgnoreValue(type) {
      if (this[`gender_${type}`] == '0') {
        this.ignoreParent[type] = false;
      } else {
        this.activeStep = false;
      }
    },

    checkboxUpdated() {
      const userFormData = JSON.parse(localStorage.getItem('userForm'));
      const relativesLength = Object.keys(userFormData.user.relatives_attributes).length;

      if (((this.gender_male == '0' && this.gender_female == '0') ||
          (this.gender_male == '1' && this.ignoreParent.male && this.gender_female == '0' && relativesLength > 1) ||
          (this.gender_female == '1' && this.ignoreParent.female && this.gender_male == '0' && relativesLength > 1)) && this.isReadModal) {
        this.activeStep = true;
      } else {
        this.activeStep = false;
      }
    },
  },
  computed: {
    handleModalState () {
      return (modalName) => {
        this.modalState[modalName] = !this.modalState[modalName];
      }
    },
  }
}
</script>
