<template>
  <div class="bg-white faqs-section" :class="{'py-10': !isContentOnly}">
    <div class="container-user-sm">
      <h2 v-if="!isContentOnly" class="mb-6 r:text-[24px] font-bold leading-[1.53] tracking-[0.25px]"> {{ viewsJa.home.faqs.title }}</h2>
      <div class="faqs-information-box">
        <div class="mb-6 collapse-block">
          <collapse
            v-for="(collapse, index) in collapseFaqs"
            :id="`collapse-${index}`"
            :active="collapse.active"
            :key="index"
            :class="index == collapseFaqs.length - 1 ? 'mb-0' : 'mb-2'"
            class="bg-neutral-gray-50 rounded-2xl"
            collapseItemCls="border-0"
          >
            <template #collapseHeader>
              <h4 class="r:text-[16px] text-neutral-gray-800 leading-[1.53] r:tracking-[0.25px] flex-1 pr-4">{{ collapse.header }}</h4>
            </template>
            <template #collapseContent>
              <div class="py-4 mx-4 border-t border-neutral-gray-200">
                <p class="text-neutral-gray-800 r:text-[16px] leading-[1.53] r:tracking-[0.25px]" v-html="collapse.content"></p>
              </div>
            </template>
          </collapse>
        </div>
      </div>
      <span class="block mb-2 r:text-[16px] text-neutral-gray-800 leading-[1.53] r:tracking-[0.25px]" v-html="viewsJa.home.faqs.note"></span>
      <div class="flex items-center justify-between p-4 bg-neutral-gray-50 rounded-2xl">
        <a id="go-to-faqs-page" data-testid="go-to-faqs-page" :href="$routes.faqs()" class="cursor-pointer r:text-[16px] text-neutral-gray-800 leading-[1.53] r:tracking-[0.25px] flex-1 pr-4 hover:opacity-70 transition">{{viewsJa.home.faqs.go_to_faq}}</a>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="rotate-[-90deg]"
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
          aria-hidden="true"
          id="go-to-faqs-page-svg"
        >
          <path id="go-to-faqs-page-path" fill-rule="evenodd" clip-rule="evenodd" d="M3.26646 6.32036C3.64182 5.91524 4.27452 5.8911 4.67965 6.26646L9 10.2693L13.3204 6.26646C13.7255 5.8911 14.3582 5.91524 14.7335 6.32036C15.1089 6.72549 15.0848 7.35819 14.6796 7.73355L9 12.9958L3.32036 7.73355C2.91524 7.35819 2.8911 6.72549 3.26646 6.32036Z" fill="#93A3B5"/>
        </svg>
      </div>
    </div>
  </div>
</template>

<script>
import Collapse from '@/Shared/Collapse.vue';

export default {
  components: {
    Collapse,
  },
  props: {
    isContentOnly: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      viewsJa: this.jaConfig.viewsJa,
      collapseFaqs: this.jaConfig.viewsJa.home.faqs.details,
    }
  },
}
</script>
