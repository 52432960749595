<template>
  <div class="flex items-center contract-progress">
    <template v-for="step in totalStep" :key="step">
      <div class="relative" :class="step == 1 ? 'r:pl-[9px]' : 'pl-2.5 r:ml-[-1px]'" :style="`z-index: ${totalStep - step}`">
        <span
          class="absolute h-1 -translate-y-1/2 top-1/2"
          :class="[
            step == 1 ? 'rounded-l-full r:w-[9px] r:left-[1px]' : 'w-2.5 left-0',
            step <= currentStep ? 'bg-primary-500' : 'bg-neutral-gray-100'
          ]"
        ></span>
        <template v-if="step > currentStep">
          <strong class="rounded-full w-8 h-8 bg-neutral-gray-100 flex items-center justify-center text-neutral-gray-300 r:text-[16px] font-bold leading-[1.5] r:tracking-[0.25px] relative z-[2] font-notosans">{{ step }}</strong>
        </template>
        <template v-else-if="step < currentStep">
          <span class="flex items-center justify-center w-8 h-8 rounded-full bg-primary-500 relative z-[2]">
            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="9" viewBox="0 0 12 9" fill="none">
              <path d="M10.501 1L7.49924 4L4.49751 7L1.49973 4" stroke="white" stroke-width="2"/>
            </svg>
          </span>
        </template>
        <template v-else>
          <span class="block w-8 h-8 bg-white r:border-[5px] border-primary-500 rounded-full relative z-[2]"></span>
        </template>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    totalStep: {
      type: Number,
      default: 5
    },
    currentStep: {
      type: Number,
      default: 1
    }
  },
  data() {
    return {
      stepGap: 10
    }
  },
  mounted() {

  }
}
</script>
