<template>
  <div>
    <Head title="Admins" />
    <h1 class="mb-5 text-[1.25rem]">
      Admins Template
    </h1>
    <tab-menu :tabs="tabs"></tab-menu>
    <div class="filter-panel bg-white shadow-[0_0_2rem_0_rgba(136,152,170,0.15)]">
      <div class="p-5 border-b border-tw-gray-250">
        <h3 class="font-bold r:text-[14px] leading-[1.2]">Others</h3>
      </div>
      <form @submit.prevent="$emit('submit')" class="p-5 mb-6">
        <div class="mb-4">
          <h4 class="r:text-[14] mb-2">Single datepicker</h4>
          <VueDatePicker
            v-model="dateSingle"
            :action-row="{ showNow: false, showPreview: false, showCancel: false, showSelect: false }"
            auto-apply
            locale="ja"
            :format="formatDate(dateSingle)"
            position="left"
            hide-input-icon
          />
        </div>
        <div class="mb-4">
          <h4 class="r:text-[14] mb-2">Range datepicker</h4>
          <div class="flex flex-col items-center gap-4 sm:flex-row">
            <div class="w-full sm:w-1/2">
              <VueDatePicker
                v-model="dateRangeStart"
                :action-row="{ showNow: false, showPreview: false, showCancel: false, showSelect: false }"
                auto-apply
                locale="ja"
                :format="formatDate(dateRangeStart)"
                :max-date="dateRangeEnd"
                position="left"
                hide-input-icon
              />
            </div>
            <div class="w-full sm:w-1/2">
              <VueDatePicker
                v-model="dateRangeEnd"
                :action-row="{ showNow: false, showPreview: false, showCancel: false, showSelect: false }"
                auto-apply
                locale="ja"
                :format="formatDate(dateRangeEnd)"
                :min-date="dateRangeStart"
                position="left"
                hide-input-icon
              />
            </div>
          </div>
        </div>
        <div class="mb-4">
          <h4 class="r:text-[14] mb-2">File Upload</h4>
          <input type="file" @change="getFileData" ref="fileItem">
        </div>
        <div class="mb-4">
          <h4 class="r:text-[14] mb-2">Checkbox - Radio</h4>
          <div class="flex flex-col gap-1 mb-4">
            <checkbox-input
              id="test-chk-1"
              label="test-chk-1"
              v-model="form.check_1"
              name="test-check-1"
              checkboxValue="test-check-1"
              checkboxClass="text-tw-blue-600"
            ></checkbox-input>
            <checkbox-input
              id="test-chk-2"
              label="test-chk-2"
              v-model="form.check_2"
              name="test-check-2"
              checkboxValue="test-check-2"
              checkboxClass="text-tw-blue-600"
            ></checkbox-input>
          </div>
          <div class="flex flex-col gap-1">
            <radio-input
              id="test-rd-1"
              label="test-rd-1"
              v-model="form.radio_1"
              name="test-rd"
              radioValue="test-radio-1"
              radioClass="after:bg-tw-blue-600"
            ></radio-input>
            <radio-input
              id="test-rd-2"
              label="test-rd-2"
              v-model="form.radio_2"
              name="test-rd"
              radioValue="test-rd-2"
              radioClass="after:bg-tw-blue-600"
            ></radio-input>
            <!-- <label class="flex items-center">
              <input type="radio" name="rd1" class="rounded-full bg-tw-blue-600 focus:ring-0 focus:ring-transparent">
              <span class="r:text-[14px] ml-2">Radio 1</span>
            </label> -->
          </div>
        </div>
        <!-- <div class="flex items-center justify-center">
          <button class="rounded-full bg-tw-blue-600 hover:bg-tw-blue-700 transition text-white font-bold leading-[1.5] py-3.5 px-8 r:min-w-[250px]">検索</button>
        </div> -->
      </form>
    </div>
  </div>
</template>

<style lang="scss">
.dp__today {
  @apply border-0;
}
</style>

<script>
import { Head } from '@inertiajs/vue3';
import VueDatePicker from '@vuepic/vue-datepicker';
import Layout from '@/Layouts/Admin.vue';
import TabMenu from '@/Shared/Admin/TabMenu.vue';
import RadioInput from '@/Shared/RadioInput.vue';
import CheckboxInput from '@/Shared/CheckboxInput.vue';
import '@vuepic/vue-datepicker/dist/main.css';

export default {
  components: {
    Head,
    TabMenu,
    RadioInput,
    CheckboxInput,
    VueDatePicker,
  },
  layout: Layout,
  data() {
    return {
      tabs: [
        {
          label: 'Guide',
          url: this.$routes.guide_admin_admins(),
          isActive: false
        },
        {
          label: 'Guide_2',
          url: this.$routes.guide_2_admin_admins(),
          isActive: true
        },
      ],
      dateSingle: null,
      dateRangeStart: null,
      dateRangeEnd: null,
      fileUpload: [],
      form: {
        radio_1: 'test-radio-1',
        radio_2: 'test-radio-2',
        check_1: '',
        check_2: '',
      }
    }
  },
  methods: {
    formatDate(date) {
      let configDate = date == null ? new Date() : date;
      const day = configDate.getDate();
      const month = configDate.getMonth() + 1;
      const year = configDate.getFullYear();

      return `${year}年${month}月${day}日`;
    },
    getFileData() {
      this.fileUpload = this.$refs.fileItem.files
    }
  }
}
</script>
