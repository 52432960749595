<template>
  <header :id="!id ? 'header' : id" class="pt-3.5 r:pb-[13px]">
    <div class="container-user-sm">
      <div class="flex items-center justify-between">
        <a :href="$routes.root()" id="logo" class="logo" data-testid="logo">
          <images name="logo" id="logo-img" />
        </a>
      </div>
    </div>
  </header>
</template>

<style lang="scss" scoped>
header {
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
}
.burger-bar {
  @apply relative flex flex-col justify-between w-6 h-5 cursor-pointer;
  .icon-bar {
    @apply block w-full h-0.5 bg-[#979797] transition-all rounded;
  }
}
</style>

<script>
import Images from '@/Shared/Images.vue';

export default {
  components: {
    Images,
  },
  props: {
    id: {
      type: String,
      required: false
    },
  },
  data() {
    return {
      viewsJa: this.jaConfig.viewsJa,
    }
  },
}
</script>
