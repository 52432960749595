<template>
  <div class="quotation-modal-info">
    <modal
      :open="isQuotationOpen"
      @close="setQuotationOpen(false)"
      modalContentCls="!bg-transparent overflow-y-auto"
      modalDialogCls="sm:r:max-w-[720px]"
    >
      <template #modalHeader>
        <div class="py-2">
          <button id="close-quotation-modal" data-testid="close-quotation-modal" type="button" class="flex w-12 h-12 p-3 ml-auto group hover:opacity-70" title="Close" style="margin-left: auto;" @click="setQuotationOpen(false)">
            <svg id="close-quotation-modal-svg" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path id="close-quotation-modal-path" d="M6.27614 4.39052C5.75544 3.86983 4.91122 3.86983 4.39052 4.39052C3.86983 4.91122 3.86983 5.75544 4.39052 6.27614L10.1144 12L4.3906 17.7239C3.8699 18.2446 3.8699 19.0888 4.3906 19.6095C4.9113 20.1302 5.75552 20.1302 6.27622 19.6095L12 13.8856L17.7239 19.6095C18.2446 20.1302 19.0888 20.1302 19.6095 19.6095C20.1302 19.0888 20.1302 18.2446 19.6095 17.7239L13.8856 12L19.6095 6.27614C20.1302 5.75544 20.1302 4.91122 19.6095 4.39052C19.0888 3.86982 18.2446 3.86983 17.7239 4.39053L12 10.1144L6.27614 4.39052Z" fill="white"/>
            </svg>
          </button>
        </div>
      </template>
      <template #modalBody>
        <form class="w-full mx-auto text-center form-submit-quotation" id="form-submit-quotation">
          <input type="hidden" name="authenticity_token" :value="authenToken" autocomplete="off">
          <!-----------block care type------------------>
          <div class="flex flex-col care-type-block mb-1 min-h-[198px]">
            <h3 class="bg-white font-bold text-center text-neutral-gray-800 r-text-[16px] xs:r:text-[18px] leading-[1.53] r:tracking-[0.25px] py-4 rounded-t-[18px]">{{ viewsJa.quotations.modal.title_care_type }}</h3>
            <div class="flex flex-1 rounded-b-[18px]">
              <template v-for="(val, key) in viewsJa.quotations.modal.care_types" :key="key">
                <radio-input
                  v-model="form.careType"
                  :id="key"
                  name="quotation[care_type]"
                  :class="`care-type-selection radio-primary inline-block w-6/12 ${v$.form.careType.$error ? 'form-invalid' : ''} ${v$.form.careType.$dirty ? 'form-dirty' : ''}`"
                  :radioValue="key"
                  :modelValue="form.careType"
                  :hasCustomLabel="true"
                  @change="handleSetTouch('careType'); handleChangeCareType(key)"
                >
                  <label :for="key" :class="`h-full bg-neutral-gray-50 ${key == 'nursing' ? 'rounded-bl-[18px]' : 'rounded-br-[18px]'}`">
                    <div class="flex flex-col justify-around h-full p-4">
                      <span class="block r:text-[14px] xs:r:text-[16px] leading-[1.33] r:tracking-[0.25px] text-center text-neutral-gray-900" v-html="val" />
                      <images :name="`${key}Img`" class="mx-auto" />
                    </div>
                  </label>
                  <span class="hidden bg-white rounded-full text-tw-blue-500 px-3 py-1 leading-[1.33] r:tracking-[0.25px] border border-tw-blue-500 top-0 left-[50%] absolute">
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M12.4447 5.33351C12.6901 5.57897 12.6901 5.97694 12.4447 6.2224L7.11133 11.5557L3.55461 7.99901C3.30915 7.75355 3.31031 7.35675 3.55577 7.11129C3.80123 6.86583 4.1992 6.86583 4.44466 7.11129L7.11133 9.77795L11.5558 5.33351C11.8012 5.08805 12.1992 5.08805 12.4447 5.33351Z" fill="#2097E5"/>
                    </svg>
                    <span class="w-full r:text-[10px] xs:r:text-[12px]">{{ viewsJa.quotations.modal.choosing }}</span>
                  </span>
                </radio-input>
              </template>
            </div>
          </div>

          <!-----------block birthday------------------>
          <div class="dob-block">
            <div class="bg-white text-neutral-gray-800 py-4 rounded-t-[18px]">
              <span class="inline-block">
                <h3 class="font-bold r:text-[16px] xs:r:text-[18px] leading-[1.53] r:tracking-[0.25px] xs:inline">{{ viewsJa.quotations.modal.title_dob }}</h3>
                <span class="r:text-[12px] xs:r:text-[14px] leading-[2] r:tracking-[0.25px] xs:ml-2">{{ viewsJa.quotations.modal.title_dob_small }}</span>
              </span>
              <a id="open-birthyear-modal" data-testid="open-birthyear-modal" class="block w-fit cursor-pointer text-tw-blue-500 underline text-center r:text-[12px] xs:r:text-[14px] mx-auto hover:opacity-[80%]" @click.prevent="handleModalState('birthyear_modal')">{{ viewsJa.quotations.modal.btn_open_birthyear_modal }}</a>
            </div>
            <div class="rounded-b-[18px] bg-neutral-gray-50 py-4 px-6 sm:px-8">
              <template v-for="(val, key) in viewsJa.quotations.modal.dob.parent" :key="key">
                <div class="flex justify-between mb-2">
                  <checkbox-input
                    :id="`checked-${key}`"
                    :label="val"
                    v-model="form[key]['checked']"
                    :name="`quotation[${key}_checked]`"
                    class="inline check-primary"
                    :checkboxValue="form[key]['checked']"
                    autocomplete="off"
                  ></checkbox-input>
                  <span v-if="!isNaN(form[key]['age']) && form[key]['checked']">{{ `${form[key]['age']}歳` }}</span>
                  <span class="text-left mt-2 flex text-tw-red-600 r:text-[12px] xs:r:text-[14px] leading-[1]" v-if="isNaN(form[key]['age']) && form[key]['checked']">{{ viewsJa.quotations.modal.errors.invalid_date }}</span>
                </div>
                <div class="dob-select" v-if="form[key]['checked']">
                  <dropdown-date
                    @handle-update-date="calcAge"
                    :dropdownType="`quotation[date_of_birth_parent_${form[key]['gender']}]`"
                    :wrapSelectClass="dropdownCls"
                    :class="`flex flex-wrap w-full ${key == 'father' ? 'mb-4' : ''}`"
                    :maxAge="this.maxAge"
                    :minAge="this.minAge"
                    :dateInit="form[key]['dob']"
                    :hasLabelDate="false"
                    :typeUser="key"
                    :yearCustomLabel="viewsJa.common.date_label.year"
                    :monthCustomLabel="viewsJa.common.date_label.month"
                    :dayCustomLabel="viewsJa.common.date_label.day"
                  />
                </div>
              </template>
              <label class="text-left mt-2 flex text-tw-red-600 r:text-[14px] leading-[1]" v-if="!form.father.checked && !form.mother.checked">
                <span class="flex-1 mt-0.5">{{ viewsJa.quotations.modal.errors.please_select }}</span>
              </label>
            </div>
          </div>

          <tippy
            interactive
            animation="fade"
            trigger="click"
            :maxWidth="280"
            :onShown="(instance) => {
              handleCloseTooltip(instance);
            }"
          >
            <template #default>
              <a id="open-age-calc-explanation-modal" data-testid="open-age-calc-explanation-modal" class="flex items-center w-fit cursor-pointer text-white underline text-center r:text-[12px] xs:r:text-[14px] leading-[1.53] r:tracking-[0.25] my-4 mx-auto hover:opacity-[80%]" @click.prevent="handleModalState('age-calc-explanation-modal')">
                <svg id="open-age-calc-explanation-modal-svg" width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path id="open-age-calc-explanation-modal-path" fill-rule="evenodd" clip-rule="evenodd" d="M9.5 17C13.9183 17 17.5 13.4183 17.5 9C17.5 4.58172 13.9183 1 9.5 1C5.08172 1 1.5 4.58172 1.5 9C1.5 13.4183 5.08172 17 9.5 17ZM11.8025 8.1875L11.1275 8.8775C11.0706 8.93516 11.016 8.98899 10.9638 9.04042C10.5201 9.47739 10.25 9.74349 10.25 10.75C10.25 11.1642 9.91421 11.5 9.5 11.5C9.08579 11.5 8.75 11.1642 8.75 10.75V10.625C8.75 9.8 9.0875 9.05 9.6275 8.5025L10.5575 7.5575C10.835 7.2875 11 6.9125 11 6.5C11 5.675 10.325 5 9.5 5C8.675 5 8 5.675 8 6.5C8 6.91421 7.66421 7.25 7.25 7.25C6.83579 7.25 6.5 6.91421 6.5 6.5C6.5 4.8425 7.8425 3.5 9.5 3.5C11.1575 3.5 12.5 4.8425 12.5 6.5C12.5 7.16 12.23 7.76 11.8025 8.1875ZM10.5 13.5C10.5 14.0523 10.0523 14.5 9.5 14.5C8.94771 14.5 8.5 14.0523 8.5 13.5C8.5 12.9477 8.94771 12.5 9.5 12.5C10.0523 12.5 10.5 12.9477 10.5 13.5Z" fill="white"/>
                </svg>
                <span id="open-age-calc-explanation-modal-span" class="ml-1">{{ convertJaParams(viewsJa.quotations.modal.btn_open_age_calc_explanation_modal, {contract_start_day: contractStartDayStr}) }}</span>
              </a>
            </template>
            <template #content>
              <div class="p-3 text-left rounded-lg bg-neutral-gray-700 xs:p-4">
                <h4 class="text-white r:text-[14px] xs:r:text-[16px] font-black leading-[1.53] r:tracking-[0.25px] mb-3 xs:mb-4">{{ convertJaParams(viewsJa.contracts.blocks.customer_information.fields_tooltip.parent.date_valid_for_contract.title, {date_valid: contractStartDayStr}) }}</h4>
                <p class="text-white r:text-[12px] xs:r:text-[14px] leading-[1.53] r:tracking-[0.25px] mb-6 xs:mb-8">{{ viewsJa.contracts.blocks.customer_information.fields_tooltip.parent.date_valid_for_contract.desc }}</p>
                <button type="button" class="btn-close-tooltip btn-solid inline-flex items-center justify-center rounded-full btn-gradient r:text-[12px] xs:r:text-[14px] py-3.5 px-4 font-medium text-white shadow-gray-1 bg-neutral-gray-800 r:tracking-[0.25px] leading-[1.33] r:min-h-[47px]">
                  <span>{{ viewsJa.contracts.blocks.customer_information.fields_tooltip.btn_close }}</span>
                </button>
              </div>
            </template>
          </tippy>

          <button id="submit-quotation-modal" type="button" class="inline-flex items-center justify-center rounded-full btn-gradient btn-blue-gradient-horizontal r:text-[10px] xs:r:text-[16px] py-2 px-2 xs:r:px-[18px] xs:py-4 font-medium text-white shadow-gray-1 leading-[1.33] r:min-h-[53px]" @click.prevent="submitQuotationForm($event)" :disabled="cantSubmitForm || (!form.father.checked && !form.mother.checked)">
            <span id="submit-quotation-modal-span" v-html="viewsJa.quotations.modal.btn_submit" />
            <svg id="submit-quotation-modal-svg" xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19" fill="none" class="ml-0 xs:ml-2">
              <path id="submit-quotation-modal-path" fill-rule="evenodd" clip-rule="evenodd" d="M6.82036 3.76646C7.22549 3.3911 7.85819 3.41524 8.23355 3.82036L13.4958 9.50001L8.23355 15.1796C7.85819 15.5848 7.22549 15.6089 6.82036 15.2335C6.41524 14.8582 6.3911 14.2255 6.76646 13.8204L10.7693 9.50001L6.76646 5.17965C6.3911 4.77452 6.41524 4.14182 6.82036 3.76646Z" fill="white"/>
            </svg>
          </button>
        </form>
      </template>
    </modal>

    <modal
      :open="modalState['birthyear_modal']"
      @close="modalState['birthyear_modal'] = false"
      modalContentCls="!bg-white"
      modalDialogCls="sm:r:max-w-[720px]"
    >
      <template #modalHeader>
        <div class="px-4 py-2 border-b border-neutral-gray-100 modal-header">
          <button id="close-birthyear-modal" data-testid="close-birthyear-modal" type="button" class="flex w-12 h-12 p-3 ml-auto group hover:opacity-70" title="Close" style="margin-left: auto;" @click="modalState['birthyear_modal'] = false">
            <svg id="close-birthyear-modal-svg" class="group-hover:opacity-70" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path id="close-birthyear-modal-path" d="M6.27614 4.39052C5.75544 3.86983 4.91122 3.86983 4.39052 4.39052C3.86983 4.91122 3.86983 5.75544 4.39052 6.27614L10.1144 12L4.3906 17.7239C3.8699 18.2446 3.8699 19.0888 4.3906 19.6095C4.9113 20.1302 5.75552 20.1302 6.27622 19.6095L12 13.8856L17.7239 19.6095C18.2446 20.1302 19.0888 20.1302 19.6095 19.6095C20.1302 19.0888 20.1302 18.2446 19.6095 17.7239L13.8856 12L19.6095 6.27614C20.1302 5.75544 20.1302 4.91122 19.6095 4.39052C19.0888 3.86982 18.2446 3.86983 17.7239 4.39053L12 10.1144L6.27614 4.39052Z" fill="#93A3B5"/>
            </svg>
          </button>
          <h2 class="text-neutral-gray-900 r:text-[16px] leading-[1.53] r:tracking-[0.25px]">{{ viewsJa.quotations.birthyear_modal.header }}</h2>
        </div>
      </template>
      <template #modalBody>
        <div class="pt-4 px-4 xs:pt-6 modal-body flex-1 overflow-y-auto">
          <h3 class="text-neutral-gray-900 r:text-[14px] leading-[1.53] r:tracking-[0.25px] mb-6">{{ viewsJa.quotations.birthyear_modal.title }}</h3>
          <div class="relative">
            <table class="w-full text-neutral-gray-900 r:text-[12px] leading-[1.53] r:tracking-[0.25px] text-center border-l border-neutral-gray-200">
              <thead class="font-bold bg-neutral-gray-200">
                <tr>
                  <template v-for="(val, _key) in viewsJa.quotations.birthyear_modal.table_years.titles" :key="_key">
                    <th scope="col" class="px-6 py-3">
                      {{ val }}
                    </th>
                  </template>
                </tr>
              </thead>
              <tbody>
                <template v-for="obj in calculatedAgeTableData" :key="obj['age']">
                  <tr class="odd:bg-neutral-gray-50 even:bg-white border-b border-neutral-gray-200">
                    <td class="px-6 py-4 border-r border-neutral-gray-200">
                      {{ obj['jp_calendar'] }}
                    </td>
                    <td class="px-6 py-4 border-r border-neutral-gray-200">
                      {{ obj['western_calendar'] }}
                    </td>
                    <td class="px-6 py-4 border-r border-neutral-gray-200">
                      {{ obj['age'] }}
                    </td>
                    <td class="px-6 py-4 border-r border-neutral-gray-200">
                      {{ obj['zodiac'] }}
                    </td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
        </div>
      </template>
      <template #modalFooter>
        <div class="text-center modal-footer p-4 xs:py-6">
          <button id="btn-close-birthyear-modal" type="button" class="shadow-gray-1 btn-solid max-w-[109px] inline-flex items-center justify-center rounded-full r:text-[10px] xs:r:text-[14px] py-3.5 px-4 xs:px-4 xs:pt-4 xs:pb-3.5 mx-auto w-full text-white bg-neutral-gray-400 leading-[1.33] r:min-h-[46px]" @click="modalState['birthyear_modal'] = false">{{ viewsJa.quotations.birthyear_modal.btn_close }}</button>
        </div>
      </template>
    </modal>
  </div>
</template>

<style lang="scss" scoped>
.care-type-selection {
  &.form-invalid {
    label {
      @apply border-tw-red-600;
    }
  }
  label {
    @apply relative block cursor-pointer;
  }
  input {
    &:hover + label {
      opacity: 90%;
    }
    &:checked {
      + label {
        @apply bg-tw-blue-500;
        + span {
          display: inline-flex;
          transform: translate(-50%, -50%);
        }

        span {
          @apply text-white font-black;
        }
      }
    }
  }
}
:deep(.tippy-content) {
  padding: 0;
}
.tippy-arrow {
  &::before {
    color: #4e5966;
  }
}
:deep(.dob-select) {
  select {
    @apply xs:pr-9 xs:bg-[right_0.375rem_center] sm:bg-[right_1rem_center];
  }
}
</style>

<script>
import { router } from '@inertiajs/vue3';
import { useVuelidate } from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import { convertJaParams } from '@/utils/utils';
import Layout from '@/Layouts/User.vue';
import RadioInput from '@/Shared/RadioInput.vue';
import CheckboxInput from '@/Shared/CheckboxInput.vue';
import DropdownDate from '@/Shared/User/DropdownDate.vue';
import Images from '@/Shared/Images.vue';
import Modal from '@/Shared/Modal.vue';
import TextInput from '@/Shared/TextInput.vue';
import { Tippy } from 'vue-tippy';
import calendarList from '@/../../db/seeds/jp_western_calendar.csv'

export default {
  components: {
    RadioInput,
    CheckboxInput,
    DropdownDate,
    Images,
    Modal,
    TextInput,
    Tippy
  },
  props: {
    careType: {
      type: String,
    },
    father: {
      type: Object,
    },
    mother: {
      type: Object,
    },
    isFirstTime: {
      type: Boolean,
      default: false
    },
    contractStartDayStr: {
      type: String,
      required: true
    },
    setQuotationOpen: {
      type: Function,
      required: false,
    },
    isQuotationOpen: {
      type: Boolean,
      required: false,
    },
  },
  emits:['handleModalState'],
  setup () {
    return {
      v$: useVuelidate(),
    }
  },
  data() {
    return {
      viewsJa: this.jaConfig.viewsJa,
      convertJaParams: convertJaParams,
      cantSubmitForm: this.isFirstTime ? false : (!this.careType || (!this.father.checked && !this.mother.checked)),
      modalState: {
        birthyear_modal: this.open
      },
      form: {
        careType: this.careType,
        father: {
          checked: this.isFirstTime ? true : this.father.checked,
          dob: this.father.dob || '1959-01-01',
          gender: 'male',
          age: 65
        },
        mother: {
          checked: this.isFirstTime ? true : this.mother.checked,
          dob: this.mother.dob || '1959-01-01',
          gender: 'female',
          age: 65
        },
      },
      dropdownCls: {
        year: 'mb-2 xs:mb-0 w-full xs:w-2/5 px-0.5',
        month: 'w-1/2 xs:flex-1 px-0.5',
        day: 'w-1/2 xs:flex-1 px-0.5',
      },
      authenToken: document.querySelector('meta[name="csrf-token"]').content,
      formItemExist: [],
      calendarList: calendarList,
      minAge: 41,
      maxAge: 79,
      isTopPage: window.location.pathname === '/',
    }
  },
  methods: {
    handleSetTouch(field) {
      this.v$.form[field].$touch();
    },
    submitQuotationForm (e) {
      e.preventDefault();
      if (this.v$.form.$invalid) {
        this.v$.form.$touch();
      }
      else {
        this.modalState['quotation_modal'] = false
        let formQuotation = document.getElementById('form-submit-quotation');
        router.post(this.$routes.quotations(), formQuotation)
      }
    },
  },
  computed: {
    handleModalState () {
      return (modalName) => {
        this.modalState[modalName] = !this.modalState[modalName];
      }
    },
    handleChangeCareType () {
      return (type) => {
        this.form.careType = type;
      }
    },
    calcAge () {
      return (date, _isDirty, typeUser) => {
        this.form[typeUser].dob = date;
        let contractStartDay = new Date(
          new Date().getFullYear(),
          new Date().getMonth() + 2,
          1
        );

        let birthDate = new Date(date);
        birthDate.setHours(0, 0, 0, 0);
        let age = contractStartDay.getFullYear() - birthDate.getFullYear();

        if (contractStartDay < birthDate.setFullYear(birthDate.getFullYear() + age)) {age --}

        this.form[typeUser]['age'] = age;
      }
    },
    handleCheckFormStatus () {
      return () => {
        for (let formItem of this.formItemExist) {
          const group = formItem.group;
          const item = formItem.item;
          if (group === 'other') {
            this.cantSubmitForm = !this.form[item];
            if (!this.form[item]) break;
          } else {
            this.cantSubmitForm = !this.form[group][item] && this.form[group]['checked'];
            if (!this.form[group][item] && this.form[group]['checked']) break;
          }
        }
      }
    },
    handleCloseTooltip () {
      return (tooltipInstance) => {
        const closeBtn = tooltipInstance.popper.querySelector('.btn-close-tooltip');
        closeBtn.addEventListener('click', () => {
          tooltipInstance.hide();
        });
      }
    },
    calculatedAgeTableData() {
      const { calendarList, minAge, maxAge } = this;

      return calendarList
        .filter(obj => {
          const age = new Date().getFullYear() - obj['western_calendar'];
          return age >= minAge && age <= maxAge;
        })
        .map(obj => {
          const age = new Date().getFullYear() - obj['western_calendar'];
          return {
            jp_calendar: obj['jp_calendar'],
            western_calendar: obj['western_calendar'],
            age,
            zodiac: obj['zodiac']
          };
        });
    }
  },
  beforeMount() {
    this.calcAge(this.form.father.dob, false, 'father');
    this.calcAge(this.form.mother.dob, false, 'mother');
  },
  watch: {
    form: {
      handler: function () {
        this.handleCheckFormStatus();
      },
      deep: true
    }
  },
  updated() {
    if (this.form.careType != this.careType) {
      this.form.careType = this.careType;
    }
  },
  validations () {
    const form = {
      careType: {},
      father: {
        dob: {},
      },
      mother: {
        dob: {},
      },
    };

    form.careType = { required };
    if (this.form.father.checked) {
      form.father.dob = { required };
    }
    if (this.form.mother.checked) {
      form.mother.dob = { required };
    }

    if (!this.formItemExist.length) {
      for (let key in form) {
        if (key === 'father' || key === 'mother') {
          for (let f in form[key]) {
            this.formItemExist.push({item: f, group: key});
          }
        } else {
          this.formItemExist.push({item: key, group: 'other'})
        }
      }
    }

    return {
      form: {...form}
    };
  },
  validationConfig: {
    $lazy: true,
  },
}
</script>
