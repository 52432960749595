<template>
  <ul class="flex mb-6">
    <li v-for="(tab, idx) in tabs" :key="idx">
      <Link
        :href="tab.url"
        :class="tab.isActive ? 'text-tw-blue-600 border-tw-blue-600' : 'text-tw-gray-800 border-tw-gray-300'"
        class="block px-4 py-3 border-b-2 r:text-[14px] leading-[1.5]"
      >
        {{ tab.label }}
      </Link>
    </li>
  </ul>
</template>

<script>
import { Link } from '@inertiajs/vue3';
export default {
  components: {
    Link
  },
  props: {
    tabs: {
      type: Array,
      required: true,
    },
  },
}
</script>
