<template>
  <div class="dropdown-combo">
    <div class="flex items-center" :class="wrapSelectClass.year">
      <select-input
        v-model="year"
        :id="`${dropdownType}_year`"
        :name="`${dropdownType}[year]`"
        :ref="`${dropdownType}_year`"
        class="w-full"
        :customClass="`form-element px-2 r:py-[15px] xs:px-[15px] r:min-h-[56px] ${v$.year.$error || serverError ? 'form-invalid' : ''} ${v$.year.$dirty ? 'form-dirty' : ''} ${year == '' ? 'text-neutral-gray-300' : ''}`"
        @change="yearChangeCallback($event)"
      >
        <option value="" :selected="year == '' ? true : false">{{ viewsJa.common.date_label.year }}</option>
        <option :value="yearItem" v-for="yearItem of yearOptions" :key="yearItem">{{ yearItem + this.yearCustomLabel }}</option>
      </select-input>
      <span v-if="hasLabelDate" class="r:text-[16px] ml-1.5 mr-1 leading-[1.5] r:tracking-[0.64px]">{{ viewsJa.common.date_label.year }}</span>
    </div>
    <div class="flex items-center" :class="wrapSelectClass.month">
      <select-input
        v-model="month"
        :id="`${dropdownType}_month`"
        :name="`${dropdownType}[month]`"
        :ref="`${dropdownType}_month`"
        class="w-full"
        :customClass="`form-element px-2 r:py-[15px] xs:px-[15px] r:min-h-[56px] ${v$.month.$error || serverError ? 'form-invalid' : ''} ${v$.month.$dirty ? 'form-dirty' : ''} ${month == '' ? 'text-neutral-gray-300' : ''}`"
        @change="monthChangeCallback($event)"
        >
        <option value="" :selected="month == '' ? true : false">{{ viewsJa.common.date_label.month }}</option>
        <option :value="monthItem" v-for="monthItem of monthOptions" :key="monthItem">{{ monthItem + this.monthCustomLabel }}</option>
      </select-input>
      <span v-if="hasLabelDate" class="r:text-[16px] ml-1.5 mr-1 leading-[1.5] r:tracking-[0.64px]">{{ viewsJa.common.date_label.month }}</span>
    </div>
    <div class="flex items-center" :class="wrapSelectClass.day">
      <select-input
        v-model="day"
        :id="`${dropdownType}_day`"
        :name="`${dropdownType}[day]`"
        :ref="`${dropdownType}_day`"
        class="w-full"
        :customClass="`form-element px-2 r:py-[15px] xs:px-[15px] r:min-h-[56px] ${v$.day.$error || serverError ? 'form-invalid' : ''} ${v$.day.$dirty ? 'form-dirty' : ''} ${day == '' ? 'text-neutral-gray-300' : ''}`"
        @change="dayChangeCallback($event)"
      >
        <option value="" :selected="day == '' ? true : false">{{ viewsJa.common.date_label.day }}</option>
        <option :value="dayItem" v-for="dayItem of dayOptions" :key="dayItem">{{ dayItem + this.dayCustomLabel }}</option>
      </select-input>
      <span v-if="hasLabelDate" class="r:text-[16px] ml-1.5 mr-1 leading-[1.5] r:tracking-[0.64px]">{{ viewsJa.common.date_label.day }}</span>
    </div>
  </div>
</template>

<script>
import SelectInput from '@/Shared/SelectInput.vue';
import { convertDateString } from '@/utils/utils';
import { useVuelidate } from '@vuelidate/core';
import { required } from '@vuelidate/validators';

export default {
  components: {
    SelectInput
  },
  props: {
    dropdownType: { type: String, default: '' },
    minAge: { type: Number, default:null },
    maxAge: { type: Number, default:null },
    minYear: { type: Number, default:null },
    maxYear: { type: Number, default:null },
    minDate: { type: String, default:null },
    maxDate: { type: String, default:null },
    dateInit: { type: String, default: null },
    dateObj: { type: Object, default: { year: '', month: '', day: ''} },
    birthYear: { type: [String, Number], default: null },
    allowPast: { type: Boolean, default:true },
    allowFuture: { type: Boolean, default:true },
    wrapSelectClass: { type: Object, default: '' },
    hasLabelDate: { type: Boolean, default: true },
    serverError: { type: Boolean, default: false },
    typeUser: {type: String, default: 'user'},
    yearCustomLabel: { type: String, default: '' },
    monthCustomLabel: { type: String, default: '' },
    dayCustomLabel: { type: String, default: '' },
    needBlock: { type: Object, default: { year: false, month: false, day: false } },
  },
  emits: ['handle-update-date'],
  data() {
    return {
      viewsJa: this.jaConfig.viewsJa,
      day: '',
      month: '',
      year: '',
      days: [],
      dayOptions: [],
      months: [],
      monthOptions: [],
      years: [],
      yearOptions: [],
      currentDay: new Date().getDate(),
      currentMonth: new Date().getMonth() + 1,
      currentYear: new Date().getFullYear(),
      minDateValue: this.minDate,
      maxDateValue: this.maxDate,
    }
  },
  setup() {
    return {
      v$: useVuelidate()
    }
  },
  created() {
    this.init();
    // preset date fields
    if (!!this.birthYear && !this.dateInit) {
      this.year = this.birthYear;
    } else if (!!this.dateInit) {
      const d = new Date(this.dateInit);
      this.year = d.getFullYear();
      this.month = d.getMonth() + 1;
      this.day = d.getDate();
    }
  },
  methods: {
    init() {
      this.populateYear();
      this.populateMonth();
      this.populateDay();
    },
    populateYear() {
      let minYear = this.minYear,
          maxYear = this.maxYear;

      const bottomGap = 64,
            topGap = 20;

      if (this.minAge != null){
        maxYear = this.currentYear - this.minAge;
      }

      if (this.maxAge != null){
        minYear = this.currentYear - this.maxAge;
      }

      if (!minYear) {
        minYear = this.currentYear - bottomGap;
      }

      if (this.minDateValue !== null){
        minYear = new Date(this.minDateValue).getFullYear();
      }

      if (!maxYear) {
        maxYear = this.currentYear + topGap;
      }

      if (this.maxDateValue !== null){
        maxYear = new Date(this.maxDateValue).getFullYear();
      }

      this.years = [];
      this.yearOptions = [];
      for (var i = minYear; i <= maxYear; i++) {
        this.years.push(i);
        this.yearOptions.push(i);
      }
    },
    populateMonth(){
      let start = 1,
          end = 12,
          year = this.year;

      year = parseInt(year);

      if (this.needBlock.month) {
        if(this.minAge != null) {
          if(year === this.currentYear - this.minAge){
            end = this.currentMonth;
          }
        }

        if(this.maxAge != null) {
          if(year === this.currentYear - this.maxAge){
            start = this.currentMonth;
          }
        }
      }

      if (this.minDateValue !== null && new Date(this.minDateValue).getFullYear() === year) {
        start = start < new Date(this.minDateValue).getMonth() + 1 ? new Date(this.minDateValue).getMonth() + 1 : start;
      }
      if (this.maxDateValue !== null && new Date(this.maxDateValue).getFullYear() === year) {
        end = end > new Date(this.maxDateValue).getMonth() + 1 ?new Date(this.maxDateValue).getMonth() + 1 : end;
      }

      this.months = [];
      this.monthOptions = [];
      for (let monthNo = start; monthNo <= end; monthNo++) {
        this.months.push(monthNo);
        this.monthOptions.push(monthNo);
      }
    },
    populateDay () {
      let start = 1,
          end = 31,
          month = parseInt(this.month),
          year = parseInt(this.year);

      let numDaysInMonth = (new Date(year, month, 0).getDate());
      if (end > numDaysInMonth) {
        end = numDaysInMonth;
      }

      if (isNaN(numDaysInMonth)) {
        this.days = [];
        this.dayOptions = [];
      }

      if (this.days.length > end) {
        for (let i = this.days.length; i > end; i--) {
          this.days.pop();
          this.dayOptions.pop();
        }
        if (this.day > end) {
          this.day = end;
        }
      } else {
        let posStart = this.days.length ? this.days.length + 1 : start;
        for (let i = posStart; i <= end; i++) {
          this.days.push(i);
          this.dayOptions.push(i);
        }
      }
      if (!!this.$refs[`${this.dropdownType}_day`]) {
        this.$refs[`${this.dropdownType}_day`].selected = this.day;
      }
    },
    yearChangeCallback(e) {
      this.v$.year.$touch();
      if (!!e) {
        this.year = e.target.value;
      }
      this.populateMonth();
      this.populateDay();
      this.handleUpdateDate();
    },
    monthChangeCallback(e) {
      this.v$.month.$touch();
      if (!!e) {
        this.month = e.target.value;
      }
      this.populateDay();
      this.handleUpdateDate();
    },
    dayChangeCallback(e){
      this.v$.day.$touch();
      if (!!e) {
        this.day = e.target.value;
      }
      this.handleUpdateDate();
    },
    handleUpdateDate(){
      let date = '';
      let isDirty = false;
      if (!!this.day && !!this.month && !!this.year) {
        date = `${this.year}-${convertDateString(this.month)}-${convertDateString(this.day)}`;
      }
      if (this.v$.year.$dirty && this.v$.month.$dirty && this.v$.day.$dirty) {
        isDirty = true;
      }
      this.$emit('handle-update-date', date, isDirty, this.typeUser);
    }
  },
  validations () {
    return {
      day: { required },
      month: { required },
      year: { required }
    }
  },
  validationConfig: {
    $lazy: true,
  },
}
</script>
