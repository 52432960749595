import { getCurrentInstance } from 'vue'

// Ja
export function convertJaParams (textConvert, keysConvert) {
  const rgParam = /(%{.*?})/g;
  const paramsJa = !!textConvert.match(rgParam) ? [...textConvert.match(rgParam)] : [];
  const paramsNames = [...paramsJa].map(param => {
    return param.replace(/[%{}]/g, '')
  });
  let textConverted = textConvert;

  for (let i = 0; i < paramsJa.length; i++) {
    textConverted = textConverted.replace(paramsJa[i], keysConvert[paramsNames[i]])
  }
  return textConverted
}

// Date
export function formatDate (date) {
  let configDate = date == null ? new Date() : new Date(date);
  const day = convertDateString(configDate.getDate());
  const month = convertDateString(configDate.getMonth() + 1);
  const year = configDate.getFullYear();

  return `${year}年${month}月${day}日`;
}

export function convertDateString (stringConvert) {
  return stringConvert < 10 ? '0' + stringConvert : stringConvert;
}

export function convertDateJP (date, splitStr) {
  const dateArr = date.split(splitStr);
  return `${dateArr[0]}年${dateArr[1]}月${dateArr[2]}日`;
}

// Mask
export function setMask (value, blocks, typeOfMask) {
  let remainValue = !value ? value : value.split(typeOfMask).join('');
  let newValueArr = [];
  if (!!value) {
    for (let i = 0; i < blocks.length; i++) {
      if (remainValue.length <= blocks[i] || i == blocks.length - 1) {
        newValueArr.push(remainValue);
        break;
      } else {
        newValueArr.push(remainValue.substring(0, blocks[i]));
        remainValue = remainValue.substring(blocks[i]);
      }
    }
  }
  return newValueArr.join(typeOfMask);
}

// Formatting
export function setLimitLength (value, limit) {
  return value.substring(0, limit);
}

export function convertGenderJP (gender) {
  return gender == 0 || gender == 'male' ? '男性' : '女性';
}

export function numberWithCommas (number) {
  if(number) {
    return Intl.NumberFormat('en-US').format(number)
  }
}

export function toCapitalize (string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function allowNumberOnly (targetStr) {
  const regNumber = /[^0-9０-９]+/g;
  const numberOnly = targetStr.replace(regNumber, '');

  return numberOnly;
}

export function fullToHalf (str) {
  return str.replace(/[！-～]/g, function(char) {
    return String.fromCharCode(char.charCodeAt(0) - 0xfee0);
  });
}

export function setAlertMessage (message, type = 'success') {
  const alertObject = {
    type: type,
    message: message,
  }
  localStorage.setItem('alertObject', JSON.stringify(alertObject))
}

// Meta
export function metaData (page = 'common') {
  const pageRoute = page.split('_');
  const internalInstance = getCurrentInstance()
  const viewsJa = internalInstance.appContext.config.globalProperties.jaConfig.viewsJa
  const serviceName = viewsJa.common.service_name;
  let pageMeta = null;
  let tempRoute = viewsJa;
  for (let i in pageRoute) {
    pageMeta = tempRoute[pageRoute[i]];
    tempRoute = pageMeta;
  }
  if (pageRoute[0] !== 'common') {
    pageMeta = tempRoute.head_info;
  }
  let metaInfo = {
    title:  convertJaParams(pageMeta.share_title, { service_name: serviceName }),
    meta: [
      // for Facebook
      { name: 'description', content: convertJaParams(pageMeta.share_description, { service_name: serviceName }) },
      { property: 'og:type', content: 'website' },
      { property: 'og:title', content: convertJaParams(pageMeta.og_title, { service_name: serviceName }) },
      { property: 'og:description', content: convertJaParams(pageMeta.og_description, { service_name: serviceName }) },
      { property: 'og:site_name', content: serviceName },
      { property: 'og:url', content: window.location.href },
      //for Twitter
      { name: 'twitter:card', content: 'summary_large_image' },
      { name: 'twitter:title', content: convertJaParams(pageMeta.twitter_title, { service_name: serviceName }) },
      { name: 'twitter:description', content: convertJaParams(pageMeta.twitter_description, { service_name: serviceName }) },
      { name: 'twitter:url', content: window.location.href }
    ],
  }
  return metaInfo;
}

export function findGetParameter(parameterName, url) {
  let result = null;
  let tmp = [];
  if (url === undefined) {
    url = location.search;
  }
  const items = url.substr(1).split('&');
  for (let index = 0; index < items.length; index++) {
    tmp = items[index].split('=');
    if (tmp[0] === parameterName) result = decodeURIComponent(tmp[1]);
  }
  return result;
}
