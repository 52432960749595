<template>
  <div class="home-page">
    <header id="header" class="r:py-[9px] md:py-1.5">
      <div class="container-user-sm">
        <div class="flex items-center justify-between">
          <a :href="$routes.root()" id="logo" class="logo">
            <images name="logo" />
          </a>
          <button id="open-quotation-header-btn" data-testid="open-quotation-header-btn" type="button" class="ml-3 inline-flex items-center justify-center rounded-full btn-gradient btn-blue-gradient-horizontal r:text-[10px] xs:r:text-[12px] md:r:text-[14px] py-2 px-2.5 md:px-4 md:r:py-[14px] font-black text-white shadow-gray-1 leading-[1.33] r:h-[32px] md:h-[46px] r:tracking-[0.25px]" @click.prevent="setQuotationOpen(true); setCareType()">
            <span id="open-quotation-header-btn-span" v-html="viewsJa.common.header.btn.open_quotation" />
            <svg id="open-quotation-header-btn-svg" xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19" fill="none" class="ml-2">
              <path id="open-quotation-header-btn-path" fill-rule="evenodd" clip-rule="evenodd" d="M6.82036 3.76646C7.22549 3.3911 7.85819 3.41524 8.23355 3.82036L13.4958 9.50001L8.23355 15.1796C7.85819 15.5848 7.22549 15.6089 6.82036 15.2335C6.41524 14.8582 6.3911 14.2255 6.76646 13.8204L10.7693 9.50001L6.76646 5.17965C6.3911 4.77452 6.41524 4.14182 6.82036 3.76646Z" fill="white"/>
            </svg>
          </button>
        </div>
      </div>
      <div v-if="announcement" class="announcement-block">
        <div class="container-user-sm">
          <div class="information-box mt-3 mb-3">
            <div class="icon-wrapper">
              <images name="announcement" class="mr-2"/>
            </div>
            <div class="announcement-text" v-html="announcement.content"></div>
          </div>
        </div>
      </div>
    </header>
    <div id="alert-target-area"></div>
    <div class="bg-white hero-section">
      <div class="r:max-w-[640px] mx-auto">
        <div class="flex flex-col items-center md:flex-row-reverse hero-info">
          <div class="w-full md:w-1/2 mb-9 md:mb-0">
            <images name="topBannerMb" class="object-cover w-full h-full md:hidden" :hasSrcSet="true"/>
            <images name="topBannerPc" class="hidden object-cover w-full h-full md:block" :hasSrcSet="true"/>
          </div>
          <div class="flex justify-between w-full px-4 mb-4 md:px-0 md:flex-col circle-parts md:w-1/2 md:pr-10">
            <div class="pr-4 title md:pr-0">
              <div class="flex items-center mb-2">
                <span class="r:text-[18px] xs:r:text-[32px] leading-[1] font-black mr-1.5 mb-0.5">{{ viewsJa.home.top_banner.intro_part.title_1 }}</span>
                <div class="r:h-[30px] xs:h-10 text-white bg-primary-500 rounded px-2 pb-1 pt-1.5 font-zenkurenaido">
                  <div class="overflow-hidden wrap-intro-1">
                    <span v-for="(item, idx) in viewsJa.home.top_banner.intro_part.animation_1" :key="idx" class="r:text-[18px] xs:r:text-[28px] leading-[1] r:tracking-[1px] intro" :class="`intro-${idx}`">{{ item }}</span>
                  </div>
                </div>
              </div>
              <div class="flex items-center mb-2">
                <div class="r:h-[30px] xs:h-10 text-white bg-primary-500 rounded px-2 pb-1 pt-1.5 font-zenkurenaido">
                  <div class="overflow-hidden wrap-intro-2">
                    <template  v-for="(item, idx) in viewsJa.home.top_banner.intro_part.animation_2" :key="idx">
                      <span v-if="idx === 2" :class="`intro intro-${idx + 2}`" v-html="item"></span>
                      <span v-else class="r:text-[16px] xs:r:text-[28px] leading-[1] r:tracking-[1px] intro" :class="`intro-${idx + 2}`">{{ item }}</span>
                    </template>
                  </div>
                </div>
                <span class="r:text-[18px] xs:r:text-[32px] leading-[1] font-black ml-1.5 mb-0.5">{{ viewsJa.home.top_banner.intro_part.title_2 }}</span>
              </div>
              <div class="text-[22px] inline-block font-bold r:mb-[10px]">
                <span class="inline">心の</span>
                <span class="inline">
                  <images name="figureOne" class="inline"/>
                </span>
                <span class="inline">を</span>
                <span class="inline">
                  <images name="figureTwo" class="inline"/>
                </span>
                <span class="inline">に。</span>
              </div>
              <div class="r:max-w-[227px] md:max-w-none">
                <p class="text-primary-600 r:text-[13px] font-bold leading-[1.53] tracking-[0.25px]"> {{ viewsJa.home.top_banner.intro_part.desc }}</p>
                <span class="text-primary-600 r:text-[10px] leading-[1.53] tracking-[0.25px]"> {{ viewsJa.home.top_banner.intro_part.note }}</span>
              </div>
            </div>
            <!-- circle-parts -->
            <div class="flex flex-col circles md:flex-row md:mt-4 r:mt-[-52px] md:-mx-0.5">
              <div class="md:px-0.5" v-for="(part, idx) in viewsJa.home.top_banner.circle_part" :key="idx">
                <span v-html="part" class="text-primary-600 r:text-[12px] leading-[1.33] r:tracking-[0.25px] flex items-center justify-center rounded-full bg-primary-50 r:w-[72px] r:h-[72px] xs:w-20 xs:h-20 flex-col md:mr-1 xs:mb-1">
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- insurance simulator -->
    <insurance-simulator @open-modal="openModalByType"></insurance-simulator>
    <!-- anxiety -->
    <div class="py-8 bg-white anxiety-section">
      <div class="container-user-sm">
        <div class="anxiety-info">
          <h2 v-html="viewsJa.home.anxiety.title.main" class="mb-6 text-center r:text-[24px] font-bold leading-[1.53] tracking-[0.25px]"></h2>
          <images name="anxietyOne" class="mx-auto"/>
          <images name="triangleSmall" class="mx-auto my-6"/>
          <h4 v-html="viewsJa.home.anxiety.title.high_light" class="text-primary-600 text-center r:text-[18px] font-bold leading-[1.53] tracking-[0.25px]"></h4>
          <h3 v-html="viewsJa.home.anxiety.title.other" class="mb-6 text-center r:text-[24px] font-bold leading-[1.53] tracking-[0.25px]"></h3>
          <p class=" r:text-[16px] leading-[1.53] tracking-[0.25px] mb-4"> {{ viewsJa.home.anxiety.desc }}</p>
          <images name="anxietyTwo" class="mx-auto"/>
        </div>
        <div class="other-anxiety-info r:mt-[-10px]">
          <template v-for="(info, index) in viewsJa.home.anxiety.details" :key="index">
            <div class="r:min-h-[125px] flex items-center p-4 mb-2 anxiety-details bg-primary-50 rounded-2xl justify-items-center">
              <div class="xs:r:min-w-[120px] r:min-w-[80px] mr-2">
                <images :name=info.img class="mx-auto" />
              </div>
              <div>
                <span class="block r:text-[18px] text-primary-600 font-bold leading-[1.53] tracking-[0.25px]"> {{ info.title }}</span>
                <span class="r:text-[14px] leading-[1.53] tracking-[0.25px]">{{ info.desc }}</span>
              </div>
            </div>
          </template>
        </div>
        <images name="triangleMedium" class="mx-auto my-6"/>
      </div>
    </div>
    <!-- Parent nursing care -->
    <div class="pb-8 bg-white parent-nursing-care">
      <div class="container-user-sm" >
        <div class="mb-2 text-center title">
          <h3 class="r:text-[18px] font-black leading-[1.53] tracking-[0.25px]" v-html="viewsJa.home.blocks.parent_nursing_care.title.other"></h3>
          <h2 class="r:text-[24px] font-black leading-[1.53] tracking-[0.25px] mb-4" v-html="viewsJa.home.blocks.parent_nursing_care.title.main"></h2>
          <images name="nursingParent" class="mx-auto -mb-6"/>
        </div>
        <parent-nursing-care></parent-nursing-care>
      </div>
    </div>
    <!-- get plan price -->
    <div class="relative pb-8 plan-price-section bg-primary-600">
      <span class="absolute left-0 top-0 w-full r:h-[33px] r:border-t-[33px] border-t-white border-x-[50vw] border-x-transparent"></span>
      <div class="r:pt-[33px] overflow-hidden r:max-w-[672px] px-4 mx-auto relative z-[2]">
        <div class="relative">
          <div class="py-4 relative z-[2] r:max-w-[327px] mx-auto">
            <div class="title r:max-w-[200px]">
              <h2 class="text-white block mb-1 r:text-[16px] xs:r:text-[24px] font-black leading-[1.53] r:tracking-[0.25px]" v-html="viewsJa.home.plan_price.title.main"></h2>
              <span class="text-white text-[12px] xs:r:text-[14px] leading-[1.53] r:tracking-[0.25px]" v-html="viewsJa.home.plan_price.title.other"></span>
            </div>
            <images name="girlPlan" class="absolute r:bottom-[-34px] xs:r:bottom-[-44px] r:right-[-43px] r:max-w-[150px] xs:max-w-none" :needLazy="false" />
          </div>
          <images class="absolute bottom-0 left-1/2 -translate-x-1/2 z-[1] max-w-none" name="planPricePattern" />
        </div>
        <div class="plan-price-info relative z-[2]">
          <div class="md:px-4">
            <div class="flex flex-col xs:flex-row mb-2 overflow-hidden plan-box rounded-2xl xs:r:min-h-[128px]">
              <div class="w-full xs:w-[calc((100%_/_2)_-_18px)] py-4 pl-4 pr-2 bg-white age relative">
                <span class="absolute h-full r:w-[18px] top-0 left-full r:border-l-[18px] border-l-white r:border-y-[64px] border-y-transparent"></span>
                <span class="small-title block font-black r:text-[16px] leading-[1.53] r:tracking-[0.25px] mb-2"> {{ viewsJa.home.plan_price.parent_age }}</span>
                <div class="flex items-end">
                  <div class="relative flex-1 overflow-hidden">
                    <div id="fake-intut" class="fake-input relative rounded-lg border-primary-600 r:border-[3px] overflow-hidden bg-[linear-gradient(180deg,#ffffff_40%,#fff2e8_100%)] r:h-[63px] text-center w-full font-black r:text-[38px] r:tracking-[0.25px] pt-0 pb-0.5" ref="fakeInput"></div>
                    <text-input
                      v-model="inputAge"
                      class="absolute inset-0"
                      id="input_age"
                      :customClass="`text-center r:text-[38px] relative -top-7 r:h-[116px] w-full font-black opacity-[0.05] border-0 text-transparent caret-transparent focus:ring-0 focus:ring-transparent`"
                      @keyup="syncTextbox"
                      @focus="handleFakeFocus"
                      @blur="handleFakeBlur"
                      @input="onChangeFee($event)"
                      @touchend="syncTextbox"
                    >
                    </text-input>
                  </div>
                  <span class="ml-2 font-black leading-[1.53] r:tracking-[0.25px] r:text-[16px]">{{ viewsJa.home.plan_price.age }}</span>
                  <images name="rightTriangle" class="absolute r:top-[-28px] right-0"/>
                </div>
              </div>
              <div class="xs:flex-1 py-4 r:pl-[34px] pr-4 premium-price bg-neutral-gray-50">
                <span class="block small-title font-black r:text-[16px] leading-[1.53] r:tracking-[0.25px] mb-2"> {{  viewsJa.home.plan_price.premium_price }}</span>
                <div class="flex xs:r:min-h-[63px] pb-4 items-end" :class="[{'text-right': !!fee}]">
                  <span v-if="!!fee" class="flex-1 text-neutral-gray-500 font-black leading-[1] r:tracking-[0.25px] r:text-[38px]">{{ numberWithCommas(fee) }}</span>
                  <span v-if="errorMessage" class="text-tw-red-500 flex-1 ml-2 leading-[1.53] r:tracking-[0.25px] r:text-[14px]">{{ viewsJa.home.plan_price.error_mess }}</span>
                  <span class="self-end ml-2 font-black leading-[1.53] r:tracking-[0.25px] r:text-[16px]">{{ viewsJa.common.unit.yen }}</span>
                </div>
              </div>
            </div>
            <span class="r:text-[10px] leading-[1.53] tracking-[0.25px] text-white">{{ viewsJa.home.plan_price.desc.desc_1 }}</span>
            <svg class="mx-auto" xmlns="http://www.w3.org/2000/svg" width="41" height="40" viewBox="0 0 41 40" fill="none">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M33.2419 14.0449C34.076 14.9452 34.0223 16.3512 33.1221 17.1853L20.5006 28.8793L7.87922 17.1853C6.97894 16.3512 6.92531 14.9452 7.75943 14.0449C8.59355 13.1446 9.99957 13.091 10.8998 13.9251L20.5006 22.8204L30.1014 13.9251C31.0017 13.091 32.4077 13.1446 33.2419 14.0449Z" fill="white"/>
            </svg>
            <div class="px-4 pt-4 pb-8 mb-2 overflow-hidden text-center simulator-plan bg-primary-500 rounded-2xl">
              <h3 class="block my-2 r:text-[16px] text-white font-black leading-[1.53] tracking-[0.25px] mb-6" v-html="viewsJa.home.plan_price.title.bottom"></h3>
              <button id="open-quotation-modal-plan-price" data-testid="open-quotation-modal-plan-price" type="button" class="mt-2 inline-flex items-center justify-center rounded-full btn-gradient btn-blue-gradient-horizontal r:text-[14px] xs:r:text-[16px] py-2 px-2 xs:r:px-[18px] xs:py-2.5 font-medium text-white shadow-gray-1 w-full leading-[1.33] r:min-h-[65px]" @click.prevent="setQuotationOpen(true); setCareType()">
                <span id="open-quotation-modal-plan-price-span" class="ml-2" v-html="viewsJa.quotations.modal.btn_open"/>
                <svg id="open-quotation-modal-plan-price-svg" xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19" fill="none" class="ml-2">
                  <path id="open-quotation-modal-plan-price-path" fill-rule="evenodd" clip-rule="evenodd" d="M6.82036 3.76646C7.22549 3.3911 7.85819 3.41524 8.23355 3.82036L13.4958 9.50001L8.23355 15.1796C7.85819 15.5848 7.22549 15.6089 6.82036 15.2335C6.41524 14.8582 6.3911 14.2255 6.76646 13.8204L10.7693 9.50001L6.76646 5.17965C6.3911 4.77452 6.41524 4.14182 6.82036 3.76646Z" fill="white"/>
                </svg>
              </button>
            </div>
            <span class="r:text-[10px] leading-[1.53] tracking-[0.25px] text-white">{{ viewsJa.home.plan_price.desc.desc_2 }}</span>
          </div>
        </div>
      </div>
    </div>
    <!-- prepare -->
    <div class="py-10 bg-neutral-white prepare-section">
      <div class="container-user-sm">
        <div class="prepare-info">
          <images name="prepare" class="mx-auto mb-6"/>
          <div class="py-8 mb-2 prepare-info-details bg-primary-100 rounded-2xl">
            <div class="text-center top-details">
              <h4 class="r:text-[20px] font-black leading-[1.53] tracking-[0.25px]">{{ viewsJa.home.prepare.title.question_1 }}</h4>
              <span class="text-primary-600 leading-[1.2 block mb-6">
                <span class="r:text-[70px]">5</span>
                <span class="r:text-[32px]">年</span>
                <span class="r:text-[70px]">1</span>
                <span class="r:text-[32px]">ヶ月</span>
              </span>
            </div>
            <div class="px-6 py-4 mx-2 mb-4 text-center bg-white md:mx-8 middle-details rounded-2xl">
              <h4 class="r:text-[20px] font-black leading-[1.53] tracking-[0.25px]">{{ viewsJa.home.prepare.title.question_2 }}</h4>
              <span class="r:text-[13px] leading-[1.4]">{{ viewsJa.home.prepare.title.other }}</span>
              <span class="text-primary-600 leading-[1.2 block mb-6">
                <span class="r:text-[70px]">74</span>
                <span class="r:text-[32px]">{{ viewsJa.common.unit.ten_thousand_yen }}</span>
                <div class="flex">
                  <images name="discussConsult" class="mr-2"/>
                  <span class="text-left text-neutral-gray-800 bg-primary-50 px-2 pt-2 pb-2.5 r:text-[14px] leading-[1.53] tracking-[0.25px]">{{ viewsJa.home.prepare.desc }}</span>
                </div>
              </span>
            </div>
            <div class="text-center bottom-details">
              <h5 class="r:text-[18px] font-black leading-[1.53] tracking-[0.25px] text-neutral-gray-300">{{ viewsJa.home.prepare.title.other_bottom }}</h5>
              <h4 class="r:text-[18px] text-neutral-gray-800 font-black leading-[1.53] tracking-[0.25px]" v-html="viewsJa.home.prepare.title.bottom"></h4>
              <simulator-box @open-modal="openModalByType" :onlyButton="true" ></simulator-box>
            </div>
          </div>
          <span class="r:text-[12px] leading-[1.53] tracking-[0.25px] text-neutral-gray-500">{{ viewsJa.home.prepare.note }}</span>
        </div>
      </div>
    </div>
    <!-- discussion -->
    <div class="pt-10 pb-6 discussion bg-neutral-gray-50">
      <div class="container-user-sm">
        <div class="text-center">
          <span class="text-primary-600 r:text-[16px] font-black leading-[1.53] tracking-[0.25px]"> {{ viewsJa.home.discussion.title.high_light }}</span>
          <h2 class="block my-2 r:text-[24px] font-black leading-[1.53] tracking-[0.25px]">{{ viewsJa.home.discussion.title.main }}</h2>
          <images name="discussionTop" class="mx-auto"/>
        </div>
      </div>
      <div class="r:max-w-[640px] mx-auto">
        <div class="rounded-2xl bg-[#83bbde] px-4 py-6">
          <div class="flex mb-1" :class="key % 2 === 0 ? 'flex-row-reverse mb-1.5' : ''" v-for="(chat, key) in viewsJa.home.discussion.chats" :key="key">
            <images v-if="!!chat.avatar" :name="chat.avatar" :class="[key % 2 === 0 ? 'ml-1' : 'mr-1' , 'w-10 h-10']" />
            <div :class="[key % 2 !== 0 ? 'flex justify-start' : 'items-end', 'line-message flex flex-col']">
              <span class="r:text-[10px] leading-[1.53] r:tracking-[0.25px] text-neutral-gray-800">{{ chat.consult_name }}</span>
              <template v-for="(mess, index) in chat.messages" :key="index">
                <div :class="[key % 2 !== 0 ? 'pl-4 pr-2 pt-2 pb-2.5 rounded-b-3xl rounded-tr-3xl mt-1 bg-white' : 'pl-4 pr-2 pt-2 pb-2.5 rounded-b-3xl rounded-tl-3xl mt-1 bg-tw-green-600 w-full', 'sm:inline-flex']">
                  <p>
                    <span :class="[key % 2 !== 0 ? 'text-tw-black-900' : 'text-white' ,'r:text-[15px] leading-[1.33] text-tw-black-900']" v-html="mess"></span>
                  </p>
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- voices -->
    <div class="py-6 bg-neutral-gray-50 voices-section">
      <div class="container-user-sm">
        <div class="voices-info">
          <template v-for="(voice, index) in viewsJa.home.voices" :key="index">
            <div class="mb-6 voice-title">
              <h4 class="r:text-[24px] font-black leading-[1.53] tracking-[0.25px]" v-html="voice.title"></h4>
              <span class="text-primary-600 r:text-[16px] leading-[1.53] tracking-[0.25px]"> {{ voice.high_light }}</span>
            </div>
            <div class="voice-details">
              <div class="flex flex-col items-start mb-4">
                <template v-for="(detail, key) in voice.details" :key="key">
                  <div class="inline-flex items-start mb-4">
                    <div class="mr-4 voice-img r:min-w-[74px] flex flex-col items-center">
                      <images :name=detail.img class="w-14 h-14"/>
                      <span class="text-center r:text-[12px] leading-[1.33] text-neutral-gray-700 tracking-[0.25px]" v-html="detail.title_img"></span>
                    </div>
                    <p class="rounded-tr-3xl rounded-b-3xl block pt-2 px-3.5 pb-2.5 bg-white r:text-[15px] leading-[1.33] tracking-[0.25px]" v-html="detail.desc"></p>
                  </div>
                  <images v-if="detail.other_img" :name=detail.other_img class="mx-auto" />
                </template>
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
    <!-- insurance box -->
    <div class="pt-8 pb-10 bg-primary-500 get-ready-section">
      <div class="container-user-sm">
        <div class="text-center get-ready-info">
          <span class="text-white r:text-[14px] leading-[1.53] tracking-[0.25px]"> {{ viewsJa.home.get_ready_info.title.other }}</span>
          <h2 class="text-white r:text-[24px] leading-[1] font-bold block mb-8 mt-2" v-html="viewsJa.home.get_ready_info.title.main"></h2>
          <simulator-box @open-modal="openModalByType"></simulator-box>
        </div>
      </div>
    </div>
    <!-- how to apply -->
    <how-to-apply @open-modal="openModalByType"></how-to-apply>
    <!-- bottom-simulator-info -->
    <div class="pb-16 bg-white border-b bottom-simulator border-neutral-gray-100">
      <div class="text-center container-user-sm">
        <div class="bottom-simulator-info">
          <span class="r:text-[16px] leading-[1.53] tracking-[0.25px]"> {{ viewsJa.home.bottom_simulator.small_title  }}</span>
          <button id="open-quotation-modal-bottom" data-testid="open-quotation-modal-bottom" type="button" class="mt-2 inline-flex items-center justify-center rounded-full btn-gradient btn-blue-gradient-horizontal r:text-[14px] xs:r:text-[16px] py-2 px-2 xs:r:px-[18px] xs:py-2.5 font-medium text-white shadow-gray-1 w-full leading-[1.33] r:min-h-[65px]" @click.prevent="setQuotationOpen(true); setCareType()">
            <span id="open-quotation-modal-bottom-span" class="ml-2" v-html="viewsJa.quotations.modal.btn_open"/>
            <svg id="open-quotation-modal-bottom-svg" xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19" fill="none" class="ml-2">
              <path id="open-quotation-modal-bottom-path" fill-rule="evenodd" clip-rule="evenodd" d="M6.82036 3.76646C7.22549 3.3911 7.85819 3.41524 8.23355 3.82036L13.4958 9.50001L8.23355 15.1796C7.85819 15.5848 7.22549 15.6089 6.82036 15.2335C6.41524 14.8582 6.3911 14.2255 6.76646 13.8204L10.7693 9.50001L6.76646 5.17965C6.3911 4.77452 6.41524 4.14182 6.82036 3.76646Z" fill="white"/>
            </svg>
          </button>
        </div>
      </div>
    </div>
    <div class="py-10 bg-white border-b age-price-section border-neutral-gray-100">
      <div class="container-user-sm">
        <div class="age-price-info">
          <h2 class="r:text-[24px] font-bold leading-[1.53] tracking-[0.25px] mb-6" v-html="viewsJa.home.age_price_table.title"></h2>
          <age-price-table></age-price-table>
          <div class="pt-6">
            <p
              v-for="(note, idx) in viewsJa.home.age_price_table.note" :key="idx"
              class="flex pl-5 relative r:text-[12px] leading-[1.53] r:tracking-[0.25px]"
            >
              <span class="absolute left-2 top-2.5 w-1 h-1 rounded-full bg-neutral-gray-900"></span>
              <span class="flex-1">{{ note }}</span>
            </p>
        </div>
        </div>
      </div>
    </div>
    <!-- faqs -->
    <faqs></faqs>
    <!-- insurance simulator -->
    <insurance-simulator @open-modal="openModalByType"></insurance-simulator>
    <div class="bg-white other-section">
      <div class="container-user-sm">
        <div class="border-b border-neutral-gray-100">
          <a :href="$routes.contact()" id="go-to-contact-from-index" data-testid="go-to-contact-from-index" class="p-4 hover:opacity-70 flex items-center justify-between r:text-[16px] text-neutral-gray-600 leading-[1.53] r:tracking-[0.25px] flex-1">{{viewsJa.home.faqs.go_to_contact}}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="rotate-[-90deg]"
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
              aria-hidden="true"
              id="go-to-contact-from-index-svg"
            >
              <path id="go-to-contact-from-index-path" fill-rule="evenodd" clip-rule="evenodd" d="M3.26646 6.32036C3.64182 5.91524 4.27452 5.8911 4.67965 6.26646L9 10.2693L13.3204 6.26646C13.7255 5.8911 14.3582 5.91524 14.7335 6.32036C15.1089 6.72549 15.0848 7.35819 14.6796 7.73355L9 12.9958L3.32036 7.73355C2.91524 7.35819 2.8911 6.72549 3.26646 6.32036Z" fill="#93A3B5"/>
            </svg>
          </a>
        </div>
        <div class="border-b border-neutral-gray-100">
          <a href="javascript:;" id="scroll-top-index" data-testid="scroll-top-index" class="p-4 text-neutral-gray-600 flex items-center justify-between hover:opacity-70 transition r:text-[16px] leading-[1.53] tracking-[0.25px]" @click="scrollToTop"> {{ viewsJa.home.faqs.go_to_top }}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
              id="scroll-top-index-svg"
            >
              <path id="scroll-top-index-path" fill-rule="evenodd" clip-rule="evenodd" d="M3.26646 11.6796C3.64182 12.0848 4.27452 12.1089 4.67965 11.7335L9 7.73066L13.3204 11.7335C13.7255 12.1089 14.3582 12.0848 14.7335 11.6796C15.1089 11.2745 15.0848 10.6418 14.6796 10.2665L9 5.00417L3.32036 10.2665C2.91524 10.6418 2.8911 11.2745 3.26646 11.6796Z" fill="#93A3B5"/>
            </svg>
          </a>
        </div>
      </div>
    </div>
    <div class="float-button fixed inset-x-0 bottom-0 px-4 pb-4 r:pt-[27px] bg-white z-[2] border-t border-tw-black-200 bg-[linear-gradient(180deg,#ffffff_0%,#e3eaf1_100%)]">
      <div class="container-user-sm">
        <button type="button" id="go-to-quotation-float" data-testid="go-to-quotation-float" class="w-full flex items-center justify-center rounded-full btn-gradient btn-blue-gradient-horizontal r:text-[14px] xs:r:text-[16px] py-2 px-2 xs:r:px-[18px] xs:py-4 font-medium text-white shadow-gray-1 leading-[1.33] r:min-h-[53px]" @click.prevent="setQuotationOpen(true); setCareType()">
          <span id="go-to-quotation-float-span" class="flex-1 ml-2" v-html="viewsJa.home.float_btn" />
          <svg id="go-to-quotation-float-svg" xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19" fill="none" class="ml-2">
            <path id="go-to-quotation-float-path" fill-rule="evenodd" clip-rule="evenodd" d="M6.82036 3.76646C7.22549 3.3911 7.85819 3.41524 8.23355 3.82036L13.4958 9.50001L8.23355 15.1796C7.85819 15.5848 7.22549 15.6089 6.82036 15.2335C6.41524 14.8582 6.3911 14.2255 6.76646 13.8204L10.7693 9.50001L6.76646 5.17965C6.3911 4.77452 6.41524 4.14182 6.82036 3.76646Z" fill="white"/>
          </svg>
        </button>
      </div>
    </div>
    <QuotationModal
      :careType="localCareType"
      :father="father"
      :mother="mother"
      :isFirstTime="isFirstTime"
      :isQuotationOpen="isQuotationOpen"
      :setQuotationOpen="setQuotationOpen"
      :contractStartDayStr="contractStartDayStr"
    />
  </div>
</template>

<style lang="scss" scoped>
:deep(input[type='number']::-webkit-inner-spin-button,
.plan-price-section::v-deep input[type='number']::-webkit-outer-spin-button) {
  -webkit-appearance: none;
  margin: 0;
}

:deep(input[type='number']) {
  -moz-appearance: textfield;
}


:deep(.fake-input) {
  > span {
    @apply flex justify-center r:h-[59px]
  }
  .caret {
    @apply inline-block h-12 bg-primary-600;
    &:not(.selection) {
      @apply w-0.5 opacity-0 r:mt-[5px];
    }
    &.selection {
      @apply bg-neutral-100 text-neutral-400 r:h-[59px];
    }
  }
  &.focused {
    .caret {
      &:not(.selection) {
        animation: blink 0.9s infinite;
      }
    }
  }
}

.wrap-intro-1 {
  @apply r:w-[54px] xs:r:w-[84px] h-full relative;
}
.wrap-intro-2 {
  @apply r:w-[68px] xs:r:w-[114px] h-full relative;
  animation: widthChange 15s infinite;
}
.intro {
  @apply absolute inset-0 whitespace-nowrap flex items-baseline;
  &.intro-0 {
    opacity: 0;
    animation: relative1 10s infinite;
  }
  &.intro-1 {
    opacity: 0;
    animation: relative2 10s infinite;
  }
  &.intro-2 {
    opacity: 0;
    animation: careType1 15s infinite;
  }
  &.intro-3 {
    opacity: 0;
    animation: careType2 15s infinite;
  }
  &.intro-4 {
    opacity: 0;
    animation: careType3 15s infinite;
  }
}
@keyframes blink {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes relative1 {
  0% { opacity: 0; }
  5% { opacity: 1; }
  45% { opacity: 1; }
  50% { opacity: 0; }
}
@keyframes relative2 {
  50% { opacity: 0; }
  55% { opacity: 1; }
  95% { opacity: 1; }
  100% { opacity: 0; }
}
@keyframes careType1 {
  0% { opacity: 0; }
  3.333% { opacity: 1; }
  29.997% { opacity: 1; }
  33.33% { opacity: 0; }
}
@keyframes careType2 {
  33.33% { opacity: 0; }
  36.663% { opacity: 1; }
  63.327% { opacity: 1; }
  66.66% { opacity: 0; }
}
@keyframes careType3 {
  66.66% { opacity: 0; }
  69.993% { opacity: 1; }
  96.667% { opacity: 1; }
  100% { opacity: 0; }
}
@keyframes widthChange {
  0% {
    @apply r:w-[68px];
  }
  63.327% {
    @apply r:w-[68px];
  }
  66.66% {
    @apply r:w-[95px];
  }
  96.667% {
    @apply r:w-[95px];
  }
  100% {
    @apply r:w-[68px];
  }
}
@media screen and (min-width: 375px) {
  @keyframes widthChange {
    0% {
      @apply r:w-[114px];
    }
    63.327% {
      @apply r:w-[114px];
    }
    66.66% {
      @apply r:w-[156px];
    }
    96.667% {
      @apply r:w-[156px];
    }
    100% {
      @apply r:w-[114px];
    }
  }
}

:deep(.announcement-block) {
  .information-box {
    display: flex;

    .icon-wrapper {
      flex-shrink: 0;
    }

    p {
      margin-bottom: 0;
    }
  }

  .announcement-text {
    font-size: 14px;
    color: #484f65;

    a {
      color: #008eff;
    }
  }
}
</style>

<script>
import Layout from '@/Layouts/User.vue';
import QuotationModal from '@/Pages/Quotations/Components/QuotationModal.vue';
import Images from '@/Shared/Images.vue';
import InsuranceSimulator from '@/Pages/Home/Blocks/InsuranceSimulator.vue';
import ParentNursingCare from '@/Pages/Home/Blocks/ParentNursingCare.vue';
import HowToApply from '@/Pages/Home/Blocks/HowToApply.vue';
import Faqs from '@/Pages/Home/Blocks/Faqs.vue';
import AgePriceTable from '@/Pages/Home/Blocks/AgePriceTable.vue';
import SimulatorBox from '@/Pages/Home/Blocks/SimulatorBox.vue';
import Collapse from '@/Shared/Collapse.vue';
import TextInput from '@/Shared/TextInput.vue';
import { numberWithCommas, setLimitLength, findGetParameter, allowNumberOnly, fullToHalf } from '@/utils/utils';

export default {
  components: {
    QuotationModal,
    Images,
    InsuranceSimulator,
    ParentNursingCare,
    HowToApply,
    Faqs,
    AgePriceTable,
    SimulatorBox,
    Collapse,
    TextInput,
  },
  layout: (h, page) => h(Layout, { footerClass: 'r:pb-[86px]', noHeader: true }, () => page),
  props: {
    careType: {
      type: String
    },
    father: {
      type: Object
    },
    mother: {
      type: Object
    },
    isFirstTime: {
      type: Boolean,
    },
    contractStartDayStr: {
      type: String,
    },
    quotation: {
      type: Object,
      required: false
    },
    summary_plan_price: {
      type: Array,
      required: true
    },
    announcement: {
      type: Object,
      required: false
    },
  },
  data() {
    return {
      viewsJa: this.jaConfig.viewsJa,
      numberWithCommas: numberWithCommas,
      isQuotationOpen: false,
      inputAge: '60',
      fee: null,
      localCareType: this.careType,
      planByAge: this.summary_plan_price,
      errorMessage: false,
      collapseFaqs: this.jaConfig.viewsJa.home.faqs.details,
      cursorTimer: null,
    }
  },
  mounted() {
    // get quotation modal state
    const showQuotation = findGetParameter('show_quotation');
    if (!!showQuotation) {
      this.setQuotationOpen(true);
      this.setCareType(showQuotation);
    }
    // show default age and fee
    this.calculateFee(parseInt(this.inputAge));
    this.syncTextbox();
  },
  methods: {
    setQuotationOpen (state) {
      this.isQuotationOpen = state;
    },
    setCareType(careType) {
      this.localCareType = careType || this.careType;
    },
    openModalByType(localCareType) {
      this.setQuotationOpen(true);
      this.setCareType(localCareType);
    },
    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    },
    onChangeFee(event) {
      const newValue = allowNumberOnly(event.target.value);
      const age = parseInt(fullToHalf(newValue));
      const ageEle = document.getElementById('input_age');
      this.inputAge = setLimitLength(newValue, 2);
      ageEle.value = this.inputAge;
      this.calculateFee(age);
    },

    calculateFee(age) {
      if (!age || isNaN(age) || age < 40 || age > 79) {
        this.errorMessage = true;
        this.fee = null;
        this.$refs.fakeInput.classList.add('text-primary-100');
        this.$refs.fakeInput.classList.remove('text-primary-600');
      } else {
        this.errorMessage = false;
        this.fee = null;
        this.$refs.fakeInput.classList.add('text-primary-600');
        this.$refs.fakeInput.classList.remove('text-primary-100');
        for (const [min, max, fee] of this.planByAge) {
          if (age >= min && age <= max) {
            this.fee = fee;
            break;
          }
        }
      }
    },
  },
  computed: {
    appendCaret () {
      return (toHere, position, selStart, selEnd) => {
        if (position === selStart) {
          toHere += "<span class='caret' id='caret'>";
        }
        if (position === selEnd) {
          toHere += "</span>";
        }
        return toHere;
      }
    },
    syncTextbox () {
      return () => {
        let inputEle = document.getElementById('input_age'),
            inputVal = inputEle.value,
            inputLength = inputVal.length,
            selStart = inputEle.selectionStart,
            selEnd = inputEle.selectionEnd,
            newOut = '',
            count = 0;

        for (count = 0; count < inputLength; count++) {
          newOut = this.appendCaret(newOut, count, selStart, selEnd);
          newOut += inputVal[count];
        }

        let wrapHtml = document.createElement('span');
        let rawContent = this.appendCaret(newOut, count, selStart, selEnd);
        wrapHtml.innerHTML = rawContent;
        this.$refs.fakeInput.innerHTML = '';
        this.$refs.fakeInput.prepend(wrapHtml);
        if (selStart != selEnd) {
          document.getElementById('caret').classList.add('selection');
        }
      }
    },
    handleFakeBlur () {
      return () => {
        this.$refs.fakeInput.classList.remove('focused');
      }
    },
    handleFakeFocus () {
      return () => {
        if (!this.cursorTimer) {
          this.$refs.fakeInput.classList.add('focused');
        }
      }
    },
  }
}
</script>
