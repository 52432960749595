// This file is automatically compiled by Vite, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import '~/styles/application-vue.css';

// import Rails from '@rails/ujs'
// Rails.start();

import * as ActiveStorage from "@rails/activestorage";
ActiveStorage.start();

import { createApp, h } from 'vue';
import { createInertiaApp } from '@inertiajs/vue3';

import * as Routes from '@/utils/routes.js';
import { VueHeadMixin, createHead } from '@unhead/vue';

import axios from 'axios';
axios.defaults.xsrfHeaderName = 'X-CSRF-Token';

// Ja path
import globalJa from '../../../config/locales/ja.yml';
import viewsJa from '../../../config/locales/views/ja.yml';
import modelsJa from '../../../config/locales/models/ja.yml';
// import settingsJa from '../../../config/settings.yml';

const pages = import.meta.globEagerDefault('../Pages/**/*.vue');
const jaConfig = {
  globalJa: globalJa.ja,
  modelsJa: modelsJa.ja,
  viewsJa: viewsJa.ja,
  // settingsJa: settingsJa,
};
const head = createHead()

createInertiaApp({
  resolve: (name) => {
    const component = pages[`../Pages/${name}.vue`];
    if (!component)
      throw new Error(
        `Unknown page ${name}. Is it located under Pages with a .vue extension?`,
      );

    return component;
  },

  setup({ el, App, props, plugin }) {
    const vueApp = createApp({
      render: () => h(App, props),
      // metaInfo: {
      //   titleTemplate (title) {
      //     return title ? `${title}` : ''
      //   },
      // },
    });
    vueApp.config.globalProperties.$routes = Routes;
    vueApp.config.globalProperties.jaConfig = jaConfig;
    vueApp
      .mixin(VueHeadMixin)
      .use(head)
      .use(plugin)
      .mount(el);
  },
});
