<template>
  <div class="layout layout-user h-screen flex flex-col">
    <Header v-if="!noHeader"></Header>
    <main class="main flex-1" :class="mainClass">
      <slot></slot>
    </main>
    <Footer :footerType="footerType" :footerClass="footerClass" :hasFormNumber="hasFormNumber"></Footer>
    <Alert :isShowAlert="isShowAlert" :message="alertMessage"></Alert>
  </div>
</template>

<script>
import Header from '@/Common/Header.vue';
import Footer from '@/Common/Footer.vue';
import Alert from '@/Shared/Alert.vue';

export default {
  components: {
    Header,
    Footer,
    Alert,
  },
  props: {
    footerType: {
      type: String,
      required: false,
    },
    footerClass: {
      type: String,
      required: false,
    },
    mainClass: {
      type: String,
      required: false,
    },
    hasFormNumber: {
      type: Boolean,
      default: true,
    },
    noHeader: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      viewsJa: this.jaConfig.viewsJa,
      isShowAlert: false,
      alertMessage: '',
      sessionTimeout: JSON.parse(localStorage.getItem('sessionTimeout')) || false,
    };
  },
  created() {
    const appEle = document.getElementById('app');
    const sessionStatus = JSON.parse(appEle.getAttribute('data-page')).props.sessionStatus;

    // session status expired when value is 'new'
    // then page will remove all session and redirect to landing page
    if (sessionStatus == 'new') {
      localStorage.clear();
    }
  },
  mounted() {
    this.handleShowAlertFromSession();
    this.moveAlertToTarget();
  },
  methods: {
    moveAlertToTarget() {
      const alertBox = document.getElementById('alert-box');
      const targetArea = document.getElementById('alert-target-area');
      const alertSession = `<div class="container-user-sm"><div class="alert alert-danger mt-4 d-flex flex items-center">
                              <svg class="mr-2" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M8.99984 0.666748C4.39984 0.666748 0.666504 4.40008 0.666504 9.00008C0.666504 13.6001 4.39984 17.3334 8.99984 17.3334C13.5998 17.3334 17.3332 13.6001 17.3332 9.00008C17.3332 4.40008 13.5998 0.666748 8.99984 0.666748ZM8.99984 13.1668C8.5415 13.1668 8.1665 12.7918 8.1665 12.3334V9.00008C8.1665 8.54175 8.5415 8.16675 8.99984 8.16675C9.45817 8.16675 9.83317 8.54175 9.83317 9.00008V12.3334C9.83317 12.7918 9.45817 13.1668 8.99984 13.1668ZM9.83317 6.50008H8.1665V4.83342H9.83317V6.50008Z" fill="#F02C27"/>
                              </svg>
                              <span class="flex-1">${this.viewsJa.common.errors.session_expired}</span>
                            </div></div>`;
      if (this.sessionTimeout) {
        targetArea.innerHTML = alertSession;
        localStorage.clear();
      }
      if (!!targetArea) {
        targetArea.append(alertBox)
        alertBox.style.display = ''
      }
    },
    handleShowAlertFromSession() {
      const ALERT_TIME = 7000;
      const alertObject = JSON.parse(localStorage.getItem('alertObject'));
      if (!alertObject) return;

      if (alertObject.message.length !== 0) {
        this.alertMessage = alertObject.message;
        this.isShowAlert = true;

        setTimeout(() => {
          localStorage.removeItem('alertObject');
          this.isShowAlert = false;
        }, ALERT_TIME);
      }
    },
  },
};
</script>
