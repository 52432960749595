<template>
  <div>
    <ul class="tabs-header">
      <li
        v-for="(tab, index) of tabs"
        :key="index"
        :class="[
          { 'is-active': index === selectedIndex},
        ]"
        class="tab-header-item"
      >
        <a :href="tab.props.route" @click.prevent="selectTab(index)">{{ tab.props.title }}</a>
      </li>
    </ul>
    <div class="tabs-detail">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import {reactive, provide, onMounted, onBeforeMount, toRefs} from "vue";

export default {
  setup(props, { slots }) {
    const state = reactive({
      selectedIndex: 0,
      tabs: [] ,
      count: 0
    });

    provide("tabsprovider", state);

    const selectTab = (i) => {
      state.selectedIndex = i;
    };

    onBeforeMount(() => {
      if (slots.default) {
        state.tabs = slots.default().filter((child) => child.props.name === 'tab');
      }
    });

    onMounted(() => {
      selectTab(0);
    });

    return {...toRefs(state), selectTab};
  },
  // methods: {
  //   convertToHref (id) {
  //     return `${this.$routes.new_contract()}#${id.toLowerCase().replace(/ /g, '-')}`
  //   }
  // }
  // data() {
  //   return { tabs: [] }
  // },

  // created() {
  //   this.tabs = this.$slots.default().filter((child) => child.props.name === 'tab')
  // },

  // methods: {
  //   selectTab(selectedTab) {
  //     this.tabs.forEach((tab) => {
  //       tab.isActive = tab.href == selectedTab.href
  //     })
  //   },
  // },
}
</script>
