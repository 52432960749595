<template>
  <div :class="$attrs.class">
    <label v-if="label" class="form-label" :for="id">{{ label }}:</label>
    <select
      :id="id"
      :ref="!!refId ? refId : 'select'"
      v-model="selected"
      v-bind="{ ...$attrs, class: null }"
      :class="[{ error: errors.length }, !!customClass ? customClass : 'form-select']"
    >
      <slot />
    </select>
    <div v-if="errors.length" class="form-error">
      {{ errors[0] }}
    </div>
  </div>
</template>

<style lang="scss" scoped>
select {
  @apply pr-9 xs:pr-11 bg-[right_0.375rem_center] xs:bg-[right_1rem_center];
  background-image: url("data:image/svg+xml, %3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'%3E%3Cpath d='M6.6664 8C6.11542 8 5.62119 8.3389 5.42264 8.85286C5.22409 9.36682 5.36213 9.94997 5.77001 10.3204L11.9996 15.9779L12.8869 15.1721C12.89 15.1694 12.893 15.1666 12.8961 15.1638L18.2294 10.3204C18.6373 9.94997 18.7754 9.36682 18.5768 8.85286C18.3783 8.3389 17.884 8 17.3331 8H6.6664Z' fill='%23B3C0CE'/%3E%3C/svg%3E");
}
</style>

<script>
import { v4 as uuid } from 'uuid';

export default {
  inheritAttrs: false,
  props: {
    id: {
      type: String,
      default() {
        return `select-input-${uuid()}`;
      },
    },
    modelValue: [String, Number, Boolean],
    label: {
      type: String,
      default: null,
    },
    errors: {
      type: Array,
      default: () => [],
    },
    customClass: {
      type: String,
      required: false,
    },
    refId: {
      type: String,
      required: false,
    }
  },
  emits: ['update:modelValue'],
  data() {
    return {
      selected: this.modelValue,
    };
  },
  watch: {
    selected(selected) {
      this.$emit('update:modelValue', selected);
    },
  },
  methods: {
    focus() {
      this.$refs.input.focus();
    },
    select() {
      this.$refs.input.select();
    },
  },
};
</script>
