<template>
  <div class="form-element-check">
    <input
      :id="id"
      :name="name"
      :ref="`checkbox-${id}`"
      type="checkbox"
      v-bind="{ ...$attrs, class: null }"
      :value="checkboxValue"
      :checked="modelValue"
      class="rounded focus:ring-0 focus:ring-transparent border-tw-gray-250"
      @change="$emit('update:modelValue', $event.target.checked)"
    >
    <label
      v-if="label"
      :for="id"
      :class="checkboxClass"
    >
      <span class="tick-box">
        <svg class="tick" xmlns="http://www.w3.org/2000/svg" width="12" height="9" viewBox="0 0 12 9" fill="none">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M11.5558 0.666642C11.8626 0.973467 11.8626 1.47093 11.5558 1.77775L4.88916 8.44442L0.443261 3.99852C0.136436 3.6917 0.137888 3.19569 0.444713 2.88886C0.751538 2.58204 1.249 2.58204 1.55582 2.88886L4.88916 6.2222L10.4447 0.666642C10.7515 0.359817 11.249 0.359817 11.5558 0.666642Z" fill="white"/>
        </svg>
      </span>
      {{ label }}
    </label>
  </div>
</template>

<style lang="scss" scoped>
.form-element-check {
  @apply relative;
  &.check-primary {
    label {
      @apply r:pl-[30px] inline-flex;
      .tick-box {
        @apply left-0;
        top: calc(50% - 1px);
        &:before {
          @apply mt-0.5;
        }
        .tick {
          @apply mt-1;
        }
      }
    }
  }
  &.check-bar-primary {
    label {
      @apply border border-tw-gray-300 pl-12 p-4 rounded-lg flex;
      .tick-box {
        @apply top-1/2 -translate-y-1/2 r:left-[17px];
      }
    }
    input {
      &:checked {
        + label {
          @apply border-primary-500;
        }
      }
    }
  }
  &.form-invalid {
    label {
      @apply text-tw-red-500;
      .tick-box {
        &:before {
          @apply border-tw-red-500;
        }
      }
    }
    &.check-bar-primary {
      label {
        @apply border-tw-red-500;
      }
    }
  }
  label {
    @apply relative cursor-pointer transition-all;
    .tick-box {
      @apply absolute -translate-y-1/2 w-5 h-5 flex items-center justify-center;
      &:before {
        @apply absolute w-full h-full border border-neutral-gray-300 bg-white left-0 top-0 rounded-sm transition-all;
        content: '';
      }
      .tick {
        @apply relative z-[2] opacity-0 transition-all;
      }
    }
  }
  input {
    @apply w-1 h-1 opacity-0 absolute;
    &:checked {
      + label {
        .tick-box {
          &:before {
            @apply border-primary-600 bg-primary-600;
          }
        }
        .tick {
          @apply opacity-100;
        }
      }
    }
  }
}
</style>

<script>
export default {
  props: {
    id: {
      type: String,
      default() {
        return `checkbox-input-${uuid()}`;
      },
    },
    type: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
    checkboxValue: [String, Boolean],
    modelValue: [String, Boolean],
    label: {
      type: String,
      default: null,
    },
    errors: {
      type: Array,
      default: () => [],
    },
    checkboxClass: {
      type: String,
      default: '',
    },
  },
  emits: ['update:modelValue'],
}
</script>
