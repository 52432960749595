export const VALIDATION_RULES = {
  EMAIL: /^[\w.!?#$%&'=~|{}`+*^][\w\-.!?#$%&'=~|{}`+*^]*@((xn--)?\w+(-\w+)*\.)+[a-z]{2,}$/i,
  KATAKANA_ONLY: /^[ｦ-ﾝァ-ヺヽ゛゜ー 　]*$/g,
  HIRAGANA_KANJI_ONLY: /^[ぁ-ゔ一-龯㐀-䶵・ゞ゛゜ー 　]*$/g,
  HIRAGANA_KANJI_KANA: /^[ぁ-ゔ一-龯㐀-䶵ｦ-ﾝァ-ヺヽ・ゞ゛゜ー 　]*$/g,
  ONLY_NUMBER: /^[0-9０-９]*$/g,
  ALPHA_NUMERIC: /^[a-zA-Zａ-ｚＡ-Ｚ0-9０-９]*$/,
  NOT_LETTER: /^[0-9０-９-+＋()（）]*$/g,
  ONLY_LETTER: /^[a-zA-Zａ-ｚＡ-Ｚ\u3040-\u309f\u30a0-\u30ff\uff66-\uff9d\u4e00-\u9faf\u3400-\u4dbf々〆〤 　]*$/,
  PHONE: /^[0-9０-９]{3}[\s.-]?[0-9０-９]{4}[\s.-]?[0-9０-９]{4}$/,
  POSTAL_CODE: /^[0-9０-９]{7}$/i,
}

export const CARD_API_ERROR_MESSAGES = {
  100: 'カード番号に誤りがあります。',
  101: 'カード番号に誤りがあります。',
  102: 'カード番号の桁数に誤りがあります。',
  110: 'ご登録いただいたカードの有効期限が切れてます。',
  111: 'カードの有効期限に誤りがあります。',
  112: 'カードの有効期限に誤りがあります。',
  121: 'セキリティコードに誤りがあります。',
  122: 'セキリティコードの桁数に誤りがあります。',
  131: '名義人の入力に誤りがあります。',
  132: '名義人の入力可能文字数を超えております。',
  553: 'すでにご登録されているクレジットカードです。',
  901: '現在課金登録がご利用いただけない状況です'
};
