<template>
  <form @submit.prevent="$emit('submit')">
    <input type="hidden" name="authenticity_token" v-model="form.authenticity_token" autocomplete="off">
    <div class="-mb-8 -mr-6 flex flex-wrap p-8">
      <text-input
        v-model="form.admin.name"
        :errors="form.errors.name"
        class="w-full pb-8 pr-6 lg:w-1/2"
        label="First name"
      />
      <text-input
        v-model="form.admin.email"
        :errors="form.errors.email"
        class="w-full pb-8 pr-6 lg:w-1/2"
        label="Email"
      />
      <select-input
        v-model="form.admin.otp_required_for_login"
        :errors="form.errors.otp_required_for_login"
        class="w-full pb-8 pr-6 lg:w-1/2"
        label="Require OTP for login"
      >
        <option :value="true">
          Yes
        </option>
        <option :value="false">
          No
        </option>
      </select-input>
      <text-input
        v-model="form.admin.password"
        :errors="form.errors.password"
        class="w-full pb-8 pr-6 lg:w-1/2"
        type="password"
        autocomplete="new-password"
        label="Password"
      />
      <text-input
        v-model="form.admin.password_confirmation"
        :errors="form.errors.password_confirmation"
        class="w-full pb-8 pr-6 lg:w-1/2"
        type="password"
        autocomplete="new-password"
        label="Password confirmation"
      />
    </div>
    <slot />
  </form>
</template>

<script>
import SelectInput from '@/Shared/SelectInput.vue';
import TextInput from '@/Shared/TextInput.vue';
import FileInput from '@/Shared/FileInput.vue';

export default {
  components: {
    SelectInput,
    TextInput,
    FileInput,
  },
  props: {
    modelValue: {
      type: Object,
      required: true,
    },
  },
  emits: ['update:modelValue', 'submit'],
  computed: {
    form: {
      get() {
        return this.modelValue;
      },
      set(val) {
        this.$emit('update:modelValue', val);
      },
    },
  },
};
</script>
