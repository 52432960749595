<template>
  <div>
    <div class="mb-4">
      <p class="p-3 border-b menu-nav-category border-tw-gray-250">Demo</p>
      <ul class="menu-nav-list">
        <li class="menu-nav-item">
          <Link
            class="group text-tw-black-900 hover:text-accent-500 leading-[1]  flex items-center p-3 transition"
            :href="$routes.admin_root()"
            role="navigation"
            aria-label="Dashboard"
          >
            <icon-raw-svg name="home" class="mr-2"></icon-raw-svg>
            <span class="flex-1">Dashboard</span>
          </Link>
        </li>
        <li class="menu-nav-item">
          <Link
            class="group text-tw-black-900 hover:text-accent-500 leading-[1]  flex items-center p-3 transition"
            :href="$routes.guide_admin_admins()"
            role="navigation"
            aria-label="Guide"
          >
            <icon-raw-svg name="file" class="mr-2"></icon-raw-svg>
            <span class="flex-1">Guide</span>
          </Link>
        </li>
      </ul>
    </div>
    <div class="pt-6 mb-4">
      <p class="p-3 border-b menu-nav-category border-tw-gray-250">案件管理</p>
      <ul class="menu-nav-list">
        <li class="menu-nav-item">
          <a class="group text-tw-black-900 leading-[1]  hover:text-accent-500 flex items-center p-3 transition" :href="$routes.admin_customers()" role="navigation">
            <icon-raw-svg name="file" class="mr-2"></icon-raw-svg>
            <span class="flex-1">相談予約一覧</span>
          </a>
        </li>
      </ul>
    </div>
    <div class="pt-6">
      <p class="p-3 border-b menu-nav-category border-tw-gray-250">サービス管理</p>
      <ul class="menu-nav-list">
        <li class="menu-nav-item">
          <a class="group text-tw-black-900 leading-[1]  hover:text-accent-500 flex items-center p-3 transition" :href="$routes.admin_announcements()" role="navigation">
            <icon-raw-svg name="bell" class="mr-2"></icon-raw-svg>
            <span class="flex-1">お知らせ管理</span>
          </a>
        </li>
        <li class="menu-nav-item">
          <a class="group text-tw-black-900 leading-[1]  hover:text-accent-500 flex items-center p-3 transition" :href="$routes.admin_faq_categories()" role="navigation">
            <icon-raw-svg name="faq" class="mr-2"></icon-raw-svg>
            <span class="flex-1">FAQ管理</span>
          </a>
        </li>
        <li class="menu-nav-item">
          <a class="group text-tw-black-900 leading-[1]  hover:text-accent-500 flex items-center p-3 transition" :href="$routes.admin_article_categories()" role="navigation">
            <icon-raw-svg name="single-file" class="mr-2"></icon-raw-svg>
            <span class="flex-1">記事管理</span>
          </a>
        </li>
      </ul>
    </div>
    <div class="pt-6">
      <ul class="menu-nav-list">
        <li class="menu-nav-item">
          <a class="group text-tw-black-900 leading-[1]  hover:text-accent-500 flex items-center p-3 transition" :href="$routes.admin_export_requests()" role="navigation">
            <icon-raw-svg name="export-file" class="mr-2"></icon-raw-svg>
            <span class="flex-1">エクスポート</span>
          </a>
        </li>
        <li class="menu-nav-item">
          <a class="group text-tw-black-900 leading-[1]  hover:text-accent-500 flex items-center p-3 transition" :href="$routes.admin_settings()" role="navigation">
            <icon-raw-svg name="setting" class="mr-2"></icon-raw-svg>
            <span class="flex-1">設定</span>
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { Link } from '@inertiajs/vue3';
import Icon from '@/Shared/Icon.vue';
import IconRawSvg from '@/Shared/IconRawSvg.vue';

export default {
  components: {
    Icon,
    Link,
    IconRawSvg,
  },
  methods: {
    isUrl(...urls) {
      let currentUrl = this.$page.url.substr(1);

      if (urls[0] === '') {
        return currentUrl === '';
      }

      return urls.filter((url) => currentUrl.startsWith(url)).length;
    },
  },
};
</script>
