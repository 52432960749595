<template>
  <minimal-layout>
    <div id="dropdown" />
    <div class="md:flex md:flex-col">
      <div class="md:flex md:h-screen md:flex-col">
        <div class="md:flex md:flex-shrink-0 border-b px-4 bg-white border-[#e9ecef]">
          <div
            class="flex items-center justify-between md:w-56 md:flex-shrink-0 md:justify-center"
          >
            <a
              class="py-3"
              :href="$routes.admin_root()"
              aria-label="Home"
              role="navigation"
            >
              <logo />
            </a>
            <dropdown
              class="md:hidden"
              placement="bottom-end"
              aria-label="admin menu"
            >
              <template #dropdown>
                <div class="px-8 py-4 mt-2 bg-indigo-800 rounded shadow-lg">
                  <admin-menu />
                </div>
              </template>
            </dropdown>
          </div>
          <div
            class="flex items-center justify-end w-full text-sm md:text-md md:px-12 md:py-0"
          >
            <!-- <div class="mt-1 mr-4">
              {{ $page.props.auth.admin.name }} ({{ $page.props.auth.admin.email }})
            </div> -->
            <dropdown
              class="mt-1"
              placement="bottom-end"
              aria-label="Admin menu"
            >
              <template #default>
                <div class="flex items-center cursor-pointer select-none group">
                  <div
                    class="mr-1 text-gray-800 whitespace-nowrap focus:text-indigo-600 group-hover:text-indigo-600"
                  >
                    {{ $page.props.auth.admin.name }}
                    <span class="hidden md:inline">{{
                      $page.props.auth.admin.email
                    }}</span>
                  </div>
                  <icon
                    class="w-5 h-5 fill-gray-700 focus:fill-indigo-600 group-hover:fill-indigo-600"
                    name="cheveron-down"
                  />
                </div>
              </template>
              <template #dropdown>
                <div class="py-2 mt-2 text-sm bg-white rounded shadow-xl">
                  <Link
                    class="block px-6 py-2 hover:bg-indigo-600 hover:text-white"
                    role="navigation"
                    href="/rails_admin"
                  >
                    rails_admin
                  </Link>
                  <Link
                    class="block px-6 py-2 hover:bg-indigo-600 hover:text-white"
                    role="navigation"
                    :href="$routes.admin_admins()"
                  >
                    Manage Admins
                  </Link>
                  <a
                    class="block w-full px-6 py-2 text-left hover:bg-indigo-600 hover:text-white"
                    :href="$routes.admin_logout()"
                    method="delete"
                    as="button"
                    v-on:click="logout"
                  >
                    Logout
                  </a>
                </div>
              </template>
            </dropdown>
          </div>
        </div>
        <div class="md:flex md:flex-grow md:overflow-hidden">
          <admin-menu
            class="flex-shrink-0 hidden w-[15.625rem] overflow-y-auto bg-white shadow-[0_0.5rem_1rem_rgba(51,51,51,0.15)] md:block"
          />
          <div
            class="px-4 py-[3%] md:flex-1 md:overflow-y-auto"
            scroll-region
          >
            <flash-messages />
            <slot />
          </div>
        </div>
      </div>
    </div>
  </minimal-layout>
</template>

<script>
import { Link } from '@inertiajs/vue3';
import MinimalLayout from '@/Layouts/Minimal.vue';
import Dropdown from '@/Shared/Dropdown.vue';
import FlashMessages from '@/Shared/FlashMessages.vue';
import Icon from '@/Shared/Icon.vue';
import Logo from '@/Shared/Logo.vue';
import AdminMenu from '@/Shared/Admin/AdminMenu.vue';
import axios from 'axios';

export default {
  components: {
    Link,
    MinimalLayout,
    Dropdown,
    FlashMessages,
    Icon,
    Logo,
    AdminMenu,
  },
  methods: {
    logout: function(e) {
      e.preventDefault();

      var newUrl = this.$routes.admin_root();
      axios.delete(this.$routes.admin_logout() + ".json").then(() => {
        window.location = newUrl;
      });
    },
  }
};
</script>
