<template>
  <div>
    <div class="collapse-block">
      <collapse
        v-for="(collapse, index) in collapseDummy"
        :id="`collapse-${index}`"
        :active="collapse.active"
        :key="index"
      >
        <template #collapseHeader>
          <span class="r:text-[16px] text-tw-black-600 leading-[1.3] r:tracking-[0.64px] font-medium">{{ collapse.header }}</span>
        </template>
        <template #collapseContent>
          <p class="text-tw-black-600">{{ collapse.content }}</p>
        </template>
      </collapse>
    </div>
    <tabs class="tab-abc">
      <tab name="tab" title="Overview" id="overview" :route="convertToHref('overview')">
        <p>Overview</p>
      </tab>
      <tab name="tab" title="Detail" id="detail" :route="convertToHref('detail')">
        <p>Detail</p>
      </tab>
      <tab name="tab" title="Finally" id="finally" :route="convertToHref('finally')">
        <p>Finally</p>
      </tab>
    </tabs>

    <div class="flex items-center justify-center test-social">
      <social-sharing name="facebook" :shareUrl="shareUrl" class="px-2" />
      <social-sharing name="twitter" :shareUrl="shareUrl" class="px-2" />
      <social-sharing name="line" :shareUrl="shareUrl" class="px-2" />
    </div>
  </div>
</template>

<script>
import SocialSharing from '@/Shared/User/SocialSharing.vue';
import Collapse from '@/Shared/Collapse.vue';
import Tabs from '@/Shared/Tabs.vue';
import Tab from '@/Shared/Tab.vue';

export default {
  components: {
    SocialSharing,
    Collapse,
    Tabs,
    Tab
  },
  props: {
    shareUrl: {
      type: String,
      required: true,
      default: ''
    }
  },
  data() {
    return {
      collapseDummy: [
        {
          header: 'test-header-1',
          content: 'test-content-1',
          active: false,
        },
        {
          header: 'test-header-2',
          content: 'test-content-2',
          active: false,
        },
        {
          header: 'test-header-3',
          content: 'test-content-3',
          active: false,
        },
        {
          header: 'test-header-4',
          content: 'test-content-4',
          active: false,
        },
      ]
    }
  }
}
</script>
