<template>
  <div class="collapse-box" :id="`collapse-${id}`">
    <div
      :id="`collapse-header-${id}`"
      class="cursor-pointer collapse-header"
      @click="toggleCollapse"
      :aria-expanded="isActive"
      :aria-controls="`collapse-content-${id}`"
    >
      <div class="flex items-center justify-between p-4 hover:opacity-70 transition" :class="collapseItemCls">
        <slot name="collapseHeader" />
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="transition-all duration-200 transform"
          :class="isActive ? 'rotate-180' : 'rotate-0'"
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
          aria-hidden="true"
        >
          <path fill-rule="evenodd" clip-rule="evenodd" d="M3.26646 6.32036C3.64182 5.91524 4.27452 5.8911 4.67965 6.26646L9 10.2693L13.3204 6.26646C13.7255 5.8911 14.3582 5.91524 14.7335 6.32036C15.1089 6.72549 15.0848 7.35819 14.6796 7.73355L9 12.9958L3.32036 7.73355C2.91524 7.35819 2.8911 6.72549 3.26646 6.32036Z" fill="#93A3B5"/>
        </svg>
      </div>
    </div>
    <div
      :id="`collapse-content-${id}`"
      class="grid overflow-hidden transition-all duration-300 ease-in-out collapse-content"
      :class="isActive ? 'grid-rows-[1fr] opacity-100' : 'grid-rows-[0fr] opacity-0'"
      role="region"
      :aria-labelledby="`collapse-header-${id}`"
    >
      <div class="overflow-hidden">
        <slot name="collapseContent" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    id: {
      type: String,
      required: true,
    },
    active: {
      type: Boolean,
      required: true,
    },
    collapseItemCls: {
      type: String,
      default: '',
    }
  },
  data() {
    return {
      isActive: false,
    }
  },
  mounted() {
    this.isActive = this.active;
  },
  methods: {
    toggleCollapse() {
      this.isActive = !this.isActive;
    }
  }
}
</script>
