<template>
  <div class="flex flex-wrap justify-end px-5 py-2">
    <template v-for="(link, key) in links">
      <div
        v-if="link.label === 'gap'"
        :key="key"
        class="flex items-center mx-1 h-9"
      >
        …
      </div>
      <div
        v-else-if="link.url === null"
        :key="key + 'else1'"
        class="flex items-center justify-center w-9 h-9 mx-1 rounded-full border border-tw-black-150 text-tw-black-150 r:text-[14px]"
        :class="{ 'mr-0': link.label === 'Next', 'ml-0': link.label === 'Prev' }"
      >
        {{ link.label === 'Prev' ? '<' : (link.label === 'Next' ? '>' : link.label)}}
      </div>
      <Link
        v-else
        :key="key + 'else2'"
        class="flex items-center justify-center w-9 h-9 mx-1 rounded-full border border-tw-black-150 hover:bg-accent-50 transition r:text-[14px]"
        :class="{ 'bg-accent-500 border-accent-500 text-white': link.active, 'mr-0': link.label === 'Next', 'ml-0': link.label === 'Prev' }"
        :href="link.url"
        preserve-state
        preserve-scroll
      >
        {{ link.label === 'Prev' ? '<' : (link.label === 'Next' ? '>' : link.label)}}
      </Link>
    </template>
  </div>
</template>

<script>
import { Link } from '@inertiajs/vue3';

export default {
  components: {
    Link,
  },
  props: {
    meta: {
      type: Object,
      required: true,
    },
  },

  computed: {
    links() {
      return [
        {
          label: 'Prev',
          url: this.url(this.meta.prev),
        },
        ...this.meta.sequels['0'].map((page) => {
          return {
            label: page,
            url: this.url(page),
            active: this.active(page),
          };
        }),
        {
          label: 'Next',
          url: this.url(this.meta.next),
        },
      ];
    },
  },

  methods: {
    url(pageNumber) {
      return pageNumber
        ? this.meta.scaffold_url.replace(/__pagy_page__/, pageNumber)
        : null;
    },
    active(pageNumber) {
      return this.meta.page == pageNumber;
    },
  },
};
</script>
