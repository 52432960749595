<template>
  <div v-if="!!onlyButton">
    <div class="flex px-4 mt-4 md:px-8">
      <template v-for="(btn, key) in viewsJa.home.prepare.btn"  :key="key">
        <a href="javascript:;" :id="`open-quotation-modal-top-prepare-a-${key}`" :data-testid="`open-quotation-modal-top-prepare-a-${key}`" :class="[key == 'nursing' ?  'mr-1' : 'ml-1' , 'font-black inline-flex items-center justify-center rounded-full btn-gradient btn-blue-gradient-horizontal r:text-[10px] xs:r:text-[12px] p-2 text-white shadow-gray-1 w-1/2 leading-[1.33] r:min-h-[64px]]']" @click.prevent="openModal(key)">
          <span :id="`open-quotation-modal-top-prepare-a-${key}-span`"  class="flex-col items-center xs:flex md:inline-block" v-html="btn"></span>
        </a>
      </template>
    </div>
  </div>
  <div v-else class="mb-8 simulator-btns">
    <div class="flex items-stretch justify-between">
      <template v-for="(button, idx) in viewsJa.home.blocks.insurance_simulator.btn_simulator" :key="idx">
        <div class="w-1/2 mb-8 bg-white rounded-2xl mx-1 flex flex-col">
          <a href="javascript:;" :id="`open-quotation-modal-top-ready-a-${idx}`" :data-testid="`open-quotation-modal-top-ready-a-${idx}`" class="overflow-hidden rounded-2xl shadow-[0_2px_7px_0_rgba(0,0,0,0.20)] flex-1 flex flex-col hover:opacity-70 transition" @click.prevent="openModal(idx)">
            <div :id="`open-quotation-modal-top-ready-a-${idx}-div-1`" class="flex flex-col items-center justify-between flex-1">
              <div :id="`open-quotation-modal-top-ready-a-${idx}-div-1-div`" class="flex flex-col items-center p-4">
                <span :id="`open-quotation-modal-top-ready-a-${idx}-div-1-span-1`" class="r:text-[16px] leading-[1.33] tracking-[0.25px] font-bold xs:flex flex-col items-center md:inline-block" v-html="button.title"></span>
                <span :id="`open-quotation-modal-top-ready-a-${idx}-div-1-span-2`" class="r:text-[16px] leading-[1.33] tracking-[0.25px]" v-html="button.desc"></span>
              </div>
              <images :id="`open-quotation-modal-top-ready-a-${idx}-div-1-img`" :name="button.img" class="mx-auto mb-2" />
            </div>
            <div :id="`open-quotation-modal-top-ready-a-${idx}-div-2`" class="py-4 btn-blue-gradient-horizontal r:min-h-[50px]">
              <svg :id="`open-quotation-modal-top-ready-a-${idx}-div-2-svg`" class="mx-auto" xmlns="http://www.w3.org/2000/svg" width="19" height="18" viewBox="0 0 19 18" fill="none">
                <path :id="`open-quotation-modal-top-ready-a-${idx}-div-2-path`" fill-rule="evenodd" clip-rule="evenodd" d="M6.57036 3.26646C6.97549 2.8911 7.60819 2.91524 7.98355 3.32036L13.2458 9.00001L7.98355 14.6796C7.60819 15.0848 6.97549 15.1089 6.57036 14.7335C6.16524 14.3582 6.1411 13.7255 6.51646 13.3204L10.5193 9.00001L6.51646 4.67965C6.1411 4.27452 6.16524 3.64182 6.57036 3.26646Z" fill="white"/>
              </svg>
            </div>
          </a>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import Layout from '@/Layouts/User.vue';
import Images from '@/Shared/Images.vue';
import Modal from '@/Shared/Modal.vue';

export default {
  components: {
    Images,
    Modal,
  },
  layout: Layout,
  emits: ['open-modal'],
  props: {
    setQuotationOpen: {
      type: Function
    },
    onlyButton: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      viewsJa: this.jaConfig.viewsJa,
      isQuotationOpen: false,
      careType: null,
    }
  },
  methods: {
    openModal(localCareType) {
      this.$emit('open-modal', localCareType);
    }
  },
}
</script>
