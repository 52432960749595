<template>
  <div id="alert-message" class="fixed top-2.5 right-2.5" v-if="isShowAlert">
    <div class="relative w-full bg-white rounded-lg alert-box r:max-w-[400px]">
      <div class="flex items-center justify-center py-2 px-4 rounded-lg alert-message-content bg-neutral-gray-700 r:max-w-[358px]">
        <div @click="isShowAlert = false" class="mr-4 cursor-pointer alert">
          <images name="closeMail" class="r:min-w-[16px] cursor-pointer"/>
        </div>
        <span class="desc block text-white r:text-[16px] leading-[1.53]">{{ message }}</span>
        <images name="alert" class="r:min-w-[100px] ml-4"/>
      </div>
    </div>
  </div>
</template>
<script>
import Images from '@/Shared/Images.vue';

export default {
  components: {
    Images,
  },
  props: {
    isShowAlert: {
      type: Boolean,
      required: true
    },
    message: {
      type: String,
      required: true
    },
  }
};
</script>
