<template>
  <div class="r:max-w-[672px] mx-auto pt-8 application-review">
    <div class="px-6 mb-6 title-section">
      <span class="text-primary-600 r:text-[18px] leading-[1.53] r:tracking-[0.25px] mb-1">{{ viewsJa.contracts.application_review.sub_title }}</span>
      <h1 class="r:text-[34px] font-black leading-[1.53] r:tracking-[0.25px]" v-html="viewsJa.contracts.application_review.title"></h1>
    </div>
    <div class="px-6 alert-box-top">
      <div class="px-4 py-3 border rounded-lg border-tw-red-200 bg-tw-red-50 xs:px-6 xs:py-4">
        <svg class="mb-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M10.8474 3.31557L1.5381 19.3293C1.02136 20.2181 1.66264 21.3327 2.6908 21.3327H21.3094C22.3376 21.3327 22.9788 20.2181 22.4621 19.3293L13.1528 3.31557C12.6387 2.43127 11.3615 2.43127 10.8474 3.31557ZM13.3337 12.666C13.3337 13.4024 12.7368 13.9994 12.0004 13.9994C11.264 13.9994 10.6671 13.4024 10.6671 12.666V8.66604C10.6671 7.92966 11.264 7.3327 12.0004 7.3327C12.7368 7.3327 13.3337 7.92966 13.3337 8.66604V12.666ZM10.3337 16.9994C10.3337 16.0789 11.0799 15.3327 12.0004 15.3327C12.9209 15.3327 13.6671 16.0789 13.6671 16.9994C13.6671 17.9198 12.9209 18.666 12.0004 18.666C11.0799 18.666 10.3337 17.9198 10.3337 16.9994Z" fill="#E33B24"/>
        </svg>
        <h4 class="r:text-[14px] font-bold leading-[1.53] r:tracking-[0.25px] text-tw-red-600 mb-1" v-html="viewsJa.contracts.application_review.notice"></h4>
        <p class="text-tw-red-600 r:text-[14px] leading-[1.53] r:tracking-[0.25px] mb-4">{{ viewsJa.contracts.application_review.explain }}</p>
      </div>
    </div>
    <!-- user-info -->
    <div class="r:max-w-[688px] mt-24 mx-auto user-info-section">
      <h2 class="mb-4 px-4 xs:px-6 r:text-[24px] leading-[1.53]">{{ viewsJa.contracts.application_review.groups.user_info.group_title }}</h2>
      <div class="px-4 py-6 mb-10 bg-white border-b xs:px-6 xs:py-8 border-neutral-gray-100">
        <h4 class="mb-8 r:text-[18px] leading-[1.53] text-primary-600"> {{ viewsJa.contracts.application_review.user_info.contractor_title }}</h4>
        <div class="mb-2 flex items-start r:text-[16px] leading-[1.53]">
          <span class="r:min-w-[130px]">{{ viewsJa.contracts.application_review.label.full_name }}</span>
          <span class="flex-1 ml-6 break-all">{{ `${this.userInfo.last_name}  ${userInfo.first_name}` }}</span>
        </div>
        <div class="mb-2 flex items-start r:text-[16px] leading-[1.53]">
          <span class="r:min-w-[130px]">{{ viewsJa.contracts.application_review.label.full_name_kana }}</span>
          <span class="flex-1 ml-6 break-all">{{ `${userInfo.last_name_kana}  ${userInfo.first_name_kana}` }}</span>
        </div>
        <div class="mb-2 flex items-start r:text-[16px] leading-[1.53]">
          <span class="r:min-w-[130px]">{{ viewsJa.contracts.application_review.label.dob }}</span>
          <span class="flex-1 ml-6">{{ formatDate(this.userInfo.date_of_birth) }}</span>
        </div>
        <div class="mb-2 flex items-start r:text-[16px] leading-[1.53]">
          <span class="r:min-w-[130px]">{{ viewsJa.contracts.application_review.label.gender }}</span>
          <span class="flex-1 ml-6">{{ convertGenderJP(userInfo.gender) }}</span>
        </div>
        <div class="mb-2 flex items-start r:text-[16px] leading-[1.53]">
          <span class="r:min-w-[130px]">{{ viewsJa.contracts.application_review.label.email }}</span>
          <span class="flex-1 ml-6 break-all">{{ this.userInfo.register_email }}</span>
        </div>
        <hr class="my-8 mx-[-24px] border-tw-neutral-gray-100" />
        <h4 class="mb-8 r:text-[18px] leading-[1.53] text-primary-600"> {{ viewsJa.contracts.application_review.user_info.shipping_title }}</h4>
        <div class="mb-2 flex items-start r:text-[16px] leading-[1.53]">
          <span class="r:min-w-[130px]">{{ viewsJa.contracts.application_review.label.postal_code }}</span>
          <span class="flex-1 ml-6">{{ this.userInfo.postal_code }}</span>
        </div>
        <div class="mb-2 flex items-start r:text-[16px] leading-[1.53]">
          <span class="r:min-w-[130px]">{{ viewsJa.contracts.application_review.label.address }}</span>
          <span class="flex-1 ml-6 break-all">{{ `${userInfo.prefecture_name}${userInfo.municipality}${userInfo.town}${userInfo.detail_address}` }}<br>{{ userInfo.address }}</span>
        </div>
        <div class="mb-2 flex items-start r:text-[16px] leading-[1.53]">
          <span class="r:min-w-[130px]">{{ viewsJa.contracts.application_review.label.phone }}</span>
          <span class="flex-1 ml-6">{{ this.userInfo.phone }}</span>
        </div>
      </div>
    </div>
    <!-- parent-info -->
    <div class="r:max-w-[688px] mx-auto parent-info-section mb-8">
      <h2 class="mb-4 px-4 xs:px-6 r:text-[24px] leading-[1.53]" v-html="viewsJa.contracts.application_review.parent_information.group_title"></h2>
      <div class="py-6 bg-white border-b xs:py-8 parent_info border-neutral-gray-100">
        <template v-for="parent in relatives" :key="parent.id">
          <div class="relative-details px-4 xs:px-6">
            <span class="block mb-8 text-primary-600" v-if="parent.gender=='male'"> {{ viewsJa.contracts.application_review.parent_information.father_section }}</span>
            <span class="block mb-8 text-primary-600" v-if="parent.gender=='female'"> {{ viewsJa.contracts.application_review.parent_information.mother_section }}</span>
            <div class="mb-2 flex items-start r:text-[16px] leading-[1.53]">
              <span class="r:min-w-[130px]">{{ viewsJa.contracts.application_review.label.full_name }}</span>
              <span class="flex-1 ml-6 break-all">{{ `${parent.last_name}  ${parent.first_name}` }}</span>
            </div>
            <div class="mb-2 flex items-start r:text-[16px] leading-[1.53]">
              <span class="r:min-w-[130px]">{{ viewsJa.contracts.application_review.label.full_name_kana }}</span>
              <span class="flex-1 ml-6 break-all">{{ `${parent.last_name_kana}  ${parent.first_name_kana}` }}</span>
            </div>
            <div class="mb-2 flex items-start r:text-[16px] leading-[1.53]">
              <span class="r:min-w-[130px]">{{ viewsJa.contracts.application_review.label.dob }}</span>
              <span class="flex-1 ml-6">{{ parent.dob }}</span>
            </div>
            <div class="mb-2 flex items-start r:text-[16px] leading-[1.53]">
              <span class="r:min-w-[130px]">{{ viewsJa.contracts.application_review.label.age }}</span>
              <span class="flex-1 ml-6">{{ parent.age }}</span>
            </div>
            <div class="mb-2 flex items-start r:text-[16px] leading-[1.53]">
              <span class="r:min-w-[130px]">{{ viewsJa.contracts.application_review.label.gender }}</span>
              <span class="flex-1 ml-6">{{ convertGenderJP(parent.gender)}}</span>
            </div>
          </div>
          <hr v-if="Object.keys(relatives).length > 1" class="my-8 border-tw-neutral-gray-100 last:hidden" />
        </template>
      </div>
    </div>
    <!-- insurance_plans -->
    <div class="r:max-w-[688px] mx-auto insurance-plan-section mb-16">
      <h2 class="mb-4 px-4 xs:px-6 r:text-[24px] leading-[1.53]">{{ viewsJa.contracts.application_review.groups.insurance_info.group_title }}</h2>
      <div class="px-4 py-6 bg-white xs:px-6 xs:py-8 insurance-plan-infos">
        <div class="death-insurance">
          <h4 class="mb-8 r:text-[18px] leading-[1.53] text-primary-600">{{ viewsJa.contracts.application_review.insurance_apply.plan_title }}</h4>
            <div class="common-info entry">
              <div class="mb-4">
                <span class="block mb-1 r:text-[14px] leading-[1.53]">{{ viewsJa.contracts.application_review.insurance_apply.insurance_person }}</span>
                <span class="mb-1 font-bold r:text-[16px] leading-[1.53]">
                  <span>{{ viewsJa.contracts.application_review.insurance_apply.policy.holder_prefix }}</span>
                  <span>
                    <template v-if="relatives.length > 1">
                      {{ `&nbsp;&nbsp;${userInfo.last_name}&nbsp;&nbsp;${userInfo.first_name}` }}
                      <span>{{ viewsJa.contracts.application_review.insurance_apply.policy.suffix.both }}</span>
                    </template>
                    <template v-else>
                      <span>{{ `&nbsp;&nbsp;${userInfo.last_name}&nbsp;&nbsp;${userInfo.first_name}` }}</span>
                      <span>{{ viewsJa.contracts.application_review.insurance_apply.policy.suffix[relatives[0].gender] }}</span>
                    </template>
                  </span>
                </span>
              </div>
              <div class="mb-4">
                <span class="block mb-1 r:text-[14px] leading-[1.53]">{{ viewsJa.contracts.application_review.insurance_apply.start_date }}</span>
                <span class="block mb-1 font-bold r:text-[16px] leading-[1.53]">{{ formatDate(lastContract.contract_start_date) }}16時</span>
              </div>
              <div class="mb-4">
                <span class="block mb-1 r:text-[14px] leading-[1.53]">{{ viewsJa.contracts.application_review.insurance_apply.first_year_period }}</span>
                <span class="block font-bold r:text-[16px] leading-[1.53]">{{ formatDate(lastContract.contract_start_date) }} {{ viewsJa.contracts.application_review.default_hour }} - {{ formatDate(lastContract.contract_end_date) }} {{ viewsJa.contracts.application_review.default_hour }}</span>
              </div>
            </div>
            <div class="pt-4 each-plans">
              <div class="px-6 pt-4 mb-4 text-center border rounded-lg xs:px-8 bg-tw-black-100">
                <h5 class="text-tw-black-500 r:text-[16px] font-bold mb-4">{{ viewsJa.contracts.application_review.insurance_apply.monthly_premium_title }}</h5>
              </div>
              <template v-for="contract in contractsData" :key="contract.id">
                <div class="r:text-[16px] leading-[1.53]">
                  <span class="mb-2 r:text-[16px] font-bold block">{{ viewsJa.contracts.application_review.insurance_apply.policy.name[contract.relative_gender] }}</span>
                  <div class="mb-2 flex justify-between items-center r:text-[16px] leading-[1.53]">
                    <span class="">{{ viewsJa.contracts.application_review.insurance_apply.insurance_fee.plan }}</span>
                    <span class="values">
                      <span>{{ viewsJa.contracts.application_review.insurance_apply.insurance_fee.plan_name[contract.plan] }}</span>
                    </span>
                  </div>
                  <div class="mb-2 flex justify-between items-center r:text-[16px] leading-[1.53]">
                    <span class="">{{ viewsJa.contracts.application_review.insurance_apply.insurance_fee.nursing_care }}</span>
                    <span class="values">
                      <span>{{ numberWithCommas(contract.care_insurance) }}</span>
                      <span>{{ viewsJa.common.unit.ten_thousand_yen }}</span>
                    </span>
                  </div>
                  <div class="mb-2 flex justify-between items-center r:text-[16px] leading-[1.53]">
                    <span class="">{{ viewsJa.contracts.application_review.insurance_apply.insurance_fee.accidental_death }}</span>
                    <span class="values">
                      <span>{{ numberWithCommas(contract.death_insurance) }}</span>
                      <span>{{ viewsJa.common.unit.ten_thousand_yen }}</span>
                    </span>
                  </div>
                  <div class="mb-2 flex justify-between items-center r:text-[16px] leading-[1.53]">
                    <span class="">{{ viewsJa.contracts.application_review.insurance_apply.insurance_fee.start_age }}</span>
                    <span class="values">{{ contract.age }}</span>
                  </div>
                  <div class="mb-2 flex justify-between items-center r:text-[16px] leading-[1.53]">
                    <span class="">{{ viewsJa.contracts.application_review.insurance_apply.insurance_fee.monthly_premium }}</span>
                    <span class="values">
                      <span>{{ numberWithCommas(contract.monthly_fee) }}</span>
                      <span>{{ viewsJa.common.unit.yen }}</span>
                    </span>
                  </div>
                </div>
                <hr v-if="Object.keys(contractsData).length > 1" class="my-8 border-tw-neutral-gray-100 last:hidden" />
              </template>
            </div>
        </div>
      </div>
    </div>
    <!-- announcement -->
    <div class="r:max-w-[688px] mx-auto announcement-section mb-16">
      <h2 class="r:text-[24px] px-4 xs:px-6 pb-4">{{ viewsJa.contracts.application_review.groups.announcement_info.group_title }}</h2>
      <div class="px-4 py-6 bg-white xs:px-6 xs:py-8 mb-7 announcement-infos">
        <div class="entry">
          <h4 class="r:text-[18px] text-primary-600 leading-[1.53] mb-8"> {{ viewsJa.contracts.application_review.announcement_info.entry_person_title }}</h4>
          <span class="inline-block pr-4 r:text-[16px]"> {{ viewsJa.contracts.application_review.announcement_info.entry_desc }}</span>
          <span class="r:text-[16px]"> {{ `${userInfo.last_name}  ${userInfo.first_name}${viewsJa.contracts.application_review.announcement_info.suffix}` }} </span>
        </div>
        <hr class="border-tw-neutral-gray-100 my-8 mx-[-24px]" />
        <div class="application-date ">
          <h4 class="r:text-[18px] text-primary-600 leading-[1.53] mb-8"> {{ viewsJa.contracts.application_review.contract_info.application_date }}</h4>
          <div class="flex justify-between">
            <div class="w-1/2">
              <span class="inline-block r:text-[16px]"> {{ viewsJa.contracts.application_review.contract_info.application_date }}</span>
            </div>
            <div class="w-1/2">
              <span class="r:text-[16px]"> {{ formatDate(lastContract.application_at) }} </span>
              <br>
              <span class="r:text-[16px]"> {{ viewsJa.contracts.application_review.contract_info.application_date_desc }} </span>
            </div>
          </div>
        </div>
        <hr class="border-tw-neutral-gray-100 my-8 mx-[-24px]" />
        <div class="pb-2 health-condition">
          <h4 class="r:text-[18px] text-primary-600 leading-[1.53] mb-8"> {{ viewsJa.contracts.application_review.announcement_info.inform }}</h4>
          <div class="px-8 pt-4 mb-4 text-center border rounded-lg bg-tw-black-100">
            <h5 class="text-tw-black-500 r:text-[16px] font-bold mb-4">{{ viewsJa.contracts.application_review.announcement_info.health_condition.title }}</h5>
          </div>
          <span class="block mb-2" v-for="(info, idx) in viewsJa.contracts.application_review.announcement_info.health_condition.infos" :key="idx">
            <span class="flex r:text-[16px] leading-[1.53]" v-html="info"></span>
          </span>
        </div>
        <div class="pt-4 announcement-regarding">
          <div class="px-8 pt-4 mb-4 text-center border rounded-lg bg-tw-black-100">
            <h5 class="text-tw-black-500 r:text-[16px] font-bold mb-4">{{ viewsJa.contracts.application_review.announcement_info.regarding.title }}</h5>
          </div>
          <span class="r:text-[16px] leading-[1.53]">{{ viewsJa.contracts.application_review.announcement_info.regarding.desc }}</span>
        </div>
        <hr class="border-tw-neutral-gray-100 my-8 mx-[-24px]" />
        <h4 class="r:text-[18px] text-primary-600 leading-[1.53] mb-8"> {{ viewsJa.contracts.application_review.announcement_info.compensation.title }}</h4>
        <div v-for="parent in relatives" :key="parent.id"  class="mb-2 flex items-center r:text-[16px] leading-[1.53]">
          <span class="r:min-w-[130px]">{{ `${parent.last_name}  ${parent.first_name}` }}さま</span>
          <span class="flex-1 ml-6">{{ viewsJa.contracts.application_review.announcement_info.compensation.all_no_desc }}</span>
        </div>
      </div>
    </div>
    <!-- plans-price -->
    <div class="r:max-w-[688px] mx-auto plans-price-section mb-16">
      <h2 class="r:text-[24px] xs:px-6 px-4 pb-4">{{ viewsJa.contracts.application_review.groups.plan_price_info.group_title }}</h2>
      <div class="px-4 py-6 bg-white plans-price-infos xs:px-6 xs:py-8">
        <span class="r:text-[16px] text-primary-600 leading-[1.53] mb-4 block">{{ viewsJa.contracts.application_review.plan_price.total }}</span>
        <div class="each-plan">
          <div class="r:text-[16px] leading-[1.53]">
            <span class="flex items-center justify-between mb-2 last:mb-4" v-for="(contract, key) in contractsData" :key="contract.id" >
              <span :data-relative-gender="key" class="r:text-[16px] block">{{ viewsJa.contracts.application_review.plan_price.each_plan[contract.relative_gender] }}</span>
              <span class="values">
                <span> {{ numberWithCommas(contract.monthly_fee) }} </span>
                <span> {{ viewsJa.common.unit.yen_per_month }}</span>
              </span>
            </span>
            <hr class="my-4 border-tw-neutral-gray-100"/>
            <span class="flex items-center justify-between">
              <span class="r:text-[16px] leading-[1.53] font-bold">{{ viewsJa.contracts.application_review.plan_price.total_value }}</span>
              <span class="r:text-[16px] leading-[1.53] font-bold">
                <span> {{ numberWithCommas(totalContractFee) }} </span>
                <span>{{ viewsJa.common.unit.yen_per_month }}</span>
              </span>
            </span>
            <hr class="my-8 mx-[-24px] border-tw-neutral-gray-100"/>
          </div>
        </div>
        <div class="r:text-[16px] pb-4 payment-method">
          <h4 class="mb-4 r:text-[18px] leading-[1.53] text-primary-600">{{ viewsJa.contracts.application_review.plan_price.payment_method }}</h4>
          <span class="r:text-[16px] leading-[1.53]">{{ viewsJa.contracts.application_review.plan_price.by_credit_card }}</span>
          <!-- Link to edit credit card -->
          <div class="mt-3 text-right">
            <a :href="$routes.edit_credit_cards()" data-testid="link-to-edit-credit-card" id="link-to-edit-credit-card" class="underline text-accent-500 hover:text-accent-600 transition leading-[2] r:text-[16px]">
              <span id="link-to-edit-credit-card-span" class=""> {{ viewsJa.contracts.application_review.payment_method.edit_card_info }}</span>
            </a>
          </div>
        </div>
      </div>
    </div>

    <div class="start-of-coverage-section r:max-w-[672px] mx-auto mb-16">
      <h2 class="r:text-[24px] leading-[1.53] r:tracking-[0.25px] px-6 mb-4">{{ viewsJa.contracts.application_review.period_explanation.title }}</h2>
      <div class="px-4 py-6 bg-white border-b explain-matter-info xs:px-6 xs:py-8 border-neutral-gray-100">
        <div class="mb-6 xs:mb-8">
          <p class="r:text-[16px] leading-[1.53] r:tracking-[0.25px] mb-6 xs:mb-8" v-html="viewsJa.contracts.application_review.start_of_coverage.desc"></p>
          <images name="thankyouExplanation" class="mx-auto mb-6" />
          <span class="r:text-[12px] leading-[1.53] r:tracking-[0.25px] block">{{ viewsJa.contracts.application_review.start_of_coverage.note_1 }}</span>
          <span class="r:text-[12px] leading-[1.53] r:tracking-[0.25px] block">{{ viewsJa.contracts.application_review.start_of_coverage.note_2 }}</span>
          <span
            v-for="(coverage, idx) in viewsJa.contracts.application_review.start_of_coverage.note_3" :key="idx"
            class="flex r:pl-[18px] relative r:text-[12px] leading-[1.53] r:tracking-[0.25px]"
          >
            <span class="absolute left-2 top-2 r:w-[3px] r:h-[3px] rounded-full bg-neutral-gray-900"></span>
            <span class="flex-1" v-html="coverage"></span>
          </span>
        </div>
      </div>
    </div>

    <div class="start-of-coverage-section r:max-w-[672px] mx-auto">
      <h2 class="r:text-[24px] leading-[1.53] r:tracking-[0.25px] px-6 mb-4">{{ viewsJa.contracts.application_review.bottom_group.title }}</h2>
      <div class="px-6 py-8 alert-box-bottom">
        <div class="px-4 py-3 border rounded-lg border-tw-red-200 bg-tw-red-50 xs:px-6 xs:py-4">
          <svg class="mb-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M10.8474 3.31557L1.5381 19.3293C1.02136 20.2181 1.66264 21.3327 2.6908 21.3327H21.3094C22.3376 21.3327 22.9788 20.2181 22.4621 19.3293L13.1528 3.31557C12.6387 2.43127 11.3615 2.43127 10.8474 3.31557ZM13.3337 12.666C13.3337 13.4024 12.7368 13.9994 12.0004 13.9994C11.264 13.9994 10.6671 13.4024 10.6671 12.666V8.66604C10.6671 7.92966 11.264 7.3327 12.0004 7.3327C12.7368 7.3327 13.3337 7.92966 13.3337 8.66604V12.666ZM10.3337 16.9994C10.3337 16.0789 11.0799 15.3327 12.0004 15.3327C12.9209 15.3327 13.6671 16.0789 13.6671 16.9994C13.6671 17.9198 12.9209 18.666 12.0004 18.666C11.0799 18.666 10.3337 17.9198 10.3337 16.9994Z" fill="#E33B24"/>
          </svg>
          <h4 class="r:text-[14px] font-bold leading-[1.53] r:tracking-[0.25px] text-tw-red-600 mb-1" v-html="viewsJa.contracts.application_review.notice"></h4>
          <p class="text-tw-red-600 r:text-[14px] leading-[1.53] r:tracking-[0.25px] mb-4">{{ viewsJa.contracts.application_review.explain }}</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Layout from '@/Layouts/User.vue';
import { convertJaParams, convertDateJP, convertGenderJP, numberWithCommas } from '@/utils/utils';
import Modal from '@/Shared/Modal.vue';
import CheckboxInput from '@/Shared/CheckboxInput.vue';
import TextInput from '@/Shared/TextInput.vue';
import RadioInput from '@/Shared/RadioInput.vue';
import DropdownDate from '@/Shared/User/DropdownDate.vue';
import Places from '@/Shared/User/Places.vue';
import Images from '@/Shared/Images.vue';

export default {
  components: {
    CheckboxInput,
    TextInput,
    Modal,
    RadioInput,
    DropdownDate,
    Places,
    Images,
  },
  layout: (h, page) => h(Layout, { mainClass: 'bg-neutral-gray-50' }, () => page),
  props: {
    relatives: {
      type: Array,
      required: true
    },
    isLogged: {
      type: Boolean,
      required: true
    },
    userInfo: {
      type: Object,
      required: true,
    },
    contractsData: {
      type: Object,
      required: true,
    }
  },
  data() {
    return {
      viewsJa: this.jaConfig.viewsJa,
      modelsJa: this.jaConfig.modelsJa,
      convertJaParams: convertJaParams,
      convertGenderJP: convertGenderJP,
      convertDateJP: convertDateJP,
      numberWithCommas: numberWithCommas,
      userData: {},
      canShow: false,
    }
  },
  methods: {
    formatDate(dateString) {
      const date = new Date(new Date(dateString).toLocaleString("en-US", {timeZone: 'Asia/Tokyo'}))
      const year = date.getFullYear();
      const month = ('0' + (date.getMonth() + 1)).slice(-2);
      const day = ('0' + date.getDate()).slice(-2);
      return `${year}年${month}月${day}日`;
    }
  },
  computed: {
    totalContractFee () {
      let total = 0;
      this.contractsData.forEach(contract => { total += contract.monthly_fee })
      return total;
    },
    lastContract() {
      return this.contractsData[this.contractsData.length - 1];
    },
  },
}
</script>
