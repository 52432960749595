<template>
  <div>
    <Head title="Create Admin" />
    <h1 class="mb-8 text-3xl font-bold">
      <Link
        class="text-indigo-400 hover:text-indigo-600"
        :href="$routes.admin_admins()"
      >
        Admins
      </Link>
      <span class="font-medium text-indigo-400">/</span> Create
    </h1>
    <div class="max-w-3xl overflow-hidden bg-white rounded shadow">
      <admin-form v-model="form" @submit="form.post($routes.admin_admins())">
        <div
          class="flex items-center justify-end px-8 py-4 bg-gray-100 border-t border-gray-200"
        >
          <loading-button
            :loading="form.processing"
            class="btn-indigo"
            type="submit"
          >
            Create Admin
          </loading-button>
        </div>
      </admin-form>
    </div>
  </div>
</template>

<script>
import { Head, Link } from '@inertiajs/vue3';
import Layout from '@/Layouts/Admin.vue';
import LoadingButton from '@/Shared/LoadingButton.vue';
import AdminForm from './Form.vue';

export default {
  components: {
    Head,
    Link,
    LoadingButton,
    AdminForm,
  },
  layout: Layout,
  props: {
    admin: {
      type: Object,
      required: true,
    },
  },
  remember: 'form',
  data() {
    return {
      form: this.$inertia.form({
        admin: this.admin,
        authenticity_token: document.querySelector('meta[name="csrf-token"]').content
      }),
    };
  },
};
</script>
