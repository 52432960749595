<template>
  <div class="form-element-radio">
    <input
      :id="id"
      :name="name"
      :ref="`radio-${id}`"
      type="radio"
      :value="radioValue"
      :checked="isChecked"
      class="rounded-full focus:ring-0 focus:ring-transparent"
      @change="$emit('update:modelValue', $event.target.value)"
      v-bind="{ ...$attrs, class: null }"
    >
    <label
      v-if="label"
      :for="id"
      :class="radioClass"
    >{{ label }}</label>
    <template v-else-if="hasCustomLabel">
      <slot></slot>
    </template>
  </div>
</template>

<style lang="scss" scoped>
.form-element-radio {
  @apply relative;
  &.radio-primary {
    label {
      @apply pl-7;
      &:before {
        @apply left-0 top-0;
      }
      &:after {
        @apply r:left-[5px] r:top-[5px];
      }
    }
  }
  &.radio-bar-primary {
    label {
      @apply border border-neutral-gray-200 pl-12 pr-4 rounded-lg r:py-[15px] block transition-all;
      &:before,
      &:after {
        @apply top-1/2 -translate-y-1/2;
      }
      &:before {
        @apply r:left-[17px];
      }
      &:after {
        @apply r:left-[22px];
      }
    }
    input {
      &:checked {
        + label {
          @apply border-primary-500;
        }
      }
      &:disabled,
      &.disabled {
        + label {
          @apply border-neutral-gray-100 bg-neutral-gray-50;
        }
      }
    }
  }
  &.form-invalid {
    label {
      @apply text-tw-red-500;
    }
    &.radio-bar-primary {
      label {
        @apply border-tw-red-500;
      }
    }
  }
  label {
    @apply relative cursor-pointer leading-[1.53] r:tracking-[0.25px] r:text-[16px];
    &:before,
    &:after {
      @apply absolute rounded-full transition-all;
      content: '';
    }
    &:before {
      @apply w-5 h-5 border border-neutral-gray-300 bg-white;
    }
    &:after {
      @apply w-2.5 h-2.5 opacity-0 bg-white;
    }
  }
  input {
    @apply w-1 h-1 opacity-0 absolute;
    &:checked {
      + label {
        &:before {
          @apply border-primary-600 bg-primary-600;
        }
        &:after {
          @apply opacity-100;
        }
      }
    }
    &:disabled,
    &.disabled {
      + label {
        @apply text-neutral-gray-300 cursor-default pointer-events-none;
        &:before {
          @apply bg-neutral-gray-50 border-neutral-gray-100;
        }
      }
    }
  }
}
</style>

<script>
export default {
  props: {
    id: {
      type: String,
      default() {
        return `radio-input-${uuid()}`;
      },
    },
    type: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
    radioValue: String,
    modelValue: [String, Boolean],
    label: {
      type: String,
      default: null,
    },
    errors: {
      type: Array,
      default: () => [],
    },
    radioClass: {
      type: String,
      default: '',
    },
    hasCustomLabel: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:modelValue'],
  computed: {
    isChecked() {
      return this.modelValue === this.radioValue
    }
  },
}
</script>
