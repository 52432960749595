<template>
  <div :class="careType == 'nursing' ? 'nursing-type' : 'home-type'">
    <template v-if="careType == 'nursing'">
      <div class="flex mb-1">
        <images :name="viewsJa.quotations.result.discussion.nursing.chats[0].avatar" class="w-10 h-10 mr-1" />
        <div class="flex flex-col">
          <span class="r:text-[10px] leading-[1.53] r:tracking-[0.25px] text-neutral-gray-800">{{ viewsJa.quotations.result.discussion.nursing.chats[0].consult_name }}</span>
          <div class="flex flex-col chat">
            <div class="flex justify-start line-message mb-1">
              <div class="pl-4 pr-2 pt-2 pb-2.5 rounded-b-3xl rounded-tr-3xl mt-1 bg-white sm:inline-flex">
                <p>
                  <span v-if="!summaryData.female" class="r:text-[16px] leading-[1.53] r:tracking-[0.25px] text-tw-black-900" v-html="convertJaParams(viewsJa.quotations.result.discussion.nursing.chats[0].messages[0].mess_only_male, { male_age: summaryData.male.age })"></span>
                  <span v-else-if="!summaryData.male" class="r:text-[16px] leading-[1.53] r:tracking-[0.25px] text-tw-black-900" v-html="convertJaParams(viewsJa.quotations.result.discussion.nursing.chats[0].messages[0].mess_only_female, { female_age: summaryData.female.age })"></span>
                  <span v-else class="r:text-[16px] leading-[1.53] r:tracking-[0.25px] text-tw-black-900" v-html="convertJaParams(viewsJa.quotations.result.discussion.nursing.chats[0].messages[0].mess, { male_age: summaryData.male.age, female_age: summaryData.female.age })"></span>
                  <a id="discuss-nursing-open-quotation" data-testid="discuss-open-quotation" class="underline text-tw-blue-500 r:text-[16px] leading-[1.53] r:tracking-[0.25px] hover:opacity-70 transition cursor-pointer" @click.prevent="setQuotationOpen(true)">{{ viewsJa.quotations.result.discussion.nursing.chats[0].messages[0].modal_quotation }}</a>
                </p>
              </div>
            </div>
            <div class="flex justify-end mb-1">
              <tippy
                interactive
                animation="fade"
                trigger="click"
                :arrow="false"
                :maxWidth="280"
                :onShown="(instance) => {
                  handleCloseTooltip(instance);
                }"
              >
                <template #default>
                  <a href="javascript:;" id="show-tooltip-contract-day-nursing" data-testid="show-tooltip-contract-day-nursing" class="inline-flex text-white transition hover:opacity-70">
                    <svg id="show-tooltip-contract-day-nursing-svg" class="mt-0.5" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                      <path id="show-tooltip-contract-day-nursing-path" fill-rule="evenodd" clip-rule="evenodd" d="M9 17C13.4183 17 17 13.4183 17 9C17 4.58172 13.4183 1 9 1C4.58172 1 1 4.58172 1 9C1 13.4183 4.58172 17 9 17ZM11.3025 8.1875L10.6275 8.8775C10.5706 8.93516 10.516 8.98898 10.4638 9.04042C10.0201 9.47739 9.75 9.74349 9.75 10.75C9.75 11.1642 9.41421 11.5 9 11.5C8.58579 11.5 8.25 11.1642 8.25 10.75V10.625C8.25 9.8 8.5875 9.05 9.1275 8.5025L10.0575 7.5575C10.335 7.2875 10.5 6.9125 10.5 6.5C10.5 5.675 9.825 5 9 5C8.175 5 7.5 5.675 7.5 6.5C7.5 6.91421 7.16421 7.25 6.75 7.25C6.33579 7.25 6 6.91421 6 6.5C6 4.8425 7.3425 3.5 9 3.5C10.6575 3.5 12 4.8425 12 6.5C12 7.16 11.73 7.76 11.3025 8.1875ZM10 13.5C10 14.0523 9.55228 14.5 9 14.5C8.44771 14.5 8 14.0523 8 13.5C8 12.9477 8.44771 12.5 9 12.5C9.55228 12.5 10 12.9477 10 13.5Z" fill="white"/>
                    </svg>
                    <span id="show-tooltip-contract-day-nursing-span" class="ml-1 r:text-[14px] leading-[1.53] r:tracking-[0.25px]">{{ convertJaParams(viewsJa.quotations.result.discussion.fields_note.date_valid_for_contract, {date_valid: contractStartDayStr}) }}</span>
                  </a>
                </template>
                <template #content>
                  <div class="p-3 rounded-lg bg-neutral-gray-700 xs:p-4">
                    <h4 class="text-white r:text-[16px] font-black leading-[1.53] r:tracking-[0.25px] mb-3 xs:mb-4">{{ convertJaParams(viewsJa.quotations.result.discussion.fields_tooltip.parent.date_valid_for_contract.title, {date_valid: contractStartDayStr}) }}</h4>
                    <p class="text-white r:text-[14px] leading-[1.53] r:tracking-[0.25px] mb-6 xs:mb-8">{{ viewsJa.quotations.result.discussion.fields_tooltip.parent.date_valid_for_contract.desc }}</p>
                    <button type="button" data-testid="nursing-open-tooltip-custom-info" id="nursing-open-tooltip-custom-info" class="btn-close-tooltip btn-solid inline-flex items-center justify-center rounded-full btn-gradient r:text-[14px] py-3.5 px-4 font-medium text-white shadow-gray-1 bg-neutral-gray-800 r:tracking-[0.25px] leading-[1.33] r:min-h-[47px]">
                      <span id="nursing-open-tooltip-custom-info-span">{{ viewsJa.quotations.result.discussion.fields_tooltip.btn_close }}</span>
                    </button>
                  </div>
                </template>
              </tippy>
            </div>
            <div class="flex justify-start line-message">
              <div class="pl-4 pr-2 pt-2 pb-2.5 rounded-b-3xl rounded-tr-3xl bg-white sm:inline-flex">
                <p>
                  <span class="r:text-[16px] leading-[1.53] r:tracking-[0.25px] text-tw-black-900" v-html="viewsJa.quotations.result.discussion.nursing.chats[0].messages[1].mess"></span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- start box info -->
      <div class="mb-1 overflow-hidden rounded-2xl">
        <div class="relative r:min-h-[193px] flex items-center justify-center p-4">
          <images name="discussMess1" class="absolute inset-0 object-cover w-full h-full" />
          <div class="bg-black bg-opacity-60 absolute inset-0 z-[2]"></div>
          <div class="text-center flex flex-col relative z-[3]">
            <span class="text-white">
              <span class="r:text-[16px] leading-[1.53] r:tracking-[0.25px]">{{ viewsJa.quotations.result.discussion.nursing.estimate_cost.intro }}</span>
              <sup class="">※1</sup>
            </span>
            <span class="flex items-baseline justify-center mb-4">
              <span class="r:text-[32px] text-white font-black leading-[1.53] r:tracking-[0.25px]">{{ numberWithCommas(125000) }}</span>
              <span class="r:text-[20px] text-white font-black leading-[1.53] r:tracking-[0.25px]">{{ `${viewsJa.common.unit.yen}〜` }}</span>
            </span>
            <span class="r:text-[12px] leading-[1.53] r:tracking-[0.25px] text-white r:max-w-[235px]">{{ viewsJa.quotations.result.discussion.nursing.estimate_cost.remind }}</span>
          </div>
        </div>
        <div class="p-4 bg-white">
          <div class="care-service">
            <div class="flex flex-col xs:flex-row xs:items-center rounded-lg bg-primary-100 p-4 mb-2.5" v-for="(item, key) in viewsJa.quotations.result.discussion.nursing.estimate_cost.care_services" :key="key">
              <images :name="item.img_name" class="xs:r:max-w-[100px] xs:w-full mx-auto" />
              <div class="flex-1 ml-4">
                <h4 class="mb-4 text-primary-600">
                  <span class="inline font-black r:text-[16px] leading-[1.33] r:tracking-[0.25px]" v-html="item.title"></span>
                  <span class="inline font-black r:text-[12px] leading-[1.33] r:tracking-[0.25px]">{{ item.sub }}</span>
                </h4>
                <p class="r:text-[12px] leading-[1.33] r:tracking-[0.25px] text-neutral-800 mb-1.5">{{ item.desc }}</p>
                <div class="flex items-baseline justify-end">
                  <span class="font-black r:text-[24px] leading-[1.53] r:tracking-[0.25px] text-neutral-gray-800">{{ numberWithCommas(item.cost) }}</span>
                  <span class="r:text-[14px] r:tracking-[0.25px] leading-[1.53] text-neutral-gray-800">{{ item.suffix }}</span>
                </div>
              </div>
            </div>
          </div>
          <p class="r:text-[12px] r:tracking-[0.25px] leading-[1.53] text-neutral-gray-800">{{ viewsJa.quotations.result.discussion.nursing.estimate_cost.note }}</p>
        </div>
      </div>
      <!-- end box info -->
      <div class="flex mb-1">
        <images :name="viewsJa.quotations.result.discussion.nursing.chats[1].avatar" class="w-10 h-10 mr-1" />
        <div class="flex flex-col">
          <span class="r:text-[10px] leading-[1.53] r:tracking-[0.25px] text-neutral-gray-800">{{ viewsJa.quotations.result.discussion.nursing.chats[1].consult_name }}</span>
          <div class="flex flex-col chat">
            <div class="flex justify-start line-message">
              <div class="pl-4 pr-2 pt-2 pb-2.5 rounded-b-3xl rounded-tr-3xl mt-1 bg-white sm:inline-flex">
                <p>
                  <span class="r:text-[16px] leading-[1.53] r:tracking-[0.25px] text-tw-black-900" v-html="viewsJa.quotations.result.discussion.nursing.chats[1].messages[0].mess"></span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="flex flex-row-reverse mb-1">
        <div class="flex flex-col">
          <div class="flex flex-col chat">
            <div class="flex justify-end line-message">
              <div class="pl-4 pr-2 pt-2 pb-2.5 rounded-b-3xl bg-tw-green-600 rounded-tl-3xl sm:inline-flex">
                <p>
                  <span class="r:text-[16px] leading-[1.53] r:tracking-[0.25px] text-white" v-html="viewsJa.quotations.result.discussion.nursing.chats[2].messages[0].mess"></span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="flex mb-1">
        <images :name="viewsJa.quotations.result.discussion.nursing.chats[3].avatar" class="w-10 h-10 mr-1" />
        <div class="flex flex-col">
          <span class="r:text-[10px] leading-[1.53] r:tracking-[0.25px] text-neutral-gray-800">{{ viewsJa.quotations.result.discussion.nursing.chats[3].consult_name }}</span>
          <div class="flex flex-col chat">
            <div class="flex justify-start line-message mb-1">
              <div class="pl-4 pr-2 pt-2 pb-2.5 rounded-b-3xl rounded-tr-3xl bg-white sm:inline-flex">
                <p>
                  <span class="r:text-[16px] leading-[1.53] r:tracking-[0.25px] text-tw-black-900" v-html="viewsJa.quotations.result.discussion.nursing.chats[3].messages[0].mess"></span>
                </p>
              </div>
            </div>
            <div class="flex justify-start line-message">
              <div class="pl-4 pr-2 pt-2 pb-2.5 rounded-b-3xl rounded-tr-3xl bg-white sm:inline-flex">
                <p>
                  <span class="r:text-[16px] leading-[1.53] r:tracking-[0.25px] text-tw-black-900" v-html="viewsJa.quotations.result.discussion.nursing.chats[3].messages[1].mess"></span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- start box info -->
      <div class="mb-1 overflow-hidden rounded-2xl">
        <div class="relative r:min-h-[193px] flex items-center justify-center p-4">
          <images name="discussMess2" class="absolute inset-0 object-cover w-full h-full" />
          <div class="bg-black bg-opacity-60 absolute inset-0 z-[2]"></div>
          <div class="text-center flex flex-col relative z-[3] text-white">
            <span class="r:text-[16px] leading-[1.53] r:tracking-[0.25px] font-black mb-2" v-html="viewsJa.quotations.result.discussion.nursing.long_term.intro"></span>
            <span class="r:text-[24px] leading-[1.53] r:tracking-[0.25px] font-black" v-html="viewsJa.quotations.result.discussion.nursing.long_term.title"></span>
          </div>
        </div>
        <div class="px-4 pt-12 pb-8 bg-white">
          <div class="r:max-w-[311px] mx-auto">
            <div class="relative pb-10 lump-sum">
              <div class="flex items-start">
                <span class="w-[20%] xs:r:w-[23px] r:h-[38px] border-t-2 border-l-2 border-neutral-gray-300"></span>
                <div class="flex flex-col xs:flex-row items-center flex-1 mx-3 text-neutral-gray-800 r:mt-[-22px]">
                  <span class="r:text-[12px] mb-1 xs:mb-0 xs:r:text-[14px] xs:flex-1 xs:mr-2.5">{{ viewsJa.quotations.result.discussion.nursing.long_term.cost_total_label }}</span>
                  <strong class="flex items-baseline">
                    <span class="r:text-[28px] xs:r:text-[36px] leading-[1] r:tracking-[0.25px]">{{ numberWithCommas(750) }}</span>
                    <span class="r:text-[12px] xs:r:text-[16px] leading-[1] r:tracking-[0.25px]">{{ viewsJa.quotations.result.discussion.nursing.long_term.average_total_suffix }}</span>
                  </strong>
                </div>
                <span class="w-[20%] xs:r:w-[23px] r:h-[38px] border-t-2 border-r-2 border-neutral-gray-300"></span>
              </div>
              <div class="flex xs:items-stretch mb-3.5">
                <div class="flex-1 flex items-center justify-center bg-neutral-gray-100 px-1.5 xs:r:px-[18px] py-3.5 text-neutral-gray-800">
                  <span class="r:max-w-[78px] r:text-[10px] xs:r:text-[12px] r:tracking-[0.216px] leading-[1.3] mr-1" v-html="viewsJa.quotations.result.discussion.nursing.long_term.cost_saving_label"></span>
                  <strong class="flex items-baseline whitespace-nowrap">
                    <span class="r:text-[24px] xs:r:text-[31px] leading-[1] r:tracking-[0.216px]">{{ numberWithCommas(522) }}</span>
                    <span class="r:text-[12px] xs:r:text-[14px] leading-[1] r:tracking-[0.216px]">{{ viewsJa.common.unit.ten_thousand_yen }}</span>
                  </strong>
                </div>
                <div class="flex items-center bg-primary-600 px-1.5 xs:px-3 py-3.5">
                  <strong class="flex items-baseline text-white">
                    <span class="r:text-[24px] xs:r:text-[31px] leading-[1] r:tracking-[0.216px]">{{ numberWithCommas(228) }}</span>
                    <span class="r:text-[12px] xs:r:text-[14px] leading-[1] r:tracking-[0.216px]">{{ viewsJa.common.unit.ten_thousand_yen }}</span>
                  </strong>
                </div>
              </div>
              <h4 class="text-primary-600 r:text-[12px] xs:r:text-[16px] font-black leading-[1.53] r:tracing-[0.25px] ml-2 xs:ml-7 r:max-w-[110px] xs:r:max-w-[160px] min-h-12">{{ viewsJa.quotations.result.discussion.nursing.long_term.cover_note }}</h4>
              <images name="discussGirl1" class="absolute bottom-0 right-1 xs:r:right-[21px] z-[2]" />
            </div>
            <div class="r:mx-[-5px] flex items-stretch">
              <div class="relative rounded-lg bg-primary-100 px-2 pt-2 pb-4 xs:px-4 flex flex-col w-1/2 r:mx-[5px]" v-for="(item, key) in viewsJa.quotations.result.discussion.nursing.long_term.injuries" :key="key">
                <svg v-if="key == 0" class="absolute r:top-[-21px] left-1/2 -translate-x-1/2" xmlns="http://www.w3.org/2000/svg" width="27" height="23" viewBox="0 0 27 23" fill="none">
                  <path d="M13.5 0L26.9234 22.5H0.0766058L13.5 0Z" fill="url(#paint0_linear_4394_46434)"/>
                  <defs>
                    <linearGradient id="paint0_linear_4394_46434" x1="13.5" y1="0" x2="13.5" y2="30" gradientUnits="userSpaceOnUse">
                      <stop stop-color="#E89241"/>
                      <stop offset="0.645" stop-color="#FBEADB"/>
                    </linearGradient>
                  </defs>
                </svg>
                <images :name="item.img_name" class="mb-2 r:max-w-[100px] w-full mx-auto" />
                <h5 class="r:min-h-[42px] r:text-[12px] xs:r:text-[16px] leading-[1.33] r:tracking-[0.25px] text-primary-600 mb-2" v-html="item.title"></h5>
                <div class="flex items-baseline justify-center mb-2 -mr-5 xs:-mr-7 text-neutral-gray-800" v-if="key==0">
                  <span class="r:text-[20px] xs:r:text-[36px] r:tracking-[0.25px] leading-[1] font-black">{{ item.value }}</span>
                  <span class="r:text-[10px] xs:r:text-[14px] r:tracking-[0.25px] leading-[1]">{{ viewsJa.common.unit.ten_thousand_yen }}</span>
                </div>
                <template v-if="!!summaryData.male && key == 1">
                  <span class="r:text-[12px] leading-[1.33] r:tracking-[0.25px] text-neutral-gray-800 mb-2">{{ item.male_text }}</span>
                  <div class="flex items-baseline justify-center mb-2 -mr-5 xs:-mr-7 text-neutral-gray-800">
                    <span class="r:text-[20px] xs:r:text-[36px] r:tracking-[0.25px] leading-[1] font-black">{{ summaryData.male.death_insurance[`insurance_pay_${form.planMale}`] }}</span>
                    <span class="r:text-[10px] xs:r:text-[14px] r:tracking-[0.25px] leading-[1]">{{ viewsJa.common.unit.ten_thousand_yen }}</span>
                  </div>
                </template>
                <template v-if="!!summaryData.female && key == 1">
                  <span class="r:text-[12px] leading-[1.33] r:tracking-[0.25px] text-neutral-gray-800 mb-2">{{ item.female_text }}</span>
                  <div class="flex items-baseline justify-center mb-2 -mr-5 xs:-mr-7 text-neutral-gray-800">
                    <span class="r:text-[20px] xs:r:text-[36px] r:tracking-[0.25px] leading-[1] font-black">{{ summaryData.female.death_insurance[`insurance_pay_${form.planFemale}`] }}</span>
                    <span class="r:text-[10px] xs:r:text-[14px] r:tracking-[0.25px] leading-[1]">{{ viewsJa.common.unit.ten_thousand_yen }}</span>
                  </div>
                </template>
                <p class="flex flex-col-reverse flex-1" :class="{'justify-end': !!item.link}">
                  <span v-if="!!item.desc" class="r:text-[10px] text-neutral-gray-600 leading-[1.53] r:tracking-[0.25px]">{{ item.desc }}</span>
                  <a id="nursing-open-change-plan-modal" data-testid="nursing-open-change-plan-modal" v-if="!!item.link" href="javascript:;" class="r:text-[14px] text-tw-blue-500 leading-[1] r:tracking-[0.25px] underline hover:opacity-70 transition self-end" @click.prevent="handleModalState('change_plan_modal')">{{ item.link }}</a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- end box info -->
      <div class="flex flex-row-reverse mb-1">
        <div class="flex flex-col">
          <div class="flex flex-col chat">
            <div class="flex justify-end line-message">
              <div class="pl-4 pr-2 pt-2 pb-2.5 rounded-b-3xl bg-tw-green-600 rounded-tl-3xl sm:inline-flex">
                <p>
                  <span class="r:text-[16px] leading-[1.53] r:tracking-[0.25px] text-white" v-html="viewsJa.quotations.result.discussion.nursing.chats[4].messages[0].mess"></span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="flex mb-1">
        <images :name="viewsJa.quotations.result.discussion.nursing.chats[5].avatar" class="w-10 h-10 mr-1" />
        <div class="flex flex-col">
          <span class="r:text-[10px] leading-[1.53] r:tracking-[0.25px] text-neutral-gray-800">{{ viewsJa.quotations.result.discussion.nursing.chats[5].consult_name }}</span>
          <div class="flex flex-col chat">
            <div class="flex justify-start line-message mb-1">
              <div class="pl-4 pr-2 pt-2 pb-2.5 rounded-b-3xl rounded-tr-3xl mt-1 bg-white sm:inline-flex">
                <p>
                  <span class="r:text-[16px] leading-[1.53] r:tracking-[0.25px] text-tw-black-900" v-html="viewsJa.quotations.result.discussion.nursing.chats[5].messages[0].mess"></span>
                </p>
              </div>
            </div>
            <div class="flex justify-start line-message">
              <div class="pl-4 pr-2 pt-2 pb-2.5 rounded-b-3xl rounded-tr-3xl bg-white sm:inline-flex">
                <p>
                  <span class="r:text-[16px] leading-[1.53] r:tracking-[0.25px] text-tw-black-900" v-html="viewsJa.quotations.result.discussion.nursing.chats[5].messages[1].mess"></span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="flex flex-row-reverse mb-1">
        <div class="flex flex-col">
          <div class="flex flex-col chat">
            <div class="flex justify-end line-message mb-1">
              <div class="pl-4 pr-2 pt-2 pb-2.5 rounded-b-3xl bg-tw-green-600 rounded-tl-3xl sm:inline-flex">
                <p>
                  <span class="r:text-[16px] leading-[1.53] r:tracking-[0.25px] text-white" v-html="viewsJa.quotations.result.discussion.nursing.chats[6].messages[0].mess"></span>
                </p>
              </div>
            </div>
            <div class="flex justify-end line-message">
              <div class="pl-4 pr-2 pt-2 pb-2.5 rounded-b-3xl bg-tw-green-600 rounded-tl-3xl sm:inline-flex">
                <p>
                  <span class="r:text-[16px] leading-[1.53] r:tracking-[0.25px] text-white" v-html="viewsJa.quotations.result.discussion.nursing.chats[6].messages[1].mess"></span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="flex mb-1">
        <images :name="viewsJa.quotations.result.discussion.nursing.chats[7].avatar" class="w-10 h-10 mr-1" />
        <div class="flex flex-col">
          <span class="r:text-[10px] leading-[1.53] r:tracking-[0.25px] text-neutral-gray-800">{{ viewsJa.quotations.result.discussion.nursing.chats[7].consult_name }}</span>
          <div class="flex flex-col chat">
            <div class="flex justify-start line-message">
              <div class="pl-4 pr-2 pt-2 pb-2.5 rounded-b-3xl rounded-tr-3xl mt-1 bg-white sm:inline-flex">
                <p>
                  <span class="r:text-[16px] leading-[1.53] r:tracking-[0.25px] text-tw-black-900" v-html="viewsJa.quotations.result.discussion.nursing.chats[7].messages[0].mess"></span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- start box info -->
      <div class="mb-1 rounded-2xl bg-primary-500 p-1.5 xs:p-2">
        <div class="flex items-stretch -mx-0.5">
          <div class="rounded-lg bg-white py-2.5 px-0.5 xs:px-1.5 mx-0.5 w-1/3" v-for="(item, key) in viewsJa.quotations.result.discussion.nursing.care_infos" :key="key">
            <h5 class="text-center r:text-[10px] xs:r:text-[12px] text-primary-600 font-black leading-[1.33] r:tracking-[0.25px] mb-1 min-h-12" v-html="item.title"></h5>
            <images :name="item.img_name" class="r:max-w-[80px] w-full mx-auto" />
          </div>
        </div>
      </div>
      <!-- end box info -->
      <div class="flex mb-1">
        <images :name="viewsJa.quotations.result.discussion.nursing.chats[8].avatar" class="w-10 h-10 mr-1" />
        <div class="flex flex-col">
          <span class="r:text-[10px] leading-[1.53] r:tracking-[0.25px] text-neutral-gray-800">{{ viewsJa.quotations.result.discussion.nursing.chats[8].consult_name }}</span>
          <div class="flex flex-col chat">
            <div class="flex justify-start line-message">
              <div class="pl-4 pr-2 pt-2 pb-2.5 rounded-b-3xl rounded-tr-3xl mt-1 bg-white sm:inline-flex">
                <p>
                  <span class="r:text-[16px] leading-[1.53] r:tracking-[0.25px] text-tw-black-900">
                    <span class="block" v-html="viewsJa.quotations.result.discussion.nursing.chats[8].messages[0].top_general_mess"></span>
                    <span class="block" v-if="!!summaryData.male" v-html="convertJaParams(viewsJa.quotations.result.discussion.nursing.chats[8].messages[0].mess_male, {planMale: numberWithCommas(summaryData.male.death_fee[`${form.planMale}_fee`])})" />
                    <span class="block" v-if="!!summaryData.female" v-html="convertJaParams(viewsJa.quotations.result.discussion.nursing.chats[8].messages[0].mess_female, {planFemale: numberWithCommas(summaryData.female.death_fee[`${form.planFemale}_fee`])})" />
                    <span>{{ viewsJa.quotations.result.discussion.home.chats[7].messages[0].bottom_general_mess }}</span>
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="flex flex-row-reverse mb-1">
        <div class="flex flex-col">
          <div class="flex flex-col chat">
            <div class="flex justify-end line-message">
              <div class="pl-4 pr-2 pt-2 pb-2.5 rounded-b-3xl bg-tw-green-600 rounded-tl-3xl sm:inline-flex">
                <p>
                  <span class="r:text-[16px] leading-[1.53] r:tracking-[0.25px] text-white" v-html="viewsJa.quotations.result.discussion.nursing.chats[9].messages[0].mess"></span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="flex mb-1">
        <images :name="viewsJa.quotations.result.discussion.nursing.chats[10].avatar" class="w-10 h-10 mr-1" />
        <div class="flex flex-col">
          <span class="r:text-[10px] leading-[1.53] r:tracking-[0.25px] text-neutral-gray-800">{{ viewsJa.quotations.result.discussion.nursing.chats[10].consult_name }}</span>
          <div class="flex flex-col chat">
            <div class="flex justify-start line-message">
              <div class="pl-4 pr-2 pt-2 pb-2.5 rounded-b-3xl rounded-tr-3xl mt-1 bg-white sm:inline-flex">
                <p>
                  <span class="r:text-[16px] leading-[1.53] r:tracking-[0.25px] text-tw-black-900" v-html="viewsJa.quotations.result.discussion.nursing.chats[10].messages[0].mess"></span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="flex flex-row-reverse mb-1">
        <div class="flex flex-col">
          <div class="flex flex-col chat">
            <div class="flex justify-end line-message">
              <div class="pl-4 pr-2 pt-2 pb-2.5 rounded-b-3xl bg-tw-green-600 rounded-tl-3xl sm:inline-flex">
                <p>
                  <span class="r:text-[16px] leading-[1.53] r:tracking-[0.25px] text-white" v-html="viewsJa.quotations.result.discussion.nursing.chats[11].messages[0].mess"></span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-if="careType == 'home'">
      <div class="flex mb-1">
        <images :name="viewsJa.quotations.result.discussion.home.chats[0].avatar" class="w-10 h-10 mr-1" />
        <div class="flex flex-col">
          <span class="r:text-[10px] leading-[1.53] r:tracking-[0.25px] text-neutral-gray-800">{{ viewsJa.quotations.result.discussion.home.chats[0].consult_name }}</span>
          <div class="flex flex-col chat">
            <div class="flex justify-start line-message mb-1">
              <div class="pl-4 pr-2 pt-2 pb-2.5 rounded-b-3xl rounded-tr-3xl mt-1 bg-white sm:inline-flex">
                <p>
                  <span v-if="!summaryData.female" class="r:text-[16px] leading-[1.53] r:tracking-[0.25px] text-tw-black-900" v-html="convertJaParams(viewsJa.quotations.result.discussion.home.chats[0].messages[0].mess_only_male, { male_age: summaryData.male.age })"></span>
                  <span v-else-if="!summaryData.male" class="r:text-[16px] leading-[1.53] r:tracking-[0.25px] text-tw-black-900" v-html="convertJaParams(viewsJa.quotations.result.discussion.home.chats[0].messages[0].mess_only_female, { female_age: summaryData.female.age })"></span>
                  <span v-else class="r:text-[16px] leading-[1.53] r:tracking-[0.25px] text-tw-black-900" v-html="convertJaParams(viewsJa.quotations.result.discussion.home.chats[0].messages[0].mess, { male_age: summaryData.male.age, female_age: summaryData.female.age })"></span>
                  <a id="discuss-home-open-quotation" data-testid="discuss-home-open-quotation" class="underline text-tw-blue-500 r:text-[16px] leading-[1.53] r:tracking-[0.25px] hover:opacity-70 transition cursor-pointer" @click.prevent="setQuotationOpen(true)">{{ viewsJa.quotations.result.discussion.home.chats[0].messages[0].modal_quotation }}</a>
                </p>
              </div>
            </div>
            <div class="flex justify-end mb-1">
              <tippy
                interactive
                animation="fade"
                trigger="click"
                :arrow="false"
                :maxWidth="280"
                :onShown="(instance) => {
                  handleCloseTooltip(instance);
                }"
              >
                <template #default>
                  <a href="javascript:;" id="show-tooltip-contract-day-home" data-testid="show-tooltip-contract-day-home" class="inline-flex text-white transition hover:opacity-70">
                    <svg id="show-tooltip-contract-day-home-svg" class="mt-0.5" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                      <path id="show-tooltip-contract-day-home-path" fill-rule="evenodd" clip-rule="evenodd" d="M9 17C13.4183 17 17 13.4183 17 9C17 4.58172 13.4183 1 9 1C4.58172 1 1 4.58172 1 9C1 13.4183 4.58172 17 9 17ZM11.3025 8.1875L10.6275 8.8775C10.5706 8.93516 10.516 8.98898 10.4638 9.04042C10.0201 9.47739 9.75 9.74349 9.75 10.75C9.75 11.1642 9.41421 11.5 9 11.5C8.58579 11.5 8.25 11.1642 8.25 10.75V10.625C8.25 9.8 8.5875 9.05 9.1275 8.5025L10.0575 7.5575C10.335 7.2875 10.5 6.9125 10.5 6.5C10.5 5.675 9.825 5 9 5C8.175 5 7.5 5.675 7.5 6.5C7.5 6.91421 7.16421 7.25 6.75 7.25C6.33579 7.25 6 6.91421 6 6.5C6 4.8425 7.3425 3.5 9 3.5C10.6575 3.5 12 4.8425 12 6.5C12 7.16 11.73 7.76 11.3025 8.1875ZM10 13.5C10 14.0523 9.55228 14.5 9 14.5C8.44771 14.5 8 14.0523 8 13.5C8 12.9477 8.44771 12.5 9 12.5C9.55228 12.5 10 12.9477 10 13.5Z" fill="white"/>
                    </svg>
                    <span id="show-tooltip-contract-day-home-span" class="ml-1 r:text-[14px] leading-[1.53] r:tracking-[0.25px]">{{ convertJaParams(viewsJa.quotations.result.discussion.fields_note.date_valid_for_contract, {date_valid: contractStartDayStr}) }}</span>
                  </a>
                </template>
                <template #content>
                  <div class="p-3 rounded-lg bg-neutral-gray-700 xs:p-4">
                    <h4 class="text-white r:text-[16px] font-black leading-[1.53] r:tracking-[0.25px] mb-3 xs:mb-4">{{ convertJaParams(viewsJa.quotations.result.discussion.fields_tooltip.parent.date_valid_for_contract.title, {date_valid: contractStartDayStr}) }}</h4>
                    <p class="text-white r:text-[14px] leading-[1.53] r:tracking-[0.25px] mb-6 xs:mb-8">{{ viewsJa.quotations.result.discussion.fields_tooltip.parent.date_valid_for_contract.desc }}</p>
                    <button type="button" data-testid="home-open-tooltip-custom-info" id="home-open-tooltip-custom-info" class="btn-close-tooltip btn-solid inline-flex items-center justify-center rounded-full btn-gradient r:text-[14px] py-3.5 px-4 font-medium text-white shadow-gray-1 bg-neutral-gray-800 r:tracking-[0.25px] leading-[1.33] r:min-h-[47px]">
                      <span id="home-open-tooltip-custom-info-span">{{ viewsJa.quotations.result.discussion.fields_tooltip.btn_close }}</span>
                    </button>
                  </div>
                </template>
              </tippy>
            </div>
            <div class="flex justify-start line-message">
              <div class="pl-4 pr-2 pt-2 pb-2.5 rounded-b-3xl rounded-tr-3xl bg-white sm:inline-flex">
                <p>
                  <span class="r:text-[16px] leading-[1.53] r:tracking-[0.25px] text-tw-black-900" v-html="viewsJa.quotations.result.discussion.home.chats[0].messages[1].mess"></span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- start box info -->
      <div class="mb-1 overflow-hidden rounded-2xl">
        <div class="relative r:min-h-[193px] flex items-center justify-center p-4">
          <images name="discussMess3" class="absolute inset-0 object-cover w-full h-full" />
          <div class="bg-black bg-opacity-60 absolute inset-0 z-[2]"></div>
          <div class="text-center flex flex-col relative z-[3]">
            <span class="text-white">
              <span class="r:text-[16px] leading-[1.53] r:tracking-[0.25px]" v-html="viewsJa.quotations.result.discussion.home.estimate_cost.intro"></span>
              <sup class="">※1</sup>
            </span>
            <span class="flex items-baseline justify-center mb-4">
              <span class="r:text-[32px] text-white font-black leading-[1.53] r:tracking-[0.25px]">{{ numberWithCommas(43931) }}</span>
              <span class="r:text-[20px] text-white font-black leading-[1.53] r:tracking-[0.25px]">{{ `${viewsJa.common.unit.yen}〜` }}</span>
            </span>
            <span class="r:text-[12px] leading-[1.53] r:tracking-[0.25px] text-white r:max-w-[235px]">{{ viewsJa.quotations.result.discussion.home.estimate_cost.remind }}</span>
          </div>
        </div>
        <div class="p-4 bg-white">
          <div class="care-service">
            <div class="flex flex-col xs:flex-row xs:items-center rounded-lg bg-primary-100 p-4 mb-2.5" v-for="(item, key) in viewsJa.quotations.result.discussion.home.estimate_cost.care_services" :key="key">
              <images :name="item.img_name" class="xs:r:max-w-[100px] xs:w-full mx-auto" />
              <div class="flex-1 ml-4">
                <h4 class="mb-4 text-primary-600">
                  <span class="inline font-black r:text-[16px] leading-[1.33] r:tracking-[0.25px]" v-html="item.title"></span>
                  <span class="inline font-black r:text-[12px] leading-[1.33] r:tracking-[0.25px]">{{ item.sub }}</span>
                </h4>
                <p class="r:text-[12px] leading-[1.33] r:tracking-[0.25px] text-neutral-800 mb-1.5">{{ item.desc }}</p>
                <div class="flex items-baseline justify-end">
                  <span class="font-black r:text-[24px] leading-[1.53] r:tracking-[0.25px] text-neutral-gray-800">{{ numberWithCommas(item.cost) }}</span>
                  <span class="r:text-[14px] r:tracking-[0.25px] leading-[1.53] text-neutral-gray-800">{{ item.suffix }}</span>
                </div>
              </div>
            </div>
          </div>
          <p class="r:text-[12px] r:tracking-[0.25px] leading-[1.53] text-neutral-gray-800">{{ viewsJa.quotations.result.discussion.home.estimate_cost.note }}</p>
        </div>
      </div>
      <!-- end box info -->
      <div class="flex mb-1">
        <images :name="viewsJa.quotations.result.discussion.home.chats[1].avatar" class="w-10 h-10 mr-1" />
        <div class="flex flex-col">
          <span class="r:text-[10px] leading-[1.53] r:tracking-[0.25px] text-neutral-gray-800">{{ viewsJa.quotations.result.discussion.home.chats[1].consult_name }}</span>
          <div class="flex flex-col chat">
            <div class="flex justify-start line-message">
              <div class="pl-4 pr-2 pt-2 pb-2.5 rounded-b-3xl rounded-tr-3xl mt-1 bg-white sm:inline-flex">
                <p>
                  <span class="r:text-[16px] leading-[1.53] r:tracking-[0.25px] text-tw-black-900" v-html="viewsJa.quotations.result.discussion.home.chats[1].messages[0].mess"></span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="flex flex-row-reverse mb-1">
        <div class="flex flex-col">
          <div class="flex flex-col chat">
            <div class="flex justify-end line-message">
              <div class="pl-4 pr-2 pt-2 pb-2.5 rounded-b-3xl bg-tw-green-600 rounded-tl-3xl sm:inline-flex">
                <p>
                  <span class="r:text-[16px] leading-[1.53] r:tracking-[0.25px] text-white" v-html="viewsJa.quotations.result.discussion.home.chats[2].messages[0].mess"></span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- start box info -->
      <div class="mb-1 overflow-hidden rounded-2xl">
        <div class="relative r:min-h-[193px] flex items-center justify-center p-4">
          <images name="discussMess2" class="absolute inset-0 object-cover w-full h-full" />
          <div class="bg-black bg-opacity-60 absolute inset-0 z-[2]"></div>
          <div class="text-center flex flex-col relative z-[3] text-white">
            <span class="r:text-[16px] leading-[1.53] r:tracking-[0.25px] font-black mb-2" v-html="viewsJa.quotations.result.discussion.home.long_term.intro"></span>
            <span class="r:text-[24px] leading-[1.53] r:tracking-[0.25px] font-black" v-html="viewsJa.quotations.result.discussion.home.long_term.title"></span>
          </div>
        </div>
        <div class="px-4 py-8 bg-white">
          <div class="r:max-w-[311px] mx-auto">
            <div class="relative pb-10 lump-sum">
              <div class="flex xs:items-stretch mb-3.5">
                <div class="flex-1 flex items-center justify-center bg-neutral-gray-100 px-1.5 xs:r:px-[18px] py-3.5 text-neutral-gray-800">
                  <span class="r:max-w-[78px] r:text-[10px] xs:r:text-[12px] r:tracking-[0.216px] leading-[1.3] mr-2.5" v-html="viewsJa.quotations.result.discussion.home.long_term.cost_total_label"></span>
                  <strong class="flex items-baseline whitespace-nowrap">
                    <span class="r:text-[24px] xs:r:text-[31px] leading-[1] r:tracking-[0.216px]">{{ numberWithCommas(264) }}</span>
                    <span class="r:text-[12px] xs:r:text-[14px] leading-[1] r:tracking-[0.216px]">{{ viewsJa.quotations.result.discussion.home.long_term.average_total_suffix }}</span>
                  </strong>
                </div>
                <div class="flex items-center bg-primary-600 r:w-[17px]"></div>
              </div>
              <h4 class="text-primary-600 r:text-[12px] xs:r:text-[16px] font-black leading-[1.53] r:tracing-[0.25px] ml-2 xs:ml-7 r:max-w-[110px] xs:r:max-w-[160px] min-h-12">{{ viewsJa.quotations.result.discussion.home.long_term.cover_note }}</h4>
              <images name="discussGirl1" class="absolute bottom-0 right-1 xs:r:right-[21px] z-[2]" />
            </div>
            <div class="r:mx-[-5px] flex items-stretch">
              <div class="relative rounded-lg bg-primary-100 px-2 pt-2 pb-4 xs:px-4 flex flex-col w-1/2 r:mx-[5px]" v-for="(item, key) in viewsJa.quotations.result.discussion.home.long_term.injuries" :key="key">
                <svg v-if="key == 0" class="absolute r:top-[-21px] left-1/2 -translate-x-1/2" xmlns="http://www.w3.org/2000/svg" width="27" height="23" viewBox="0 0 27 23" fill="none">
                  <path d="M13.5 0L26.9234 22.5H0.0766058L13.5 0Z" fill="url(#paint0_linear_4394_46434)"/>
                  <defs>
                    <linearGradient id="paint0_linear_4394_46434" x1="13.5" y1="0" x2="13.5" y2="30" gradientUnits="userSpaceOnUse">
                      <stop stop-color="#E89241"/>
                      <stop offset="0.645" stop-color="#FBEADB"/>
                    </linearGradient>
                  </defs>
                </svg>
                <images :name="item.img_name" class="mb-2 r:max-w-[100px] w-full mx-auto" />
                <h5 class="r:min-h-[42px] r:text-[12px] xs:r:text-[16px] leading-[1.33] r:tracking-[0.25px] text-primary-600 mb-2" v-html="item.title"></h5>
                <div class="flex items-baseline justify-center mb-2 -mr-5 xs:-mr-7 text-neutral-gray-800" v-if="key==0">
                  <span class="r:text-[20px] xs:r:text-[36px] r:tracking-[0.25px] leading-[1] font-black">{{ item.value }}</span>
                  <span class="r:text-[10px] xs:r:text-[14px] r:tracking-[0.25px] leading-[1]">{{ viewsJa.common.unit.ten_thousand_yen }}</span>
                </div>
                <template v-if="!!summaryData.male && key == 1">
                  <span class="r:text-[12px] leading-[1.33] r:tracking-[0.25px] text-neutral-gray-800 mb-2">{{ item.male_text }}</span>
                  <div class="flex items-baseline justify-center mb-2 -mr-5 xs:-mr-7 text-neutral-gray-800">
                    <span class="r:text-[20px] xs:r:text-[36px] r:tracking-[0.25px] leading-[1] font-black">{{ summaryData.male.death_insurance[`insurance_pay_${form.planMale}`] }}</span>
                    <span class="r:text-[10px] xs:r:text-[14px] r:tracking-[0.25px] leading-[1]">{{ viewsJa.common.unit.ten_thousand_yen }}</span>
                  </div>
                </template>
                <template v-if="!!summaryData.female && key == 1">
                  <span class="r:text-[12px] leading-[1.33] r:tracking-[0.25px] text-neutral-gray-800 mb-2">{{ item.female_text }}</span>
                  <div class="flex items-baseline justify-center mb-2 -mr-5 xs:-mr-7 text-neutral-gray-800">
                    <span class="r:text-[20px] xs:r:text-[36px] r:tracking-[0.25px] leading-[1] font-black">{{ summaryData.female.death_insurance[`insurance_pay_${form.planFemale}`] }}</span>
                    <span class="r:text-[10px] xs:r:text-[14px] r:tracking-[0.25px] leading-[1]">{{ viewsJa.common.unit.ten_thousand_yen }}</span>
                  </div>
                </template>
                <p class="flex flex-1" :class="{'justify-end': !!item.link}">
                  <span v-if="!!item.desc" class="r:text-[10px] text-neutral-gray-600 leading-[1.53] r:tracking-[0.25px]">{{ item.desc }}</span>
                  <a id="home-open-change-plan-modal" data-testid="home-open-change-plan-modal" v-if="!!item.link" href="javascript:;" class="r:text-[14px] text-tw-blue-500 leading-[1] r:tracking-[0.25px] underline hover:opacity-70 transition self-end" @click.prevent="handleModalState('change_plan_modal')">{{ item.link }}</a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- end box info -->
      <div class="flex flex-row-reverse mb-1">
        <div class="flex flex-col">
          <div class="flex flex-col chat">
            <div class="flex justify-end line-message">
              <div class="pl-4 pr-2 pt-2 pb-2.5 rounded-b-3xl bg-tw-green-600 rounded-tl-3xl sm:inline-flex">
                <p>
                  <span class="r:text-[16px] leading-[1.53] r:tracking-[0.25px] text-white" v-html="viewsJa.quotations.result.discussion.home.chats[3].messages[0].mess"></span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="flex mb-1">
        <images :name="viewsJa.quotations.result.discussion.home.chats[4].avatar" class="w-10 h-10 mr-1" />
        <div class="flex flex-col">
          <span class="r:text-[10px] leading-[1.53] r:tracking-[0.25px] text-neutral-gray-800">{{ viewsJa.quotations.result.discussion.home.chats[4].consult_name }}</span>
          <div class="flex flex-col chat">
            <div class="flex justify-start line-message mb-1">
              <div class="pl-4 pr-2 pt-2 pb-2.5 rounded-b-3xl rounded-tr-3xl mt-1 bg-white sm:inline-flex">
                <p>
                  <span class="r:text-[16px] leading-[1.53] r:tracking-[0.25px] text-tw-black-900" v-html="viewsJa.quotations.result.discussion.home.chats[4].messages[0].mess"></span>
                </p>
              </div>
            </div>
            <div class="flex justify-start line-message">
              <div class="pl-4 pr-2 pt-2 pb-2.5 rounded-b-3xl rounded-tr-3xl bg-white sm:inline-flex">
                <p>
                  <span class="r:text-[16px] leading-[1.53] r:tracking-[0.25px] text-tw-black-900" v-html="viewsJa.quotations.result.discussion.home.chats[4].messages[1].mess"></span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="flex flex-row-reverse mb-1">
        <div class="flex flex-col">
          <div class="flex flex-col chat">
            <div class="flex justify-end line-message mb-1">
              <div class="pl-4 pr-2 pt-2 pb-2.5 rounded-b-3xl bg-tw-green-600 rounded-tl-3xl sm:inline-flex">
                <p>
                  <span class="r:text-[16px] leading-[1.53] r:tracking-[0.25px] text-white" v-html="viewsJa.quotations.result.discussion.home.chats[5].messages[0].mess"></span>
                </p>
              </div>
            </div>
            <div class="flex justify-end line-message">
              <div class="pl-4 pr-2 pt-2 pb-2.5 rounded-b-3xl bg-tw-green-600 rounded-tl-3xl sm:inline-flex">
                <p>
                  <span class="r:text-[16px] leading-[1.53] r:tracking-[0.25px] text-white" v-html="viewsJa.quotations.result.discussion.home.chats[5].messages[1].mess"></span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="flex mb-1">
        <images :name="viewsJa.quotations.result.discussion.home.chats[6].avatar" class="w-10 h-10 mr-1" />
        <div class="flex flex-col">
          <span class="r:text-[10px] leading-[1.53] r:tracking-[0.25px] text-neutral-gray-800">{{ viewsJa.quotations.result.discussion.home.chats[6].consult_name }}</span>
          <div class="flex flex-col chat">
            <div class="flex justify-start line-message">
              <div class="pl-4 pr-2 pt-2 pb-2.5 rounded-b-3xl rounded-tr-3xl mt-1 bg-white sm:inline-flex">
                <p>
                  <span class="r:text-[16px] leading-[1.53] r:tracking-[0.25px] text-tw-black-900" v-html="viewsJa.quotations.result.discussion.home.chats[6].messages[0].mess"></span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- start box info -->
      <div class="mb-1 rounded-2xl bg-primary-500 p-1.5 xs:p-2">
        <div class="flex items-stretch -mx-0.5">
          <div class="rounded-lg bg-white py-2.5 px-0.5 xs:px-1.5 mx-0.5 w-1/3" v-for="(item, key) in viewsJa.quotations.result.discussion.home.care_infos" :key="key">
            <h5 class="text-center r:text-[10px] xs:r:text-[12px] text-primary-600 font-black leading-[1.33] r:tracking-[0.25px] mb-1 min-h-12" v-html="item.title"></h5>
            <images :name="item.img_name" class="r:max-w-[80px] w-full mx-auto" />
          </div>
        </div>
      </div>
      <!-- end box info -->
      <div class="flex mb-1">
        <images :name="viewsJa.quotations.result.discussion.home.chats[7].avatar" class="w-10 h-10 mr-1" />
        <div class="flex flex-col">
          <span class="r:text-[10px] leading-[1.53] r:tracking-[0.25px] text-neutral-gray-800">{{ viewsJa.quotations.result.discussion.home.chats[7].consult_name }}</span>
          <div class="flex flex-col chat">
            <div class="flex justify-start line-message">
              <div class="pl-4 pr-2 pt-2 pb-2.5 rounded-b-3xl rounded-tr-3xl mt-1 bg-white sm:inline-flex">
                <p>
                  <span class="r:text-[16px] leading-[1.53] r:tracking-[0.25px] text-tw-black-900">
                    <span class="block" v-html="viewsJa.quotations.result.discussion.home.chats[7].messages[0].top_general_mess"></span>
                    <span class="block" v-if="!!summaryData.male" v-html="convertJaParams(viewsJa.quotations.result.discussion.home.chats[7].messages[0].mess_male, {planMale: numberWithCommas(summaryData.male.death_fee[`${form.planMale}_fee`])})" />
                    <span class="block" v-if="!!summaryData.female" v-html="convertJaParams(viewsJa.quotations.result.discussion.home.chats[7].messages[0].mess_female, {planFemale: numberWithCommas(summaryData.female.death_fee[`${form.planFemale}_fee`])})" />
                    <span>{{ viewsJa.quotations.result.discussion.home.chats[7].messages[0].bottom_general_mess }}</span>
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="flex flex-row-reverse mb-1">
        <div class="flex flex-col">
          <div class="flex flex-col chat">
            <div class="flex justify-end line-message">
              <div class="pl-4 pr-2 pt-2 pb-2.5 rounded-b-3xl bg-tw-green-600 rounded-tl-3xl sm:inline-flex">
                <p>
                  <span class="r:text-[16px] leading-[1.53] r:tracking-[0.25px] text-white" v-html="viewsJa.quotations.result.discussion.home.chats[8].messages[0].mess"></span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="flex mb-1">
        <images :name="viewsJa.quotations.result.discussion.home.chats[9].avatar" class="w-10 h-10 mr-1" />
        <div class="flex flex-col">
          <span class="r:text-[10px] leading-[1.53] r:tracking-[0.25px] text-neutral-gray-800">{{ viewsJa.quotations.result.discussion.home.chats[9].consult_name }}</span>
          <div class="flex flex-col chat">
            <div class="flex justify-start line-message">
              <div class="pl-4 pr-2 pt-2 pb-2.5 rounded-b-3xl rounded-tr-3xl mt-1 bg-white sm:inline-flex">
                <p>
                  <span class="r:text-[16px] leading-[1.53] r:tracking-[0.25px] text-tw-black-900" v-html="viewsJa.quotations.result.discussion.home.chats[9].messages[0].mess"></span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="flex flex-row-reverse mb-1">
        <div class="flex flex-col">
          <div class="flex flex-col chat">
            <div class="flex justify-end line-message">
              <div class="pl-4 pr-2 pt-2 pb-2.5 rounded-b-3xl bg-tw-green-600 rounded-tl-3xl sm:inline-flex">
                <p>
                  <span class="r:text-[16px] leading-[1.53] r:tracking-[0.25px] text-white" v-html="viewsJa.quotations.result.discussion.home.chats[10].messages[0].mess"></span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>

    <modal
      :open="modalState['change_plan_modal']"
      @close="modalState['change_plan_modal'] = false"
      modalContentCls="!bg-white"
      modalDialogCls="sm:r:max-w-[720px]"
    >
      <template #modalHeader>
        <div class="px-4 py-2 border-b border-neutral-gray-100 bg-neutral-gray-50 modal-header">
          <button id="close-change-plan" data-testid="close-change-plan" type="button" class="flex w-12 h-12 p-3 ml-auto group hover:opacity-70" title="Close" style="margin-left: auto;" @click="modalState['change_plan_modal'] = false">
            <svg id="close-change-plan-svg" class="group-hover:opacity-70" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path id="close-change-plan-path" d="M6.27614 4.39052C5.75544 3.86983 4.91122 3.86983 4.39052 4.39052C3.86983 4.91122 3.86983 5.75544 4.39052 6.27614L10.1144 12L4.3906 17.7239C3.8699 18.2446 3.8699 19.0888 4.3906 19.6095C4.9113 20.1302 5.75552 20.1302 6.27622 19.6095L12 13.8856L17.7239 19.6095C18.2446 20.1302 19.0888 20.1302 19.6095 19.6095C20.1302 19.0888 20.1302 18.2446 19.6095 17.7239L13.8856 12L19.6095 6.27614C20.1302 5.75544 20.1302 4.91122 19.6095 4.39052C19.0888 3.86982 18.2446 3.86983 17.7239 4.39053L12 10.1144L6.27614 4.39052Z" fill="#93A3B5"/>
            </svg>
          </button>
          <h2 class="text-neutral-gray-900 r:text-[16px] leading-[1.53] r:tracking-[0.25px]">{{ viewsJa.quotations.result.change_plan_modal.title }}</h2>
        </div>
      </template>
      <template #modalBody>
        <div class="px-4 py-6 modal-body flex-1 overflow-y-auto">
          <form class="change-plan-form" id="change-plan-form">
            <input type="hidden" name="authenticity_token" :value="authenToken" autocomplete="off">
            <template v-for="(val, key) in summaryData" :key="key">
              <div :class="`flex justify-center items-center mb-2 ${key == 'female' ? 'mt-8' : ''}`">
                <images :name="`quotePrFee${toCapitalize(key)}`" class="mb-2" :data-a="key" />
                <span class="r:text-[14px] leading-[1.53] r:tracking-[0.25px] text-neutral-gray-900 ml-2">{{ viewsJa.quotations.result.change_plan_modal.parent_text[key] }}</span>
              </div>
              <template v-for="(planNameJP, planName) in viewsJa.quotations.result.change_plan_modal.plan_types" :key="planName">
                <radio-input
                  v-model="form[`plan${toCapitalize(key)}`]"
                  :id="`${key}-${planName}`"
                  :name="`quotation[plan_parent_${key}]`"
                  class="inline-block w-1/3 plan-type-selection"
                  :radioValue="planName"
                  :modelValue="form[`plan${toCapitalize(key)}`]"
                  :hasCustomLabel="true"
                  @click.prevent="handleChangePlanType(key, planName)"
                >
                  <label :for="key" :class="`h-full bg-neutral-gray-50 r:tracking-[0.25px] ${planName == 'premium' ? 'mr-0' : 'mr-1'}`">
                    <div class="flex flex-col justify-around h-full">
                      <span class="block r:text-[8px] xs:r:text-[10px] leading-[1.53] text-center text-neutral-gray-800 mt-2">{{ planNameJP }}</span>
                      <span class="block mt-1 mb-2 font-bold text-center text-neutral-gray-800 leading-1">
                        <span class="r:text-[16px] xs:r:text-[24px] inline">{{ val.death_insurance[`insurance_pay_${planName}`] }}</span>
                        <span class="r:text-[10px] xs:r:text-[14px]">{{ viewsJa.quotations.result.change_plan_modal.ten_thousand_yen }}</span>
                      </span>
                      <span class="block r:text-[10px] xs:r:text-[12px] leading-[1.33] text-center text-neutral-gray-600">{{ viewsJa.quotations.result.change_plan_modal.monthly_fee }}</span>
                      <span class="block r:text-[12px] leading-[1.33] text-center text-neutral-gray-600">{{ numberWithCommas(val.death_fee[`${planName}_fee`]) + viewsJa.quotations.result.change_plan_modal.yen }}</span>
                    </div>
                  </label>
                </radio-input>
              </template>
            </template>
          </form>
        </div>
      </template>
      <template #modalFooter>
        <div class="py-6 text-center border-t border-neutral-gray-100 bg-neutral-gray-50 modal-footer">
          <button id="btn-change-plan" data-testid="btn-change-plan" class="inline-flex items-center justify-center rounded-full btn-gradient btn-primary-gradient-horizontal r:text-[14px] py-2 px-2 xs:r:px-[18px] xs:py-4 font-medium text-white shadow-gray-1 leading-[1.33] r:min-h-[53px]" @click.prevent="submitFormChangePlan($event)">{{ viewsJa.quotations.result.change_plan_modal.btn_submit }}</button>
        </div>
      </template>
    </modal>
  </div>
</template>

<style lang="scss" scoped>
:deep(.tippy-content) {
  padding: 0;
}
.plan-type-selection {
  &.form-invalid {
    label {
      @apply border-tw-red-600;
    }
  }
  label {
    @apply relative block cursor-pointer rounded-lg bg-neutral-gray-100 pt-10 px-2 sm:px-4 pb-4;

    &:hover {
      opacity: 70%;
    }

    &:before {
      @apply absolute rounded-full bg-white border border-neutral-gray-300 r:w-[24px] r:h-[24px];
      content: '';
      transform: translate(-50%);
      left: 50%;
      top: 10px;
    }
  }
  input {
    &:checked {
      + label {
        @apply bg-primary-100;

        &:hover {
          opacity: 100%;
        }

        &:before {
          @apply absolute rounded-full bg-white r:border-[6px] border-primary-600 r:w-[24px] r:h-[24px];
          content: '';
          transform: translate(-50%);
          left: 50%;
          top: 10px;
        }
        span {
          @apply text-primary-600 font-bold;
        }
      }
    }
  }
}
</style>

<script>
import { router } from '@inertiajs/vue3';
import { useVuelidate } from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import Images from '@/Shared/Images.vue';
import Modal from '@/Shared/Modal.vue';
import RadioInput from '@/Shared/RadioInput.vue';
import { numberWithCommas, convertJaParams, toCapitalize } from '@/utils/utils';
import { Tippy } from 'vue-tippy';
import 'tippy.js/dist/tippy.css';

export default {
  components: {
    Images,
    Tippy,
    Modal,
    RadioInput
  },
  props: {
    careType: {
      type: String,
      required: true
    },
    summaryData: {
      type: Object,
      required: true
    },
    contractStartDayStr: {
      type: String,
      required: true
    },
    planMale: {
      type: String,
      required: false
    },
    planFemale: {
      type: String,
      required: false
    },
    setQuotationOpen: {
      type: Function,
      required: true
    },
    handleCloseTooltip: {
      type: Function,
      required: true
    }
  },
  setup () {
    return {
      v$: useVuelidate(),
    }
  },
  data() {
    return {
      viewsJa: this.jaConfig.viewsJa,
      convertJaParams: convertJaParams,
      numberWithCommas: numberWithCommas,
      toCapitalize: toCapitalize,
      modalState: {
        change_plan_modal: false
      },
      form: {
        planMale: this.planMale,
        planFemale: this.planFemale
      },
      authenToken: document.querySelector('meta[name="csrf-token"]').content,
    }
  },
  methods: {
    submitFormChangePlan (e) {
      e.preventDefault();
      if (this.v$.form.$invalid) {
        this.v$.form.$touch();
      } else {
        let formData = document.getElementById('change-plan-form');
        router.patch(this.$routes.update_plan_quotations(), formData)
        this.modalState['change_plan_modal'] = false;
      }
    }
  },
  computed: {
    handleModalState () {
      return (modalName) => {
        this.modalState[modalName] = !this.modalState[modalName];
      }
    },
    handleChangePlanType () {
      return (key, planName) => {
        this.form[`plan${toCapitalize(key)}`] = planName;
      }
    }
  },
  validations () {
    const form = {
      planMale: '',
      planFemale: ''
    };

    if (this.summaryData["male"] !== undefined) {
      form.planMale = { required };
    }

    if (this.summaryData["female"] !== undefined) {
      form.planFemale = { required };
    }

    return {
      form: {...form}
    }
  }
}
</script>
