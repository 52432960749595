<template>
  <div class="table-custom">
    <div class="border-t table-custom-head border-neutral-gray-200">
      <div class="flex table-custom-row">
        <div class="w-2/5 p-2 border-b bg-neutral-gray-200 border-x border-neutral-gray-200">
          <span class="r:text-[12px] font-black leading-[1.53] r:tracking-[0.25px]">{{ viewsJa.contracts.blocks.explanation_information.modal.insurance_premium.table.plan_info.plan_name }}</span>
        </div>
        <div class="w-1/5 p-2 border-b border-r bg-neutral-gray-200 border-neutral-gray-200">
          <span class="r:text-[12px] font-black leading-[1.53] r:tracking-[0.25px]">{{ viewsJa.contracts.blocks.explanation_information.modal.insurance_premium.table.plan_info.plan_value }}</span>
        </div>
        <div class="w-1/5 p-2 border-b border-r bg-neutral-gray-200 border-neutral-gray-200">
          <span class="r:text-[12px] font-black leading-[1.53] r:tracking-[0.25px]">{{ viewsJa.contracts.blocks.explanation_information.modal.insurance_premium.table.plan_info.plan_standard }}</span>
        </div>
        <div class="w-1/5 p-2 border-b border-r bg-neutral-gray-200 border-neutral-gray-200">
          <span class="r:text-[12px] font-black leading-[1.53] r:tracking-[0.25px]">{{ viewsJa.contracts.blocks.explanation_information.modal.insurance_premium.table.plan_info.plan_premium }}</span>
        </div>
      </div>
    </div>
    <div class="table-custom-body">
      <div class="flex table-custom-row" v-for="(planRow, idx) in viewsJa.contracts.blocks.explanation_information.modal.insurance_premium.table.plan_info.plan_types" :key="idx">
        <template v-for="(item, index) in planRow" :key="index">
          <div v-if="index == 0" class="w-2/5 p-2 border-b bg-neutral-gray-50 border-x border-neutral-gray-200">
            <span class="r:text-[12px] font-black leading-[1.53] r:tracking-[0.25px]" v-html="item"></span>
          </div>
          <div v-else class="w-1/5 p-2 text-right border-b border-r border-neutral-gray-200">
            <span class="r:text-[12px] leading-[1.53] r:tracking-[0.25px]" v-html="item"></span>
          </div>
        </template>
      </div>
    </div>
  </div>
  <div class="table-custom">
    <div class="border-t table-custom-head border-neutral-gray-200">
      <div class="flex table-custom-row">
        <div class="w-2/5 p-2 border-b bg-neutral-gray-200 border-x border-neutral-gray-200">
          <span class="r:text-[12px] font-black leading-[1.53] r:tracking-[0.25px]">{{ viewsJa.contracts.blocks.explanation_information.modal.insurance_premium.table.insured_cost.age_of_insured }}</span>
        </div>
        <div class="w-3/5 p-2 border-b border-r bg-neutral-gray-200 border-neutral-gray-200">
          <span class="r:text-[12px] leading-[1.53] r:tracking-[0.25px]">{{ viewsJa.contracts.blocks.explanation_information.modal.insurance_premium.table.insured_cost.monthly_premiums }}</span>
        </div>
      </div>
    </div>
    <div class="table-custom-body">
      <div class="flex table-custom-row" v-for="(insurcedRow, key) in viewsJa.contracts.blocks.explanation_information.modal.insurance_premium.table.insured_cost.insurced_underwritten" :key="key">
        <template v-for="(item, index) in insurcedRow" :key="index">
          <div v-if="index == 0" class="w-1/5 p-2 bg-neutral-gray-50 border-x border-neutral-gray-200" :class="{'border-b': key >= 7}">
            <span class="r:text-[12px] font-black leading-[1.53] r:tracking-[0.25px]">{{ item }}</span>
          </div>
          <div v-else class="w-1/5 p-2 text-right border-b border-r">
            <span class="r:text-[12px] leading-[1.53] r:tracking-[0.25px]">{{ item }}</span>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        viewsJa: this.jaConfig.viewsJa,
      }
    }
  }
</script>

