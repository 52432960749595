<template>
  <v-lazy-image
    v-if="needLazy && hasSrcSet"
    :src="imagesRender[name]['src']"
    :alt="altImg"
    :srcset="`${imagesRender[name]['srcset']['2x']} 2x, ${imagesRender[name]['srcset']['3x']} 3x`"></v-lazy-image>
  <v-lazy-image
    v-else-if="needLazy && !hasSrcSet"
    :src="imagesRender[name]"
    :alt="altImg"></v-lazy-image>
  <img
    v-else-if="!needLazy && hasSrcSet"
    :src="imagesRender[name]['src']"
    :alt="altImg"
    :srcset="`${imagesRender[name]['srcset']['2x']} 2x, ${imagesRender[name]['srcset']['3x']} 3x`" />
  <img
    v-else-if="!needLazy && !hasSrcSet"
    :src="imagesRender[name]"
    :alt="altImg" />
</template>

<script>
import VLazyImage from "v-lazy-image";
export default {
  components: {
    VLazyImage,
  },
  props: {
    name: {
      type: String,
      required: true
    },
    altImg: {
      type: String,
      default: ''
    },
    hasSrcSet: {
      type: Boolean,
      default: false
    },
    needLazy: {
      type: Boolean,
      default: true
    }
  },
  setup() {
    return {
      imagesRender: {
        logo: new URL(`@assets/images/logo-new.svg`, import.meta.url).href,
        logoFixedCost: new URL(`@assets/images/logo-fixed-cost.svg`, import.meta.url).href,
        logoFPMatching: new URL(`@assets/images/logo-fp-matching.svg`, import.meta.url).href,
        logoGuardian: new URL(`@assets/images/logo-guardian.svg`, import.meta.url).href,
        facebook: {
          src: new URL(`@assets/images/ic-facebook.png`, import.meta.url).href,
          srcset: {
            '2x': new URL(`@assets/images/ic-facebook@2x.png`, import.meta.url).href,
            '3x': new URL(`@assets/images/ic-facebook@3x.png`, import.meta.url).href,
          }
        },
        twitter: {
          src: new URL(`@assets/images/ic-twitter.png`, import.meta.url).href,
          srcset: {
            '2x': new URL(`@assets/images/ic-twitter@2x.png`, import.meta.url).href,
            '3x': new URL(`@assets/images/ic-twitter@3x.png`, import.meta.url).href,
          }
        },
        line: {
          src: new URL(`@assets/images/ic-line.png`, import.meta.url).href,
          srcset: {
            '2x': new URL(`@assets/images/ic-line@2x.png`, import.meta.url).href,
            '3x': new URL(`@assets/images/ic-line@3x.png`, import.meta.url).href,
          }
        },
        jcbCard: {
          src: new URL(`@assets/images/contracts/jcb.png`, import.meta.url).href,
          srcset: {
            '2x': new URL(`@assets/images/contracts/jcb@2x.png`, import.meta.url).href,
            '3x': new URL(`@assets/images/contracts/jcb@3x.png`, import.meta.url).href,
          }
        },
        visaCard: {
          src: new URL(`@assets/images/contracts/visa.png`, import.meta.url).href,
          srcset: {
            '2x': new URL(`@assets/images/contracts/visa@2x.png`, import.meta.url).href,
            '3x': new URL(`@assets/images/contracts/visa@3x.png`, import.meta.url).href,
          }
        },
        dinersCard: {
          src: new URL(`@assets/images/contracts/diners.png`, import.meta.url).href,
          srcset: {
            '2x': new URL(`@assets/images/contracts/diners@2x.png`, import.meta.url).href,
            '3x': new URL(`@assets/images/contracts/diners@3x.png`, import.meta.url).href,
          }
        },
        masterCard: {
          src: new URL(`@assets/images/contracts/master.png`, import.meta.url).href,
          srcset: {
            '2x': new URL(`@assets/images/contracts/master@2x.png`, import.meta.url).href,
            '3x': new URL(`@assets/images/contracts/master@3x.png`, import.meta.url).href,
          }
        },
        amexCard: {
          src: new URL(`@assets/images/contracts/amex.png`, import.meta.url).href,
          srcset: {
            '2x': new URL(`@assets/images/contracts/amex@2x.png`, import.meta.url).href,
            '3x': new URL(`@assets/images/contracts/amex@3x.png`, import.meta.url).href,
          }
        },
        contractsExplanation: new URL(`@assets/images/contracts/explanation-img.png`, import.meta.url).href,
        contractsCodeSample: new URL(`@assets/images/contracts/card-code-sample.svg`, import.meta.url).href,
        thankyouExplanation: new URL(`@assets/images/contracts/explanation-thankyou-img.svg`, import.meta.url).href,
        sendMail: new URL(`@assets/images/contracts/send-mail.svg`, import.meta.url).href,
        closeMail: new URL(`@assets/images/contracts/close-mail.svg`, import.meta.url).href,
        scrollGuide: new URL(`@assets/images/contracts/icon-scroll.svg`, import.meta.url).href,
        alert: new URL(`@assets/images/alert.svg`, import.meta.url).href,
        // QUOTATION RESULT PAGE
        nursingImg: new URL(`@assets/images/quotations/nursing.svg`, import.meta.url).href,
        homeImg: new URL(`@assets/images/quotations/home.svg`, import.meta.url).href,
        quoteTopPattern: new URL(`@assets/images/quotations/top-pattern.svg`, import.meta.url).href,
        quoteTopHome: new URL(`@assets/images/quotations/top-home.svg`, import.meta.url).href,
        quoteTopNursing: new URL(`@assets/images/quotations/top-nursing.svg`, import.meta.url).href,
        quotePrFeeBenefits: new URL(`@assets/images/quotations/premium-fee-benefits.svg`, import.meta.url).href,
        quotePrFeeMale: new URL(`@assets/images/quotations/premium-fee-ic-father.svg`, import.meta.url).href,
        quotePrFeeFemale: new URL(`@assets/images/quotations/premium-fee-ic-mother.svg`, import.meta.url).href,
        quotePrFeeGirl: new URL(`@assets/images/quotations/premium-fee-girl-1.svg`, import.meta.url).href,
        discussIntro: new URL(`@assets/images/quotations/discussion-intro.svg`, import.meta.url).href,
        discussConsult: new URL(`@assets/images/quotations/discussion-consult-1.svg`, import.meta.url).href,
        discussCustomer: new URL(`@assets/images/quotations/discussion-customer-1.svg`, import.meta.url).href,
        discussMess1: new URL(`@assets/images/quotations/discussion-mess-1.png`, import.meta.url).href,
        discussMess2: new URL(`@assets/images/quotations/discussion-mess-2.png`, import.meta.url).href,
        discussMess3: new URL(`@assets/images/quotations/discussion-mess-3.png`, import.meta.url).href,
        discussCost1: new URL(`@assets/images/quotations/discussion-cost-1.svg`, import.meta.url).href,
        discussCost2: new URL(`@assets/images/quotations/discussion-cost-2.svg`, import.meta.url).href,
        discussCost3: new URL(`@assets/images/quotations/discussion-cost-3.svg`, import.meta.url).href,
        discussGirl1: new URL(`@assets/images/quotations/discussion-girl-1.svg`, import.meta.url).href,
        discussDisability: new URL(`@assets/images/quotations/discussion-disability.svg`, import.meta.url).href,
        discussDeath: new URL(`@assets/images/quotations/discussion-death.svg`, import.meta.url).href,
        discussContact: new URL(`@assets/images/quotations/discussion-contact.svg`, import.meta.url).href,
        discussFacilities: new URL(`@assets/images/quotations/discussion-facilities.svg`, import.meta.url).href,
        discussLegal: new URL(`@assets/images/quotations/discussion-legal.svg`, import.meta.url).href,
        careSituation: new URL(`@assets/images/quotations/care-situation.svg`, import.meta.url).href,
        careSituationText: new URL(`@assets/images/quotations/care-situation-text.svg`, import.meta.url).href,
        experience1: new URL(`@assets/images/quotations/experience-1.svg`, import.meta.url).href,
        experience2: new URL(`@assets/images/quotations/experience-2.svg`, import.meta.url).href,
        home1: new URL(`@assets/images/quotations/home-1.svg`, import.meta.url).href,
        home2: new URL(`@assets/images/quotations/home-2.svg`, import.meta.url).href,
        family: new URL(`@assets/images/quotations/family.svg`, import.meta.url).href,
        numbImg: new URL(`@assets/images/quotations/number-img.svg`, import.meta.url).href,
        numbImgPC: new URL(`@assets/images/quotations/number-pc.svg`, import.meta.url).href,
        provide1: new URL(`@assets/images/quotations/provide-1.svg`, import.meta.url).href,
        provide2: new URL(`@assets/images/quotations/provide-2.svg`, import.meta.url).href,
        sendByMail: new URL(`@assets/images/quotations/send-by-mail.svg`, import.meta.url).href,
        // TOP PAGE
        topBannerPc: {
          src: new URL(`@assets/images/home/top-banner-pc.png`, import.meta.url).href,
          srcset: {
            '2x': new URL(`@assets/images/home/top-banner-pc@2x.png`, import.meta.url).href,
            '3x': new URL(`@assets/images/home/top-banner-pc@3x.png`, import.meta.url).href,
          }
        },
        topBannerMb: {
          src: new URL(`@assets/images/home/top-banner-mb.png`, import.meta.url).href,
          srcset: {
            '2x': new URL(`@assets/images/home/top-banner-mb@2x.png`, import.meta.url).href,
            '3x': new URL(`@assets/images/home/top-banner-mb@3x.png`, import.meta.url).href,
          }
        },
        figureOne: new URL(`@assets/images/home/figure-img-1.svg`, import.meta.url).href,
        figureTwo: new URL(`@assets/images/home/figure-img-2.svg`, import.meta.url).href,
        anxietyOne: new URL(`@assets/images/home/anxiety-1.svg`, import.meta.url).href,
        anxietyTwo: new URL(`@assets/images/home/anxiety-2.svg`, import.meta.url).href,
        triangleSmall: new URL(`@assets/images/home/triangle-small.svg`, import.meta.url).href,
        triangleMedium: new URL(`@assets/images/home/triangle-medium.svg`, import.meta.url).href,
        nursingCare: new URL(`@assets/images/home/nursing-care.svg`, import.meta.url).href,
        fund: new URL(`@assets/images/home/fund.svg`, import.meta.url).href,
        ownMood: new URL(`@assets/images/home/on-mood.svg`, import.meta.url).href,
        humanFinger: new URL(`@assets/images/home/human-finger.svg`, import.meta.url).href,
        yenImg: new URL(`@assets/images/home/yen.svg`, import.meta.url).href,
        letterImg: new URL(`@assets/images/home/letter.svg`, import.meta.url).href,
        sompoImg: new URL(`@assets/images/home/sompo.svg`, import.meta.url).href,
        parentImg: new URL(`@assets/images/home/parent.svg`, import.meta.url).href,
        phoneImg: new URL(`@assets/images/home/phone.svg`, import.meta.url).href,
        insuranceFree: new URL(`@assets/images/home/insurance-fee.svg`, import.meta.url).href,
        greenGirl: new URL(`@assets/images/home/green-girl.svg`, import.meta.url).href,
        nursingParent: new URL(`@assets/images/home/nursing-parent.svg`, import.meta.url).href,
        heart: new URL(`@assets/images/home/heart.svg`, import.meta.url).href,
        femaleAvatar: new URL(`@assets/images/home/female-avatar.svg`, import.meta.url).href,
        maleAvatar: new URL(`@assets/images/home/male-avatar.svg`, import.meta.url).href,
        oldFemaleAvatar: new URL(`@assets/images/home/old-female.svg`, import.meta.url).href,
        youngFemaleAvatar: new URL(`@assets/images/home/young-female.svg`, import.meta.url).href,
        voices: new URL(`@assets/images/home/voices-img.svg`, import.meta.url).href,
        lookUp: new URL(`@assets/images/home/look-up.svg`, import.meta.url).href,
        handShake: new URL(`@assets/images/home/hand-shake.svg`, import.meta.url).href,
        applyTime: new URL(`@assets/images/home/apply-time.svg`, import.meta.url).href,
        prepare: new URL(`@assets/images/home/prepare-img.svg`, import.meta.url).href,
        discussionTop: new URL(`@assets/images/home/discussion-top.svg`, import.meta.url).href,
        girlPlan: new URL(`@assets/images/home/girl-get-plan.svg`, import.meta.url).href,
        planPricePattern: new URL(`@assets/images/home/plan-price-pattern.svg`, import.meta.url).href,
        rightTriangle: new URL(`@assets/images/home/right-triangle.svg`, import.meta.url).href,
        announcement: new URL(`@assets/images/home/announcement.svg`, import.meta.url).href,
      }
    }
  },
}
</script>
