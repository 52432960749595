<template>
  <Loading :loading="isLoading"/>
  <div class="payment-information bg-neutral-gray-50">
    <div class="r:max-w-[672px] mx-auto pt-8 pb-6">
      <div class="px-6 mb-24 title-section">
        <span class="text-primary-600 r:text-[18px] leading-[1.53] r:tracking-[0.25px] mb-1">{{ viewsJa.credit_cards.edit.sub_title }}</span>
        <h1 class="r:text-[34px] font-black leading-[1.53] r:tracking-[0.25px]" v-html="viewsJa.credit_cards.edit.title"></h1>
      </div>
      <div class="card-info-section">
        <h2 class="r:text-[24px] leading-[1.53] r:tracking-[0.25px] px-6 mb-4">{{ viewsJa.credit_cards.edit.card_info.title }}</h2>
        <form class="form-contract-payment" id="form-contract-payment" method="post">
          <input type="hidden" name="authenticity_token" :value="authenToken" autocomplete="off">
          <div class="px-6 py-8 bg-white border-b border-tw-black-200">
            <div class="form-control-group" v-if="cardDetail">
              <h3 class="r:text-[18px] leading-[1.53] r:tracking-[0.25px] text-primary-500 mb-4">{{ viewsJa.credit_cards.edit.card_info.current_card }}</h3>
              <div class="form-group">
                <div class="relative block px-6 py-4 mb-4 border cursor-pointer card-edit border-neutral-gray-100 rounded-2xl bg-neutral-gray-50">
                  <strong class="block mb-1 r:text-[16px] font-black leading-[1.53] r:tracking-[0.25px]">{{ cardDetail.masked_number }}</strong>
                  <span class="flex items-center mb-1">
                    <span class="r:text-[16px] leading-[1.53] r:tracking-[0.25px]">{{ viewsJa.credit_cards.edit.card_info.effect_date }}</span>
                    <span class="r:text-[16px] leading-[1.53] r:tracking-[0.25px] ml-1">{{ formatCardExpiration(cardDetail.expiration) }}</span>
                  </span>
                  <span class="block mb-2 r:text-[16px] leading-[1.53] r:tracking-[0.25px] ml-1">{{ cardDetail.holder_name.toUpperCase() }}</span>
                  <span class="flex justify-end">
                    <images :name="`${cardDetail.brand.toLowerCase()}Card`" class="r:w-[86px] r:h-[66px]" :hasSrcSet="true" />
                  </span>
                </div>
              </div>
            </div>
            <h3 class="r:text-[18px] leading-[1.53] r:tracking-[0.25px] text-primary-500 mb-4">{{ viewsJa.credit_cards.edit.card_info.update_card_info }}</h3>
            <div
              class="pl-6 border-l-4 form-control-group border-tw-black-200"
            >
              <p class="r:text-[16px] leading-[1.53] r:tracking-[0.25px] mb-4">{{ viewsJa.credit_cards.edit.card_info.please_update_card_info }}</p>
              <!-- card number -->
              <div class="mb-4 form-group">
                <label class="flex items-center mb-2">
                  <span class="r:text-[16px] leading-[1.53] r:tracking-[0.25px]">{{ modelsJa.activerecord.attributes.contracts.card_number }}</span>
                  <span class="r:text-[10px] r:tracking-[0.25px] leading-[1.53] text-tw-red-500 bg-tw-red-50 rounded px-1 mt-1 ml-2">{{ viewsJa.contracts.required }}</span>
                </label>
                <div class="flex mb-2 -mx-2">
                  <div class="w-full px-2">
                    <text-input
                      v-model="form.cardNumber"
                      class="w-full"
                      id="user_card_number"
                      name="user[card_number]"
                      :placeholder="viewsJa.credit_cards.edit.card_info.placeholders.card_number"
                      :customClass="`form-element px-3 py-2.5 r:min-h-[52px] ${v$.form.cardNumber.$error ? 'form-invalid' : ''} ${v$.form.cardNumber.$dirty ? 'form-dirty' : ''}`"
                      @change="handleSetTouch('cardNumber')"
                    />
                  </div>
                </div>
              </div>
              <!-- expired date -->
              <div class="mb-4 form-group">
                <label class="flex items-center mb-2">
                  <span class="r:text-[16px] leading-[1.53] r:tracking-[0.25px]">{{ modelsJa.activerecord.attributes.contracts.expired_date }}</span>
                  <span class="r:text-[10px] r:tracking-[0.25px] leading-[1.53] text-tw-red-500 bg-tw-red-50 rounded px-1 mt-1 ml-2">{{ viewsJa.contracts.required }}</span>
                </label>
                <div class="flex mb-2 -mx-0.5">
                  <div class="px-0.5">
                    <select-input
                      v-model="form.expiredMonth"
                      id="user_expired_month"
                      name="user[expired_month]"
                      class="w-full"
                      :customClass="`form-element r:min-w-[90px] px-3 py-2.5 r:min-h-[52px] ${v$.form.expiredMonth.$error || v$.form.expiredDate.$error ? 'form-invalid' : ''} ${v$.form.expiredMonth.$dirty ? 'form-dirty' : ''} ${!form.expiredMonth ? 'text-tw-black-200' : ''}`"
                      @change="handleSetTouch('expiredMonth'); handleUpdateExpiredDate('expiredMonth', 'expiredYear')"
                    >
                      <option value="">{{ viewsJa.credit_cards.edit.card_info.placeholders.expired_date.month }}</option>
                      <option :value="monthItem" v-for="monthItem of months" :key="monthItem">{{ monthItem }}</option>
                    </select-input>
                  </div>
                  <div class="px-0.5">
                    <select-input
                      v-model="form.expiredYear"
                      id="user_expired_year"
                      name="user[expired_year]"
                      class="w-full"
                      :customClass="`form-element r:min-w-[90px] px-3 py-2.5 r:min-h-[52px] ${v$.form.expiredYear.$error || v$.form.expiredDate.$error ? 'form-invalid' : ''} ${v$.form.expiredYear.$dirty ? 'form-dirty' : ''} ${!form.expiredYear ? 'text-tw-black-200' : ''}`"
                      @change="handleSetTouch('expiredYear'); handleUpdateExpiredDate('expiredMonth', 'expiredYear')"
                    >
                      <option value="">{{ viewsJa.credit_cards.edit.card_info.placeholders.expired_date.year }}</option>
                      <option :value="yearItem" v-for="yearItem of years" :key="yearItem">{{ yearItem }}</option>
                    </select-input>
                  </div>
                  <text-input
                    v-model="form.expiredDate"
                    id="user_expirred_date"
                    name="user[expirred_date]"
                    type="hidden"
                    :customClass="`form-element ${v$.form.expiredDate.$error ? 'form-invalid' : ''} ${v$.form.expiredDate.$dirty ? 'form-dirty' : ''}`"
                  />
                </div>
              </div>
              <!-- security code -->
              <div class="mb-2 form-group">
                <label class="flex items-center mb-2">
                  <span class="r:text-[16px] leading-[1.53] r:tracking-[0.25px]">{{ modelsJa.activerecord.attributes.contracts.security_code }}</span>
                  <span class="r:text-[10px] r:tracking-[0.25px] leading-[1.53] text-tw-red-500 bg-tw-red-50 rounded px-1 mt-1 ml-2">{{ viewsJa.contracts.required }}</span>
                </label>
                <div class="flex items-center mb-2 -mx-0.5">
                  <div class="px-0.5">
                    <text-input
                      v-model="form.securityCode"
                      class="r:w-[60px]"
                      id="user_security_code"
                      name="user[security_code]"
                      :placeholder="viewsJa.credit_cards.edit.card_info.placeholders.security_code"
                      :customClass="`form-element px-3 py-2.5 r:min-h-[52px] ${v$.form.securityCode.$error ? 'form-invalid' : ''} ${v$.form.securityCode.$dirty ? 'form-dirty' : ''}`"
                      @change="handleSetTouch('securityCode')"
                    />
                  </div>
                  <div class="ml-1.5 px-0.5">
                    <images name="contractsCodeSample" />
                  </div>
                </div>
                <span class="block r:text-[14px] leading-[1.53] r:tracking-[0.25px] text-neutral-gray-400" v-html="viewsJa.credit_cards.edit.card_info.note.security_code"></span>
              </div>
            </div>
          </div>
          <div class="px-6 py-8 bg-white border-b border-tw-black-200">
            <span class="text-primary-500 r:text-[18px] leading-[1.53] r:tracking-[0.25px] mb-6 block">{{ viewsJa.credit_cards.edit.card_info.support_company_label }}</span>
            <div class="flex items-center justify-center flex-wrap xs:flex-nowrap r:mx-[-5px]">
              <div class="r:px-[5px] mt-2">
                <images name="jcbCard" class="w-full r:max-w-[62px]" :hasSrcSet="true"/>
              </div>
              <div class="r:px-[5px] mt-2">
                <images name="visaCard" class="w-full r:max-w-[62px]" :hasSrcSet="true"/>
              </div>
              <div class="r:px-[5px] mt-2">
                <images name="dinersCard" class="w-full r:max-w-[62px]" :hasSrcSet="true"/>
              </div>
              <div class="r:px-[5px] mt-2">
                <images name="masterCard" class="w-full r:max-w-[62px]" :hasSrcSet="true"/>
              </div>
              <div class="r:px-[5px] mt-2">
                <images name="amexCard" class="w-full r:max-w-[62px]" :hasSrcSet="true"/>
              </div>
            </div>
          </div>
          <div class="pt-16 payment-action">
            <label class="mb-2 flex justify-center text-tw-red-600 r:text-[14px] leading-[1] w-9/12 mx-auto" for="last_name" v-if="isFormHasError || gmoError.state">
              <span class="leading-[1.53] r:tracking-[0.25px]">{{ !gmoError.message ? viewsJa.credit_cards.edit.card_info.errors.field_required : gmoError.message }}</span>
            </label>
            <div class="flex-col items-center form-group text-centerflex">
              <button type="button" :disabled="isDisabledSubmitButton" id="submit-gmo" data-testid="submit-gmo" class="flex items-center justify-center rounded-full btn-gradient btn-primary-gradient-horizontal r:text-[14px] xs:r:text-[16px] py-2 px-2 r:xs:px-[18px] xs:pt-4 xs:pb-3.5 mx-auto font-medium text-white shadow-gray-1 leading-[1.4] r:min-h-[56px]" @click="submitGMO">
                <span id="submit-gmo-span" class="mr-2">{{ viewsJa.credit_cards.edit.btn.submit }}</span>
                <svg id="submit-gmo-svg" xmlns="http://www.w3.org/2000/svg" width="18" height="19" viewBox="0 0 18 19" fill="none">
                  <path id="submit-gmo-path" fill-rule="evenodd" clip-rule="evenodd" d="M6.32036 3.76646C6.72549 3.3911 7.35819 3.41524 7.73355 3.82036L12.9958 9.50001L7.73355 15.1796C7.35819 15.5848 6.72549 15.6089 6.32036 15.2335C5.91524 14.8582 5.8911 14.2255 6.26646 13.8204L10.2693 9.50001L6.26646 5.17965C5.8911 4.77452 5.91524 4.14182 6.32036 3.76646Z" :fill="!isDisabledSubmitButton ? '#FFFFFF' : '#D3DDE8'"/>
                </svg>
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { useVuelidate } from '@vuelidate/core';
import { useHead } from '@unhead/vue'
import { required, minLength, maxLength, numeric } from '@vuelidate/validators';
import { futureDate } from '@/utils/custom-validators';
import { convertJaParams, convertDateString } from '@/utils/utils';
import Layout from '@/Layouts/User.vue';
import TextInput from '@/Shared/TextInput.vue';
import SelectInput from '@/Shared/SelectInput.vue';
import RadioInput from '@/Shared/RadioInput.vue';
import IconRawSvg from '@/Shared/IconRawSvg.vue';
import Images from '@/Shared/Images.vue';
import ContractProgress from '@/Pages/Contracts/Components/ContractProgress.vue';
import Loading from '@/Shared/Loading.vue';
import { setMask, setAlertMessage } from '@/utils/utils';
import axios from 'axios';
import { CARD_API_ERROR_MESSAGES } from '@/utils/constants';

export default {
  components: {
    TextInput,
    SelectInput,
    RadioInput,
    IconRawSvg,
    Images,
    ContractProgress,
    Loading,
  },
  layout: Layout,
  props: {
    shopId: {
      type: String,
      default: '',
    },
    mulPayURL: {
      type: String,
      default: '',
    },
    cardDetail: {
      type: Object,
      default: {},
    },
    isLogged: {
      type: Boolean,
      required: true,
    },
  },
  setup (props) {
    useHead({
      script: [{
        src: props.mulPayURL,
        defer: true,
      }],
    })
    return {
      v$: useVuelidate(),
    }
  },
  data () {
    return {
      setMask: setMask,
      setAlertMessage: setAlertMessage,
      convertJaParams: convertJaParams,
      modelsJa: this.jaConfig.modelsJa,
      viewsJa: this.jaConfig.viewsJa,
      isValid: true,
      form: {
        cardRegistered: null,
        cardNumber: '',
        expiredYear: '',
        expiredMonth: '',
        expiredDate: '',
        securityCode: '',
      },
      fieldServerErr: {
        card_number: false,
        expired_year: false,
        expired_month: false,
        security_code: false,
      },
      gmoError: {
        state: false,
        message: '',
      },
      authenToken: document.querySelector('meta[name="csrf-token"]').content,
      months: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
      years: [],
      isLoading: false,
    }
  },
  mounted() {
    // pre-check login state to clear local storage in form page
    if (this.isLogged) {
      localStorage.setItem('trackLoggedIn', true);
    }
    // render year
    const currentYear = new Date().getFullYear();
    const gap = 10;
    for (let i = currentYear; i < (currentYear + gap); i++) {
      this.years.push(i);
    }
  },
  methods: {
    submitGMO () {
      const cardObj = {
        cardno: this.form.cardNumber.replaceAll('_', ''),
        expire: `${this.form.expiredYear}${convertDateString(this.form.expiredMonth)}`,
        securitycode: this.form.securityCode,
        tokennumber: '2',
      }

      const self = this;
      self.isLoading = true;

      Multipayment.init(this.shopId);
      Multipayment.getToken(cardObj, (response) => {
        self.isLoading = false;
        this.execPurchase(response)
      });
    },
    execPurchase(response) {
      const self = this;
      if(response.resultCode != '000') {
        self.gmoError.state = true;
        if(CARD_API_ERROR_MESSAGES[response.resultCode]) {
          self.gmoError.message = `${self.viewsJa.contracts.api_error_messages.connection_text}${CARD_API_ERROR_MESSAGES[response.resultCode]}`;
        } else {
          self.gmoError.message = `${response.resultCode}${self.viewsJa.contracts.api_error_messages.connection_text}`;
        }
      } else {
        const cardData = {
          credit_card: {
            token: response.tokenObject.token[0],
            token_for_bin_verification: response.tokenObject.token[1],
          },
          authenticity_token: self.authenToken,
        }

        self.isLoading = true;

        axios({
          method: 'patch',
          url: self.$routes.credit_cards(),
          data: cardData,
        }).then(function (response) {
          self.setAlertMessage(response.data.message);
          window.location.href = response.data.redirect_url;
        }).catch(function (_error) {
          self.gmoError.message = self.viewsJa.credit_cards.edit.update_card_error_message;
          self.gmoError.state = true;
        }).finally(function () {
          self.isLoading = false;
        });
    }
    },
    handleSetTouch (field) {
      this.v$.form[field].$touch();
    },
    formatCardExpiration (expiration) {
      return `${expiration.month}/${expiration.year}`
    },
  },
  computed: {
    handleActiveCard () {
      return (card) => {
        if (!card) {
          this.form.cardNumber = '';
          this.form.expiredYear = '';
          this.form.expiredMonth = '';
          this.form.securityCode = '';
        } else {
          const expire = card.expire.split('/');
          this.v$.form.cardNumber.$reset();
          this.v$.form.expiredYear.$reset();
          this.v$.form.expiredMonth.$reset();
          this.v$.form.securityCode.$reset();
          this.form.cardNumber = card.cardno;
          this.form.expiredYear = expire[1];
          this.form.expiredMonth = expire[0];
          this.form.securityCode = card.securitycode;
        }
      }
    },
    isFormHasError () {
      return this.v$.form.cardNumber.$error ||
        this.v$.form.expiredYear.$error ||
        this.v$.form.expiredMonth.$error ||
        this.v$.form.securityCode.$error ||
        this.v$.form.expiredDate.$error
    },
    isDisabledSubmitButton () {
      return !this.form.cardNumber || !this.form.expiredYear ||
        !this.form.expiredMonth || !this.form.securityCode ||
        this.isFormHasError || !this.form.expiredDate
    },
    handleUpdateExpiredDate () {
      return (monthField, yearField) => {
        const today = new Date();
        if (this.v$.form[monthField].$dirty && this.v$.form[yearField].$dirty) {
          if (!!this.form[monthField] && !!this.form[yearField]) {
            this.form.expiredDate = `${this.form[yearField]}-${convertDateString(this.form[monthField])}-${convertDateString(today.getDate())}`;
          } else {
            this.form.expiredDate = '';
          }
          this.v$.form.expiredDate.$touch();
        }
      }
    }
  },
  validations () {
    const form = {
      cardNumber: {},
      expiredYear: {},
      expiredMonth: {},
      expiredDate: {},
      securityCode: {},
    };

    form.cardRegistered = { required };
    form.cardNumber = { required, minLength: minLength(14), maxLength: maxLength(16), numeric };
    form.expiredYear = { required };
    form.expiredMonth = { required };
    form.expiredDate = { futureDate };
    form.securityCode = { required, minLength: minLength(3), maxLength: maxLength(4), numeric };

    return {
      form: {...form}
    };
  },
  validationConfig: {
    $lazy: true,
  },
}
</script>
