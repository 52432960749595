<template>
  <div class="quotation-result">
    <div class="top-area bg-primary-500">
      <div class="container-user-sm">
        <div class="relative py-4">
          <div class="relative z-[3] r:min-h-[169px] pb-24 xs:pb-0">
            <h3 class="r:text-[16px] font-black r:tracking-[0.25px] leading-[1.33] mb-2 mt-2.5 text-white">― {{ viewsJa.quotations.result.top_area[careType].sub_title }} ―</h3>
            <h2 class="r:text-[24px] font-black r:tracking-[0.25px] leading-[1.53] mb-2 text-white r:max-w-[218px]">{{ viewsJa.quotations.result.top_area[careType].title }}</h2>
            <p class="r:max-w-[180px] r:text-[16px] leading-[1.53] r:tracking-[0.25px] text-white" v-html="viewsJa.quotations.result.top_area[careType].desc"></p>
          </div>
          <images class="absolute bottom-5 right-1 md:right-5 z-[2]" :name="`quoteTop${toCapitalize(careType)}`" />
          <images class="absolute bottom-0 -right-4 md:right-0 z-[1]" name="quoteTopPattern" />
        </div>
      </div>
    </div>
    <div class="py-6 premium-fee bg-primary-50">
      <div class="container-user-sm">
        <div class="pr-24 xs:r:pr-[135px] mb-4 relative">
          <span class="r:text-[12px] leading-[1.75] r:tracking-[0.25px] text-neutral-gray-800">{{ viewsJa.quotations.result.premium_fee.intro_text }}</span>
          <images name="quotePrFeeBenefits" />
          <images class="absolute right-1 -bottom-8 xs:r:bottom-[-45px] max-w-20 xs:max-w-none" name="quotePrFeeGirl" />
        </div>
        <div class="fees">
          <div class="flex items-stretch mb-2 overflow-hidden bg-white border rounded-2xl border-primary-100" v-for="(parent, key) in summaryData" :key="key">
            <div class="flex flex-col items-center justify-center p-2 text-center bg-primary-100">
              <images :name="`quotePrFee${toCapitalize(key)}`" class="mb-2" :data-a="key" />
              <span class="r:text-[12px] leading-[1.33] r:tracking-[0.25px] text-neutral-gray-800" v-html="convertJaParams(viewsJa.quotations.result.premium_fee.age[key], { age: parent.age})"></span>
            </div>
            <div class="py-2 pl-4 r:pr-[22px] flex-1">
              <h4 class="r:text-[14px] text-neutral-gray-800 mb-1.5 leading-[1.53] r:tracking-[0.25px] font-black">{{ viewsJa.quotations.result.premium_fee.title[`${this.form[`plan${toCapitalize(key)}`]}_plan`] }}</h4>
              <div class="flex items-baseline justify-end pb-4 mb-2 border-b border-primary-200">
                <span class="r:text-[12px] leading-[1] r:tracking-[0.786px] text-neutral-gray-800 mr-0.5">{{ viewsJa.quotations.result.premium_fee.monthly_premium }}</span>
                <span class="text-tw-yellow-500 r:text-[18px] leading-[1] r:tracking-[0.786px]">{{ numberWithCommas(parent.death_fee[`${this.form[`plan${toCapitalize(key)}`]}_fee`]) }}</span>
                <span class="text-tw-yellow-500 r:text-[12px] leading-[1] r:tracking-[0.786px]">{{ viewsJa.quotations.result.premium_fee.yen }}</span>
              </div>
              <div class="flex flex-col items-center xs:flex-row">
                <div class="w-full xs:r:w-[108px] sm:w-[130px] pb-2 xs:pb-0 mb-2 xs:mb-0 xs:mr-2 xs:pr-2 border-b xs:border-b-0 xs:border-r border-primary-200 flex items-center justify-end xs:justify-start">
                  <span class="r:text-[11px] leading-[1.2] r:tracking-[0.25px] text-neutral-gray-800 mr-0.5 sm:mr-1 sm:whitespace-nowrap" v-html="viewsJa.quotations.result.premium_fee.lump_sum"></span>
                  <span class="flex items-baseline">
                    <strong class="text-primary-600 r:text-[28px] leading-[1] r:tracking-[0.25px]">{{ numberWithCommas(parent.death_insurance[`insurance_care_${this.form[`plan${toCapitalize(key)}`]}`]) }}</strong>
                    <span class="text-primary-600 r:text-[11px] leading-[1] r:tracking-[0.25px]">{{ viewsJa.quotations.result.premium_fee.million }}</span>
                  </span>
                </div>
                <div class="flex items-center justify-end w-full xs:w-auto xs:justify-start xs:flex-1">
                  <span class="r:text-[10px] leading-[1.2] r:tracking-[0.25px] text-neutral-gray-800 mr-0.5 sm:mr-1 sm:whitespace-nowrap" v-html="viewsJa.quotations.result.premium_fee.death_disability"></span>
                  <span class="flex items-baseline">
                    <strong class="text-primary-600 r:text-[28px] leading-[1] r:tracking-[0.25px]">{{ numberWithCommas(parent.death_insurance[`insurance_pay_${this.form[`plan${toCapitalize(key)}`]}`]) }}</strong>
                    <span class="text-primary-600 r:text-[11px] leading-[1] r:tracking-[0.25px]">{{ viewsJa.quotations.result.premium_fee.million }}</span>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="pt-6 pb-10 border-b discussion border-neutral-gray-100">
      <div class="r:max-w-[640px] mx-auto">
        <images name="discussIntro" class="block mx-auto" />
        <div class="rounded-2xl bg-[#83bbde] px-4 py-6 mb-4">
          <!-- nursing -->
          <discussion-box :careType="careType" :summaryData="summaryData" :contractStartDayStr="contractStartDayStr" :planMale="this.form.planMale" :planFemale="this.form.planFemale" :setQuotationOpen="setQuotationOpen" :handleCloseTooltip="handleCloseTooltip"></discussion-box>
          <div class="flex flex-col items-center py-10 discussion-action">
            <p class="r:text-[16px] leading-[1.53] r:tracking-[0.25px] text-white mb-2">{{ viewsJa.quotations.result.discussion.encourage_text }}</p>
            <button id="submit-update-plan-btn" data-testid="submit-update-plan-btn" type="button" class="inline-flex items-center justify-center rounded-full btn-gradient btn-primary-gradient-horizontal r:text-[14px] xs:r:text-[16px] py-2 px-2 xs:r:px-[18px] xs:py-4 font-medium text-white shadow-gray-1 leading-[1.33] r:min-h-[53px]" @click.prevent="submitUpdatePlan($event)">
              <span id="submit-update-plan-btn-span" class="ml-2" v-html="viewsJa.quotations.result.discussion.btn_submit" />
              <svg id="submit-update-plan-btn-svg" xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19" fill="none" class="ml-2">
                <path id="submit-update-plan-btn-path" fill-rule="evenodd" clip-rule="evenodd" d="M6.82036 3.76646C7.22549 3.3911 7.85819 3.41524 8.23355 3.82036L13.4958 9.50001L8.23355 15.1796C7.85819 15.5848 7.22549 15.6089 6.82036 15.2335C6.41524 14.8582 6.3911 14.2255 6.76646 13.8204L10.7693 9.50001L6.76646 5.17965C6.3911 4.77452 6.41524 4.14182 6.82036 3.76646Z" fill="white"/>
              </svg>
            </button>
          </div>
        </div>
        <p class="r:text-[12px] leading-[1.53] r:tracking-[0.25px] text-neutral-gray-400 px-4" v-html="viewsJa.quotations.result.discussion.note[careType]"></p>
      </div>
    </div>
    <div class="py-10 border-b care-situation border-neutral-gray-100">
      <div class="container-user-sm">
        <div class="care-situation-info">
          <div class="flex items-center justify-center mb-6">
            <images name="careSituation" />
            <div class="title">
              <images name="careSituationText" class="-ml-4" />
              <h4 class="r:text-[24px] font-bold leading-[1.53] tracking-[0.25px]" v-html="viewsJa.quotations.result.care_situation.title"></h4>
            </div>
          </div>
          <div class="flex flex-col items-center justify-center p-6 mb-4 bg-primary-100 rounded-2xl">
            <span class="flex items-center mb-2">
              <span class="r:text-[16px] leading-[1.53] tracking-[0.25px] mr-1.5">{{ viewsJa.quotations.result.care_situation.experience_box.title }}</span>
              <images name="family" class="inline" />
            </span>
            <div class="inline">
              <svg class="inline -mt-3.5 mr-2" width="28" height="23" viewBox="0 0 28 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_202_636)">
                  <path d="M6.91682 22.8039C2.92082 22.8039 0.814819 19.7259 0.814819 14.3799C0.814819 8.43988 3.78482 3.90388 9.94082 0.879883L11.9928 5.09188C8.15882 6.87388 6.32282 9.30388 6.32282 12.9759L6.48482 18.4839L3.40682 14.2179C4.37882 13.4619 5.62082 12.8139 6.91682 12.8139C9.61682 12.8139 11.8308 14.6499 11.8308 17.5659C11.8308 20.7519 9.77882 22.8039 6.91682 22.8039ZM22.0368 22.8039C18.0408 22.8039 15.9348 19.7259 15.9348 14.3799C15.9348 8.43988 18.9048 3.90388 25.0608 0.879883L27.1128 5.09188C23.2788 6.87388 21.4428 9.30388 21.4428 12.9759L21.6048 18.4839L18.5268 14.2179C19.4988 13.4619 20.7408 12.8139 22.0368 12.8139C24.7368 12.8139 26.9508 14.6499 26.9508 17.5659C26.9508 20.7519 24.8988 22.8039 22.0368 22.8039Z" fill="#EEAC6F"/>
                </g>
                <defs>
                  <clipPath id="clip0_202_636">
                    <rect width="28" height="23" fill="white"/>
                  </clipPath>
                </defs>
              </svg>
              <span class="r:text-[24px] text-primary-600 font-black leading-[1.53] tracking-[0.25px]"> {{ viewsJa.quotations.result.care_situation.experience_box.desc.desc_1 }}</span>
              <br>
              <span class="ml-10 r:text-[24px] font-black leading-[1.53] tracking-[0.25px]"> {{ viewsJa.quotations.result.care_situation.experience_box.desc.desc_2 }}</span>
              <svg class="inline ml-2 -mt-5" width="28" height="22" viewBox="0 0 28 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_202_639)">
                  <path d="M3.00078 21.8856L0.894775 17.7276C4.72878 15.8916 6.61878 13.5156 6.61878 9.78963L6.40278 4.33563L9.48078 8.60163C8.56278 9.35763 7.26678 9.95163 6.02478 9.95163C3.27078 9.95163 1.05678 8.11563 1.05678 5.19963C1.05678 2.01363 3.16278 0.015625 6.02478 0.015625C10.0208 0.015625 12.0728 3.09363 12.0728 8.33163C12.0728 14.2716 9.10278 18.9156 3.00078 21.8856ZM18.1208 21.8856L16.0148 17.7276C19.8488 15.8916 21.7388 13.5156 21.7388 9.78963L21.5228 4.33563L24.6008 8.60163C23.6828 9.35763 22.3868 9.95163 21.1448 9.95163C18.3908 9.95163 16.1768 8.11563 16.1768 5.19963C16.1768 2.01363 18.2828 0.015625 21.1448 0.015625C25.1408 0.015625 27.1928 3.09363 27.1928 8.33163C27.1928 14.2716 24.2228 18.9156 18.1208 21.8856Z" fill="#EEAC6F"/>
                </g>
                <defs>
                  <clipPath id="clip0_202_639">
                    <rect width="28" height="22" fill="white"/>
                  </clipPath>
                </defs>
              </svg>
            </div>
          </div>
          <div class="flex">
            <template v-for="(detail, idx) in viewsJa.quotations.result.care_situation.experience_nursing" :key="idx">
              <div :class="[idx == 1 ? 'mr-2' : 'ml-2','w-1/2 px-6 py-4 each bg-primary-500 rounded-2xl text-white r:text-[16px] font-black leading-[1.53] tracking-[0.25px] nursing-details']">
                <span>{{ detail.title }}</span>
                <div :class="idx == 1 ? 'text-center' : 'text-right'">
                  <images :name="detail.img" class="mx-auto my-1"/>
                  <span class="r:text-[24px] xs:r:text-[36px] font-normal">{{ detail.number }}</span>
                  <span class="font-normal">%</span>
                </div>
              </div>
            </template>
          </div>
          <images name="numbImg" class="block mx-auto my-4 md:hidden"/>
          <images name="numbImgPC" class="hidden mx-auto my-4 sm:block md:block"/>
          <span class="block mb-4 r:text-[12px] leading-[1.55] text-neutral-gray-500">{{ viewsJa.quotations.result.care_situation.nursing_desc.desc_1 }}</span>
          <div class="px-6 py-4 mb-4 text-white other-care bg-primary-500 rounded-2xl">
            <span class="r:text-[16px] font-black block leading-[1.33] tracking-[0.25px]">{{ viewsJa.quotations.result.care_situation.other.other_1 }}</span>
            <span class="block text-right">
              <span class="r:text-[24px] xs:r:text-[36px] font-normal leading-[1.4]">5</span>
              <span class="r:text-[16px] leading-[1.4]">年</span>
              <span class="r:text-[24px] xs:r:text-[36px] font-normal leading-[1.4]">1</span>
              <span class="r:text-[16px] leading-[1.4]">ヶ月</span>
            </span>
          </div>
          <div class="flex mb-4">
            <template v-for="(detail, idx) in viewsJa.quotations.result.care_situation.experience_home" :key="idx">
              <div :class="[idx == 1 ? 'mr-2' : 'ml-2','w-1/2 px-6 py-4 each bg-primary-500 rounded-2xl text-white r:text-[16px] font-black leading-[1.53] tracking-[0.25px] nursing-details']">
                <div class="xs:r:min-h-[80px] sm:r:min-h-[30px] md:min-h-[40px]">
                  <span v-html="detail.title"></span>
                  <span class="block font-normal text-center r:text-[10px]" v-if="!!detail.other_title"> {{ detail.other_title }}</span>
                </div>
                <div :class="idx == 1 ? 'text-center' : 'text-right'">
                  <images :name="detail.img" class="mx-auto my-1 r:min-h-[50px]"/>
                  <span class="r:text-[24px] xs:r:text-[36px] font-normal">{{ detail.number }}</span>
                  <span class="font-normal">{{ viewsJa.common.unit.ten_thousand_yen }}</span>
                </div>
              </div>
            </template>
          </div>
          <span class="block mb-4 r:text-[12px] leading-[1.55] text-neutral-gray-500">{{ viewsJa.quotations.result.care_situation.nursing_desc.desc_2 }}</span>
          <div class="flex flex-col items-center px-5 py-4 mb-4 bg-primary-100 rounded-2xl">
            <div class="relative flex p-5 md:p-6">
              <svg class="absolute top-0 -left-1" width="28" height="23" viewBox="0 0 28 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_202_636)">
                  <path d="M6.91682 22.8039C2.92082 22.8039 0.814819 19.7259 0.814819 14.3799C0.814819 8.43988 3.78482 3.90388 9.94082 0.879883L11.9928 5.09188C8.15882 6.87388 6.32282 9.30388 6.32282 12.9759L6.48482 18.4839L3.40682 14.2179C4.37882 13.4619 5.62082 12.8139 6.91682 12.8139C9.61682 12.8139 11.8308 14.6499 11.8308 17.5659C11.8308 20.7519 9.77882 22.8039 6.91682 22.8039ZM22.0368 22.8039C18.0408 22.8039 15.9348 19.7259 15.9348 14.3799C15.9348 8.43988 18.9048 3.90388 25.0608 0.879883L27.1128 5.09188C23.2788 6.87388 21.4428 9.30388 21.4428 12.9759L21.6048 18.4839L18.5268 14.2179C19.4988 13.4619 20.7408 12.8139 22.0368 12.8139C24.7368 12.8139 26.9508 14.6499 26.9508 17.5659C26.9508 20.7519 24.8988 22.8039 22.0368 22.8039Z" fill="#EEAC6F"/>
                </g>
                <defs>
                  <clipPath id="clip0_202_636">
                    <rect width="28" height="23" fill="white"/>
                  </clipPath>
                </defs>
              </svg>
              <span class="r:text-[24px] font-black leading-[1.53] tracking-[0.25px] block text-center" v-html="viewsJa.quotations.result.care_situation.other.title"></span>
              <svg class="absolute bottom-0 right-2.5" width="28" height="22" viewBox="0 0 28 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_202_639)">
                    <path d="M3.00078 21.8856L0.894775 17.7276C4.72878 15.8916 6.61878 13.5156 6.61878 9.78963L6.40278 4.33563L9.48078 8.60163C8.56278 9.35763 7.26678 9.95163 6.02478 9.95163C3.27078 9.95163 1.05678 8.11563 1.05678 5.19963C1.05678 2.01363 3.16278 0.015625 6.02478 0.015625C10.0208 0.015625 12.0728 3.09363 12.0728 8.33163C12.0728 14.2716 9.10278 18.9156 3.00078 21.8856ZM18.1208 21.8856L16.0148 17.7276C19.8488 15.8916 21.7388 13.5156 21.7388 9.78963L21.5228 4.33563L24.6008 8.60163C23.6828 9.35763 22.3868 9.95163 21.1448 9.95163C18.3908 9.95163 16.1768 8.11563 16.1768 5.19963C16.1768 2.01363 18.2828 0.015625 21.1448 0.015625C25.1408 0.015625 27.1928 3.09363 27.1928 8.33163C27.1928 14.2716 24.2228 18.9156 18.1208 21.8856Z" fill="#EEAC6F"/>
                </g>
                <defs>
                  <clipPath id="clip0_202_639">
                    <rect width="28" height="22" fill="white"/>
                  </clipPath>
                </defs>
              </svg>
            </div>
          </div>
          <div class="px-6 py-4 mb-4 text-white other-care bg-primary-500 rounded-2xl">
            <span class="r:text-[16px] font-black block leading-[1.33] tracking-[0.25px]">{{ viewsJa.quotations.result.care_situation.other.other_2 }}</span>
            <span class="block font-normal text-right">
              <span class="r:text-[36px] leading-[1.4]">92</span>
              <span class="r:text-[16px] leading-[1.4]">%</span>
            </span>
          </div>
          <div class="flex mb-4">
            <template v-for="(detail, idx) in viewsJa.quotations.result.care_situation.experience_providing" :key="idx">
              <div :class="[idx == 1 ? 'mr-2' : 'flex flex-col ml-2','w-1/2 px-6 py-4 each bg-primary-500 rounded-2xl text-white r:text-[16px] font-black leading-[1.53] tracking-[0.25px] nursing-details']">
                <div class="block xs:r:min-h-[141px] sm:r:min-h-[45px] md:r:min-h-[65px]">
                  <span>{{ detail.title }}</span>
                  <span class="block r:text-[10px] leading-[1.53] tracking-[0.25px] font-normal">{{ detail.other_title }}</span>
                </div>
                <div :class="[idx == 1 ? 'text-center' : 'text-right', 'flex-1']">
                  <images :name="detail.img" :class="[idx == 1 ? 'md:mt-1' :'md:mt-2' , 'my-1 mx-auto r:min-h-[50px]']"/>
                  <span class="r:text-[24px] xs:r:text-[36px] font-normal">{{ detail.number }}</span>
                  <span class="font-normal">%</span>
                </div>
              </div>
            </template>
          </div>
          <span class="block r:text-[12px] leading-[1.55] text-neutral-gray-500">{{ viewsJa.quotations.result.care_situation.nursing_desc.desc_3 }}</span>
        </div>
      </div>
    </div>

    <!-- Parent nursing care -->
    <div class="py-10 pb-8 bg-white parent-nursing-care">
      <div class="container-user-sm" >
        <div class="mb-2 text-center title">
          <h3 class="r:text-[14px] text-primary-600 leading-[1.53] tracking-[0.25px]" v-html="viewsJa.quotations.result.parent_nursing_care.sub_title"></h3>
          <h2 class="r:text-[24px] leading-[1.53] font-bold tracking-[0.25px] mb-6 text-center" v-html="viewsJa.quotations.result.parent_nursing_care.title"></h2>
        </div>
        <parent-nursing-care pageType='quotations'></parent-nursing-care>
      </div>
    </div>

    <!-- benefits -->
    <div class="pt-8 pb-10 benefits bg-primary-500">
      <div class="mx-auto container-user-sm">
        <div class="flex items-center justify-center mb-4 explanation-title">
          <svg class="mr-2" xmlns="http://www.w3.org/2000/svg" width="13" height="38" viewBox="0 0 13 38" fill="none">
            <path d="M1.5 0.5L11.5 37" stroke="#F1BA87" stroke-width="2"/>
          </svg>
          <h4 v-html="viewsJa.home.blocks.insurance_simulator.title.explanation" class="text-white text-center r:text-[14px] font-black leading-[1.53] tracking-[0.25px]"></h4>
          <svg class="ml-2" xmlns="http://www.w3.org/2000/svg" width="13" height="38" viewBox="0 0 13 38" fill="none">
            <path d="M11.5 1L1.5 37" stroke="#F1BA87" stroke-width="2"/>
          </svg>
        </div>
        <div class="benefits">
          <div class="benefit-block">
            <div class="flex flex-wrap items-stretch overflow-hidden benefit-infos rounded-2xl">
              <template v-for="(benefit, idx) in viewsJa.home.blocks.insurance_simulator.benefits" :key="idx">
                <div :class="[
                  'p-4 bg-white benefit w-1/3 border-primary-600',
                  (idx % 3 !== 0 ? 'border-r' : ''),
                  (idx > 0 && idx < 4 ? 'border-b' : '')
                ]">
                  <div class="text-center">
                    <p class="r:min-h-[48px] r:text-[12px] leading-[1.33] tracking-[0.25px] mb-1 font-black" v-html="benefit.title"></p>
                    <images :name="benefit.img" class="mx-auto r:h-[50px]" />
                  </div>
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Table -->
    <div class="py-10 border-b age-price-section border-neutral-gray-100">
      <div class="container-user-sm">
        <div class="age-price-info">
          <h2 class="r:text-[24px] font-bold leading-[1.53] tracking-[0.25px] mb-6" v-html="viewsJa.home.age_price_table.title"></h2>
          <age-price-table></age-price-table>
          <div class="pt-6">
            <p
              v-for="(note, idx) in viewsJa.home.age_price_table.note" :key="idx"
              class="flex pl-5 relative r:text-[12px] leading-[1.53] r:tracking-[0.25px]"
            >
              <span class="absolute left-2 top-2.5 w-1 h-1 rounded-full bg-neutral-gray-900"></span>
              <span class="flex-1">{{ note }}</span>
            </p>
          </div>
        </div>
      </div>
    </div>

    <!-- send by mail -->
    <div v-if="sendMail" class="pt-6 pb-12 border-b border-neutral-gray-100 send-by-mail">
      <div class="container-user-sm">
        <h2 class="r:text-[24px] font-black r:tracking-[0.25px] leading-[1.53] text-neutral-gray-800 mb-2">{{ viewsJa.quotations.result.send_by_mail.title }}</h2>
        <p class="r:text-[16px] leading-[1.53] r:tracking-[0.25px] text-tw-black-900 mb-6">{{ viewsJa.quotations.result.send_by_mail.desc }}</p>
        <div class="flex flex-col items-center px-4 pt-4 pb-6 border border-primary-600 bg-primary-50 rounded-2xl">
          <images name="sendByMail" />
          <button type="button" data-testid="send-mail" id="send-mail" class="flex w-full items-center justify-center rounded-full btn-gradient btn-primary-gradient-horizontal r:text-[14px] xs:r:text-[16px] py-2 px-2 xs:r:px-[18px] xs:py-4 font-medium text-white shadow-gray-1 leading-[1.33] r:min-h-[53px]">
            <span id="send-mail-span" class="mr-2">{{ viewsJa.quotations.result.send_by_mail.btn_send_mail }}</span>
            <svg id="send-mail-svg" xmlns="http://www.w3.org/2000/svg" width="18" height="19" viewBox="0 0 18 19" fill="none">
              <path id="send-mail-path" fill-rule="evenodd" clip-rule="evenodd" d="M6.32036 3.76646C6.72549 3.3911 7.35819 3.41524 7.73355 3.82036L12.9958 9.50001L7.73355 15.1796C7.35819 15.5848 6.72549 15.6089 6.32036 15.2335C5.91524 14.8582 5.8911 14.2255 6.26646 13.8204L10.2693 9.50001L6.26646 5.17965C5.8911 4.77452 5.91524 4.14182 6.32036 3.76646Z" fill="#FFFFFFs"/>
            </svg>
          </button>
          <span class="font-notosans r:text-[13px] leading-[1.4] text-neutral-gray-500 text-center">{{ viewsJa.quotations.result.send_by_mail.note }}</span>
        </div>
      </div>
    </div>

    <div class="more-info-block">
      <div class="container-user-sm">
        <div class="collapse-block">
          <collapse id="more-info-0" :active="false" class="border-b border-tw-black-200">
            <template #collapseHeader>
              <h4 class="r:text-[16px] text-neutral-gray-800 leading-[1.53] r:tracking-[0.25px] flex-1 pr-4">{{ viewsJa.quotations.result.more_info.application_process }}</h4>
            </template>
            <template #collapseContent>
              <div class="pb-4 mx-4">
                <how-to-apply :isContentOnly="true" @open-modal="openModalByType"></how-to-apply>
              </div>
            </template>
          </collapse>
          <collapse id="more-info-1" :active="false" class="border-b border-tw-black-200">
            <template #collapseHeader>
              <h4 class="r:text-[16px] text-neutral-gray-800 leading-[1.53] r:tracking-[0.25px] flex-1 pr-4">{{ viewsJa.quotations.result.more_info.faqs }}</h4>
            </template>
            <template #collapseContent>
              <div class="pb-4 mx-4">
                <faqs :isContentOnly="true"></faqs>
              </div>
            </template>
          </collapse>
        </div>
        <div class="border-b border-neutral-gray-100">
          <a :href="$routes.contact()" id="go-to-contact-from-result" data-testid="go-to-contact-from-result" class="p-4 hover:opacity-70 flex items-center justify-between r:text-[16px] text-neutral-gray-600 leading-[1.53] r:tracking-[0.25px] flex-1 transition">{{viewsJa.home.faqs.go_to_contact}}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="rotate-[-90deg]"
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
              aria-hidden="true"
              id="go-to-contact-from-result-svg"
            >
              <path id="go-to-contact-from-result-path" fill-rule="evenodd" clip-rule="evenodd" d="M3.26646 6.32036C3.64182 5.91524 4.27452 5.8911 4.67965 6.26646L9 10.2693L13.3204 6.26646C13.7255 5.8911 14.3582 5.91524 14.7335 6.32036C15.1089 6.72549 15.0848 7.35819 14.6796 7.73355L9 12.9958L3.32036 7.73355C2.91524 7.35819 2.8911 6.72549 3.26646 6.32036Z" fill="#93A3B5"/>
            </svg>
          </a>
        </div>
        <div class="border-b border-neutral-gray-100">
          <a href="javascript:;" id="scroll-top-result" data-testid="scroll-top-result" class="p-4 text-neutral-gray-600 flex items-center justify-between hover:opacity-70 transition cursor-pointer r:text-[16px] leading-[1.53] tracking-[0.25px]" @click="scrollToTop"> {{ viewsJa.home.faqs.go_to_top }}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
              id="scroll-top-result-svg"
            >
              <path id="scroll-top-result-path" fill-rule="evenodd" clip-rule="evenodd" d="M3.26646 11.6796C3.64182 12.0848 4.27452 12.1089 4.67965 11.7335L9 7.73066L13.3204 11.7335C13.7255 12.1089 14.3582 12.0848 14.7335 11.6796C15.1089 11.2745 15.0848 10.6418 14.6796 10.2665L9 5.00417L3.32036 10.2665C2.91524 10.6418 2.8911 11.2745 3.26646 11.6796Z" fill="#93A3B5"/>
            </svg>
          </a>
        </div>
      </div>
    </div>
    <div class="fixed inset-x-0 bottom-0 z-10 float-premium-fee">
      <div class="show-more-block border-b border-neutral-gray-200 pt-4 pb-2 relative bg-white shadow-[0_0_20px_0_rgba(0,0,0,0.15)]" :class="{'transition-all duration-200': !isLoad}" id="fee-box">
        <a id="display-fee-float" data-testid="display-fee-float" href="javascript:;" @click.prevent="displayFee" class="absolute bottom-[calc(100%_-1px)] left-0 flex items-center transition-all px-4 pt-2 rounded-tr-lg bg-white shadow-[0_-10px_20px_-8px_rgba(0,0,0,0.15)]">
          <svg id="display-fee-float-svg" class="transition-all duration-200" :class="isShow ? '-rotate-180': 'rotate-0'" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
            <rect id="display-fee-float-rect" width="18" height="18" fill="white"/>
            <path id="display-fee-float-path" fill-rule="evenodd" clip-rule="evenodd" d="M3.26646 11.6796C3.64182 12.0848 4.27452 12.1089 4.67965 11.7335L9 7.73066L13.3204 11.7335C13.7255 12.1089 14.3582 12.0848 14.7335 11.6796C15.1089 11.2745 15.0848 10.6418 14.6796 10.2665L9 5.00417L3.32036 10.2665C2.91524 10.6418 2.8911 11.2745 3.26646 11.6796Z" fill="#B3C0CE"/>
          </svg>
          <span id="display-fee-float-span" class="ml-1 r:text-[12px] leading-[1.53] r:tracking-[0.25px] text-neutral-gray-800">{{ isShow ? viewsJa.quotations.result.float_premium_fee.label.hide : viewsJa.quotations.result.float_premium_fee.label.show }}</span>
        </a>
        <div class="container-user-sm">
          <div v-for="(parent, key) in summaryData" :key="key" class="flex mb-2">
            <strong class="r:text-[12px] font-black leading-[1.53] r:tracking-[0.25px] text-neutral-gray-800">{{ viewsJa.quotations.result.float_premium_fee.summary[key].name }}</strong>
            <p class="flex flex-col flex-1 ml-2 text-neutral-gray-800">
              <span class="r:text-[12px] leading-[1.53] r:tracking-[0.25px]">{{ `${viewsJa.quotations.result.float_premium_fee.label.premium} ${numberWithCommas(parent.death_fee[`${form[`plan${toCapitalize(key)}`]}_fee`])}` }}円</span>
              <span class="r:text-[12px] leading-[1.53] r:tracking-[0.25px]">{{ `${viewsJa.quotations.result.float_premium_fee.label.contract_plan} ${viewsJa.quotations.result.float_premium_fee.plan[form[`plan${toCapitalize(key)}`]]}` }}</span>
              <span class="relative r:pl-[18px] r:text-[12px] leading-[1.53]">
                <span class="absolute top-2 left-2 r:w-[3px] r:h-[3px] rounded-full bg-neutral-gray-800"></span>
                <span class="leading-[1.53] r:tracking-[0.25px]">{{ `${viewsJa.quotations.result.float_premium_fee.label.lump_sum_payment} ${numberWithCommas(parent.death_insurance[`insurance_care_${form[`plan${toCapitalize(key)}`]}`])}${viewsJa.common.unit.ten_thousand_yen}` }}</span>
              </span>
              <span class="relative r:pl-[18px] r:text-[12px] leading-[1.53]">
                <span class="absolute top-2 left-2 r:w-[3px] r:h-[3px] rounded-full bg-neutral-gray-800"></span>
                <span class="leading-[1.53] r:tracking-[0.25px]">{{ `${viewsJa.quotations.result.float_premium_fee.label.accidental} ${numberWithCommas(parent.death_insurance[`insurance_pay_${form[`plan${toCapitalize(key)}`]}`])}${viewsJa.common.unit.ten_thousand_yen}` }}</span>
              </span>
            </p>
          </div>
        </div>
      </div>
      <div class="sticky-block py-4 bg-[linear-gradient(180deg,#ffffff_0%,#e3eaf1_100%);] relative z-[2]">
        <div class="container-user-sm">
          <div class="flex items-stretch">
            <div class="flex-1">
              <h4 class="r:text-[12px] font-bold r:tracking-[0.25px] leading-[1.33] text-neutral-gray-800 mb-1">{{ viewsJa.quotations.result.float_premium_fee.label.total_monthly_premium }}</h4>
              <span class="flex items-baseline justify-end mr-4 text-neutral-gray-800">
                <span class="r:text-[25px] leading-[0.93] r:tracking-[0.25px]">{{ numberWithCommas(totalPremiumFee) }}</span>
                <span class="r:text-[16px] leading-[0.93] r:tracking-[0.25px]">{{ viewsJa.common.unit.yen }}</span>
              </span>
            </div>
            <button id="submit-update-plan-float-btn" data-testid="submit-update-plan-float-btn" type="button" class="inline-flex items-center justify-center rounded-full btn-gradient btn-blue-gradient-horizontal r:text-[14px] py-2 px-2 xs:r:px-[18px] xs:py-4 font-medium text-white shadow-gray-1 leading-[1.33] r:min-h-[46px] r:tracking-[0.25px]" @click.prevent="submitUpdatePlan($event)">
              <span id="submit-update-plan-float-btn-span" class="ml-2" v-html="viewsJa.quotations.result.float_premium_fee.btn_submit_plan" />
              <svg id="submit-update-plan-float-btn-svg" xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19" fill="none" class="ml-2">
                <path id="submit-update-plan-float-btn-path" fill-rule="evenodd" clip-rule="evenodd" d="M6.82036 3.76646C7.22549 3.3911 7.85819 3.41524 8.23355 3.82036L13.4958 9.50001L8.23355 15.1796C7.85819 15.5848 7.22549 15.6089 6.82036 15.2335C6.41524 14.8582 6.3911 14.2255 6.76646 13.8204L10.7693 9.50001L6.76646 5.17965C6.3911 4.77452 6.41524 4.14182 6.82036 3.76646Z" fill="white"/>
              </svg>
            </button>
          </div>
        </div>
      </div>
    </div>
    <QuotationModal
      :careType="careType"
      :father="father"
      :mother="mother"
      :isFirstTime="isFirstTime"
      :isQuotationOpen="isQuotationOpen"
      :setQuotationOpen="setQuotationOpen"
      :contractStartDayStr="contractStartDayStr"
    />
  </div>
</template>

<script>
import { router } from '@inertiajs/vue3'
import { useVuelidate } from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import Layout from '@/Layouts/User.vue';
import RadioInput from '@/Shared/RadioInput.vue';
import Images from '@/Shared/Images.vue';
import QuotationModal from '@/Pages/Quotations/Components/QuotationModal.vue';
import DiscussionBox from '@/Pages/Quotations/Blocks/DiscussionBox.vue';
import ParentNursingCare from '@/Pages/Home/Blocks/ParentNursingCare.vue';
import AgePriceTable from '@/Pages/Home/Blocks/AgePriceTable.vue';
import Collapse from '@/Shared/Collapse.vue';
import HowToApply from '@/Pages/Home/Blocks/HowToApply.vue';
import Faqs from '@/Pages/Home/Blocks/Faqs.vue';
import { numberWithCommas, convertJaParams, toCapitalize } from '@/utils/utils';
import { useHead } from '@unhead/vue'
import { metaData } from '@/utils/utils';

export default {
  components: {
    RadioInput,
    Images,
    QuotationModal,
    DiscussionBox,
    ParentNursingCare,
    AgePriceTable,
    Collapse,
    HowToApply,
    Faqs,
  },
  layout: (h, page) => h(Layout, { footerClass: 'r:pb-[86px]' }, () => page),
  props: {
    summaryData: {
      type: Object
    },
    careType: {
      type: String
    },
    father: {
      type: Object
    },
    mother: {
      type: Object
    },
    isFirstTime: {
      type: Boolean,
    },
    contractStartDayStr: {
      type: String,
    },
    planMale: {
      type: String,
      default: 'standard'
    },
    planFemale: {
      type: String,
      default: 'standard'
    }
  },
  setup () {
    useHead(metaData('quotations_result'));
    return {
      v$: useVuelidate(),
    }
  },
  data() {
    return {
      viewsJa: this.jaConfig.viewsJa,
      numberWithCommas: numberWithCommas,
      convertJaParams: convertJaParams,
      toCapitalize: toCapitalize,
      plans: ['value', 'standard', 'premium'],
      isQuotationOpen: false,
      form: {
        planMale: this.planMale,
        planFemale: this.planFemale,
      },
      authenToken: document.querySelector('meta[name="csrf-token"]').content,
      sendMail: false,
      isShow: false,
      isLoad: true,
      totalPremiumFee: 0,
    }
  },
  mounted() {
    this.setHeightFeeBox()
    this.calcTotalFee(this.summaryData);
  },
  methods: {
    submitUpdatePlan (e) {
      e.preventDefault();
      if (this.v$.form.$invalid) {
        this.v$.form.$touch();
      }
      else {
        localStorage.clear();
        localStorage.setItem('userFormStep', 1);
        router.get(this.$routes.new_contract());
      }
    },
    setQuotationOpen (state) {
      this.isQuotationOpen = state;
    },
    setCareType(careType) {
      this.localCareType = careType || this.careType;
    },
    openModalByType(localCareType) {
      this.setQuotationOpen(true);
      this.setCareType(localCareType);
    },
    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    },
  },
  computed: {
    handleCloseTooltip () {
      return (tooltipInstance) => {
        const closeBtn = tooltipInstance.popper.querySelector('.btn-close-tooltip');
        closeBtn.addEventListener('click', () => {
          tooltipInstance.hide();
        });
      }
    },
    displayFee() {
      return () => {
        this.isShow = !this.isShow;
        this.isLoad = false;
        this.setHeightFeeBox()
      }
    },
    setHeightFeeBox() {
      return () => {
        const feeBox = document.getElementById('fee-box');
        const feeBoxHeight = feeBox.clientHeight;
        if (this.isShow) {
          feeBox.style.marginBottom = '0px';
        } else {
          feeBox.style.marginBottom = `-${feeBoxHeight}px`;
        }
      }
    },
    calcTotalFee() {
      return (data) => {
        this.totalPremiumFee = 0;
        for (let i in data) {
          const planName = `plan${toCapitalize(i)}`
          this.totalPremiumFee += data[i].death_fee[`${this.form[planName]}_fee`];
        }
      }
    }
  },
  watch: {
    planMale: {
      handler: function (val) {
        this.form.planMale = val;
      }
    },
    planFemale: {
      handler: function (val) {
        this.form.planFemale = val;
      }
    },
    summaryData: {
      handler: function (data) {
        this.calcTotalFee(data);
      }
    }
  },
  validations () {
    const form = {
      planMale: {},
      planFemale: {},
    };

    if (this.planMale) {
      form.planMale = { required };
    }

    if (this.planFemale) {
      form.planFemale = { required };
    }

    return {
      form: {...form}
    };
  },
  validationConfig: {
    $lazy: true,
  },
}
</script>
