<template>
  <div class="r:max-w-[672px] mx-auto confirmation">
    <Loading :loading="isLoading" />
    <!-- user-info -->
    <div class="r:max-w-[688px] mx-auto user-info-section">
      <h2 class="mb-4 px-6 r:text-[24px] leading-[1.53]">{{ viewsJa.contracts.blocks.confirmation.groups.user_info.group_title }}</h2>
      <div class="px-4 py-6 mb-10 bg-white border-b xs:px-6 xs:py-8 border-neutral-gray-100">
        <h4 class="mb-8 r:text-[18px] leading-[1.53] text-primary-600"> {{ viewsJa.contracts.blocks.confirmation.user_info.contractor_title }}</h4>
        <div class="mb-2 flex items-start r:text-[16px] leading-[1.53]">
          <span class="r:min-w-[130px]">{{ viewsJa.contracts.blocks.confirmation.label.full_name }}</span>
          <span class="flex-1 ml-6">{{ `${userData.last_name}  ${userData.first_name}` }}</span>
        </div>
        <div class="mb-2 flex items-start r:text-[16px] leading-[1.53]">
          <span class="r:min-w-[130px]">{{ viewsJa.contracts.blocks.confirmation.label.full_name_kana }}</span>
          <span class="flex-1 ml-6">{{ `${userData.last_name_kana}  ${userData.first_name_kana}` }}</span>
        </div>
        <div class="mb-2 flex items-start r:text-[16px] leading-[1.53]">
          <span class="r:min-w-[130px]">{{ viewsJa.contracts.blocks.confirmation.label.dob }}</span>
          <span class="flex-1 ml-6">{{ formatDate(this.userData.date_of_birth) }}</span>
        </div>
        <div class="mb-2 flex items-start r:text-[16px] leading-[1.53]">
          <span class="r:min-w-[130px]">{{ viewsJa.contracts.blocks.confirmation.label.gender }}</span>
          <span class="flex-1 ml-6">{{ convertGenderJP(userData.gender) }}</span>
        </div>
        <div class="mb-2 flex items-start r:text-[16px] leading-[1.53]">
          <span class="r:min-w-[130px]">{{ viewsJa.contracts.blocks.confirmation.label.email }}</span>
          <span class="flex-1 ml-6 break-all">{{ this.userData.register_email }}</span>
        </div>
        <!-- open-modal common info -->
        <div class="mt-6 text-right">
          <button type="button" data-testid="btn-common-info" id="btn-common-info" class="underline text-accent-500 hover:text-accent-600 transition leading-[2] r:text-[16px]" @click.prevent="handleModalState('common'); handleSetDataModal('common')">
            <span id="btn-common-info-span" class=""> {{ viewsJa.contracts.blocks.confirmation.edit_user }}</span>
          </button>
        </div>
        <hr class="my-8 mx-[-24px] border-tw-neutral-gray-100" />
        <h4 class="mb-8 r:text-[18px] leading-[1.53] text-primary-600"> {{ viewsJa.contracts.blocks.confirmation.user_info.shipping_title }}</h4>
        <div class="mb-2 flex items-start r:text-[16px] leading-[1.53]">
          <span class="r:min-w-[130px]">{{ viewsJa.contracts.blocks.confirmation.label.postal_code }}</span>
          <span class="flex-1 ml-6">{{ this.userData.postal_code }}</span>
        </div>
        <div class="mb-2 flex items-start r:text-[16px] leading-[1.53]">
          <span class="r:min-w-[130px]">{{ viewsJa.contracts.blocks.confirmation.label.address }}</span>
          <span class="flex-1 ml-6">{{ `${userData.prefecture_name}${userData.municipality}${userData.town}${userData.detail_address}` }}<br>{{ userData.address }}</span>
        </div>
        <div class="mb-2 flex items-start r:text-[16px] leading-[1.53]">
          <span class="r:min-w-[130px]">{{ viewsJa.contracts.blocks.confirmation.label.phone }}</span>
          <span class="flex-1 ml-6">{{ setMask(userData.phone, [3, 4, 4], '-') }}</span>
        </div>
        <!-- open-modal address -->
        <div class="mt-6 text-right">
          <button type="button" data-testid="btn-address-info" id="btn-address-info" class="underline text-accent-500 hover:text-accent-600 transition leading-[2] r:text-[16px]" @click.prevent="handleModalState('location'); handleSetDataModal('location')">
            <span id="btn-address-info-span"> {{ viewsJa.contracts.blocks.confirmation.edit_user }}</span>
          </button>
        </div>
      </div>
    </div>
    <!-- parent-info -->
    <div class="r:max-w-[688px] mx-auto parent-info-section mb-8">
      <h2 class="mb-4 px-4 xs:px-6 r:text-[24px] leading-[1.53]" v-html=" viewsJa.contracts.blocks.confirmation.groups.parent_info.group_title"></h2>
      <div class="px-4 py-6 bg-white border-b xs:px-6 xs:py-8 parent_info border-neutral-gray-100">
        <template v-for="(parent, index) in userData.relatives_attributes" :key="index">
          <div class="relative-details" v-if="!ignoreParent[parent.gender]">
            <span class="block mb-8 text-primary-600" v-if="parent.gender=='male'"> {{ viewsJa.contracts.blocks.confirmation.father }}</span>
            <span class="block mb-8 text-primary-600" v-if="parent.gender=='female'"> {{ viewsJa.contracts.blocks.confirmation.mother }}</span>
            <div class="mb-2 flex items-start r:text-[16px] leading-[1.53]">
              <span class="r:min-w-[130px]">{{ viewsJa.contracts.blocks.confirmation.label.full_name }}</span>
              <span class="flex-1 ml-6">{{ `${parent.last_name}  ${parent.first_name}` }}</span>
            </div>
            <div class="mb-2 flex items-start r:text-[16px] leading-[1.53]">
              <span class="r:min-w-[130px]">{{ viewsJa.contracts.blocks.confirmation.label.full_name_kana }}</span>
              <span class="flex-1 ml-6">{{ `${parent.last_name_kana}  ${parent.first_name_kana}` }}</span>
            </div>
            <div class="mb-2 flex items-start r:text-[16px] leading-[1.53]">
              <span class="r:min-w-[130px]">{{ viewsJa.contracts.blocks.confirmation.label.dob }}</span>
              <span class="flex-1 ml-6">{{ parent.dob }}</span>
            </div>
            <div class="mb-2 flex items-start r:text-[16px] leading-[1.53]">
              <span class="r:min-w-[130px]">{{ viewsJa.contracts.blocks.confirmation.label.age }}</span>
              <span class="flex-1 ml-6">{{ parent.age }}</span>
            </div>
            <div class="mb-2 flex items-start r:text-[16px] leading-[1.53]">
              <span class="r:min-w-[130px]">{{ viewsJa.contracts.blocks.confirmation.label.gender }}</span>
              <span class="flex-1 ml-6">{{ convertGenderJP(parent.gender)}}</span>
            </div>
            <!-- open-modal father -->
            <div v-if="parent.gender=='male'" class="mt-6 text-right">
              <button type="button" data-testid="btn-male-info" id="btn-male-info" class="underline text-accent-500 hover:text-accent-600 transition leading-[2] r:text-[16px]" @click.prevent="handleModalState('relativeMale'); handleSetDataModal('relativeMale')">
                <span id="btn-male-info-span"> {{ viewsJa.contracts.blocks.confirmation.edit_parent }}</span>
              </button>
            </div>
            <!-- open-modal mother -->
            <div v-if="parent.gender=='female'" class="mt-6 text-right">
              <button type="button" data-testid="btn-female-info" id="btn-female-info" class="underline text-accent-500 hover:text-accent-600 transition leading-[2] r:text-[16px]" @click.prevent="handleModalState('relativeFemale'); handleSetDataModal('relativeFemale')">
                <span id="btn-female-info-span"> {{ viewsJa.contracts.blocks.confirmation.edit_parent }}</span>
              </button>
            </div>
          </div>
          <hr v-if="!ignoreParent[parent.gender]" class="my-8 mx-[-24px] border-neutral-gray-100 last:border-none last:my-0"/>
        </template>
      </div>
    </div>
    <!-- application-remind -->
    <div class="application-remind r:max-w-[672px] mx-auto mb-16">
      <div class="px-4 pt-6 xs:px-6 xs:pt-8">
        <div class="px-4 py-4 border rounded-lg border-neutral-gray-200 bg-neutral-gray-50 xs:px-6 xs:pb-6">
          <svg class="mb-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M11.9997 22.6663C17.8907 22.6663 22.6663 17.8907 22.6663 11.9997C22.6663 6.10864 17.8907 1.33301 11.9997 1.33301C6.10864 1.33301 1.33301 6.10864 1.33301 11.9997C1.33301 17.8907 6.10864 22.6663 11.9997 22.6663ZM13.6663 6.99967C13.6663 7.92015 12.9201 8.66634 11.9997 8.66634C11.0792 8.66634 10.333 7.92015 10.333 6.99967C10.333 6.0792 11.0792 5.33301 11.9997 5.33301C12.9201 5.33301 13.6663 6.0792 13.6663 6.99967ZM10.6663 11.9997C10.6663 11.2633 11.2633 10.6663 11.9997 10.6663C12.7361 10.6663 13.333 11.2633 13.333 11.9997V17.333C13.333 18.0694 12.7361 18.6663 11.9997 18.6663C11.2633 18.6663 10.6663 18.0694 10.6663 17.333V11.9997Z" fill="#93A3B5"/>
          </svg>
          <h4 class="r:text-[14px] font-bold leading-[1.53] r:tracking-[0.25px] mb-1">{{ viewsJa.contracts.blocks.customer_information.remind_box.title }}</h4>
          <p class="r:text-[14px] leading-[1.53] r:trackin-[0.25px] mb-4">{{ viewsJa.contracts.blocks.customer_information.remind_box.desc }}</p>
          <a id="open-quotation-modal" class="r:text-[14px] text-tw-blue-500 hover:opacity-70 transition r:tracking-[0.25px] leading-[1.57] underline cursor-pointer" @click.prevent="setQuotationOpen(true)">{{ viewsJa.contracts.blocks.customer_information.remind_box.back_to_quote }}</a>
          <QuotationModal
            :careType="careType"
            :father="father"
            :mother="mother"
            :isFirstTime="isFirstTime"
            :isQuotationOpen="isQuotationOpen"
            :setQuotationOpen="setQuotationOpen"
            :contractStartDayStr = "contractStartDayStr"
          />
        </div>
      </div>
    </div>
    <!-- insurance_plans -->
    <div class="r:max-w-[688px] mx-auto insurance-plan-section mb-16">
      <h2 class="mb-4 px-4 xs:px-6 r:text-[24px] leading-[1.53]">{{ viewsJa.contracts.blocks.confirmation.groups.insurance_info.group_title }}</h2>
      <div class="px-4 py-6 bg-white xs:px-6 xs:py-8 insurance-plan-infos">
        <div class="death-insurance">
          <h4 class="mb-8 r:text-[18px] leading-[1.53] text-primary-600">{{ viewsJa.contracts.blocks.confirmation.insurance_apply.plan_title }}</h4>
            <div class="common-info entry">
              <div class="mb-4">
                <span class="block mb-1 r:text-[14px] leading-[1.53]">{{ viewsJa.contracts.blocks.confirmation.insurance_apply.insurance_person }}</span>
                <span class="mb-1 font-bold r:text-[16px] leading-[1.53]">
                  <span>{{ viewsJa.contracts.blocks.confirmation.insurance_apply.policy.holder_prefix }}</span>
                  <span>
                    <span>{{ ` ${userData.last_name}  ${userData.first_name} ` }}</span>
                    <template v-for="(relative, key) in userData.relatives_attributes" :key="key">
                      <template v-if="key > 0 && !ignoreParent.male && !ignoreParent.female">
                        <span>{{ viewsJa.contracts.blocks.confirmation.insurance_apply.policy.name.both }}</span>
                      </template>
                      <template v-else-if="ignoreParent.male || ignoreParent.female">
                        <span v-if="!ignoreParent[relative.gender]">{{ viewsJa.contracts.blocks.confirmation.insurance_apply.policy.name[relative.gender] }}</span>
                      </template>
                      <template v-else-if="!ignoreParent.male && !ignoreParent.female && Object.keys(userData.relatives_attributes).length != 2">
                        <span>{{ viewsJa.contracts.blocks.confirmation.insurance_apply.policy.name[relative.gender] }}</span>
                      </template>
                    </template>
                  </span>
                </span>
              </div>
              <div class="mb-4">
                <span class="block mb-1 r:text-[14px] leading-[1.53]">{{ viewsJa.contracts.blocks.confirmation.insurance_apply.start_date }}</span>
                <span class="block mb-1 font-bold r:text-[16px] leading-[1.53]">{{ contractStartDay}}16時</span>
              </div>
              <div class="mb-4">
                <span class="block mb-1 r:text-[14px] leading-[1.53]">{{ viewsJa.contracts.blocks.confirmation.insurance_apply.first_year_period }}</span>
                <span class="block font-bold r:text-[16px] leading-[1.53]">{{ contractStartDay}} {{ viewsJa.contracts.blocks.confirmation.default_hour }} - {{ contractEndDay}} {{ viewsJa.contracts.blocks.confirmation.default_hour }}</span>
              </div>
            </div>
            <div class="pt-4 each-plans">
              <div class="px-6 pt-4 mb-4 text-center border rounded-lg xs:px-8 bg-tw-black-100">
                <h5 class="text-tw-black-500 r:text-[16px] font-bold mb-4">{{ viewsJa.contracts.blocks.confirmation.insurance_apply.monthly_premium_title }}</h5>
              </div>
              <template v-for="({gender}, _key) in userData.relatives_attributes" :key="_key">
                <template v-if="!quotationEdit[gender].ignore_value">
                  <div class="r:text-[16px] leading-[1.53]">
                    <span :data-relative-gender="gender" class="mb-2 r:text-[16px] font-bold block">{{ viewsJa.contracts.blocks.confirmation.insurance_apply.policy.each_plan[gender] }}</span>
                    <div class="mb-2 flex justify-between items-center r:text-[16px] leading-[1.53]">
                      <span class="">{{ viewsJa.contracts.blocks.confirmation.insurance_apply.insurance_fee.plan }}</span>
                      <span class="values">
                        <span>{{ viewsJa.contracts.blocks.confirmation.insurance_apply.insurance_fee.plan_name[quotationEdit[gender].plan] }}</span>
                      </span>
                    </div>
                    <div class="mb-2 flex justify-between items-center r:text-[16px] leading-[1.53]">
                      <span class="">{{ viewsJa.contracts.blocks.confirmation.insurance_apply.insurance_fee.nursing_care }}</span>
                      <span class="values">
                        <span>{{ numberWithCommas(quotationEdit[gender].care_insurance) }}</span>
                        <span>{{ viewsJa.common.unit.ten_thousand_yen }}</span>
                      </span>
                    </div>
                    <div class="mb-2 flex justify-between items-center r:text-[16px] leading-[1.53]">
                      <span class="">{{ viewsJa.contracts.blocks.confirmation.insurance_apply.insurance_fee.accidental_death }}</span>
                      <span class="values">
                        <span>{{ numberWithCommas(quotationEdit[gender].death_insurance) }}</span>
                        <span>{{ viewsJa.common.unit.ten_thousand_yen }}</span>
                      </span>
                    </div>
                    <div class="mb-2 flex justify-between items-center r:text-[16px] leading-[1.53]">
                      <span class="">{{ viewsJa.contracts.blocks.confirmation.insurance_apply.insurance_fee.start_age }}</span>
                      <span class="values">{{ quotationEdit[gender].age }}</span>
                    </div>
                    <div class="mb-2 flex justify-between items-center r:text-[16px] leading-[1.53]">
                      <span class="">{{ viewsJa.contracts.blocks.confirmation.insurance_apply.insurance_fee.monthly_premium }}</span>
                      <span class="values">
                        <span>{{ numberWithCommas(quotationEdit[gender].total_fee) }}</span>
                        <span>{{ viewsJa.common.unit.yen }}</span>
                      </span>
                    </div>
                  </div>
                  <hr v-if="Object.keys(userData.relatives_attributes).length > 1" class="my-8 border-tw-neutral-gray-100 last:hidden" />
                </template>
              </template>
            </div>
        </div>
      </div>
    </div>
    <!-- announcement -->
    <div class="r:max-w-[688px] mx-auto announcement-section mb-16">
      <h2 class="r:text-[24px] px-4 xs:px-6 pb-4">{{ viewsJa.contracts.blocks.confirmation.groups.announcement_info.group_title }}</h2>
      <div class="px-4 py-6 bg-white xs:px-6 xs:py-8 mb-7 announcement-infos">
        <div class="entry">
          <h4 class="r:text-[18px] text-primary-600 leading-[1.53] mb-8"> {{ viewsJa.contracts.blocks.confirmation.announcement_info.entry_person_title }}</h4>
          <span class="inline-block pr-4 r:text-[16px]"> {{ viewsJa.contracts.blocks.confirmation.announcement_info.entry_desc }}</span>
          <span class="r:text-[16px]"> {{ `${userData.last_name}  ${userData.first_name}${viewsJa.contracts.blocks.confirmation.announcement_info.suffix}` }} </span>
        </div>
        <hr class="border-tw-neutral-gray-100 my-8 mx-[-24px]" />
        <div class="pb-2 health-condition">
          <h4 class="r:text-[18px] text-primary-600 leading-[1.53] mb-8"> {{ viewsJa.contracts.blocks.confirmation.announcement_info.inform }}</h4>
          <div class="px-8 pt-4 mb-4 text-center border rounded-lg bg-tw-black-100">
            <h5 class="text-tw-black-500 r:text-[16px] font-bold mb-4">{{ viewsJa.contracts.blocks.confirmation.announcement_info.health_condition.title }}</h5>
          </div>
          <span class="block mb-2" v-for="(info, idx) in viewsJa.contracts.blocks.confirmation.announcement_info.health_condition.infos" :key="idx">
            <span class="flex r:text-[16px] leading-[1.53]" v-html="info"></span>
          </span>
        </div>
        <div class="pt-4 announcement-regarding">
          <div class="px-8 pt-4 mb-4 text-center border rounded-lg bg-tw-black-100">
            <h5 class="text-tw-black-500 r:text-[16px] font-bold mb-4">{{ viewsJa.contracts.blocks.confirmation.announcement_info.regarding.title }}</h5>
          </div>
          <span class="r:text-[16px] leading-[1.53]">{{ viewsJa.contracts.blocks.confirmation.announcement_info.regarding.desc }}</span>
        </div>
        <hr class="border-tw-neutral-gray-100 my-8 mx-[-24px]" />
        <h4 class="r:text-[18px] text-primary-600 leading-[1.53] mb-8"> {{ viewsJa.contracts.blocks.confirmation.announcement_info.compensation.title }}</h4>
        <div v-for="(parent, index) in userData.relatives_attributes" :key="index"  class="mb-2 flex items-center r:text-[16px] leading-[1.53]">
          <template v-if="!ignoreParent[parent.gender]">
            <span class="r:min-w-[130px]">{{ `${parent.last_name}  ${parent.first_name}` }}さま</span>
            <span class="flex-1 ml-6">{{ viewsJa.contracts.blocks.confirmation.announcement_info.compensation.all_no_desc }}</span>
          </template>
        </div>
      </div>
    </div>
    <!-- plans-price -->
    <div class="r:max-w-[688px] mx-auto plans-price-section mb-16">
      <h2 class="r:text-[24px] xs:px-6 px-4 pb-4">{{ viewsJa.contracts.blocks.confirmation.groups.plan_price_info.group_title }}</h2>
      <div class="px-4 py-6 bg-white plans-price-infos xs:px-6 xs:py-8">
        <span class="r:text-[16px] text-primary-600 leading-[1.53] mb-4 block">{{ viewsJa.contracts.blocks.confirmation.plan_price.total }}</span>
        <div class="each-plan">
          <div class="r:text-[16px] leading-[1.53]">
            <span class="flex items-center justify-between mb-2 last:mb-4" v-for="({gender}, _key) in userData.relatives_attributes" :key="_key" >
              <template v-if="!quotationEdit[gender].ignore_value">
                <span :data-relative-gender="gender" class="r:text-[16px] block">{{ viewsJa.contracts.blocks.confirmation.plan_price.each_plan[gender] }}</span>
                <span class="values">
                  <span>{{ numberWithCommas(quotationEdit[gender].total_fee) }} </span>
                  <span>{{ viewsJa.common.unit.yen_per_month }}</span>
                </span>
              </template>
            </span>
            <hr class="my-4 border-tw-neutral-gray-100"/>
            <span class="flex items-center justify-between">
              <span class="r:text-[16px] leading-[1.53] font-bold">{{ viewsJa.contracts.blocks.confirmation.plan_price.total_value }}</span>
              <span class="r:text-[16px] leading-[1.53] font-bold">
                <span>{{ numberWithCommas(totalQuotationFee) }}</span>
                <span>{{ viewsJa.common.unit.yen_per_month }}</span>
              </span>
            </span>
            <hr class="my-8 mx-[-24px] border-tw-neutral-gray-100"/>
          </div>
        </div>
        <div class="r:text-[16px] pb-4  payment-method">
          <h4 class="mb-4 r:text-[18px] leading-[1.53] text-primary-600">{{ viewsJa.contracts.blocks.confirmation.plan_price.payment_method }}</h4>
          <span class="r:text-[16px] leading-[1.53]">{{ viewsJa.contracts.blocks.confirmation.plan_price.by_credit_card }}</span>
        </div>
      </div>
    </div>
    <!-- other checklist -->
    <div class="r:max-w-[688px] mx-auto checklist-section mb-16">
      <h2 class="mb-4 xs:px-6 px-4 r:text-[24px] leading-[1.53]">{{ viewsJa.contracts.blocks.confirmation.groups.checklist_info.group_title }}</h2>
      <div class="bg-white xs:px-6 xs:pt-8 xs:pb-4 checklist-infos">
        <span class="block mb-2">
          <span class="r:text-[14px] leading-[1.53]"> {{ viewsJa.contracts.blocks.confirmation.final_intentions }}</span>
          <span class="r:text-[10px] text-tw-red-500 leading-[1] bg-tw-red-50 rounded-sm ml-1 px-1 pt-[calc(5/16)rem] pb-[calc(3/16)rem]"> {{ viewsJa.common.require_field }}</span>
        </span>
        <span class="block mb-2 r:text-[12px] leading-[1.53] text-neutral-gray-500"> {{ viewsJa.contracts.blocks.confirmation.intro_sending }}</span>
        <div class="pb-4 rounded-lg item-center">
          <checkbox-input
            v-model="check_agree"
            id="user_check_agree"
            name="user[check_agree]"
            :label="viewsJa.contracts.blocks.confirmation.note_confirmation"
            class="check-bar-primary"
            checkboxValue="true"
            checkboxClass="r:text-[16px] leading-[1.53] r:tracking-[0.64px]"
            @change="setOtherFlag();"
          >
          </checkbox-input>
        </div>
        <div v-if="!magazineFlag" class="pb-4 rounded-lg item-center">
          <span class="block mb-2 r:text-[14px] leading-[1.53]"> {{ viewsJa.contracts.blocks.confirmation.magazine_title }}</span>
          <checkbox-input
            v-model="accept_mail_magazine_flag"
            id="user_accept_mail_magazine_flag"
            name="user[accept_mail_magazine_flag]"
            :label="viewsJa.contracts.blocks.confirmation.note_magazine"
            class="check-bar-primary"
            checkboxValue="true"
            checkboxClass="r:text-[16px] leading-[1.53] r:tracking-[0.64px]"
            @change="handleMagazineFlag(); setOtherFlag();"
          >
          </checkbox-input>
        </div>
      </div>
    </div>
    <div class="group-button r:max-w-[672px] mx-auto">
      <div class="flex flex-col items-center px-4 pb-6 xs:px-6">
        <form class="form-contract" id="form-contract" method="post">
          <input type="hidden" name="authenticity_token" :value="authenToken" autocomplete="off">
        </form>
        <span v-if="!check_agree && !cantSubmitForm.common && !cantSubmitForm.location && !cantSubmitForm.relativeMale && !cantSubmitForm.relativeFemale" class="text-tw-red-500 r:text-[14px] leading-[1.53] r:tracking-[0.25px] mb-2">{{ viewsJa.contracts.blocks.customer_information.not_complete_form }}</span>
        <button type="button" data-testid="submit-application-form-step-4" id="submit-application-form-step-1" :disabled="check_agree && !cantSubmitForm.common && !cantSubmitForm.location && !cantSubmitForm.relativeMale && !cantSubmitForm.relativeFemale ? false : true" class="mb-6 inline-flex items-center justify-center rounded-full btn-gradient btn-primary-gradient-horizontal r:text-[14px] xs:r:text-[16px] py-2 px-2 xs:r:px-[18px] xs:py-4 font-medium text-white shadow-gray-1 leading-[1.33] r:min-h-[53px]" @click.prevent="submitContract($event, false)">
          <span id="submit-application-form-step-4-span" class="mr-2">{{ viewsJa.contracts.blocks.confirmation.btn.submit }}</span>
          <svg id="submit-application-form-step-4-svg" xmlns="http://www.w3.org/2000/svg" width="18" height="19" viewBox="0 0 18 19" fill="none">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M6.32036 3.76646C6.72549 3.3911 7.35819 3.41524 7.73355 3.82036L12.9958 9.50001L7.73355 15.1796C7.35819 15.5848 6.72549 15.6089 6.32036 15.2335C5.91524 14.8582 5.8911 14.2255 6.26646 13.8204L10.2693 9.50001L6.26646 5.17965C5.8911 4.77452 5.91524 4.14182 6.32036 3.76646Z" :fill="check_agree ? '#FFFFFF' : '#D3DDE8'"/>
          </svg>
        </button>
        <button type="button" data-testid="back-form-step-4" id="back-form-step-4" class="btn-solid inline-flex items-center justify-center rounded-full btn-gradient r:text-[14px] xs:r:text-[16px] py-2 px-2 xs:px-4 xs:py-4 text-white bg-neutral-gray-400 leading-[1.33] r:min-h-[53px] r:tracking-[0.25px] shadow-gray-1" @click.prevent="setStep(pageStep - 1)">
          <svg id="back-form-step-4-svg" xmlns="http://www.w3.org/2000/svg" width="18" height="19" viewBox="0 0 18 19" fill="none">
            <path id="back-form-step-4-path" fill-rule="evenodd" clip-rule="evenodd" d="M11.6796 15.2335C11.2745 15.6089 10.6418 15.5848 10.2665 15.1796L5.00417 9.49999L10.2665 3.82035C10.6418 3.41523 11.2745 3.3911 11.6796 3.76645C12.0848 4.14181 12.1089 4.77451 11.7335 5.17964L7.73066 9.49999L11.7335 13.8204C12.1089 14.2255 12.0848 14.8582 11.6796 15.2335Z" fill="white"/>
          </svg>
          <span id="back-form-step-4-span" class="ml-2">{{ viewsJa.contracts.blocks.confirmation.btn.prev }}</span>
        </button>
      </div>
    </div>
    <!-- button temp -->
    <div class="button-temp r:max-w-[672px] mx-auto">
      <div class="px-4 py-6 save-temporary bg-neutral-gray-700 xs:px-6 xs:py-8">
        <div class="mb-4 text-center">
          <button type="button" data-testid="submit-application-form-temp" id="submit-application-form-temp"  class="btn-solid mb-6 inline-flex items-center justify-center rounded-full bg-white text-primary-600 r:text-[16px] xs:r:text-[16px] p-2 xs:p-4 xs:pb-3.5 font-bold shadow-gray-1 r:tracking-[0.25px] leading-[1.33] r:min-h-[53px]" @click.prevent="submitContract($event, true)">
            <span class="r:min-w-[122px]" id="submit-application-form-temp-span">{{ viewsJa.contracts.blocks.confirmation.btn.temporary_save }}</span>
            <svg id="submit-application-form-temp-svg" class="arrow right-4 mt-0.5" width="18" height="18" viewBox="0 0 9 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path id="submit-application-form-temp-path" d="M1 0.998535L4 4.00027L7 7.002L4 9.99978L1 13.0015" stroke="#E2750F" stroke-width="2"/>
            </svg>
          </button>
        </div>
        <span class="flex justify-center r:text-[12px] leading-[1.53] text-white">{{ viewsJa.contracts.blocks.confirmation.bottom_note }}</span>
      </div>
    </div>
    <!-- father info modal -->
    <modal
      :open="modalState['relativeMale']"
      @close="modalState['relativeMale'] = false"
      modalDialogCls="sm:r:max-w-[720px]"
    >
      <template #modalHeader>
        <div class="px-4 py-2 border-b border-neutral-gray-100 bg-neutral-gray-50 modal-header">
          <button type="button" class="flex w-12 h-12 p-3 ml-auto group hover:opacity-70" title="Close" style="margin-left: auto;" @click="modalState['relativeMale'] = false">
            <svg class="group-hover:opacity-70" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path d="M6.27614 4.39052C5.75544 3.86983 4.91122 3.86983 4.39052 4.39052C3.86983 4.91122 3.86983 5.75544 4.39052 6.27614L10.1144 12L4.3906 17.7239C3.8699 18.2446 3.8699 19.0888 4.3906 19.6095C4.9113 20.1302 5.75552 20.1302 6.27622 19.6095L12 13.8856L17.7239 19.6095C18.2446 20.1302 19.0888 20.1302 19.6095 19.6095C20.1302 19.0888 20.1302 18.2446 19.6095 17.7239L13.8856 12L19.6095 6.27614C20.1302 5.75544 20.1302 4.91122 19.6095 4.39052C19.0888 3.86982 18.2446 3.86983 17.7239 4.39053L12 10.1144L6.27614 4.39052Z" fill="#93A3B5"/>
            </svg>
          </button>
          <h2 class="r:text-[24px] leading-[1.53] r:tracking-[0.25px]" v-html="viewsJa.contracts.blocks.confirmation.modal.male_relative.title"></h2>
        </div>
      </template>
      <template #modalBody>
        <div class="flex-1 px-4 py-8 mb-5 overflow-y-auto xs:py-6 modal-body">
          <h3 class="mb-8 r:text-[18px] leading-[1.53] r:tracking-[0.25px] text-primary-600">{{ viewsJa.contracts.blocks.confirmation.modal.male_relative.hight_light }}</h3>
          <form class="form-father" method="post">
            <!-- full name father -->
            <div class="mb-4 form-group">
              <label class="flex items-center mb-2">
                <span class="r:text-[16px] leading-[1.53] r:tracking-[0.25px]">{{ modelsJa.activerecord.attributes.contracts.full_name_father }}</span>
                <span class="r:text-[10px] r:tracking-[0.25px] leading-[1.53] text-tw-red-500 bg-tw-red-50 rounded px-1 mt-1 ml-2">{{ viewsJa.contracts.required }}</span>
                <br>
              </label>
              <div class="flex -mx-0.5">
                <div class="w-1/2 px-0.5">
                  <text-input
                    v-model="userUpdate.relativeMale.last_name"
                    class="w-full"
                    id="user_relative_attributes_0_last_name"
                    name="user[relative_attributes][0][last_name]"
                    :placeholder="viewsJa.contracts.blocks.customer_information.placeholders.last_name_father"
                    data-group-length="full-name-father"
                    :customClass="`form-element px-2 r:py-[15px] xs:r:px-[15px] r:min-h-[56px] ${v$.userUpdate.relativeMale.last_name.$error ? 'form-invalid' : ''} ${v$.userUpdate.relativeMale.last_name.$dirty ? 'form-dirty' : ''}`"
                    @change="handleSetTouch('last_name', 'relativeMale'); setTouchParallel('last_name_kana', 'last_name', 'relativeMale')"
                    @blur="handleCheckLength('father')"
                    @input="setKana($event, 'last_name', 'last_name_kana', 'relativeMale');"
                  />
                </div>
                <div class="w-1/2 px-0.5">
                  <text-input
                    v-model="userUpdate.relativeMale.first_name"
                    class="w-full"
                    id="user_relative_attributes_0_first_name"
                    name="user[relative_attributes][0][first_name]"
                    :placeholder="viewsJa.contracts.blocks.customer_information.placeholders.first_name_father"
                    data-group-length="full-name-father"
                    :customClass="`form-element px-2 r:py-[15px] xs:r:px-[15px] r:min-h-[56px] ${v$.userUpdate.relativeMale.first_name.$error ? 'form-invalid' : ''} ${v$.userUpdate.relativeMale.first_name.$dirty ? 'form-dirty' : ''}`"
                    @change="handleSetTouch('first_name', 'relativeMale'); setTouchParallel('first_name_kana', 'first_name', 'relativeMale')"
                    @blur="handleCheckLength('father')"
                    @input="setKana($event, 'first_name', 'first_name_kana', 'relativeMale');"
                  />
                </div>
              </div>
              <label class="mt-2 flex text-tw-red-600 r:text-[14px] leading-[1]" for="last_name" v-if="v$.userUpdate.relativeMale.last_name.$error || v$.userUpdate.relativeMale.first_name.$error || fieldLengthErr.fullNameFather">
                <span class="flex-1 mt-0.5">{{ viewsJa.contracts.blocks.customer_information.errors.full_name_father }}</span>
              </label>
            </div>
            <!-- full name kana father -->
            <div class="mb-4 form-group">
              <label class="flex items-center mb-2">
                <span class="r:text-[16px] leading-[1.53] r:tracking-[0.25px]">{{ modelsJa.activerecord.attributes.contracts.full_name_kana_father }}</span>
                <span class="r:text-[10px] r:tracking-[0.25px] leading-[1.53] text-tw-red-500 bg-tw-red-50 rounded px-1 mt-1 ml-2">{{ viewsJa.contracts.required }}</span>
              </label>
              <div class="flex -mx-0.5">
                <div class="w-1/2 px-0.5">
                  <text-input
                    v-model="userUpdate.relativeMale.last_name_kana"
                    class="w-full"
                    id="user_relative_attributes_0_last_name_kana"
                    name="user[relative_attributes][0][last_name_kana]"
                    :placeholder="viewsJa.contracts.blocks.customer_information.placeholders.last_name_kana_father"
                    data-group-length="full-name-kana-father"
                    :customClass="`form-element px-2 r:py-[15px] xs:r:px-[15px] r:min-h-[56px] ${v$.userUpdate.relativeMale.last_name_kana.$error || fieldLengthErr.fullNameFatherKana ? 'form-invalid' : ''} ${v$.userUpdate.relativeMale.last_name_kana.$dirty ? 'form-dirty' : ''}`"
                    @change="handleSetTouch('last_name_kana', 'relativeMale')"
                    @blur="handleCheckLength('father-kana')"
                  />
                </div>
                <div class="w-1/2 px-0.5">
                  <text-input
                    v-model="userUpdate.relativeMale.first_name_kana"
                    class="w-full"
                    id="user_relative_attributes_0_first_name_kana"
                    name="user[relative_attributes][0][first_name_kana]"
                    :placeholder="viewsJa.contracts.blocks.customer_information.placeholders.first_name_kana_father"
                    data-group-length="full-name-kana-father"
                    :customClass="`form-element px-2 r:py-[15px] xs:r:px-[15px] r:min-h-[56px] ${v$.userUpdate.relativeMale.first_name_kana.$error || fieldLengthErr.fullNameFatherKana ? 'form-invalid' : ''} ${v$.userUpdate.relativeMale.first_name_kana.$dirty ? 'form-dirty' : ''}`"
                    @change="handleSetTouch('first_name_kana', 'relativeMale')"
                    @blur="handleCheckLength('father-kana')"
                  />
                </div>
              </div>
              <label class="mt-2 flex text-tw-red-600 r:text-[14px] leading-[1]" for="last_name" v-if="v$.userUpdate.relativeMale.last_name_kana.$error || v$.userUpdate.relativeMale.first_name_kana.$error || fieldLengthErr.fullNameFatherKana">
                <span class="flex-1 mt-0.5">{{ viewsJa.contracts.blocks.customer_information.errors.full_name_kana_father }}</span>
              </label>
            </div>
          </form>
        </div>
      </template>
      <template #modalFooter>
        <div class="pt-6 text-center border-t border-neutral-gray-100 bg-neutral-gray-50">
          <button type="button" data-testid="btn-submit-male" id="btn-submit-male" :disabled="cantSubmitForm.relativeMale" class="inline-flex items-center justify-center rounded-full btn-gradient btn-primary-gradient-horizontal r:text-[14px] xs:r:text-[16px] py-2 px-2 xs:r:px-[18px] xs:py-4 font-medium text-white shadow-gray-1 leading-[1.33] r:min-h-[53px] mb-6" @click.prevent="updateFormData('relativeMale')">
            <span id="btn-submit-male-span" class="text-white">{{ viewsJa.contracts.blocks.confirmation.modal.btn_submit }}</span>
          </button>
        </div>
      </template>
    </modal>
    <!-- mother modal -->
    <modal
      :open="modalState['relativeFemale']"
      @close="modalState['relativeFemale'] = false"
      modalDialogCls="sm:r:max-w-[720px]"
    >
      <template #modalHeader>
        <div class="px-4 py-2 border-b border-neutral-gray-100 bg-neutral-gray-50 modal-header">
          <button type="button" class="flex w-12 h-12 p-3 ml-auto group hover:opacity-70" title="Close" style="margin-left: auto;" @click="modalState['relativeFemale'] = false">
            <svg class="group-hover:opacity-70" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path d="M6.27614 4.39052C5.75544 3.86983 4.91122 3.86983 4.39052 4.39052C3.86983 4.91122 3.86983 5.75544 4.39052 6.27614L10.1144 12L4.3906 17.7239C3.8699 18.2446 3.8699 19.0888 4.3906 19.6095C4.9113 20.1302 5.75552 20.1302 6.27622 19.6095L12 13.8856L17.7239 19.6095C18.2446 20.1302 19.0888 20.1302 19.6095 19.6095C20.1302 19.0888 20.1302 18.2446 19.6095 17.7239L13.8856 12L19.6095 6.27614C20.1302 5.75544 20.1302 4.91122 19.6095 4.39052C19.0888 3.86982 18.2446 3.86983 17.7239 4.39053L12 10.1144L6.27614 4.39052Z" fill="#93A3B5"/>
            </svg>
          </button>
          <h2 class="r:text-[24px] leading-[1.53] r:tracking-[0.25px]" v-html="viewsJa.contracts.blocks.confirmation.modal.female_relative.title"></h2>
        </div>
      </template>
      <template #modalBody>
        <div class="flex-1 px-4 py-8 mb-5 overflow-y-auto xs:py-6 modal-body">
          <h3 class="mb-8 r:text-[18px] leading-[1.53] r:tracking-[0.25px] text-primary-600">{{ viewsJa.contracts.blocks.confirmation.modal.female_relative.hight_light }}</h3>
          <form class="form-mother" method="post">
            <!-- full name mother -->
            <div class="mb-4 form-group">
              <label class="flex items-center mb-2">
                <span class="r:text-[16px] leading-[1.53] r:tracking-[0.25px]">{{ modelsJa.activerecord.attributes.contracts.full_name_mother }}</span>
                <span class="r:text-[10px] r:tracking-[0.25px] leading-[1.53] text-tw-red-500 bg-tw-red-50 rounded px-1 mt-1 ml-2">{{ viewsJa.contracts.required }}</span>
              </label>
              <div class="flex -mx-0.5">
                <div class="w-1/2 px-0.5">
                  <text-input
                    v-model="userUpdate.relativeFemale.last_name"
                    class="w-full"
                    id="user_relative_attributes_1_last_name"
                    name="user[relative_attributes][0][last_name]"
                    :placeholder="viewsJa.contracts.blocks.customer_information.placeholders.last_name_mother"
                    data-group-length="full-name-mother"
                    :customClass="`form-element px-2 r:py-[15px] xs:r:px-[15px] r:min-h-[56px] ${v$.userUpdate.relativeFemale.last_name.$error ? 'form-invalid' : ''} ${v$.userUpdate.relativeFemale.last_name.$dirty ? 'form-dirty' : ''}`"
                    @change="handleSetTouch('last_name', 'relativeFemale'); setTouchParallel('last_name_kana', 'last_name', 'relativeFemale')"
                    @blur="handleCheckLength('mother')"
                    @input="setKana($event, 'last_name', 'last_name_kana', 'relativeFemale');"
                  />
                </div>
                <div class="w-1/2 px-0.5">
                  <text-input
                    v-model="userUpdate.relativeFemale.first_name"
                    class="w-full"
                    id="user_relative_attributes_1_first_name"
                    name="user[relative_attributes][1][first_name]"
                    :placeholder="viewsJa.contracts.blocks.customer_information.placeholders.first_name_mother"
                    data-group-length="full-name-mother"
                    :customClass="`form-element px-2 r:py-[15px] xs:r:px-[15px] r:min-h-[56px] ${v$.userUpdate.relativeFemale.first_name.$error ? 'form-invalid' : ''} ${v$.userUpdate.relativeFemale.first_name.$dirty ? 'form-dirty' : ''}`"
                    @change="handleSetTouch('first_name', 'relativeFemale'); setTouchParallel('first_name_kana', 'first_name', 'relativeFemale')"
                    @blur="handleCheckLength('mother')"
                    @input="setKana($event, 'first_name', 'first_name_kana', 'relativeFemale');"
                  />
                </div>
              </div>
              <label class="mt-2 flex text-tw-red-600 r:text-[14px] leading-[1]" for="last_name" v-if="v$.userUpdate.relativeFemale.last_name.$error || v$.userUpdate.relativeFemale.first_name.$error || fieldLengthErr.fullNameMother">
                <span class="flex-1 mt-0.5">{{ viewsJa.contracts.blocks.customer_information.errors.full_name_mother }}</span>
              </label>
            </div>
            <!-- full name kana mother -->
            <div class="mb-4 form-group">
              <label class="flex items-center mb-2">
                <span class="r:text-[16px] leading-[1.53] r:tracking-[0.25px]">{{ modelsJa.activerecord.attributes.contracts.full_name_kana_mother }}</span>
                <span class="r:text-[10px] r:tracking-[0.25px] leading-[1.53] text-tw-red-500 bg-tw-red-50 rounded px-1 mt-1 ml-2">{{ viewsJa.contracts.required }}</span>
              </label>
              <div class="flex -mx-0.5">
                <div class="w-1/2 px-0.5">
                  <text-input
                    v-model="userUpdate.relativeFemale.last_name_kana"
                    class="w-full"
                    id="user_relative_attributes_1_last_name_kana"
                    name="user[relative_attributes][1][last_name_kana]"
                    :placeholder="viewsJa.contracts.blocks.customer_information.placeholders.last_name_kana_mother"
                    data-group-length="full-name-kana-mother"
                    :customClass="`form-element px-2 r:py-[15px] xs:r:px-[15px] r:min-h-[56px] ${v$.userUpdate.relativeFemale.last_name_kana.$error || fieldLengthErr.fullNameMotherKana ? 'form-invalid' : ''} ${v$.userUpdate.relativeFemale.last_name_kana.$dirty ? 'form-dirty' : ''}`"
                    @change="handleSetTouch('last_name_kana', 'relativeFemale')"
                    @blur="handleCheckLength('mother-kana')"
                  />
                </div>
                <div class="w-1/2 px-0.5">
                  <text-input
                    v-model="userUpdate.relativeFemale.first_name_kana"
                    class="w-full"
                    id="user_relative_attributes_1_first_name_kana"
                    name="user[relative_attributes][1][first_name_kana]"
                    :placeholder="viewsJa.contracts.blocks.customer_information.placeholders.first_name_kana_mother"
                    data-group-length="full-name-kana-mother"
                    :customClass="`form-element px-2 r:py-[15px] xs:r:px-[15px] r:min-h-[56px] ${v$.userUpdate.relativeFemale.first_name_kana.$error || fieldLengthErr.fullNameMotherKana ? 'form-invalid' : ''} ${v$.userUpdate.relativeFemale.first_name_kana.$dirty ? 'form-dirty' : ''}`"
                    @change="handleSetTouch('first_name_kana', 'relativeFemale')"
                    @blur="handleCheckLength('mother-kana')"
                  />
                </div>
              </div>
              <label class="mt-2 flex text-tw-red-600 r:text-[14px] leading-[1]" for="last_name" v-if="v$.userUpdate.relativeFemale.last_name_kana.$error || v$.userUpdate.relativeFemale.first_name_kana.$error || fieldLengthErr.fullNameMotherKana">
                <span class="flex-1 mt-0.5">{{ viewsJa.contracts.blocks.customer_information.errors.full_name_kana_mother }}</span>
              </label>
            </div>
          </form>
        </div>
      </template>
      <template #modalFooter>
        <div class="pt-6 text-center border-t border-neutral-gray-100 bg-neutral-gray-50 modal-footer">
          <button type="button" data-testid="btn-submit-female" id="btn-submit-female" :disabled="cantSubmitForm.relativeFemale" class="inline-flex items-center justify-center rounded-full btn-gradient btn-primary-gradient-horizontal r:text-[14px] xs:r:text-[16px] py-2 px-2 xs:r:px-[18px] xs:py-4 font-medium text-white shadow-gray-1 leading-[1.33] r:min-h-[53px] mb-6" @click.prevent="updateFormData('relativeFemale')">
            <span id="btn-submit-female-span" class="text-white">{{ viewsJa.contracts.blocks.confirmation.modal.btn_submit }}</span>
          </button>
        </div>
      </template>
    </modal>
    <!-- common user info -->
    <modal
      :open="modalState['common']"
      @close="modalState['common'] = false"
      modalDialogCls="sm:r:max-w-[720px]"
    >
      <template #modalHeader>
        <div class="px-4 py-2 border-b border-neutral-gray-100 bg-neutral-gray-50 modal-header">
          <button type="button" class="flex w-12 h-12 p-3 ml-auto group hover:opacity-70" title="Close" style="margin-left: auto;" @click="modalState['common'] = false">
            <svg class="group-hover:opacity-70" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path d="M6.27614 4.39052C5.75544 3.86983 4.91122 3.86983 4.39052 4.39052C3.86983 4.91122 3.86983 5.75544 4.39052 6.27614L10.1144 12L4.3906 17.7239C3.8699 18.2446 3.8699 19.0888 4.3906 19.6095C4.9113 20.1302 5.75552 20.1302 6.27622 19.6095L12 13.8856L17.7239 19.6095C18.2446 20.1302 19.0888 20.1302 19.6095 19.6095C20.1302 19.0888 20.1302 18.2446 19.6095 17.7239L13.8856 12L19.6095 6.27614C20.1302 5.75544 20.1302 4.91122 19.6095 4.39052C19.0888 3.86982 18.2446 3.86983 17.7239 4.39053L12 10.1144L6.27614 4.39052Z" fill="#93A3B5"/>
            </svg>
          </button>
          <h2 class="r:text-[24px] leading-[1.53] r:tracking-[0.25px]">{{ viewsJa.contracts.blocks.confirmation.modal.user_common.title }}</h2>
        </div>
      </template>
      <template #modalBody>
        <div class="flex-1 p-4 overflow-y-auto xs:py-6 modal-body">
          <h3 class="mb-8 r:text-[18px] leading-[1.53] r:tracking-[0.25px] text-primary-600">{{ viewsJa.contracts.blocks.confirmation.modal.user_common.hight_light }}</h3>
          <form class="form-mother" method="post">
            <!-- full name -->
            <div class="mb-4 form-group">
              <label class="flex items-center mb-2">
                <span class="r:text-[16px] leading-[1.53] r:tracking-[0.25px]">{{ modelsJa.activerecord.attributes.contracts.full_name }}</span>
                <span class="r:text-[10px] r:tracking-[0.25px] leading-[1.53] text-tw-red-500 bg-tw-red-50 rounded px-1 mt-1 ml-2">{{ viewsJa.contracts.required }}</span>
              </label>
              <div class="flex -mx-0.5">
                <div class="w-1/2 px-0.5">
                  <text-input
                    v-model="userUpdate.common.last_name"
                    class="w-full"
                    id="user_last_name"
                    name="user[last_name]"
                    :placeholder="viewsJa.contracts.blocks.customer_information.placeholders.last_name"
                    :customClass="`form-element px-2 r:py-[15px] xs:r:px-[15px] r:min-h-[56px] ${v$.userUpdate.common.last_name.$error ? 'form-invalid' : ''} ${v$.userUpdate.common.last_name.$dirty ? 'form-dirty' : ''}`"
                    @change="handleSetTouch('last_name', 'common'); setTouchParallel('last_name_kana', 'last_name', 'common')"
                    @blur="handleCheckLength('user-kana')"
                    @input="setKana($event, 'last_name', 'last_name_kana', 'common')"
                  />
                </div>
                <div class="w-1/2 px-0.5">
                  <text-input
                    v-model="userUpdate.common.first_name"
                    class="w-full"
                    id="user_first_name"
                    name="user[first_name]"
                    :placeholder="viewsJa.contracts.blocks.customer_information.placeholders.first_name"
                    :customClass="`form-element px-2 r:py-[15px] xs:r:px-[15px] r:min-h-[56px] ${v$.userUpdate.common.first_name.$error ? 'form-invalid': ''} ${v$.userUpdate.common.first_name.$dirty ? 'form-dirty' : ''}`"
                    @change="handleSetTouch('first_name', 'common'); setTouchParallel('first_name_kana', 'first_name', 'common')"
                    @blur="handleCheckLength('user-kana')"
                    @input="setKana($event, 'first_name', 'first_name_kana', 'common');"
                  />
                </div>
              </div>
                <label class="mt-2 flex text-tw-red-600 r:text-[14px] leading-[1]" for="last_name" v-if="v$.userUpdate.common.last_name.$error">
                  <span class="flex-1 mt-0.5">{{ viewsJa.contracts.blocks.customer_information.errors.last_name }}</span>
                </label>
                <label class="mt-2 flex text-tw-red-600 r:text-[14px] leading-[1]" for="first_name" v-if="v$.userUpdate.common.first_name.$error">
                  <span class="flex-1 mt-0.5">{{ viewsJa.contracts.blocks.customer_information.errors.first_name }}</span>
                </label>
            </div>
            <!-- full name kana -->
            <div class="mb-4 form-group">
              <label class="flex items-center mb-2">
                <span class="r:text-[16px] leading-[1.53] r:tracking-[0.25px]">{{ modelsJa.activerecord.attributes.contracts.full_name_kana }}</span>
                <span class="r:text-[10px] r:tracking-[0.25px] leading-[1.53] text-tw-red-500 bg-tw-red-50 rounded px-1 mt-1 ml-2">{{ viewsJa.contracts.required }}</span>
              </label>
              <div class="flex -mx-0.5">
                <div class="w-1/2 px-0.5">
                  <text-input
                    v-model="userUpdate.common.last_name_kana"
                    class="w-full"
                    id="user_last_name_kana"
                    name="user[last_name_kana]"
                    :placeholder="viewsJa.contracts.blocks.customer_information.placeholders.last_name_kana"
                    data-group-length="full-name-kana"
                    :customClass="`form-element px-2 r:py-[15px] xs:r:px-[15px] r:min-h-[56px] ${v$.userUpdate.common.last_name_kana.$error || fieldLengthErr.fullNameKana ? 'form-invalid' : ''} ${v$.userUpdate.common.last_name_kana.$dirty ? 'form-dirty' : ''}`"
                    @change="handleSetTouch('last_name_kana', 'common');"
                    @blur="handleCheckLength('user-kana')"
                  />
                </div>
                <div class="w-1/2 px-0.5">
                  <text-input
                    v-model="userUpdate.common.first_name_kana"
                    class="w-full"
                    id="user_first_name_kana"
                    name="user[first_name_kana]"
                    :placeholder="viewsJa.contracts.blocks.customer_information.placeholders.first_name_kana"
                    data-group-length="full-name-kana"
                    :customClass="`form-element px-2 r:py-[15px] xs:r:px-[15px] r:min-h-[56px] ${v$.userUpdate.common.first_name_kana.$error || fieldLengthErr.fullNameKana ? 'form-invalid' : ''} ${v$.userUpdate.common.first_name_kana.$dirty ? 'form-dirty' : ''}`"
                    @change="handleSetTouch('last_name_kana', 'common');"
                    @blur="handleCheckLength('user-kana')"
                  />
                </div>
              </div>
              <label class="mt-2 flex text-tw-red-600 r:text-[14px] leading-[1]" for="last_name" v-if="v$.userUpdate.common.last_name_kana.$error || v$.userUpdate.common.first_name_kana.$error || fieldLengthErr.fullNameKana">
                <span class="flex-1 mt-0.5">{{ viewsJa.contracts.blocks.customer_information.errors.full_name_kana }}</span>
              </label>
            </div>
            <!-- date of birth -->
            <div class="mb-4 form-group">
              <label class="flex items-center mb-2">
                <span class="r:text-[16px] leading-[1.53] r:tracking-[0.25px]">{{ modelsJa.activerecord.attributes.contracts.date_of_birth }}</span>
                <span class="r:text-[10px] r:tracking-[0.25px] leading-[1.53] text-tw-red-500 bg-tw-red-50 rounded px-1 mt-1 ml-2">{{ viewsJa.contracts.required }}</span>
              </label>
              <div class="flex -mx-0.5">
                <dropdown-date
                  @handle-update-date="fillDob"
                  dropdownType="user"
                  :wrapSelectClass="dropdownCls"
                  class="flex flex-wrap w-full"
                  :maxAge="100"
                  :minAge="20"
                  :dateInit="userUpdate.common.date_of_birth"
                  :hasLabelDate="false"
                />
                <text-input
                  v-model="userUpdate.common.date_of_birth"
                  class="wrap-hidden"
                  type="hidden"
                  id="user_date_of_birth"
                  name="user[date_of_birth]"
                  :customClass="`${v$.userUpdate.common.date_of_birth.$error ? 'form-invalid' : ''} ${v$.userUpdate.common.date_of_birth.$dirty ? 'form-dirty' : ''}`"
                />
              </div>
              <label class="mt-2 flex text-tw-red-600 r:text-[14px] leading-[1]" for="date_of_birth" v-if="v$.userUpdate.common.date_of_birth.$error">
                <span class="flex-1 mt-0.5">{{ viewsJa.contracts.blocks.customer_information.errors.date_of_birth }}</span>
              </label>
            </div>
            <!-- gender -->
            <div class="mb-4 form-group">
              <label class="flex items-center mb-2">
                <span class="r:text-[16px] leading-[1.53] r:tracking-[0.25px]">{{ modelsJa.activerecord.attributes.contracts.gender }}</span>
                <span class="r:text-[10px] r:tracking-[0.25px] leading-[1.53] text-tw-red-500 bg-tw-red-50 rounded px-1 mt-1 ml-2">{{ viewsJa.contracts.required }}</span>
              </label>
              <div class="flex -mx-0.5">
                <div class="px-0.5">
                  <radio-input
                    v-model="userUpdate.common.gender"
                    id="user_gender_male"
                    name="user[gender]"
                    :label="viewsJa.contracts.blocks.customer_information.fields.gender.male"
                    :class="`radio-bar-primary mb-2 ${v$.userUpdate.common.gender.$error ? 'form-invalid' : ''} ${v$.userUpdate.common.gender.$dirty ? 'form-dirty' : ''} `"
                    radioValue="0"
                    radioClass="r:text-[16px] leading-[1.53] r:tracking-[0.25px]"
                    @change="handleSetTouch('gender', 'common');"
                  ></radio-input>
                </div>
                <div class="px-0.5">
                  <radio-input
                    v-model="userUpdate.common.gender"
                    id="user_gender_female"
                    name="user[gender]"
                    :label="viewsJa.contracts.blocks.customer_information.fields.gender.female"
                    :class="`radio-bar-primary ${v$.userUpdate.common.gender.$error ? 'form-invalid' : ''} ${v$.userUpdate.common.gender.$dirty ? 'form-dirty' : ''}`"
                    radioValue="1"
                    radioClass="r:text-[16px] leading-[1.53] r:tracking-[0.25px]"
                    @change="handleSetTouch('gender', 'common');"
                  ></radio-input>
                </div>
              </div>
              <label class="mt-2 flex text-tw-red-600 r:text-[14px] leading-[1]" for="date_of_birth" v-if="v$.userUpdate.common.gender.$error">
                <span class="flex-1 mt-0.5">{{ viewsJa.contracts.blocks.customer_information.errors.gender }}</span>
              </label>
            </div>
            <!-- email -->
            <div class="form-group">
              <label class="flex items-center mb-2">
                <span class="r:text-[16px] leading-[1.53] r:tracking-[0.25px]">{{ modelsJa.activerecord.attributes.contracts.email }}</span>
                <span class="r:text-[10px] r:tracking-[0.25px] leading-[1.53] text-tw-red-500 bg-tw-red-50 rounded px-1 mt-1 ml-2">{{ viewsJa.contracts.required }}</span>
              </label>
              <div class="flex -mx-2">
                <div class="w-full px-2">
                  <text-input
                    v-model="userUpdate.common.register_email"
                    class="w-full"
                    id="user_email"
                    name="user[email]"
                    :placeholder="viewsJa.contracts.blocks.customer_information.placeholders.email"
                    :customClass="`form-element px-2 r:py-[15px] xs:r:px-[15px] r:min-h-[56px] ${v$.userUpdate.common.register_email.$error ? 'form-invalid' : ''} ${v$.userUpdate.common.register_email.$dirty ? 'form-dirty' : ''}`"
                    @change="handleSetTouch('register_email', 'common');"
                  />
                </div>
              </div>
              <label class="mt-2 flex text-tw-red-600 r:text-[14px] leading-[1]" for="last_name" v-if="v$.userUpdate.common.register_email.$error">
                <span class="flex-1 mt-0.5">{{ viewsJa.contracts.blocks.customer_information.errors.email }}</span>
              </label>
            </div>
          </form>
        </div>
      </template>
      <template #modalFooter>
        <div class="pt-6 text-center border-t border-neutral-gray-100 bg-neutral-gray-50 modal-footer">
          <button type="button" data-testid="btn-submit-common" id="btn-submit-common" :disabled="cantSubmitForm.common" class="inline-flex items-center justify-center rounded-full btn-gradient btn-primary-gradient-horizontal r:text-[14px] xs:r:text-[16px] py-2 px-2 xs:r:px-[18px] xs:py-4 font-medium text-white shadow-gray-1 leading-[1.33] r:min-h-[53px] mb-6" @click.prevent="updateFormData('common')">
            <span id="btn-submit-common-span" class="text-white">{{ viewsJa.contracts.blocks.confirmation.modal.btn_submit }}</span>
          </button>
        </div>
      </template>
    </modal>
    <!-- address info modal -->
    <modal
      :open="modalState['location']"
      @close="modalState['location'] = false"
      modalDialogCls="sm:r:max-w-[720px]"
      class="overflow-y-auto"
    >
      <template #modalHeader>
        <div class="px-4 py-2 border-b border-neutral-gray-100 bg-neutral-gray-50 modal-header">
          <button type="button" class="flex w-12 h-12 p-3 ml-auto group hover:opacity-70" title="Close" style="margin-left: auto;" @click="modalState['location'] = false">
            <svg class="group-hover:opacity-70" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path d="M6.27614 4.39052C5.75544 3.86983 4.91122 3.86983 4.39052 4.39052C3.86983 4.91122 3.86983 5.75544 4.39052 6.27614L10.1144 12L4.3906 17.7239C3.8699 18.2446 3.8699 19.0888 4.3906 19.6095C4.9113 20.1302 5.75552 20.1302 6.27622 19.6095L12 13.8856L17.7239 19.6095C18.2446 20.1302 19.0888 20.1302 19.6095 19.6095C20.1302 19.0888 20.1302 18.2446 19.6095 17.7239L13.8856 12L19.6095 6.27614C20.1302 5.75544 20.1302 4.91122 19.6095 4.39052C19.0888 3.86982 18.2446 3.86983 17.7239 4.39053L12 10.1144L6.27614 4.39052Z" fill="#93A3B5"/>
            </svg>
          </button>
          <h2 class="r:text-[24px] leading-[1.53] r:tracking-[0.25px]">{{ viewsJa.contracts.blocks.confirmation.modal.user_address.title }}</h2>
        </div>
      </template>
      <template #modalBody>
        <div class="flex-1 p-4 overflow-y-auto xs:py-6 modal-body">
          <!-- applicator address -->
          <div class="applicator-address">
            <h3 class="r:text-[18px] text-primary-600 leading-[1.53] r:tracking-[0.25px] mb-6 xs:mb-8">{{ viewsJa.contracts.blocks.customer_information.groups.subscriber_information.address_section }}</h3>
            <!-- postal code -->
            <div class="mb-4 form-group">
              <label class="flex items-center mb-2">
                <span class="r:text-[16px] leading-[1.53] r:tracking-[0.25px]">{{ modelsJa.activerecord.attributes.contracts.postal_code }}</span>
                <span class="r:text-[10px] r:tracking-[0.25px] leading-[1.53] text-tw-red-500 bg-tw-red-50 rounded px-1 mt-1 ml-2">{{ viewsJa.contracts.required }}</span>
              </label>
              <div class="flex items-center mb-2 -mx-0.5">
                <places
                  class="r:w-[100px] px-0.5"
                  :apiKey="apiKey"
                  @handle-update-location="fillLocation"
                  @handle-postal-code-error="checkPostalCodeError"
                  :postalCodeVal="userUpdate.location.postal_code"
                  :isTriggerAddress="userUpdate.location.prefecture && userUpdate.location.municipality && userUpdate.location.town ? false : true"
                />
                <div class="px-0.5">
                  <a href="https://www.post.japanpost.jp/zipcode/" class="text-accent-500 r:text-[14px] leading-[1.53] hover:opacity-70 transition r:tracking-[0.25px]" target="_blank" rel="noopener">{{ viewsJa.contracts.blocks.customer_information.fields_note.postal_code_refer }}</a>
                </div>
              </div>
              <span class="block r:text-[14px] leading-[1.53] r:tracking-[0.25px] text-neutral-gray-400">{{ viewsJa.contracts.blocks.customer_information.fields_note.postal_code }}</span>
              <label class="mt-2 flex text-tw-red-600 r:text-[14px] leading-[1]" for="postal_code" v-if="postalCodeErr">
                <span class="flex-1 mt-0.5">{{ viewsJa.contracts.blocks.customer_information.errors.postal_code }}</span>
              </label>
            </div>
            <!-- prefecture & city & town -->
            <div class="mb-4 form-group">
              <label class="flex items-center mb-2">
                <span class="r:text-[16px] leading-[1.53] r:tracking-[0.25px]">{{ modelsJa.activerecord.attributes.contracts.prefecture_municipality }}</span>
                <span class="r:text-[10px] r:tracking-[0.25px] leading-[1.53] text-tw-red-500 bg-tw-red-50 rounded px-1 mt-1 ml-2">{{ viewsJa.contracts.required }}</span>
              </label>
              <div class="flex -mx-2">
                <div class="w-full px-2">
                  <span
                    class="block form-element px-2 r:py-[15px] xs:px-[15px] r:min-h-[56px] leading-[1.53]"
                    :class="[
                      userUpdate.location.prefecture_name.$error || userUpdate.location.municipality.$error || userUpdate.location.town.$error || fieldLengthErr.totalAddress ? 'form-invalid' : '',
                      userUpdate.location.prefecture_name.$dirty || userUpdate.location.municipality.$dirty || userUpdate.location.town.$dirty ? 'form-dirty' : '' ? 'form-invalid-server' : '',
                      !!userUpdate.location.prefecture_name && !!userUpdate.location.municipality && !!userUpdate.location.town ? '' : 'text-neutral-gray-300'
                    ]"
                  >
                    {{prefectureCityTownVal}}
                  </span>
                </div>
                <text-input
                  v-model="userUpdate.location.prefecture_name"
                  class="wrap-hidden"
                  type="hidden"
                  id="user_prefecture"
                  name="user[prefecture]"
                  data-group-length="total-address"
                  :customClass="`${v$.userUpdate.location.prefecture_name.$error || fieldLengthErr.totalAddress ? 'form-invalid' : ''} ${v$.userUpdate.location.prefecture_name.$dirty ? 'form-dirty' : ''}`"
                />
                <text-input
                  v-model="userUpdate.location.municipality"
                  class="wrap-hidden"
                  type="hidden"
                  id="user_municipality"
                  name="user[municipality]"
                  data-group-length="total-address"
                  :customClass="`${userUpdate.location.municipality.$error || fieldLengthErr.totalAddress ? 'form-invalid' : ''} ${userUpdate.location.municipality.$dirty ? 'form-dirty' : ''}`"
                />
                <text-input
                  v-model="userUpdate.location.town"
                  class="wrap-hidden"
                  type="hidden"
                  id="user_town"
                  name="user[town]"
                  data-group-length="total-address"
                  :customClass="`${v$.userUpdate.location.town.$error || fieldLengthErr.totalAddress ? 'form-invalid' : ''} ${v$.userUpdate.location.town.$dirty ? 'form-dirty' : ''}`"
                />
              </div>
            </div>
            <!-- detail address -->
            <div class="mb-4 form-group">
              <label class="flex items-center mb-2">
                <span class="r:text-[16px] leading-[1.53] r:tracking-[0.25px]">{{ modelsJa.activerecord.attributes.contracts.detail_address }}</span>
                <span class="r:text-[10px] r:tracking-[0.25px] leading-[1.53] text-tw-red-500 bg-tw-red-50 rounded px-1 mt-1 ml-2">{{ viewsJa.contracts.required }}</span>
              </label>
              <div class="flex mb-2 -mx-2">
                <div class="w-full px-2">
                  <text-input
                    v-model="userUpdate.location.detail_address"
                    class="w-full"
                    id="user_detail_address"
                    name="user[detail_address]"
                    :placeholder="viewsJa.contracts.blocks.customer_information.placeholders.detail_address"
                    data-group-length="total-address"
                    :customClass="`form-element px-2 r:py-[15px] xs:r:px-[15px] r:min-h-[56px] ${v$.userUpdate.location.detail_address.$error || fieldLengthErr.totalAddress ? 'form-invalid' : ''} ${v$.userUpdate.location.detail_address.$dirty ? 'form-dirty' : ''}`"
                    maxlength="20"
                    @blur="handleCheckLength('total-address')"
                    @input="handleSetTouch('detail_address', 'location');"
                  />
                </div>
              </div>
            </div>
            <!-- address -->
            <div class="mb-4 form-group">
              <label class="flex items-center mb-2">
                <span class="r:text-[16px] leading-[1.53] r:tracking-[0.25px]">{{ modelsJa.activerecord.attributes.contracts.address }}</span>
              </label>
              <div class="flex -mx-2">
                <div class="w-full px-2">
                  <text-input
                    v-model="userUpdate.location.address"
                    class="w-full"
                    id="user_address"
                    name="user[address]"
                    :placeholder="viewsJa.contracts.blocks.customer_information.placeholders.address"
                    data-group-length="total-address"
                    :customClass="`form-element px-2 r:py-[15px] xs:r:px-[15px] r:min-h-[56px] ${fieldLengthErr.totalAddress ? 'form-invalid' : ''} `"
                    maxlength="20"
                    @blur="handleCheckLength('total-address')"
                  />
                </div>
              </div>
              <label class="mt-2 flex text-tw-red-600 r:text-[14px] leading-[1]" for="postal_code" v-if="fieldLengthErr.totalAddress || v$.userUpdate.location.prefecture_name.$error || v$.userUpdate.location.municipality.$error || v$.userUpdate.location.town.$error || v$.userUpdate.location.detail_address.$error ">
                <span class="flex-1 mt-0.5">{{ viewsJa.contracts.blocks.customer_information.errors.full_address }}</span>
              </label>
            </div>
            <!-- phone -->
            <div class="form-group">
              <label class="flex items-center mb-2">
                <span class="r:text-[16px] leading-[1.53] r:tracking-[0.25px]">{{ modelsJa.activerecord.attributes.contracts.phone }}</span>
                <span class="r:text-[10px] r:tracking-[0.25px] leading-[1.53] text-tw-red-500 bg-tw-red-50 rounded px-1 mt-1 ml-2">{{ viewsJa.contracts.required }}</span>
              </label>
              <div class="flex mb-2 -mx-2">
                <div class="relative w-full px-2">
                  <text-input
                    v-model="userUpdate.location.phone"
                    class="w-full"
                    id="user_phone"
                    name="user[phone]"
                    :placeholder="viewsJa.contracts.blocks.customer_information.placeholders.phone"
                    data-mask-blocks="[3,4,4]"
                    data-mask-delimiter="-"
                    type="tel"
                    :customClass="`form-element px-2 r:py-[15px] xs:r:px-[15px] r:min-h-[56px] ${v$.userUpdate.location.phone.$error ? 'form-invalid' : ''} ${v$.userUpdate.location.phone.$dirty ? 'form-dirty' : ''}`"
                    @change="handleSetTouch('phone', 'location');"
                    @input="getMaskValue($event, 'location', 'phone', 13)"
                  />
                </div>
              </div>
              <span class="block text-right r:text-[14px] leading-[1.53] r:tracking-[0.25px] text-neutral-gray-400">{{ viewsJa.contracts.blocks.customer_information.fields_note.phone }}</span>
              <label class="mt-2 flex text-tw-red-600 r:text-[14px] leading-[1]" for="last_name" v-if="v$.userUpdate.location.phone.$error">
                <span class="flex-1 mt-0.5">{{ viewsJa.contracts.blocks.customer_information.errors.phone }}</span>
              </label>
            </div>
          </div>
        </div>
      </template>
      <template #modalFooter>
        <div class="pt-6 text-center border-t border-neutral-gray-100 bg-neutral-gray-50 modal-footer">
          <button type="button" data-testid="btn-submit-location" id="btn-submit-location" :disabled="cantSubmitForm.location" class="inline-flex items-center justify-center rounded-full btn-gradient btn-primary-gradient-horizontal r:text-[14px] xs:r:text-[16px] py-2 px-2 xs:r:px-[18px] xs:py-4 font-medium text-white shadow-gray-1 leading-[1.33] r:min-h-[53px] mb-6" @click.prevent="updateFormData('location')">
            <span id="btn-submit-location-span" class="text-white">{{ viewsJa.contracts.blocks.confirmation.modal.btn_submit }}</span>
          </button>
        </div>
      </template>
    </modal>
    <!-- send mail notification -->
    <div id="alert-sent-mail" class="fixed top-2.5 right-2.5 z-20" :class="canShow ? '' : 'hidden'">
      <div class="relative w-full bg-white rounded-lg alert-box r:max-w-[400px]">
        <!-- <input type="checkbox" id="hide" v-model="isVisible" class="hidden"> -->
        <div class="flex items-center justify-center py-2 px-4 rounded-lg sent-mail-alert-content bg-neutral-700 r:max-w-[358px]">
          <div @click="canShow = false" class="mr-4 cursor-pointer alert">
            <images name="closeMail" class="r:min-w-[16px] cursor-pointer"/>
          </div>
          <span class="desc block text-white r:text-[16px] leading-[1.53]">{{ viewsJa.contracts.blocks.confirmation.send_mail_desc }}</span>
          <images name="sendMail" class="r:min-w-[100px]"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { router } from '@inertiajs/vue3';
import { useVuelidate } from '@vuelidate/core';
import { required, email, minLength, maxLength } from '@vuelidate/validators';
import { hiraganaKanjiKana, katakanaOnly, phone } from '@/utils/custom-validators';
import { convertJaParams, convertDateJP, convertGenderJP, numberWithCommas } from '@/utils/utils';
import Modal from '@/Shared/Modal.vue';
import CheckboxInput from '@/Shared/CheckboxInput.vue';
import TextInput from '@/Shared/TextInput.vue';
import axios from 'axios';
import RadioInput from '@/Shared/RadioInput.vue';
import Loading from '@/Shared/Loading.vue';
import DropdownDate from '@/Shared/User/DropdownDate.vue';
import Places from '@/Shared/User/Places.vue';
import * as AutoKana from 'vanilla-autokana';
import { setMask, setLimitLength, formatDate } from '@/utils/utils';
import Images from '@/Shared/Images.vue';
import QuotationModal from '@/Pages/Quotations/Components/QuotationModal.vue';

let autoKanaNameField = {
  common: {
    last_name: null,
    first_name: null,
  },
  relativeMale: {
    last_name: null,
    first_name: null,
  },
  relativeFemale: {
    last_name: null,
    first_name: null,
  }
}

export default {
  components: {
    CheckboxInput,
    TextInput,
    Modal,
    RadioInput,
    DropdownDate,
    Places,
    Loading,
    Images,
    QuotationModal,
  },
  props: {
    apiKey: {
      type: String,
      required: true
    },
    pageStep: {
      type: Number,
      required: true,
    },
    isLogged: {
      type: Boolean,
      required: true,
    },
    quotationData: {
      type: Object,
      required: false,
    },
    contractStartDay: {
      type: String,
      required: false
    },
    contractEndDay: {
      type: String,
      required: false
    },
    relatives: {
      type: Array,
      required: false,
    },
    userInfo: {
      type: Object,
      required: false,
    },
    isSentMail: {
      type: Boolean,
      required: false,
    },
    magazineFlag: {
      type: Boolean,
      required: false,
    },
    quotation: {
      type: Object,
      required: true
    },
    formErrors: {
      type: Object,
      required: false
    },
  },
  emits: ['set-step'],
  data() {
    return {
      viewsJa: this.jaConfig.viewsJa,
      modelsJa: this.jaConfig.modelsJa,
      convertJaParams: convertJaParams,
      convertGenderJP: convertGenderJP,
      convertDateJP: convertDateJP,
      formatDate: formatDate,
      numberWithCommas: numberWithCommas,
      setMask: setMask,
      isAcceptRule: false,
      userData: {},
      userDataForStore: {},
      check_agree: false,
      accept_mail_magazine_flag: null,
      authenToken: document.querySelector('meta[name="csrf-token"]').content,
      canShow: false,
      careType: this.quotation.careType,
      father: this.quotation.father,
      mother: this.quotation.mother,
      isFirstTime: this.quotation.isFirstTime,
      contractStartDayStr: this.quotation.contractStartDayStr,
      formItemExist: [],
      cantSubmitForm: {
        common: false,
        location: false,
        relativeMale: false,
        relativeFemale: false,
      },
      isValid: {
        common: true,
        location: true,
        relativeMale: true,
        relativeFemale: true,
      },
      fieldLengthErr: {
        fullNameKana: false,
        fullNameFather: false,
        fullNameFatherKana: false,
        fullNameMother: false,
        fullNameMotherKana: false,
        totalAddress: false,
      },
      modalState: {
        common: false,
        address: false,
        father: false,
        mother: false,
      },
      userUpdate : {
        common: {
          first_name: '',
          last_name: '',
          first_name_kana: '',
          last_name_kana: '',
          date_of_birth: '',
          gender: '',
          register_email: '',
          birth_year: ''
        },
        location: {
          address: '',
          postal_code: '',
          prefecture_name: '',
          municipality: '',
          town: '',
          detail_address: '',
          phone: ''
        },
        relativeMale: {
          first_name: '',
          last_name: '',
          first_name_kana: '',
          last_name_kana: '',
          gender: null
        },
        relativeFemale: {
          first_name: '',
          last_name: '',
          first_name_kana: '',
          last_name_kana: '',
          gender: null
        },
      },
      dropdownCls: {
        year: 'mb-2 xs:mb-0 w-full xs:w-1/3 px-0.5',
        month: 'w-1/2 xs:w-1/3 px-0.5',
        day: 'w-1/2 xs:w-1/3 px-0.5',
      },
      postalCodeErr: false,
      prefectureCityTownVal: this.jaConfig.viewsJa.contracts.blocks.customer_information.placeholders.prefecture_municipality,
      userBirthYear: null,
      ignoreParent: {},
      quotationEdit: {...this.quotationData},
      otherFlag: JSON.parse(localStorage.getItem('otherFlag')) || {
        agree: null,
        magazine: null,
      },
      isLoading: false,
      isQuotationOpen: false,
      firstSentMail: localStorage.getItem('firstSentMail') || 'true',
    }
  },
  setup() {
    return {
      v$: useVuelidate(),
    }
  },
  created() {
    this.setIgnoreParent();
    if (Object.keys(this.userInfo).length && (JSON.parse(localStorage.getItem('isFromPayment')) || JSON.parse(localStorage.getItem('isFromMail')))) {
      this.userData = {...this.userInfo};
      this.userData.relatives_attributes = {...this.relatives};
      this.userDataForStore = {
        user: {...this.userData}
      };
      localStorage.setItem('userForm', JSON.stringify(this.userDataForStore));
      localStorage.setItem('isFromPayment', false);
    } else {
      this.userData = JSON.parse(localStorage.getItem('userForm')).user;
    }
    this.addIgnoreVal();

    // preset data to prevent empty value while mounted component (Postal Code)
    this.handleSetDataModal('common');
    this.handleSetDataModal('location');
    for (let i in this.userData.relatives_attributes) {
      if (this.userData.relatives_attributes[i].gender == 'male' && !this.ignoreParent['male']) {
        this.handleSetDataModal('relativeMale');
      }
      if (this.userData.relatives_attributes[i].gender == 'female' && !this.ignoreParent['female']) {
        this.handleSetDataModal('relativeFemale');
      }
    }

    // keep flag between step 3 and 4
    this.check_agree = this.otherFlag.agree;
  },
  mounted() {
    if (this.isSentMail && this.firstSentMail == 'true') {
      this.setDisplayToast();
      localStorage.setItem('firstSentMail', false);
    }
    this.handleCheckFormStatus();
    autoKanaNameField.common.last_name = AutoKana.bind('#user_last_name', '#user_last_name_kana', { katakana: true });
    autoKanaNameField.common.first_name = AutoKana.bind('#user_first_name', '#user_first_name_kana', { katakana: true });

    if (this.userUpdate.relativeMale.gender == 'male' && !this.ignoreParent[this.userUpdate.relativeMale.gender]) {
      autoKanaNameField.relativeMale.last_name = AutoKana.bind('#user_relative_attributes_0_last_name', '#user_relative_attributes_0_last_name_kana', { katakana: true });
      autoKanaNameField.relativeMale.first_name = AutoKana.bind('#user_relative_attributes_0_first_name', '#user_relative_attributes_0_first_name_kana', { katakana: true });
    }
    if (this.userUpdate.relativeFemale.gender == 'female' && !this.ignoreParent[this.userUpdate.relativeFemale.gender]) {
      autoKanaNameField.relativeFemale.last_name = AutoKana.bind('#user_relative_attributes_1_last_name', '#user_relative_attributes_1_last_name_kana', { katakana: true });
      autoKanaNameField.relativeFemale.first_name = AutoKana.bind('#user_relative_attributes_1_first_name', '#user_relative_attributes_1_first_name_kana', { katakana: true });
    }
  },
  methods: {
    async submitContract (e, isDraft) {
      const self = this;
      let formData = JSON.parse(localStorage.getItem('userForm'));

      self.isLoading = true;
      if (!!formData) {
        for(let i in formData.user.relatives_attributes) {
          delete formData.user.relatives_attributes[i]["relative_type"]
          delete formData.user.relatives_attributes[i]["created_at"]
          delete formData.user.relatives_attributes[i]["dob"]
          delete formData.user.relatives_attributes[i]["updated_at"]
          delete formData.user.relatives_attributes[i]["age"]

          if (this.ignoreParent[formData.user.relatives_attributes[i]["gender"]] == true) {
            formData.user.relatives_attributes[i]["ignore_value"] = true;
          }
        }
        delete formData.user.dob
      } else {
        formData = {};
      }

      e.preventDefault();
      formData.authenticity_token = this.authenToken;
      formData.draft = !!isDraft;
      if (self.isLogged) {
        router.post(self.$routes.contracts(), formData, {
          onError: errors => {
            // set back to step form
            if (!!errors && !!errors.form_field && Object.keys(errors.form_field).length) {
              this.setStep(1);
              localStorage.setItem('userFormStep', 1);
              localStorage.setItem('formErrors', true)
            }
          },
          onSuccess: page => {
            if (page.props.isSentMail) {
              this.setDisplayToast();
            }
          },
          onFinish: visit => {
            self.isLoading = false;
          }
        })
      } else {
        axios({
          method: 'post',
          url: self.$routes.store_contract_params_in_session(),
          data: formData,
        }).then(function (response) {
          if (response.data.success) {
            let formContract = document.getElementById('form-contract');
            formContract.setAttribute('action', self.$routes.user_mfid_omniauth_authorize())
            formContract.submit();
          } else {
            localStorage.setItem('sessionTimeout', true)
            window.location.href = self.$routes.root();
            self.isLoading = false;
          }
        }).catch(function (error) {
          if (error.response.status == '409') {
            localStorage.setItem('sessionTimeout', true)
            window.location.href = self.$routes.root();
          }
          self.isLoading = false;
        });
      }
    },
    setDisplayToast () {
      const delayHideToast = 3000;
      this.canShow = true;
      setTimeout(() => {
        this.canShow = false;
      }, delayHideToast);
    },
    handleMagazineFlag() {
      this.userData.accept_mail_magazine_flag = this.accept_mail_magazine_flag;
      // Save to localStorage
      const userFormData = { user: this.userData };
      localStorage.setItem('userForm', JSON.stringify(userFormData));
    },
    setOtherFlag() {
      this.otherFlag.agree = this.check_agree;
      this.otherFlag.magazine = this.accept_mail_magazine_flag;
      localStorage.setItem('otherFlag', JSON.stringify(this.otherFlag));
    },
    updateGroupData(group) {
      if (group == 'relativeMale') {
        // fill data for father
        for (let r in this.userData.relatives_attributes) {
          if (!this.ignoreParent.male && this.userData.relatives_attributes[r].gender == 'male') {
            for (let ud in this.userUpdate.relativeMale) {
              if (this.userUpdate.relativeMale[ud] == this.userData.relatives_attributes[r][ud]) continue;
              this.userData.relatives_attributes[r][ud] = this.userUpdate.relativeMale[ud];
            }
          }
        }
      } else if (group == 'relativeFemale') {
        // fill data for mother
        for (let r in this.userData.relatives_attributes) {
          if (!this.ignoreParent.female && this.userData.relatives_attributes[r].gender == 'female') {
            for (let ud in this.userUpdate.relativeFemale) {
              if (this.userUpdate.relativeFemale[ud] == this.userData.relatives_attributes[r][ud]) continue;
              this.userData.relatives_attributes[r][ud] = this.userUpdate.relativeFemale[ud];
            }
          }
        }
      } else {
        // fill data for other
        for (let ud in this.userUpdate[group]) {
          if (this.userData[ud] == this.userUpdate[group][ud]) continue;
          if (ud == 'phone') {
            this.userData[ud] = this.userUpdate[group][ud].replaceAll('-', '');
          } else {
            this.userData[ud] = this.userUpdate[group][ud];
          }
        }
      }

      // Save to localStorage
      const userFormData = { user: this.userData };
      localStorage.setItem('userForm', JSON.stringify(userFormData));
    },


    async updateFormData(group) {
      this.isValid[group] = await this.v$.userUpdate[group].$validate();

      if (this.isValid[group]) {
        this.modalState[group] = false; // close modal
        this.updateGroupData(group);
      } else {
        // Focus on first field with error
        document.querySelectorAll('.form-invalid')[0].focus();
      }
    },

    setStep (step) {
      this.$emit('set-step', step);
      localStorage.setItem('userFormStep', step);
    },
    addIgnoreVal() {
      if (this.quotationEdit.male !== undefined) {
        this.quotationEdit.male.ignore_value = this.ignoreParent.male;
      }
      if (this.quotationEdit.female !== undefined) {
        this.quotationEdit.female.ignore_value = this.ignoreParent.female;
      }
    },

    handleSetTouch (field, group) {
      this.v$.userUpdate[group][field].$touch();
    },
    fillDob (date, isDirty) {
      this.userUpdate.common.date_of_birth = date;
      if (isDirty) {
        this.v$.userUpdate.common.date_of_birth.$touch();
      }
    },
    checkPostalCodeError (errorState) {
      this.postalCodeErr = errorState;
    },
    fillLocation (prefecture, municipality, town, postalCode) {
      this.userUpdate.location.prefecture_name = prefecture;
      this.userUpdate.location.municipality = municipality;
      this.userUpdate.location.town = town;
      this.userUpdate.location.postal_code = postalCode;
      this.v$.userUpdate.location.postal_code.$touch();
      this.v$.userUpdate.location.prefecture_name.$touch();
      this.v$.userUpdate.location.municipality.$touch();
      this.v$.userUpdate.location.town.$touch();
      this.prefectureCityTownVal = !prefecture && !municipality && !town ? '' : `${prefecture}${municipality}${town}`;
      this.handleCheckLength('total-address');
    },
    setQuotationOpen (state) {
      this.isQuotationOpen = state;
    },
    setIgnoreParent () {
      this.ignoreParent = JSON.parse(localStorage.getItem('ignoreParent'));

      if (this.ignoreParent) {
        return;
      }

      this.ignoreParent = {};

      const ignoreRelatives = this.relatives.filter((relative) =>
        relative.first_name == null || relative.last_name == null || relative.first_name_kana == null || relative.last_name_kana == null
      );

      if (ignoreRelatives.length) {
        this.ignoreParent[ignoreRelatives[0].gender] = true;
        localStorage.setItem('ignoreParent', JSON.stringify(this.ignoreParent));
      }
    }
  },
  computed: {
    handleSetDataModal () {
      return (group) => {
        // add and modify data to handle step confirmation
        switch (group) {
          case 'relativeMale':
            // fill data for father
            for (let ud in this.userData.relatives_attributes) {
              if (this.userData.relatives_attributes[ud].gender == 'male') {
                for (let uu in this.userUpdate.relativeMale) {
                  this.userUpdate.relativeMale[uu] = this.userData.relatives_attributes[ud][uu];
                }
              }
            }
            break;
          case 'relativeFemale':
            // fill data for mother
            for (let ud in this.userData.relatives_attributes) {
              if (this.userData.relatives_attributes[ud].gender == 'female') {
                for (let uu in this.userUpdate.relativeFemale) {
                  this.userUpdate.relativeFemale[uu] = this.userData.relatives_attributes[ud][uu];
                }
              }
            }
            break;
          default:
            // // fill data for others
            for (let uu in this.userUpdate[group]) {
              this.userUpdate[group][uu] = this.userData[uu];
              if (uu == 'phone') {
                this.userUpdate.location.phone = setMask(this.userUpdate.location.phone, [3, 4, 4], '-')
              }
            }
            break;
        }
      }
    },
    handleModalState () {
      return (modalName) => {
        this.modalState[modalName] = !this.modalState[modalName];
      }
    },
    totalQuotationFee () {
      let total = 0;
      Object.entries(this.userData.relatives_attributes).forEach(([_key, {gender}]) => {
        if (!this.quotationEdit[gender].ignore_value) {
          total += this.quotationEdit[gender].total_fee;
        }
      });

      return total;
    },
    setTouchParallel () {
      return (targetField, inputField, group) => {
        if (this.v$.userUpdate[group][inputField].$dirty && !!this.userUpdate[group][targetField]) {
          this.v$.userUpdate[group][targetField].$touch();
        }
      }
    },
    checkFieldLength () {
      return (fields, trackField, lengthNum) => {
        const groupLength = document.getElementById(fields[0]['id']).getAttribute('data-group-length');
        const groupEles = document.querySelectorAll(`[data-group-length="${groupLength}"]`);
        const delay = 100;
        let totalLength = 0;
        // set delay for get length value of field
        setTimeout(() => {
          for (let i = 0; i < groupEles.length; i++) {
            totalLength += groupEles[i].value.length;
          }
          this.fieldLengthErr[trackField] = totalLength > lengthNum ? true : false;
        }, delay);
      }
    },
    handleCheckLength() {
      return (type) => {
        switch (type) {
          case 'total-address':
            this.checkFieldLength([{ id: 'user_prefecture', model: 'prefecture_name'}, { id: 'user_municipality', model: 'municipality'}, { id: 'user_town', model: 'town'}, { id: 'user_detail_address', model: 'detail_address'}, { id: 'user_address', model: 'address'}], 'totalAddress', 45)
            break;

          case 'father':
            this.checkFieldLength([{ id: 'user_relative_attributes_0_last_name', model: 'lastNameFather'}, { id: 'user_relative_attributes_0_first_name', model: 'firstNameFather'}], 'fullNameFather', 14)
            this.checkFieldLength([{ id: 'user_relative_attributes_0_last_name_kana', model: 'lastNameKanaFather'}, { id: 'user_relative_attributes_0_first_name_kana', model: 'firstNameKanaFather'}], 'fullNameFatherKana', 19)
            break;

          case 'father-kana':
            this.checkFieldLength([{ id: 'user_relative_attributes_0_last_name_kana', model: 'lastNameKanaFather'}, { id: 'user_relative_attributes_0_first_name_kana', model: 'firstNameKanaFather'}], 'fullNameFatherKana', 19)
            break;

          case 'mother':
            this.checkFieldLength([{ id: 'user_relative_attributes_1_last_name', model: 'lastNameMother'}, { id: 'user_relative_attributes_1_first_name', model: 'firstNameMother'}], 'fullNameMother', 14)
            this.checkFieldLength([{ id: 'user_relative_attributes_1_last_name_kana', model: 'lastNameKanaMother'}, { id: 'user_relative_attributes_1_first_name_kana', model: 'firstNameKanaMother'}], 'fullNameMotherKana', 19)
            break;

          case 'mother-kana':
            this.checkFieldLength([{ id: 'user_relative_attributes_1_last_name_kana', model: 'lastNameKanaMother'}, { id: 'user_relative_attributes_1_first_name_kana', model: 'firstNameKanaMother'}], 'fullNameMotherKana', 19)
            break;

          default:
            this.checkFieldLength([{ id: 'user_last_name_kana', model: 'lastNameKana'}, { id: 'user_first_name_kana', model: 'firstNameKana'}], 'fullNameKana', 19)
            break;
        }
      }
    },
    getMaskValue () {
      return (e, group, field, limitLength) => {
        const fieldEle = e.target;
        const blocks = JSON.parse(fieldEle.getAttribute('data-mask-blocks'));
        const delimiter = fieldEle.getAttribute('data-mask-delimiter');
        this.userUpdate[group][field] = setMask(e.target.value, blocks, delimiter);
        this.userUpdate[group][field] = setLimitLength(this.userUpdate[group][field], limitLength);
        fieldEle.value = this.userUpdate[group][field];
      }
    },
    setKana () {
      return (e, field, targetField, group) => {
        const delayKanaValue = 50;
        if (e.target.value) {
          setTimeout(() => {
            if (!autoKanaNameField[group][field].getFurigana()) return;
            this.userUpdate[group][targetField] = autoKanaNameField[group][field].getFurigana();
          }, delayKanaValue);
        } else {
          this.userUpdate[group][targetField] = '';
        }
      }
    },
    handleCheckFormStatus () {
      return () => {
        for (let formItem of this.formItemExist) {
          const group = formItem.group;
          const item = formItem.item;
          if ((group == 'relativeMale' && this.ignoreParent['male']) || (group == 'relativeFemale' && this.ignoreParent['female'])) continue;
          this.cantSubmitForm[group] = !this.userUpdate[group][item];
          if (!this.userUpdate[group][item]) break;
        }
      }
    }
  },
  watch: {
    userUpdate: {
      handler: function () {
        this.handleCheckFormStatus();
      },
      deep: true
    }
  },
  validations () {
    const userUpdate = {
      common: {
        first_name: { required, minLength: minLength(1), maxLength: maxLength(10), hiraganaKanjiKana },
        last_name: { required, minLength: minLength(1), maxLength: maxLength(10), hiraganaKanjiKana },
        first_name_kana: { required, minLength: minLength(1), maxLength: maxLength(18), katakanaOnly },
        last_name_kana: { required, minLength: minLength(1), maxLength: maxLength(18), katakanaOnly },
        date_of_birth: { required },
        gender: { required },
        register_email: { required, email },
      },
      location: {
        detail_address: { required },
        postal_code: { required },
        prefecture_name: { required },
        municipality: { required },
        town: { required },
        phone: { required, phone },
      },
      relativeMale: {
        first_name: {},
        last_name: {},
        first_name_kana: {},
        last_name_kana: {},
      },
      relativeFemale: {
        first_name: {},
        last_name: {},
        first_name_kana: {},
        last_name_kana: {},
      },
    }

    for (let r in this.userData.relatives_attributes) {
      if (this.userData.relatives_attributes[r].gender == 'male') {
        userUpdate.relativeMale.first_name = { required, minLength: minLength(1), maxLength: maxLength(14), hiraganaKanjiKana };
        userUpdate.relativeMale.last_name = { required, minLength: minLength(1), maxLength: maxLength(14), hiraganaKanjiKana };
        userUpdate.relativeMale.first_name_kana = { required, minLength: minLength(1), maxLength: maxLength(18), katakanaOnly };
        userUpdate.relativeMale.last_name_kana = { required, minLength: minLength(1), maxLength: maxLength(18), katakanaOnly };
      }
      if (this.userData.relatives_attributes[r].gender == 'female') {
        userUpdate.relativeFemale.first_name = { required, minLength: minLength(1), maxLength: maxLength(14), hiraganaKanjiKana };
        userUpdate.relativeFemale.last_name = { required, minLength: minLength(1), maxLength: maxLength(14), hiraganaKanjiKana };
        userUpdate.relativeFemale.first_name_kana = { required, minLength: minLength(1), maxLength: maxLength(18), katakanaOnly };
        userUpdate.relativeFemale.last_name_kana = { required, minLength: minLength(1), maxLength: maxLength(18), katakanaOnly };
      }
    }

    for (let group in userUpdate) {
      for (let f in userUpdate[group]) {
        if (Object.keys(userUpdate[group][f]).length) {
          this.formItemExist.push({item: f, group: group});
        }
      }
    }

    return {
      userUpdate: {...userUpdate}
    };
  },
  validationConfig: {
    $lazy: true,
  },
}
</script>
