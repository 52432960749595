<template>
  <div class="img-svg" :class="wrapIconClass" v-html="icons[name]" />
</template>

<style lang="scss">
.img-svg {
  svg {
    @apply w-full h-full;
  }
}
</style>

<script>
import home from '@assets/icons/home.svg?raw'
import file from '@assets/icons/file.svg?raw'
import bell from '@assets/icons/bell.svg?raw'
import exportfile from '@assets/icons/export-file.svg?raw'
import faq from '@assets/icons/faq.svg?raw'
import setting from '@assets/icons/setting.svg?raw'
import singlefile from '@assets/icons/single-file.svg?raw'
import error from '@assets/icons/error.svg?raw'

export default {
  props: {
    name: {
      type: String,
      required: true
    },
    wrapIconClass: {
      type: String,
      default: 'w-4 h-4'
    },
  },
  data() {
    return {
      icons: {
        home: home,
        file: file,
        bell: bell,
        "export-file": exportfile,
        faq: faq,
        setting: setting,
        "single-file": singlefile,
        error: error,
      }
    }
  },
}
</script>
