<template>
  <div class="nursing-care-component">
    <div class="nursing-info">
      <div class="info" v-for="(detail, index) in parentNursingCareJa.details" :key="index">
        <div class="r:min-h-[125px] px-6 pt-6 pb-8 mb-2 bg-primary-50 rounded-2xl">
          <div class="mb-4 info-title">
            <span class="px-4 py-1 inline-block rounded-full bg-primary-500 r:text-[14px] text-white font-black leading-[1.33] tracking-[0.25px] mb-1"> {{ detail.high_light_title }}</span>
            <span class="block r:text-[20px] font-black leading-[1.53] tracking-[0.25px]">{{ detail.title }}</span>
          </div>
          <div class="flex items-center justify-center md:items-start info-content">
            <div class="mr-3">
              <span class="r:text-[14px] leading-[1.53] tracking-[0.25px]" v-html="detail.desc"></span>
              <!-- open tool tip -->
              <div v-if="!!detail.link">
                <tippy
                  interactive
                  animation="fade"
                  trigger="click"
                  :maxWidth="280"
                  :onShown="(instance) => {
                    handleCloseTooltip(instance);
                  }"
                >
                  <template #default>
                    <span>※</span>
                    <a id="open-need-nursing-care-a" data-testid="open-need-nursing-care-a" href="javascript:;" class="underline r:text-[12px] leading-[1.33] tracking-[0.25px] transition text-tw-blue-500 hover:opacity-70">
                      <span id="open-need-nursing-care-a-span" class="">{{ detail.link }}</span>
                    </a>
                  </template>
                  <template #content>
                    <div class="p-3 rounded-lg bg-neutral-gray-700 xs:p-4">
                      <h4 class="text-white r:text-[16px] font-black leading-[1.53] r:tracking-[0.25px] mb-3 xs:mb-4">{{ parentNursingCareJa.tooltip.title }}</h4>
                      <p class="text-white r:text-[14px] leading-[1.53] r:tracking-[0.25px] mb-6 xs:mb-8" v-html="parentNursingCareJa.tooltip.content"></p>
                      <button id="close-tooltip-nursing-care" data-testid="close-tooltip-nursing-care" type="button" class="btn-close-tooltip btn-solid inline-flex items-center justify-center rounded-full btn-gradient r:text-[14px] py-3.5 px-4 font-medium text-white shadow-gray-1 bg-neutral-gray-800 r:tracking-[0.25px] leading-[1.33] r:min-h-[47px]">
                        <span id="close-tooltip-nursing-care-span">{{ viewsJa.contracts.blocks.customer_information.fields_tooltip.btn_close }}</span>
                      </button>
                    </div>
                  </template>
                </tippy>
              </div>
            </div>
            <images :name=detail.img class="mx-auto r:w-[112px] r:h-[112px]"/>
          </div>
          <div v-if="!!detail.note" class="p-4 mt-4 bg-white border note rounded-2xl border-primary-200">
            <span class="text-primary-600" v-html="detail.note"></span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
:deep(.tippy-content) {
  padding: 0;
}
</style>

<script>
import Layout from '@/Layouts/User.vue';
import Images from '@/Shared/Images.vue';
import { Tippy } from 'vue-tippy';

export default {
  components: {
    Images,
    Tippy,
  },
  layout: Layout,
  props: {
    pageType: {
      type: String,
      default: 'home'
    }
  },
  data() {
    return {
      viewsJa: this.jaConfig.viewsJa,
      parentNursingCareJa: this.pageType == 'home' ? this.jaConfig.viewsJa.home.blocks.parent_nursing_care : this.jaConfig.viewsJa.quotations.result.parent_nursing_care,
    }
  },
  computed: {
    handleCloseTooltip () {
      return (tooltipInstance) => {
        const closeBtn = tooltipInstance.popper.querySelector('.btn-close-tooltip');
        closeBtn.addEventListener('click', () => {
          tooltipInstance.hide();
        });
      }
    },
  }
}
</script>
