<template>
  <images name="logo"></images>
</template>

<script>
import Images from '@/Shared/Images.vue';

export default {
  components: {
    Images,
  },
};
</script>
