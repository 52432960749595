import { VALIDATION_RULES } from '@/utils/constants';
import { helpers } from '@vuelidate/validators';

// validation rules with vuelidate helper
export const hiraganaKanjiKana = helpers.regex(VALIDATION_RULES.HIRAGANA_KANJI_KANA);
export const hiraganaKanjiOnly = helpers.regex(VALIDATION_RULES.HIRAGANA_KANJI_ONLY);
export const katakanaOnly = helpers.regex(VALIDATION_RULES.KATAKANA_ONLY);
export const phone = helpers.regex(VALIDATION_RULES.PHONE);
export const postalCode = helpers.regex(VALIDATION_RULES.POSTAL_CODE);

export const futureDate = (value) => {
  const today = new Date();
  const expireDate = new Date(value);
  expireDate.setFullYear(expireDate.getFullYear(), expireDate.getMonth() + 1, expireDate.getDate());
  return expireDate >= today;
}
