<template>
  <div class="w-full overflow-x-auto table-custom table-responsive">
    <table class="w-full table-auto">
      <thead>
        <tr>
          <th v-for="(header, index) in tableHeader" :key="index">
            <a v-if="!!hasSort" :href="header.url" :class="{'order-by': !!hasSort}" class="r:text-[14px] leading-[1.5] text-tw-navy-700 font-bold">{{ header.label }}</a>
            <span v-else class="r:text-[14px] leading-[1.5] text-tw-navy-700 font-bold">{{ header.label }}</span>
          </th>
          <th v-if="!!hasSlotEdit"></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="content in tableBody" :key="content.id">
          <template v-for="(prop, idx) of content" :key="idx">
            <td class="r:text-[14px] leading-[1.5]">{{ prop }}</td>
          </template>
          <td v-if="!!hasSlotEdit">
            <slot name="edit" />
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<style lang="scss" scoped>
.table-custom {
  th {
    @apply bg-tw-gray-200;
  }
  th,
  td {
    @apply border border-tw-gray-250 p-3;
    &:first-child {
      @apply border-l-0;
    }
    &:last-child {
      @apply border-r-0;
    }
  }
  td {
    @apply whitespace-nowrap;
  }
}
.order-by {
  @apply relative block pr-6;
  &:before,
  &:after {
    @apply absolute right-0 opacity-30;
    content: '';
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
  }
  &:before {
    top: calc(50% - 12px);
    border-bottom: 11px solid theme('colors.tw-navy.700');
  }
  &:after {
    bottom: calc(50% - 12px);
    border-top: 11px solid theme('colors.tw-navy.700');
  }
  &.order-asc,
  &.order-desc {
    border-top-color: theme('colors.tw-blue.600');
  }
  &.order-asc {
    &:before {
      opacity: 1;
    }
  }
  &.order-desc {
    &:after {
      opacity: 1;
    }
  }
}
</style>

<script>

export default {
  props: {
    tableHeader: {
      type: Array,
      required: true,
    },
    tableBody: {
      type: Array,
      required: true,
    },
    hasSlotEdit: {
      type: Boolean,
      required: false,
    },
    hasSort: {
      type: Boolean,
      required: false,
    },
  },
}
</script>
