<template>
  <div class="loading-wrap" :class="{ 'fixed-loading': isFixedLoading }" v-if="loading"></div>
</template>
<style lang="scss" scoped>

.loading-wrap {
  @apply absolute inset-0 bg-white opacity-70 z-[12];
  &.fixed-loading {
    position: fixed;
  }
  &:before {
    @apply absolute inset-0 w-10 h-10 m-auto r:border-[3px] rounded-full;
    content: '';
    animation: loader-rotation .8s infinite linear;
    border-color: rgba(114, 144, 182, 0.15);
    border-top-color: theme('colors.primary.600');
  }
}

@keyframes loader-rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>

<script>
export default {
  props: {
    loading: {
      type: Boolean,
      required: true
    },
    isFixedLoading: {
      type: Boolean,
      default: true,
    },
  }
};
</script>
