<!-- Source: https://github.com/adamwathan/vue-tailwind-examples -->

<template>
  <div
    class="fixed inset-0 overflow-y-auto modal"
    :class="showModal ? 'opacity-100 z-20' : 'opacity-0 z-[-1]'"
    @click="close"
  >
    <transition
      appear
      @before-leave="backdropLeaving = true"
      @after-leave="backdropLeaving = false"
    >
      <div v-if="showBackdrop">
        <div class="fixed inset-0 bg-black opacity-70" />
      </div>
    </transition>

    <transition
      appear
      @before-leave="cardLeaving = true"
      @after-leave="cardLeaving = false"
    >
      <div
        class="modal-dialog transition-all duration-[250ms] ease-in-out flex items-center h-full mx-auto"
        :class="[showContent ? 'opacity-100 scale-100' : 'opacity-0 scale-0', modalDialogCls ? modalDialogCls : ' py-6 sm:py-7 sm:r:max-w-[500px]']"
      >
        <div class="absolute inline-block w-0 h-screen align-middle" />

        <div
          class="flex flex-col w-full m-4 overflow-hidden bg-white rounded-2xl modal-content max-h-[calc(100%_-_32px)]"
          :class="modalContentCls"
          @click.stop
        >
          <template v-if="$slots.modalHeader">
            <slot name="modalHeader" />
          </template>
          <slot name="modalBody" />
          <template v-if="$slots.modalFooter">
            <slot name="modalFooter" />
          </template>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: null,
    },
    modalDialogCls: {
      type: String,
      default: '',
    },
    modalContentCls: {
      type: String,
      default: '',
    },
    hasHeader: {
      type: Boolean,
      default: false,
    },
    hasFooter: {
      type: Boolean,
      default: false,
    }
  },
  emits: ['close'],
  data() {
    return {
      showModal: false,
      showBackdrop: false,
      showContent: false,
      backdropLeaving: false,
      cardLeaving: false,
    };
  },
  computed: {
    leaving() {
      return this.backdropLeaving || this.cardLeaving;
    },
  },
  watch: {
    open: {
      handler: function (newValue) {
        if (newValue) {
          this.show();
        } else {
          this.close();
        }
      },
      immediate: true,
    },
    leaving(newValue) {
      if (newValue === false) {
        this.showModal = false;
        this.$emit('close');
      }
    },
  },
  created() {
    document.addEventListener('keydown', this.onEscape);
  },
  unmounted() {
    document.removeEventListener('keydown', this.onEscape);
  },
  methods: {
    show() {
      this.showModal = true;
      this.showBackdrop = true;
      this.showContent = true;
      document.body.style.setProperty('overflow', 'hidden');
    },
    close() {
      this.showBackdrop = false;
      this.showContent = false;
      document.body.style.removeProperty('overflow');
    },
    onEscape(e) {
      if (this.open && e.keyCode === 27) {
        this.close();
      }
    },
  },
};
</script>
