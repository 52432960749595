<template>
  <div class="contract-new bg-neutral-gray-50">
    <div class="fixed inset-0 z-10 bg-white" v-if="whiteBoard"></div>
    <div class="r:max-w-[672px] mx-auto pt-8 pb-6">
      <div
        v-if="
          pageStep == 1 &&
          (!!errors && !!errors.form_field &&
            (!!errors.form_field['female_male.contract'] ||
            ((!!errors.form_field['male.contract'] || !!errors.form_field['female.contract']) && !Object.keys(relatives).length)))"
        class="px-6"
      >
        <div class="px-4 py-3 border rounded-lg border-tw-red-200 bg-tw-red-50 xs:px-6 xs:py-4">
          <svg class="mb-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M10.8474 3.31557L1.5381 19.3293C1.02136 20.2181 1.66264 21.3327 2.6908 21.3327H21.3094C22.3376 21.3327 22.9788 20.2181 22.4621 19.3293L13.1528 3.31557C12.6387 2.43127 11.3615 2.43127 10.8474 3.31557ZM13.3337 12.666C13.3337 13.4024 12.7368 13.9994 12.0004 13.9994C11.264 13.9994 10.6671 13.4024 10.6671 12.666V8.66604C10.6671 7.92966 11.264 7.3327 12.0004 7.3327C12.7368 7.3327 13.3337 7.92966 13.3337 8.66604V12.666ZM10.3337 16.9994C10.3337 16.0789 11.0799 15.3327 12.0004 15.3327C12.9209 15.3327 13.6671 16.0789 13.6671 16.9994C13.6671 17.9198 12.9209 18.666 12.0004 18.666C11.0799 18.666 10.3337 17.9198 10.3337 16.9994Z" fill="#E33B24"/>
          </svg>
          <h4 v-if="errors.form_field['female_male.contract']" class="r:text-[14px] font-bold leading-[1.53] r:tracking-[0.25px] text-tw-red-600 mb-4">{{ viewsJa.contracts.blocks.customer_information.fields_note.parent.exist_contract_both }}</h4>
          <h4 v-else-if="errors.form_field['male.contract']" class="r:text-[14px] font-bold leading-[1.53] r:tracking-[0.25px] text-tw-red-600 mb-4">{{ viewsJa.contracts.blocks.customer_information.fields_note.parent.exist_contract.male.title }}</h4>
          <h4 v-else-if="errors.form_field['female.contract']" class="r:text-[14px] font-bold leading-[1.53] r:tracking-[0.25px] text-tw-red-600 mb-4">{{ viewsJa.contracts.blocks.customer_information.fields_note.parent.exist_contract.female.title }}</h4>
          <div class="text-center">
            <a :href="$routes.root()" id="back-top-from-step-1" class="inline-flex items-center justify-center rounded-full btn-gradient btn-primary-gradient-horizontal r:text-[14px] xs:r:text-[16px] py-2 px-2 xs:r:px-[18px] xs:py-4 font-medium text-white shadow-gray-1 leading-[1.33] r:min-h-[53px]">
              <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19" fill="none">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M12.1796 15.2335C11.7745 15.6089 11.1418 15.5848 10.7665 15.1796L5.50417 9.49999L10.7665 3.82035C11.1418 3.41523 11.7745 3.3911 12.1796 3.76645C12.5848 4.14181 12.6089 4.77451 12.2335 5.17964L8.23066 9.49999L12.2335 13.8204C12.6089 14.2255 12.5848 14.8582 12.1796 15.2335Z" fill="white"/>
              </svg>
              <span id="back-quotation-from-step-1-span" class="ml-2">{{ viewsJa.contracts.blocks.customer_information.btn.back_top }}</span>
            </a>
          </div>
        </div>
      </div>
      <template v-else>
        <contract-progress :currentStep="pageStep" class="px-6 mb-6" />
        <div class="px-6 mb-24 title-section">
          <span class="text-primary-600 r:text-[18px] leading-[1.53] r:tracking-[0.25px] mb-1">{{ viewsJa.contracts.new.page_state[pageStep].sub }}</span>
          <h1 class="r:text-[34px] font-black leading-[1.53] r:tracking-[0.25px]" v-html="viewsJa.contracts.new.page_state[pageStep].title"></h1>
          <div v-if="pageStep == 1 && !!errors && !!errors.form_field && (errors.form_field['male.contract'] !== undefined || errors.form_field['female.contract'] !== undefined)" class="mt-6">
            <div class="px-4 py-3 border rounded-lg border-tw-red-200 bg-tw-red-50 xs:px-6 xs:py-4">
              <svg class="mb-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M10.8474 3.31557L1.5381 19.3293C1.02136 20.2181 1.66264 21.3327 2.6908 21.3327H21.3094C22.3376 21.3327 22.9788 20.2181 22.4621 19.3293L13.1528 3.31557C12.6387 2.43127 11.3615 2.43127 10.8474 3.31557ZM13.3337 12.666C13.3337 13.4024 12.7368 13.9994 12.0004 13.9994C11.264 13.9994 10.6671 13.4024 10.6671 12.666V8.66604C10.6671 7.92966 11.264 7.3327 12.0004 7.3327C12.7368 7.3327 13.3337 7.92966 13.3337 8.66604V12.666ZM10.3337 16.9994C10.3337 16.0789 11.0799 15.3327 12.0004 15.3327C12.9209 15.3327 13.6671 16.0789 13.6671 16.9994C13.6671 17.9198 12.9209 18.666 12.0004 18.666C11.0799 18.666 10.3337 17.9198 10.3337 16.9994Z" fill="#E33B24"/>
              </svg>
              <template v-if="errors.form_field['male.contract'] !== undefined">
                <h4 class="r:text-[14px] font-bold leading-[1.53] r:tracking-[0.25px] text-tw-red-600 mb-1">{{ viewsJa.contracts.blocks.customer_information.fields_note.parent.exist_contract.male.title }}</h4>
                <p class="text-tw-red-600 r:text-[14px] leading-[1.53] r:tracking-[0.25px]">{{ viewsJa.contracts.blocks.customer_information.fields_note.parent.exist_contract.male.desc }}</p>
              </template>
              <template v-if="errors.form_field['female.contract'] !== undefined">
                <h4 class="r:text-[14px] font-bold leading-[1.53] r:tracking-[0.25px] text-tw-red-600 mb-1">{{ viewsJa.contracts.blocks.customer_information.fields_note.parent.exist_contract.female.title }}</h4>
                <p class="text-tw-red-600 r:text-[14px] leading-[1.53] r:tracking-[0.25px]">{{ viewsJa.contracts.blocks.customer_information.fields_note.parent.exist_contract.female.desc }}</p>
              </template>
            </div>
          </div>
          <div v-if="pageStep == 4 && !!errors && !!errors.messages" class="mt-6">
            <div class="px-4 py-3 border rounded-lg border-tw-red-200 bg-tw-red-50 xs:px-6 xs:py-4">
              <svg class="mb-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M10.8474 3.31557L1.5381 19.3293C1.02136 20.2181 1.66264 21.3327 2.6908 21.3327H21.3094C22.3376 21.3327 22.9788 20.2181 22.4621 19.3293L13.1528 3.31557C12.6387 2.43127 11.3615 2.43127 10.8474 3.31557ZM13.3337 12.666C13.3337 13.4024 12.7368 13.9994 12.0004 13.9994C11.264 13.9994 10.6671 13.4024 10.6671 12.666V8.66604C10.6671 7.92966 11.264 7.3327 12.0004 7.3327C12.7368 7.3327 13.3337 7.92966 13.3337 8.66604V12.666ZM10.3337 16.9994C10.3337 16.0789 11.0799 15.3327 12.0004 15.3327C12.9209 15.3327 13.6671 16.0789 13.6671 16.9994C13.6671 17.9198 12.9209 18.666 12.0004 18.666C11.0799 18.666 10.3337 17.9198 10.3337 16.9994Z" fill="#E33B24"/>
              </svg>
              <h4 class="r:text-[14px] font-bold leading-[1.53] r:tracking-[0.25px] text-tw-red-600 mb-1">{{ errors.messages }}</h4>
            </div>
          </div>
          <div v-if="!!invalidCard" class="mt-6">
            <div class="px-4 py-3 border rounded-lg border-tw-red-200 bg-tw-red-50 xs:px-6 xs:py-4">
              <svg class="mb-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M10.8474 3.31557L1.5381 19.3293C1.02136 20.2181 1.66264 21.3327 2.6908 21.3327H21.3094C22.3376 21.3327 22.9788 20.2181 22.4621 19.3293L13.1528 3.31557C12.6387 2.43127 11.3615 2.43127 10.8474 3.31557ZM13.3337 12.666C13.3337 13.4024 12.7368 13.9994 12.0004 13.9994C11.264 13.9994 10.6671 13.4024 10.6671 12.666V8.66604C10.6671 7.92966 11.264 7.3327 12.0004 7.3327C12.7368 7.3327 13.3337 7.92966 13.3337 8.66604V12.666ZM10.3337 16.9994C10.3337 16.0789 11.0799 15.3327 12.0004 15.3327C12.9209 15.3327 13.6671 16.0789 13.6671 16.9994C13.6671 17.9198 12.9209 18.666 12.0004 18.666C11.0799 18.666 10.3337 17.9198 10.3337 16.9994Z" fill="#E33B24"/>
              </svg>
              <h4 class="r:text-[14px] font-bold leading-[1.53] r:tracking-[0.25px] text-tw-red-600">{{ invalidCard }}</h4>
            </div>
          </div>
        </div>
        <customer-information v-if="pageStep == 1" :apiKey="apiKey" @set-step="getPageStep" :pageStep="pageStep" :relatives="relatives" :contractStartDay="contractStartDay" :isLogged="isLogged" :userInfo="user" :formErrors="errors" :quotation="quotation" :quotationData="quotationData"></customer-information>
        <explanation-information v-if="pageStep == 2" @set-step="getPageStep" :pageStep="pageStep"></explanation-information>
        <notification v-else-if="pageStep == 3" @set-step="getPageStep" :pageStep="pageStep" :quotationData="quotationData" :relatives="relatives" :userInfo="user"></notification>
        <confirmation v-else-if="pageStep == 4" :apiKey="apiKey" @set-step="getPageStep" :pageStep="pageStep" :isLogged="isLogged" :quotationData="quotationData" :contractStartDay="contractStartDay" :contractEndDay="contractEndDay" :relatives="relatives" :userInfo="user" :magazineFlag="magazineFlag" :isSentMail="isSentMail" :quotation="quotation" :formErrors="errors"></confirmation>
      </template>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.progress-pie {
  --percent: 1;
  @apply r:w-[55px] aspect-[1] relative inline-grid place-content-center;
  // aspect-ratio: 1;
  &:before,
  &:after {
    @apply absolute rounded-full;
    content: '';
  }
  &:before {
    inset: 0;
    background: radial-gradient(farthest-side, theme('colors.primary.500') 98%,#0000) top/3px 3px no-repeat,
                conic-gradient(theme('colors.primary.500') calc(var(--percent)*20%), #dfdfdf 0);
    -webkit-mask: radial-gradient(farthest-side,#0000 calc(99% - 3px), theme('colors.black') calc(100% - 3px));
    mask: radial-gradient(farthest-side,#0000 calc(99% - 3px), theme('colors.black') calc(100% - 3px));
  }
  &:after {
    inset: calc(50% - 55px/2);
    background: 3px;
    transform: rotate(calc(var(--percent)*72deg)) translateY(calc(50% - 55px/2));
  }
}
</style>
<script>
import { convertJaParams, findGetParameter } from '@/utils/utils';
import Layout from '@/Layouts/User.vue';
import CustomerInformation from '@/Pages/Contracts/Blocks/CustomerInformation.vue';
import ExplanationInformation from '@/Pages/Contracts/Blocks/ExplanationInformation.vue';
import Notification from '@/Pages/Contracts/Blocks/Notification.vue';
import Confirmation from '@/Pages/Contracts/Blocks/Confirmation.vue';
import ContractProgress from '@/Pages/Contracts/Components/ContractProgress.vue';
import { useHead } from '@unhead/vue'
import { metaData } from '@/utils/utils';

export default {
  components: {
    CustomerInformation,
    ExplanationInformation,
    Notification,
    Confirmation,
    ContractProgress,
  },
  layout: (h, page) => h(Layout, { footerType: 'simple', footerClass: 'bg-neutral-gray-50', mainClass: 'bg-neutral-gray-50' }, () => page),
  props: {
    apiKey: {
      type: String,
      required: true
    },
    relatives: {
      type: Array,
      required: true
    },
    user: {
      type: Object,
      required: true
    },
    isLogged: {
      type: Boolean,
      required: true
    },
    errors: {
      type: Object,
      required: false
    },
    contractStartDay: {
      type: String,
      required: false
    },
    contractEndDay: {
      type: String,
      required: false
    },
    quotationData: {
      type: Object,
      required: false
    },
    comeFromMe: {
      type: Boolean,
      required: false
    },
    magazineFlag: {
      type: Boolean,
      required: false
    },
    isSentMail: {
      type: Boolean,
      required: false
    },
    quotation: {
      type: Object,
      required: false
    },
    hasContractForEdit: {
      type: Boolean,
      required: false
    },
    isFromPayment: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  data () {
    return {
      convertJaParams: convertJaParams,
      modelsJa: this.jaConfig.modelsJa,
      viewsJa: this.jaConfig.viewsJa,
      pageStep: !localStorage.getItem('userFormStep') ? 1 : parseInt(localStorage.getItem('userFormStep')),
      invalidCard: localStorage.getItem('invalidCard') || null,
      whiteBoard: true,
    }
  },
  setup(props) {
    useHead(metaData('contracts_new'));

    if (props.isFromPayment) {
      localStorage.setItem('isFromPayment', true);
    }

    // pre-check login state to clear session storage in form page
    if (props.isLogged) {
      localStorage.setItem('trackLoggedIn', true);
    } else if (!props.isLogged && JSON.parse(localStorage.getItem('trackLoggedIn'))) {
      localStorage.clear();
    }
  },
  created() {
    const relativeStored = JSON.parse(localStorage.getItem('userForm'));

    if (!!relativeStored && Object.keys(this.quotationData).length !== Object.keys(relativeStored.user.relatives_attributes).length) {
      this.pageStep = 1;
      localStorage.setItem('userFormStep', 1);
      localStorage.setItem('notFirstLoadForm', false);
      localStorage.setItem('quotationChange', true);
    } else if (findGetParameter('utm_source') == 'email' && this.hasContractForEdit) {
      // check link from email
      this.pageStep = 4;
      localStorage.setItem('userFormStep', 4);
      localStorage.setItem('notFirstLoadForm', true);
      localStorage.setItem('isFromMail', true);
    }

    if (!!this.errors) {
      this.pageStep = this.errors.step || 1;
      localStorage.setItem('userFormStep', this.pageStep);

      if (!!this.errors.messages) {
      } else if (!!this.errors.form_field && Object.keys(this.errors.form_field).length) {
        localStorage.setItem('formErrors', true);
      }
    }
    this.whiteBoard = false;
    localStorage.removeItem('invalidCard');
  },
  mounted() {
    const delay = 700;
    // set reload at top
    setTimeout(() => {
      window.scrollTo(0,0);
    }, delay);
  },
  methods: {
    getPageStep(step) {
      this.pageStep = step;
      window.scrollTo(0,0);
    },
  }
}
</script>
